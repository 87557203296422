import React, { useCallback, useContext, useState, useEffect } from "react";
import { Frequency } from "tone";
import { debounce, throttle } from "lodash";
import classNames from "classnames";
import { generatePath, RouteComponentProps } from "react-router";
import { useParams, useHistory } from "react-router-dom";

import { FrequencyBand } from "./FrequencyBand";
import * as audio from "./main/audio";
import { MIN_NOTE, MAX_NOTE } from "./constants";
import { SVGCanvas } from "./SVGCanvas";

import { midiToFreq, TString, TuningSystem } from "./main/core";
import { MIDIOutput } from "./main/MIDIOutput";
import { MIDIContext } from "./MIDIContext";
import {
  parseRefPitch,
  formatRefPitch,
  formatStrings,
} from "./urlSerialization";
import { RefPitchInput } from "./RefPitchInput";
import * as api from "./main/api";
import { Breadcrumbs } from "./Breadcrumbs";
import { UserInfo } from "./UserInfo";

import "./RefPitchScreen.scss";
import { LeimmaHelmet } from "./LeimmaHelmet";
import { UserGuideLink } from "./UserGuideLink";

const EMPTY_STRINGS: TString[] = [
  {
    ratioUpper: 1,
    ratioLower: 1,
    pitchClasses: [
      { ratioUpper: 1, ratioLower: 1, name8ve1: "", name8ve2: "" },
    ],
  },
];
interface RefPitchScreenParams {
  tuningSystemId: string;
  refPitch: string;
}
export const RefPitchScreen: React.FC<
  RouteComponentProps<RefPitchScreenParams>
> = ({ match }) => {
  let path = match?.path;
  let { tuningSystemId, refPitch } = useParams<RefPitchScreenParams>();
  let [tuningSystem, setTuningSystem] = useState<TuningSystem | null>();
  let [hasEntered, setHasEntered] = useState(false);
  let [isExiting, setIsExiting] = useState(false);

  let { semitones, note } = parseRefPitch(refPitch);
  let history = useHistory();
  let {
    outputs: { leimma: output },
  } = useContext(MIDIContext);

  useEffect(() => {
    if (tuningSystemId !== "new") {
      api.loadTuningSystem(+tuningSystemId).then(setTuningSystem);
    } else {
      setTuningSystem(null);
    }
  }, [tuningSystemId]);

  let setRefPitch = useCallback(
    throttle(
      (newRefPitchSt: number, newNote?: string) => {
        history.replace(
          generatePath(path, {
            tuningSystemId,
            refPitch: formatRefPitch(newRefPitchSt, newNote),
          })
        );
      },
      100,
      { leading: true, trailing: true }
    ),
    [history, path, tuningSystemId]
  );

  let stopSound = useCallback(
    debounce(() => {
      audio.noteOff(-1, output);
    }, 500),
    [output]
  );

  let playSound = useCallback(() => {
    audio.noteOn(-1, midiToFreq(semitones), 1, null, output, false);
    stopSound();
  }, [semitones, output, stopSound]);
  let onSetFreq = useCallback(
    (st: number, refNote = note) => {
      let semitones = Math.max(MIN_NOTE, Math.min(MAX_NOTE, st));
      setRefPitch(semitones, refNote);
      let freq = Frequency(semitones, "midi").toFrequency();
      playSound();
      audio.setFrequency(-1, freq, null, output);
      stopSound();
    },
    [output, playSound, stopSound, note, setRefPitch]
  );

  let onEntered = useCallback(() => {
    setHasEntered(true);
  }, []);

  let onNextAction = useCallback(() => {
    setIsExiting(true);
    return new Promise<void>((res) => setTimeout(res, 500));
  }, []);

  return (
    <div
      className={classNames("refPitchScreen", "screen", {
        hasEntered,
        isExiting,
      })}
    >
      <LeimmaHelmet />
      <div className="refPitchScreen--htmlOverlay">
        <h1>Reference Pitch</h1>
        <p className="introText"></p>
        <div className="inputWrapper">
          <svg
            className="inputWrapperArrow"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 16"
          >
            <polygon points="32 16 16 0 0 16 32 16" />
          </svg>
          <RefPitchInput
            semitones={semitones}
            note={note}
            onUpdateSemitones={(st) => setRefPitch(st, note)}
            onUpdateNote={(note) => setRefPitch(Frequency(note).toMidi(), note)}
          />
        </div>
        <Breadcrumbs
          currentStep={1}
          currentDescription={`
          To hear and play a tuning system, a reference pitch must be chosen.
          Click a note name to select. Drag the slider or type a Hz value to
          adjust. Common values are A4 = 440 Hz or C4 = 261.626 Hz`}
          nextActionUrl={`/leimma/${tuningSystemId}/refpitch/${formatRefPitch(
            semitones,
            note
          )}/tuningsystem/${formatStrings(
            tuningSystem ? tuningSystem.strings : EMPTY_STRINGS
          )}`}
          nextActionEnabled
          onNextAction={onNextAction}
        />
        <MIDIOutput productName="Leimma" showInternalOptions fixed withLabel />
      </div>
      <SVGCanvas className="refPitchScreen--canvas">
        <FrequencyBand
          value={semitones}
          note={note}
          min={MIN_NOTE}
          max={MAX_NOTE}
          entry="refPitch"
          onChange={onSetFreq}
          onStartInteraction={playSound}
          onEndInteraction={stopSound}
          onEntered={onEntered}
        />
      </SVGCanvas>
      <UserInfo />
      <UserGuideLink />
    </div>
  );
};
