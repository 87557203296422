import {
  getTransport,
  TransportTime,
  context,
  dbToGain,
  getDraw,
  Part,
  Ticks,
  immediate,
  TicksClass,
} from "tone";
import {
  isNumber,
  memoize,
  startCase,
  findIndex,
  range,
  transform,
  debounce,
  reduce,
  isUndefined,
  random,
  isNull,
  findLast,
  max,
  partition,
  isEqual,
  flatten,
  intersection,
  difference,
} from "lodash";
import {
  getMonotonicallyIncreasingScale,
  getRefPitchOctave,
  MIDIOutput,
  midiToFreq,
  mod,
  Scale,
  TuningSystem,
  Weights,
} from "../main/core";
import { Rnd } from "react-rnd";
import EventEmitter from "events";
import { getClosestMIDINote, getMIDIPitchBend } from "../main/audio";
import { Output } from "webmidi";
import { Simple1DNoise } from "./noise";
import * as lfos from "./lfos";
import {
  BeatDivision,
  DEXEDState,
  InstrumentBanks,
  OBXDState,
  ReturnTrackSettings,
  TimeSignatureDenominator,
  TrackControls,
  TrackInstrument,
  TransportControls,
  YoshimiState,
} from "./types";
import { LocalApotomePlayer } from "./LocalApotomePlayer";
import {
  BEAT_DIVISION_BEAT_VALUES,
  BEAT_DIVISION_TUPLETS,
  DEFAULT_RETURN_TRACK_SETTINGS,
  DEFAULT_TIME_SIGNATURE_DENOMINATOR,
  DEFAULT_TIME_SIGNATURE_NUMERATOR,
  MIN_VOLUME_DB,
  SYNCED_ECHO_OPTIONS,
  MIN_BASE_VELOCITY,
  MAX_BASE_VELOCITY,
  ACCENT_VELOCITY_DELTA,
  DEFAULT_TEMPO,
} from "./constants";
import { CompositeApotomePlayer } from "./CompositeApotomePlayer";
import { ApotomePlayer } from "./ApotomePlayer";
import {
  MIDI_CHANNEL_MPE_ROUND_ROBIN_MAX,
  MIDI_CHANNEL_MPE_ROUND_ROBIN_MIN,
  MIDI_CHANNEL_ROUND_ROBIN_MAX,
  MIDI_CHANNEL_ROUND_ROBIN_MIN,
} from "../constants";

type PlayableScaleDegree = {
  role: "Tonic" | "Primary" | "Secondary" | "None" | "Rest";
  index: number;
  cents: number;
  stringIndex: number;
  pitchClassIndex: number;
};

type PlayedNote = {
  type: "note";
  beatDivision: BeatDivision;
  chosenScaleWeight: string;
  scaleDegree: PlayableScaleDegree;
  interval: number | "8ve" | null;
  octave: number;
  freq: number;
  velocity: number;
  time: number;
  ticks: number;
  delay: number;
  duration: number;
  stringIndex: number;
  pitchClassIndex: number;
};
type PlayedRest = {
  type: "rest";
  beatDivision: BeatDivision;
  time: number;
  ticks: number;
  duration: number;
};
type PlayedEvent = PlayedNote | PlayedRest;

export type TrackState = {
  hasBasicSynth: boolean;
  hasString: boolean;
  hasObxd: boolean;
  lastObxdState?: OBXDState;
  hasDexed: boolean;
  lastDexedState?: DEXEDState;
  hasYoshimi: boolean;
  lastYoshimiState?: YoshimiState;
  controls: TrackControls;
  eventHistory: PlayedEvent[];
  queuedUpTupletRepeats: BeatDivision[];
  euclideanIndex: number;
  velocityNoiseSeed: number;
  articulationSeed: number;
  noteDelaySeed: number;
  startedOnTick?: number;
  nextSchedule?: number;
  currentLoopPart?: Part;
  currentLoopLength?: number;
  currentLoopStartedOnTick?: number;
  lastPan: number;
  lastGain: number;
  lastSend1Gain: number;
  lastSend2Gain: number;
  lastTuningSystem?: TuningSystem;
  lastScale?: Scale;
  lfoStates: LFOState[];
  events: EventEmitter;
};

export type LFOState = {
  triggeredOnTick: number | null;
  lastTargets: { [instr in TrackInstrument]: number };
  pendingRetriggerTimes: number[];
  randomValue?: number;
  nextRandomValueAtTick?: number;
};

let player = new CompositeApotomePlayer();

export function addPlayer(aPlayer: ApotomePlayer) {
  player.addPlayer(aPlayer);
}
export function removePlayer(aPlayer: ApotomePlayer) {
  player.removePlayer(aPlayer);
}

let noise = Simple1DNoise();

export let playbackEvents = new EventEmitter();

let tracks = new Map<string, TrackState>();
let transportControls: TransportControls = {
  tempo: DEFAULT_TEMPO,
  timeSignatureNumerator: DEFAULT_TIME_SIGNATURE_NUMERATOR,
  timeSignatureDenominator: DEFAULT_TIME_SIGNATURE_DENOMINATOR,
};
let returnTrackSettings = DEFAULT_RETURN_TRACK_SETTINGS;

let pluginGUIContainer: Rnd;

let midiOutputs: { [trackId: string]: MIDIOutput } = {};
let midiChannelRoundRobins = new Map<string, number>();
let midiClockOutputs: { output: Output; started: boolean }[] = [];
let midiClockTickSchedule: number | null = null;

let getTrackScaleDegrees = memoize(
  (controls: TrackControls) => {
    if (controls.scale) {
      let scale = getMonotonicallyIncreasingScale(
        controls.scale.scaleDegrees,
        controls.tuningSystem!.strings
      ).map((sd) => ({
        role: sd.role ? startCase(sd.role) : "None",
        cents: sd.cents,
        stringIndex: sd.stringIndex,
        pitchClassIndex: sd.pitchClassIndex,
      }));
      let tonicIndex = findIndex(scale, (s) => s.role === "Tonic");
      return [
        ...scale.slice(tonicIndex),
        ...scale
          .slice(0, tonicIndex)
          .map((sd) => ({ ...sd, cents: sd.cents + 1200 })),
      ].map((deg, index) => ({ ...deg, index })) as PlayableScaleDegree[];
    } else {
      return [];
    }
  },
  (controls: TrackControls) =>
    `${controls.tuningSystem?.id}-${controls.scale?.id}`
);

export let getTimeSignatureTicks = memoize(
  (numerator: string, denominator: TimeSignatureDenominator, ppq: number) => {
    let compounds = numerator.split(/[^\d]/).map((c) => +c);
    let totalBeats = compounds.reduce((sum, c) => sum + c, 0);
    let denominatorQuarters = 4 / denominator;
    let denominatorTicks = denominatorQuarters * ppq;
    let totalTicks = totalBeats * denominatorTicks;
    let accentedTicks = new Set<number>();
    let tick = 0;
    for (let c of compounds) {
      accentedTicks.add(tick);
      tick += c * denominatorTicks;
    }
    return { totalTicks, accentedTicks };
  },
  (n: string, d: number, ppq: number) => `${n}/${d}@${ppq}`
);

function chooseNote(
  controls: TrackControls,
  allowRest: boolean,
  concurrentNotesOnOtherTracks: { note: PlayedNote; forcePolyphony: boolean }[],
  lastNoteOnThisTrack?: PlayedNote
): {
  key: string;
  value?: {
    scaleDegree: PlayableScaleDegree;
    octave: number;
    interval: number | "8ve" | null;
  };
} {
  let scaleDegrees = getTrackScaleDegrees(controls);
  if (lastNoteOnThisTrack) {
    return chooseNoteBasedOnLastNote(
      controls,
      scaleDegrees,
      allowRest,
      concurrentNotesOnOtherTracks,
      lastNoteOnThisTrack
    );
  } else {
    return chooseNoteWithNewStartingPoint(
      controls,
      scaleDegrees,
      allowRest,
      concurrentNotesOnOtherTracks
    );
  }
}

function chooseNoteBasedOnLastNote(
  controls: TrackControls,
  scaleDegrees: PlayableScaleDegree[],
  allowRest: boolean,
  concurrentNotesOnOtherTracks: { note: PlayedNote; forcePolyphony: boolean }[],
  lastNote: PlayedNote
) {
  let options: {
    scaleDegree: PlayableScaleDegree;
    octave: number;
    interval: number | "8ve";
  }[] = [];
  for (let { interval, allowed } of controls.allowedIntervals) {
    if (!allowed) continue;
    if (interval === "8ve") {
      options.push({
        scaleDegree: lastNote.scaleDegree,
        octave: lastNote.octave + 1,
        interval,
      });
      options.push({
        scaleDegree: lastNote.scaleDegree,
        octave: lastNote.octave - 1,
        interval,
      });
    } else {
      options.push({
        scaleDegree:
          scaleDegrees[
            mod(lastNote.scaleDegree.index + interval, scaleDegrees.length)
          ],
        octave:
          lastNote.octave +
          (lastNote.scaleDegree.index + interval >= scaleDegrees.length
            ? 1
            : 0),
        interval,
      });
      options.push({
        scaleDegree:
          scaleDegrees[
            mod(lastNote.scaleDegree.index - interval, scaleDegrees.length)
          ],
        octave:
          lastNote.octave - (lastNote.scaleDegree.index - interval < 0 ? 1 : 0),
        interval,
      });
    }
  }

  // Pick the weights to use
  let givenWeights =
    controls.activeScaleWeights === "role"
      ? controls.roleWeights
      : controls.scaleDegreeWeights;
  // See how non-rests and rests were weighted, respectively
  let totalGivenNonRestWeight = reduce(
    givenWeights,
    (total, weight, key) => (key === "Rest" ? total : total + weight),
    0
  );
  let givenRestWeight = givenWeights.Rest;
  // Form the actual weights to choose from, given the possible intervals we could move
  let actualWeights = transform(
    options,
    (weights, option, index) =>
      (weights[`${index}`] =
        (getScaleDegreeWeight(option.scaleDegree, controls) ?? 0) *
        (controls.octaveWeights[option.octave] ?? 0)),
    {} as Weights
  );
  // If rests are allowed, scale the rest weight accordingly so it keeps the same relative probability as was given
  if (allowRest) {
    let totalActualNonRestWeights = Object.values(actualWeights).reduce(
      (total, weight) => total + weight,
      0
    );
    let adjustedRestWeight =
      (givenRestWeight / totalGivenNonRestWeight) * totalActualNonRestWeights;
    actualWeights.Rest = adjustedRestWeight;
  }
  // If polyphony is forced, eliminate any options that are currently already playing
  for (let i = 0; i < options.length; i++) {
    let option = options[i];
    let midiNote = getClosestMIDINote(
      calculateNoteFreq(controls, option),
      Number.MAX_VALUE,
      null
    );
    for (let other of concurrentNotesOnOtherTracks) {
      let oMidi = getClosestMIDINote(other.note.freq, Number.MAX_VALUE, null);
      if (
        midiNote === oMidi &&
        (controls.forcePolyphony || other.forcePolyphony)
      ) {
        actualWeights[i] = 0;
        break;
      }
    }
  }

  // If any scale degree weights are nonzero, but we can't get to them with our current intervals, consider the walk "stuck" and start from a new position
  let anyScaleDegreeAllowed = false,
    allAllowedScaleDegreesStuck = true;
  for (let i = 0; i < options.length; i++) {
    let allowed = getScaleDegreeWeight(options[i].scaleDegree, controls) > 0;
    anyScaleDegreeAllowed = anyScaleDegreeAllowed || allowed;
    if (allowed) {
      allAllowedScaleDegreesStuck =
        allAllowedScaleDegreesStuck && actualWeights[i] === 0;
    }
  }
  if (anyScaleDegreeAllowed && allAllowedScaleDegreesStuck) {
    return chooseNoteWithNewStartingPoint(
      controls,
      scaleDegrees,
      allowRest,
      concurrentNotesOnOtherTracks
    );
  }

  let selectionOptions = range(options.length).map((i) => `${i}`);
  if (allowRest) {
    selectionOptions = selectionOptions.concat(["Rest"]);
  }
  let selection = weightedRandom(selectionOptions, actualWeights);
  if (selection === "Rest") {
    return { key: "Rest" };
  } else if (!isUndefined(selection)) {
    return {
      key: getScaleDegreeWeightKey(options[+selection].scaleDegree, controls),
      value: options[+selection],
    };
  }
  return { key: "Rest" };
}

function getScaleDegreeWeight(
  scaleDegree: PlayableScaleDegree,
  controls: TrackControls
) {
  if (controls.activeScaleWeights === "role") {
    return controls.roleWeights[scaleDegree.role];
  } else {
    return controls.scaleDegreeWeights[`${scaleDegree.index + 1}`];
  }
}

function getScaleDegreeWeightKey(
  scaleDegree: PlayableScaleDegree,
  controls: TrackControls
) {
  if (controls.activeScaleWeights === "role") {
    return scaleDegree.role;
  } else {
    return `${scaleDegree.index + 1}`;
  }
}

function chooseNoteWithNewStartingPoint(
  controls: TrackControls,
  scaleDegrees: PlayableScaleDegree[],
  allowRest: boolean,
  concurrentNotesOnOtherTracks: { note: PlayedNote; forcePolyphony: boolean }[]
) {
  if (allowRest) {
    let weights =
      controls.activeScaleWeights === "role"
        ? controls.roleWeights
        : controls.scaleDegreeWeights;
    let chooseRest = weightedRandom(Object.keys(weights), weights) === "Rest";
    if (chooseRest) {
      return { key: "Rest" };
    }
  }

  let options: {
    key: string;
    value: { scaleDegree: PlayableScaleDegree; octave: number; interval: null };
  }[] = [];
  let weights: Weights = {};
  let availableOctaves = Object.keys(controls.octaveWeights).filter(
    (o) => controls.octaveWeights[o] > 0
  );
  for (let scaleDegree of scaleDegrees) {
    let baseSDWeight =
      controls.activeScaleWeights === "role"
        ? controls.roleWeights[scaleDegree.role]
        : controls.scaleDegreeWeights[`${scaleDegree.index + 1}`];
    let key =
      controls.activeScaleWeights === "role"
        ? scaleDegree.role
        : "" + scaleDegree.index;
    for (let octave of availableOctaves) {
      let octaveWeight = controls.octaveWeights[octave];
      let weight = baseSDWeight * octaveWeight;
      let midiNote = getClosestMIDINote(
        calculateNoteFreq(controls, { scaleDegree, octave: +octave }),
        Number.MAX_VALUE,
        null
      );
      for (let other of concurrentNotesOnOtherTracks) {
        let otherMidiNote = getClosestMIDINote(
          other.note.freq,
          Number.MAX_VALUE,
          null
        );
        if (
          midiNote === otherMidiNote &&
          (controls.forcePolyphony || other.forcePolyphony)
        ) {
          weight = 0;
          break;
        }
      }

      weights[options.length] = weight;
      options.push({
        key,
        value: { scaleDegree, octave: +octave, interval: null },
      });
    }
  }

  let chosen = weightedRandom(
    range(options.length).map((i) => `${i}`),
    weights
  );

  if (chosen) {
    return options[+chosen];
  } else {
    return { key: "Rest" };
  }
}

function chooseBeatDivision(track: TrackState): {
  division?: BeatDivision;
  count: number;
  forceRest?: boolean;
} {
  let controls = track.controls;
  if (controls.beatDivisionType === "weights") {
    return {
      division: weightedRandom(
        Object.keys(controls.beatDivisionWeights),
        controls.beatDivisionWeights
      ) as BeatDivision | undefined,
      count: 1,
    };
  } else {
    let pattern = euclideanPattern(
      controls.beatDivisionEuclideanK,
      controls.beatDivisionEuclideanN
    );
    let index = track.euclideanIndex;
    if (pattern[index % pattern.length] > 0) {
      let count = 1;
      index++;
      while (pattern[index % pattern.length] <= 0 && count < pattern.length) {
        count++;
        index++;
      }
      track.events.emit("euclideanStep", track.euclideanIndex % pattern.length);
      track.euclideanIndex = index;
      return { division: controls.beatDivisionEuclideanBeatValue, count };
    } else {
      let count = 0;
      while (pattern[index % pattern.length] <= 0 && count < pattern.length) {
        count++;
        index++;
      }
      track.euclideanIndex = index;
      track.events.emit("euclideanStep", index % pattern.length);
      return {
        division: controls.beatDivisionEuclideanBeatValue,
        count,
        forceRest: true,
      };
    }
  }
}

function weightedRandom(options: string[], weights: Weights) {
  let totalWeight = options.reduce((sum, r) => sum + weights[r], 0);
  if (totalWeight === 0) return undefined;

  let rnd = Math.random() * totalWeight;
  let sum = 0;
  for (let opt of options) {
    sum += weights[opt];
    if (rnd <= sum) {
      return opt;
    }
  }
}

export let euclideanPattern = memoize(
  (k: number, n: number) => {
    let seq: number[][] = [];
    for (let i = 0; i < k; i++) {
      seq.push([1]);
    }
    for (let i = 0; i < n - k; i++) {
      seq.push([0]);
    }
    while (true) {
      let [head, remainder] = partition(seq, (i) => isEqual(i, seq[0]));
      if (remainder.length < 2) break;
      for (let i = 0; i < Math.min(head.length, remainder.length); i++) {
        seq[i] = seq[i].concat(seq.pop()!);
      }
    }
    return flatten(seq);
  },
  (k: number, n: number) => `${k}-${n}`
);

export function nextTrackId() {
  let mx = max(Array.from(tracks.keys()).map((i) => +i)) ?? 0;
  return `${mx + 1}`;
}

export async function init(
  latencyHint?: number,
  lookAhead?: number
): Promise<{ banks: InstrumentBanks; isSupported: boolean }> {
  let banks = await LocalApotomePlayer.init(latencyHint, lookAhead);
  getTransport().bpm.value = DEFAULT_TEMPO;
  let localPlayer = new LocalApotomePlayer();
  player.addPlayer(localPlayer);
  startLoopers();
  startLFOTicks();
  return { banks, isSupported: localPlayer.isSupported() };
}

export async function start() {
  if (getTransport().state === "started") return;
  for (let [id, track] of Array.from(tracks.entries())) {
    if (track.controls.started) {
      launchTrackSteps(track, id);
    }
  }
  await player.start();
  getTransport().start();
  playbackEvents.emit("start");
}

export async function stop(effectiveAtTicks?: number) {
  if (getTransport().state === "stopped") return;
  let doStop = () => {
    getTransport().stop();
    for (let track of Array.from(tracks.values())) {
      if (isNumber(track.nextSchedule)) {
        getTransport().clear(track.nextSchedule);
        track.nextSchedule = undefined;
        track.startedOnTick = undefined;
      }
    }
    let oneClockTickS = getTransport().toSeconds(`${getTransport().PPQ / 24}i`);
    let lookaheadS = context.lookAhead;
    let stopDelayS = lookaheadS * 2 + oneClockTickS;
    for (let clockOut of midiClockOutputs) {
      clockOut.output.sendStop({ time: `+${stopDelayS * 1000}` });
      clockOut.started = false;
    }
    playbackEvents.emit("stop");
  };
  if (isNumber(effectiveAtTicks)) {
    getTransport().scheduleOnce(doStop, `${effectiveAtTicks}i`);
  } else {
    doStop();
  }
}

export function getTransportControls() {
  return transportControls;
}
export function setTransportControls(newTransportControls: TransportControls) {
  if (transportControls.tempo !== newTransportControls.tempo) {
    getTransport().bpm.value = newTransportControls.tempo;
    if (returnTrackSettings.echoTempoSync) {
      player.setEchoDelayTimes(
        getTransport().toSeconds(
          SYNCED_ECHO_OPTIONS[returnTrackSettings.echoDelayLeftSynced]
        ),
        getTransport().toSeconds(
          SYNCED_ECHO_OPTIONS[returnTrackSettings.echoDelayRightSynced]
        ),
        immediate()
      );
    }
  }
  transportControls = newTransportControls;
}

export function setMasterVolume(newMasterVolume: number, rampTime = 0.03) {
  player.setMasterVolume(newMasterVolume, rampTime, immediate());
  playbackEvents.emit("masterVolumeChange", newMasterVolume);
}

export function resync() {
  for (let [id, track] of Array.from(tracks.entries())) {
    if (track.controls.started) {
      if (isNumber(track.nextSchedule)) {
        getTransport().clear(track.nextSchedule);
      }
      launchTrackSteps(track, id);
    }
  }
}

export function sendMidiPanic() {
  for (let midiOutput of Object.values(midiOutputs)) {
    if (midiOutput.output) {
      midiOutput.output.sendChannelMode("allnotesoff");
      midiOutput.output.sendChannelMode("resetallcontrollers");
    }
  }
  player.panic(immediate());
}

export let updateReturnTrackSettings = debounce(
  (newSettings: ReturnTrackSettings) => {
    returnTrackSettings = newSettings;
    player.setReverbSettings(
      newSettings.reverbDecay,
      newSettings.reverbPreDelay
    );
    player.setEchoFeedback(newSettings.echoFeedback, immediate());
    if (newSettings.echoTempoSync) {
      player.setEchoDelayTimes(
        getTransport().toSeconds(
          SYNCED_ECHO_OPTIONS[newSettings.echoDelayLeftSynced]
        ),
        getTransport().toSeconds(
          SYNCED_ECHO_OPTIONS[newSettings.echoDelayRightSynced]
        ),
        immediate()
      );
    } else {
      player.setEchoDelayTimes(
        newSettings.echoDelayLeftFree / 1000,
        newSettings.echoDelayRightFree / 1000,
        immediate()
      );
    }
  },
  200
);

export function setTrack(
  id: string,
  controls: TrackControls,
  launchTime?: number,
  launchTicks?: number
) {
  let track = tracks.get(id);
  if (!track) {
    track = {
      controls,
      hasBasicSynth: false,
      hasString: false,
      hasObxd: false,
      hasDexed: false,
      hasYoshimi: false,
      eventHistory: [],
      queuedUpTupletRepeats: [],
      euclideanIndex: 0,
      events: new EventEmitter(),
      lastPan: 0,
      lastGain: 1,
      lastSend1Gain: 1,
      lastSend2Gain: 1,
      velocityNoiseSeed: random(1000),
      articulationSeed: random(1000),
      noteDelaySeed: random(1000),
      lfoStates: controls.lfos.map(() => ({
        triggeredOnTick: null,
        pendingRetriggerTimes: [],
        lastTargets: {
          basicSynth: -1,
          obxd: -1,
          dexed: -1,
          midi: -1,
          string: -1,
          yoshimi: -1,
        },
      })),
    };
    track.events.setMaxListeners(100);
    tracks.set(id, track);
    player.addTrack(id);
  }

  if (track.controls.scale !== controls.scale) {
    track.eventHistory.length = 0;
  }
  track.controls = controls;

  if (controls.instrument === "basicSynth") {
    if (!track.hasBasicSynth) {
      player.initTrackBasicSynth(
        id,
        controls.tone,
        controls.amplitudeEnvelope,
        controls.filterFrequency,
        controls.filterResonance
      );
      track.hasBasicSynth = true;
    }
    player.setTrackBasicSynthToneControls(
      id,
      controls.tone,
      controls.amplitudeEnvelope
    );
    player.setTrackBasicSynthFilterFrequency(
      id,
      controls.filterFrequency,
      immediate()
    );
    player.setTrackBasicSynthFilterQ(id, controls.filterResonance, immediate());
  } else {
    if (track.hasBasicSynth) {
      player.disposeTrackBasicSynth(id, immediate());
      track.hasBasicSynth = false;
    }
  }

  if (controls.instrument === "string") {
    if (!track.hasString) {
      player.initTrackString(id);
      track.hasString = true;
    }
  } else {
    if (track.hasString) {
      player.disposeTrackString(id, immediate());
      track.hasString = false;
    }
  }

  if (controls.instrument === "obxd") {
    if (!track.hasObxd) {
      let onLocalParamChange = (
        param: number,
        value: number,
        emitEvent: boolean
      ) => {
        player.setOBXDParam(id, param, value);
        if (emitEvent) {
          track?.events.emit("controlsChange", tracks.get(id)?.controls);
        }
      };
      player.initTrackOBXD(id, onLocalParamChange);
      track.hasObxd = true;
    }
    if (controls.obState !== track.lastObxdState) {
      player.setOBXDState(
        id,
        controls.obState.bank,
        controls.obState.preset,
        controls.obState.patchState
      );
      track.lastObxdState = controls.obState;
    }
    if (controls.pluginGUIOpen) {
      player.openOBXDGUI(id, pluginGUIContainer);
    } else {
      player.closeOBXDGUI(id);
    }
  } else {
    if (track.hasObxd) {
      player.disposeTrackOBXD(id, immediate());
      track.hasObxd = false;
      track.lastObxdState = undefined;
    }
  }

  if (controls.instrument === "dexed") {
    if (!track.hasDexed) {
      let onLocalParamChange = (
        param: number,
        value: number,
        newPackedPatch: number[]
      ) => {
        player.setDEXEDParam(id, param, value, newPackedPatch);
      };
      player.initTrackDEXED(id, onLocalParamChange);
      track.hasDexed = true;
    }
    if (controls.dxState !== track.lastDexedState) {
      player.setDEXEDState(
        id,
        controls.dxState.bank,
        controls.dxState.preset,
        controls.dxState.patchState
      );
      track.lastDexedState = controls.dxState;
    }
    if (controls.pluginGUIOpen) {
      player.openDEXEDGUI(id, pluginGUIContainer);
    } else {
      player.closeDEXEDGUI(id);
    }
  } else {
    if (track.hasDexed) {
      player.disposeTrackDEXED(id, immediate());
      track.hasDexed = false;
      track.lastDexedState = undefined;
    }
  }

  if (controls.instrument === "yoshimi") {
    if (!track.hasYoshimi) {
      player.initTrackYoshimi(id);
      track.hasYoshimi = true;
    }
    if (controls.yoshimiState !== track.lastYoshimiState) {
      player.setYoshimiState(
        id,
        controls.yoshimiState.bank,
        controls.yoshimiState.preset
      );
      track.lastYoshimiState = controls.yoshimiState;
    }
  } else {
    if (track.hasYoshimi) {
      player.disposeTrackYoshimi(id, immediate());
      track.hasYoshimi = false;
      track.lastYoshimiState = undefined;
    }
  }

  if (track.lastPan !== track.controls.pan) {
    player.setTrackPan(id, track.controls.pan, immediate());
    track.lastPan = track.controls.pan;
  }

  let isMuted = track.controls.muted || track.controls.soloStatus === "other";
  let newGain =
    isMuted || track.controls.volume === MIN_VOLUME_DB
      ? 0
      : dbToGain(track.controls.volume);
  if (track.lastGain !== newGain) {
    player.setTrackGain(id, newGain, immediate());
    track.lastGain = newGain;
  }
  if (track.lastSend1Gain !== track.controls.send1Gain) {
    player.setTrackSend1Gain(id, track.controls.send1Gain, immediate());
    track.lastSend1Gain = track.controls.send1Gain;
  }
  if (track.lastSend2Gain !== track.controls.send2Gain) {
    player.setTrackSend2Gain(id, track.controls.send2Gain, immediate());
    track.lastSend2Gain = track.controls.send2Gain;
  }

  hidePluginGUIContainerIfNoGUIOpen();

  if (
    track.controls.tuningSystem &&
    track.controls.scale &&
    (track.lastTuningSystem !== track.controls.tuningSystem ||
      track.lastScale !== track.controls.scale)
  ) {
    track.lastTuningSystem = track.controls.tuningSystem;
    track.lastScale = track.controls.scale;

    let tunings = Array.from(tracks.values()).map((track) => ({
      tuningSystem: track.lastTuningSystem,
      scale: track.lastScale,
    }));
    playbackEvents.emit("tuningsChange", tunings);
    player.setCurrentTunings(tunings);
  }

  if (
    getTransport().state === "started" &&
    controls.started &&
    !isNumber(track.nextSchedule)
  ) {
    launchTrackSteps(track, id, launchTime, launchTicks);
  } else if (!controls.started && isNumber(track.nextSchedule)) {
    getTransport().clear(track.nextSchedule);
    track.nextSchedule = undefined;
    track.startedOnTick = undefined;
  }
}

export function scheduleBatchUpdate(
  newTracks: { id: string; controls: TrackControls }[],
  newMidiOutputs: { [trackId: string]: MIDIOutput },
  newReturnTrackSettings: ReturnTrackSettings,
  newTransportControls: TransportControls,
  atTicks: number,
  startPlayback: boolean
) {
  return new Promise<void>((res) => {
    if (getTransport().state === "started") {
      let startTicks =
        atTicks >= 0
          ? atTicks
          : getTransport().getTicksAtTime(getTransport().nextSubdivision(`4n`));
      getTransport().scheduleOnce((t: number) => {
        let oldTrackIds = Array.from(tracks.keys());
        let newTrackIds = newTracks.map((t) => t.id);
        let tracksIdsToUpdate = intersection(oldTrackIds, newTrackIds);
        let trackIdsToRemove = difference(oldTrackIds, newTrackIds);
        let trackIdsToAdd = difference(newTrackIds, oldTrackIds);

        for (let trackId of trackIdsToRemove) {
          removeTrack(trackId);
        }
        for (let trackId of tracksIdsToUpdate) {
          setTrack(
            trackId,
            newTracks.find((t) => t.id === trackId)!.controls,
            t,
            startTicks
          );
        }
        for (let trackId of trackIdsToAdd) {
          setTrack(
            trackId,
            newTracks.find((t) => t.id === trackId)!.controls,
            t,
            startTicks
          );
        }
        updateReturnTrackSettings(newReturnTrackSettings);
        setTransportControls(newTransportControls);
        setMidiOutputs(newMidiOutputs);
        res();
      }, `${startTicks - 1}i`);
    } else {
      for (let trackId of Array.from(tracks.keys())) {
        removeTrack(trackId);
      }
      for (let newTrack of newTracks) {
        setTrack(newTrack.id, newTrack.controls);
      }
      setMidiOutputs(newMidiOutputs);
      updateReturnTrackSettings(newReturnTrackSettings);
      setTransportControls(newTransportControls);
      res();
    }
    if (startPlayback) {
      start();
    }
  });
}

function launchTrackSteps(
  track: TrackState,
  id: string,
  launchTime?: number,
  launchTicks?: number
) {
  if (isNumber(launchTime) && isNumber(launchTicks)) {
    track.startedOnTick = launchTicks;
    playTrackStep(id, track!, launchTicks, launchTime);
  } else {
    let startTime = getTransport().nextSubdivision(`4n`);
    let startTicks = getTransport().getTicksAtTime(startTime);
    track.startedOnTick = startTicks;
    track.nextSchedule = getTransport().scheduleOnce(
      (t: number) => playTrackStep(id, track!, startTicks, t),
      `${startTicks}i`
    );
  }
}

function playTrackStep(
  id: string,
  track: TrackState,
  atTicks: number,
  time: number
) {
  let beatDivisionToPlay: BeatDivision | undefined,
    multiplier = 1,
    forceRest: boolean | undefined = false;
  if (track.queuedUpTupletRepeats.length > 0) {
    beatDivisionToPlay = track.queuedUpTupletRepeats.shift()!;
  } else {
    ({
      division: beatDivisionToPlay,
      count: multiplier,
      forceRest,
    } = chooseBeatDivision(track));
    let tupletRepeats = 0;
    if (beatDivisionToPlay) {
      tupletRepeats = 1;
      if (
        track.controls.beatDivisionType === "weights" &&
        track.controls.forceTuplets &&
        beatDivisionToPlay in BEAT_DIVISION_TUPLETS
      ) {
        tupletRepeats = BEAT_DIVISION_TUPLETS[beatDivisionToPlay];
      }
    }
    for (let i = 0; i < tupletRepeats - 1; i++) {
      track.queuedUpTupletRepeats.push(beatDivisionToPlay!);
    }
  }

  let nextTicks: number;
  if (beatDivisionToPlay) {
    let parsedBeatDivisionToPlay =
      BEAT_DIVISION_BEAT_VALUES[beatDivisionToPlay];
    let allowRest =
      track.controls.beatDivisionType === "weights"
        ? track.controls.beatDivisionRestToggles.find(
            (t) => t.division === `${beatDivisionToPlay}`
          )?.enabled ?? true
        : false;
    let duration = TransportTime({
      "1n": parsedBeatDivisionToPlay * multiplier,
    }).toSeconds();
    let concurrentNotes = getPlayedNotesOnTick(atTicks);
    let lastNote = findLast(track.eventHistory, (e) => e.type === "note") as
      | PlayedNote
      | undefined;
    let noteToPlay = forceRest
      ? { key: "Rest" }
      : chooseNote(track.controls, allowRest, concurrentNotes, lastNote);
    if (
      !track.currentLoopPart &&
      noteToPlay.value?.scaleDegree &&
      isNumber(noteToPlay.value.octave)
    ) {
      let freq = calculateNoteFreq(track.controls, noteToPlay.value);
      let { velocity, baseVelocity, accented } = chooseNoteVelocity(
        track,
        atTicks
      );

      let delay = chooseNoteDelay(track, duration, atTicks);
      let { value: noteLength, key: chosenNoteLength } = chooseNoteLength(
        beatDivisionToPlay,
        duration - delay,
        track,
        atTicks
      );
      let playedNote: PlayedNote = {
        type: "note",
        beatDivision: beatDivisionToPlay,
        chosenScaleWeight: noteToPlay.key,
        scaleDegree: noteToPlay.value.scaleDegree,
        interval: noteToPlay.value.interval,
        octave: noteToPlay.value.octave,
        freq,
        velocity,
        time,
        ticks: atTicks,
        delay,
        duration,
        stringIndex: noteToPlay.value.scaleDegree.stringIndex,
        pitchClassIndex: noteToPlay.value.scaleDegree.pitchClassIndex,
      };
      playNoteOnTrack(
        track,
        id,
        noteToPlay.value.scaleDegree.cents,
        noteToPlay.value.octave,
        freq,
        velocity,
        baseVelocity,
        accented,
        time,
        delay,
        noteLength,
        chosenNoteLength,
        playedNote
      );
      track.eventHistory.push(playedNote);
    } else if (!track.currentLoopPart) {
      let playedRest: PlayedRest = {
        type: "rest",
        beatDivision: beatDivisionToPlay,
        time,
        ticks: atTicks,
        duration,
      };
      playRestOnTrack(track, time, playedRest);
      track.eventHistory.push(playedRest);
    }
    while (track.eventHistory.length > 256) {
      track.eventHistory.shift();
    }
    nextTicks =
      atTicks +
      getTransport().toTicks({ "1n": parsedBeatDivisionToPlay * multiplier });
  } else {
    nextTicks = atTicks + getTransport().toTicks("1n");
  }

  track.nextSchedule = getTransport().scheduleOnce(
    (t: number) => playTrackStep(id, track, nextTicks, t),
    `${nextTicks}i`
  );
}

function startLoopers() {
  let looperTicksPerStep = getTransport().toTicks("4n");
  let looperTicks = 0;
  getTransport().scheduleRepeat(
    function loopers(time) {
      for (let [id, track] of Array.from(tracks.entries())) {
        if (
          track.controls.looper &&
          track.controls.looper !== track.currentLoopLength
        ) {
          if (track.currentLoopPart) {
            track.currentLoopPart.stop(time);
            track.currentLoopPart.dispose();
          }

          let timeSig = track.controls.overridetimeSignature
            ? getTimeSignatureTicks(
                track.controls.timeSignatureNumerator,
                track.controls.timeSignatureDenominator,
                getTransport().PPQ
              )
            : getTimeSignatureTicks(
                transportControls.timeSignatureNumerator,
                transportControls.timeSignatureDenominator,
                getTransport().PPQ
              );
          let loopTicks = timeSig.totalTicks * track.controls.looper;
          let loopDuration = `${loopTicks}i`;
          let ticksAtLoopStart = track.currentLoopStartedOnTick ?? looperTicks;

          let ticksFrom = ticksAtLoopStart - loopTicks;
          let loopNotes: [
            TicksClass,
            { event: PlayedEvent; noteTicksInLoop: number }
          ][] = [];
          for (let playedEvent of track.eventHistory) {
            let noteTicksInLoop = playedEvent.ticks - ticksFrom;
            if (noteTicksInLoop >= 0) {
              loopNotes.push([
                Ticks(noteTicksInLoop),
                { event: playedEvent, noteTicksInLoop },
              ]);
            }
          }

          let loopedTimes = -1;
          track.currentLoopPart = new Part(
            (
              time: number,
              {
                event,
                noteTicksInLoop,
              }: { event: PlayedEvent; noteTicksInLoop: number }
            ) => {
              if (event === loopNotes[0][1].event) {
                loopedTimes++;
              }
              if (event.type === "note") {
                let atTicks =
                  ticksAtLoopStart + loopTicks * loopedTimes + noteTicksInLoop;
                let delay = chooseNoteDelay(track, event.duration, atTicks);
                let { value: noteLength, key: chosenNoteLength } =
                  chooseNoteLength(
                    event.beatDivision,
                    event.duration - delay,
                    track,
                    atTicks
                  );
                let { velocity, baseVelocity, accented } = chooseNoteVelocity(
                  track,
                  atTicks
                );
                playNoteOnTrack(
                  track,
                  id,
                  event.scaleDegree.cents,
                  event.octave,
                  event.freq,
                  velocity,
                  baseVelocity,
                  accented,
                  time,
                  delay,
                  noteLength,
                  chosenNoteLength,
                  event
                );
              } else {
                playRestOnTrack(track, time, event);
              }
            },
            loopNotes as any
          );
          track.currentLoopPart.loop = true;
          track.currentLoopPart.loopStart = 0;
          track.currentLoopPart.loopEnd = loopDuration;
          track.currentLoopPart.start(
            TransportTime(getTransport().ticks, "i").toSeconds()
          );
          track.currentLoopLength = track.controls.looper;
          track.currentLoopStartedOnTick = ticksAtLoopStart;
        } else if (track.currentLoopPart && !track.controls.looper) {
          track.currentLoopPart.stop(time);
          track.currentLoopPart.dispose();
          track.currentLoopPart = undefined;
          track.currentLoopLength = undefined;
          track.currentLoopStartedOnTick = undefined;
        }
      }
      looperTicks += looperTicksPerStep;
    },
    "4n",
    0
  );
}

function startLFOTicks() {
  getTransport().scheduleRepeat(
    (time) => {
      lfos.runLFOTick(tracks, player, midiOutputs, time);
    },
    "16n",
    0
  );
}

function getPlayedNotesOnTick(tick: number) {
  let result: { note: PlayedNote; forcePolyphony: boolean }[] = [];
  tracks.forEach((track) => {
    for (let i = track.eventHistory.length - 1; i >= 0; i--) {
      if (
        track.eventHistory[i].ticks === tick &&
        track.eventHistory[i].type === "note"
      ) {
        result.push({
          note: track.eventHistory[i] as PlayedNote,
          forcePolyphony: track.controls.forcePolyphony,
        });
      }
      if (track.eventHistory[i].ticks < tick) {
        break;
      }
    }
  });
  return result;
}

function calculateNoteFreq(
  controls: TrackControls,
  noteToPlay: { scaleDegree: PlayableScaleDegree; octave: number }
) {
  let refPitchOctave = getRefPitchOctave(
    controls.tuningSystem?.refPitchNoteMidi || 0,
    controls.tuningSystem?.refPitchNoteName
  );
  let baseFreq = midiToFreq(controls.tuningSystem?.refPitchNoteMidi || 0);
  let freq = baseFreq * 2 ** (noteToPlay.scaleDegree.cents / 1200);
  freq *= 2 ** (noteToPlay.octave - refPitchOctave);
  return freq;
}

function chooseNoteVelocity(track: TrackState, atTicks: number) {
  let accented = false;
  if (track.controls.useAccentVelocity) {
    let timeSig = track.controls.overridetimeSignature
      ? getTimeSignatureTicks(
          track.controls.timeSignatureNumerator,
          track.controls.timeSignatureDenominator,
          getTransport().PPQ
        )
      : getTimeSignatureTicks(
          transportControls.timeSignatureNumerator,
          transportControls.timeSignatureDenominator,
          getTransport().PPQ
        );
    let trackTicks = track.controls.overridetimeSignature
      ? atTicks - track.startedOnTick!
      : atTicks;
    let relativeTicks = trackTicks % timeSig.totalTicks;
    accented = timeSig.accentedTicks.has(relativeTicks);
  }

  let noiseValue = noise.getVal(track.velocityNoiseSeed + atTicks / 500);
  let relBaseVelocity =
    track.controls.minVelocity +
    noiseValue * (track.controls.maxVelocity - track.controls.minVelocity);
  let baseVelocity =
    MIN_BASE_VELOCITY +
    relBaseVelocity * (MAX_BASE_VELOCITY - MIN_BASE_VELOCITY);

  let velocity = baseVelocity;
  if (accented) {
    velocity += ACCENT_VELOCITY_DELTA;
  }
  return { velocity, baseVelocity: relBaseVelocity, accented };
}

function chooseNoteLength(
  beatDivision: BeatDivision,
  duration: number,
  track: TrackState,
  atTicks: number
) {
  if (track.controls.activeArticulation === "minMax") {
    let noiseValue = noise.getVal(track.articulationSeed + atTicks / 500);
    let rnd =
      track.controls.minNoteLength +
      noiseValue *
        (track.controls.maxNoteLength - track.controls.minNoteLength);
    return { key: rnd, value: rnd * duration };
  } else {
    let maxDur = BEAT_DIVISION_BEAT_VALUES[beatDivision];
    let articulationDur = track.controls.noteLengths[beatDivision];
    return {
      key: beatDivision,
      value: Math.min(
        duration,
        Math.max(
          0.01,
          getTransport().toSeconds({ "1n": maxDur * articulationDur })
        )
      ),
    };
  }
}

function chooseNoteDelay(track: TrackState, duration: number, atTicks: number) {
  let noiseValue = noise.getVal(track.noteDelaySeed + atTicks / 500);
  return Math.min(
    track.controls.minBeatDelay / 1000 +
      (noiseValue *
        (track.controls.maxBeatDelay - track.controls.minBeatDelay)) /
        1000,
    duration - 0.01
  );
}

function playNoteOnTrack(
  track: TrackState,
  trackId: string,
  cents: number,
  octave: number,
  freq: number,
  velocity: number,
  baseVelocity: number,
  accented: boolean,
  time: number,
  delay: number,
  duration: number,
  chosenNoteLength: string | number | undefined,
  playedNote: PlayedNote
) {
  if (track.controls.instrument === "string") {
    player.playTrackNoteOnString(
      trackId,
      cents,
      octave,
      freq,
      duration,
      delay,
      velocity,
      time
    );
  } else if (track.controls.instrument === "basicSynth") {
    player.playTrackNoteOnBasicSynth(
      trackId,
      cents,
      octave,
      freq,
      duration,
      delay,
      velocity,
      time
    );
  } else if (track.controls.instrument === "obxd") {
    player.playTrackNoteOnOBXD(
      trackId,
      cents,
      octave,
      freq,
      duration,
      delay,
      velocity,
      time
    );
  } else if (track.controls.instrument === "dexed") {
    player.playTrackNoteOnDEXED(
      trackId,
      cents,
      octave,
      freq,
      duration,
      delay,
      velocity,
      time
    );
  } else if (track.controls.instrument === "yoshimi") {
    player.playTrackNoteOnYoshimi(
      trackId,
      cents,
      octave,
      freq,
      duration,
      delay,
      velocity,
      time
    );
  } else if (track.controls.instrument === "midi") {
    let output = midiOutputs[trackId];
    if (output && output.output) {
      let midiNote = getClosestMIDINote(freq, output.pitchBendRangeCents, null);
      let pitchBend = getMIDIPitchBend(
        freq,
        midiNote,
        output.pitchBendRangeCents
      );
      let midiNoteTime = `+${
        (time + delay - getTransport().immediate()) * 1000
      }`;
      let channel = getMidiChannel(output);
      output.output.playNote(midiNote, channel, {
        duration: duration * 1000 - 10,
        velocity,
        time: midiNoteTime,
      });
      output.output.sendPitchBend(pitchBend, channel, { time: midiNoteTime });
      player.playTrackNoteOnMIDI(
        trackId,
        cents,
        octave,
        freq,
        duration,
        delay,
        velocity,
        time
      );
    }
  }
  for (let i = 0; i < track.controls.lfos.length; i++) {
    if (track.controls.lfos[i].on && track.controls.lfos[i].retrigger) {
      track.lfoStates[i].pendingRetriggerTimes.push(time);
    }
  }
  getDraw().schedule(
    () =>
      track.events.emit("note", {
        stringIndex: playedNote.stringIndex,
        pitchClassIndex: playedNote.pitchClassIndex,
        beatDivision: playedNote.beatDivision,
        chosenScaleWeight: playedNote.chosenScaleWeight,
        cents: playedNote.scaleDegree.cents,
        freq,
        velocity,
        baseVelocity,
        accented,
        duration,
        delay: delay * 1000,
        octave: playedNote.octave,
        interval: playedNote.interval,
        noteLength: chosenNoteLength,
      }),
    time + delay
  );
}

function getMidiChannel(output: MIDIOutput) {
  if (output.channel === "all" || output.channel === "mpe") {
    let min =
      output.channel === "all"
        ? MIDI_CHANNEL_ROUND_ROBIN_MIN
        : MIDI_CHANNEL_MPE_ROUND_ROBIN_MIN;
    let max =
      output.channel === "all"
        ? MIDI_CHANNEL_ROUND_ROBIN_MAX
        : MIDI_CHANNEL_MPE_ROUND_ROBIN_MAX;
    let rr = midiChannelRoundRobins.get(output.output!.id) ?? min;
    if (rr < min) {
      rr = min;
    }
    let nextRr = rr + 1;
    if (nextRr > max) {
      nextRr = min;
    }
    midiChannelRoundRobins.set(output.output!.id, nextRr);
    return rr;
  } else {
    return output.channel;
  }
}

function playRestOnTrack(
  track: TrackState,
  time: number,
  playedRest: PlayedRest
) {
  getDraw().schedule(
    () =>
      track.events.emit("note", {
        beatDivision: playedRest.beatDivision,
        chosenScaleWeight: "Rest",
        duration: playedRest.duration,
      }),
    time
  );
}

export function removeTrack(id: string) {
  let track = tracks.get(id);
  if (track && isNumber(track.nextSchedule)) {
    getTransport().clear(track.nextSchedule);
  }
  if (track?.controls.pluginGUIOpen) {
    player.closeOBXDGUI(id);
    player.closeDEXEDGUI(id);
  }
  if (track?.hasBasicSynth) {
    player.disposeTrackBasicSynth(id, immediate());
    track.hasBasicSynth = false;
  }
  if (track?.hasString) {
    player.disposeTrackString(id, immediate());
    track.hasString = false;
  }
  if (track?.hasObxd) {
    player.disposeTrackOBXD(id, immediate());
    track.hasObxd = false;
    track.lastObxdState = undefined;
  }
  if (track?.hasDexed) {
    player.disposeTrackDEXED(id, immediate());
    track.hasDexed = false;
    track.lastDexedState = undefined;
  }
  if (track?.hasYoshimi) {
    player.disposeTrackYoshimi(id, immediate());
    track.hasYoshimi = false;
    track.lastYoshimiState = undefined;
  }
  player.removeTrack(id);
  tracks.delete(id);
  hidePluginGUIContainerIfNoGUIOpen();
}

export function getTrackEvents(id: string) {
  return tracks.get(id)?.events;
}

export function setPluginGUIContainer(container: Rnd) {
  pluginGUIContainer = container;
}

export function setMidiOutputs(newOutputs: { [id: string]: MIDIOutput }) {
  midiOutputs = newOutputs;
}

function hidePluginGUIContainerIfNoGUIOpen() {
  setTimeout(() => {
    if (!Array.from(tracks.values()).find((l) => l.controls.pluginGUIOpen)) {
      pluginGUIContainer.updateSize({ width: 0, height: 0 });
    }
  });
}

export function setMidiClockOutputs(outputs: Output[]) {
  for (let output of outputs) {
    if (!midiClockOutputs.find((o) => o.output === output)) {
      midiClockOutputs.push({ output, started: false });
    }
  }
  midiClockOutputs = midiClockOutputs.filter((current) =>
    outputs.find((o) => current.output === o)
  );
  if (midiClockOutputs.length > 0 && isNull(midiClockTickSchedule)) {
    let clockTicks = getTransport().PPQ / 24;
    let startTime = getTransport().nextSubdivision(`4n`);
    let startTicks = getTransport().getTicksAtTime(startTime);
    console.log("starting midi clock at", `${clockTicks}i`, "time", startTicks);
    midiClockTickSchedule = getTransport().scheduleRepeat(
      sendMidiClockTick,
      `${clockTicks}i`,
      `${startTicks}i`
    );
  } else if (midiClockOutputs.length === 0 && !isNull(midiClockTickSchedule)) {
    console.log("stopping midi clock");
    getTransport().clear(midiClockTickSchedule);
    midiClockTickSchedule = null;
  }
}

function sendMidiClockTick(atTime: number) {
  if (getTransport().state === "stopped") return;
  let time = `+${(atTime - immediate()) * 1000}`;
  for (let output of midiClockOutputs) {
    if (output.output.state === "connected") {
      if (!output.started) {
        output.output.sendStart({ time });
        output.started = true;
      }
      output.output.sendClock({ time });
    }
  }
}
