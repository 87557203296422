import { formatPitch, getPitchCents, isRatioPitch, Pitch } from "../main/core";

export function parseScala(scalaText: string): Pitch[] {
    let lines = scalaText.split(/\r?\n/)
        .map(line => line.trim())
        .filter(line => line.length > 0 && !line.startsWith('!'));
    let [, , ...pcLines] = lines;
    let pitches: Pitch[] = [{
        cents: 0,
        ratioLower: 1,
        ratioUpper: 1
    }];
    for (let pcLine of pcLines) {
        if (pcLine.indexOf('/') > 0) {
            let [n, d] = pcLine.split('/');
            if (+n / +d < 2) {
                pitches.push({
                    ratioUpper: +n,
                    ratioLower: +d,
                });
            }
        } else {
            let cents = +pcLine;
            if (cents < 1200) {
                pitches.push({ cents });
            }
        }
    }
    return pitches;
}

export function formatScala(pitches: Pitch[], refPitchHz: number, refPitchNote: string, filename: string, description?: string, source?: string, subsetOf?: string, scaleRoot?: Pitch) {
    let result = '';
    result += `! ${filename}\n`;
    if (subsetOf) {
        result += `! This is a subset of the ${subsetOf} tuning system.\n`;
    }
    result += `! Reference pitch: ${refPitchNote} = ${refPitchHz}Hz\n`;
    if (scaleRoot) {
        result += `! Root is: ${formatPitchScala(scaleRoot)}.\n`
    }
    if (source) {
        result += `! Source: ${source.replace(/(?:\r\n|\r|\n)/g, ' ')}\n`;
    }
    result += '! Created with Leimma by Khyam Allami and Counterpoint https://isartum.net\n';
    if (description) {
        result += description.replace(/(?:\r\n|\r|\n)/g, ' ') + '\n';
    } else {
        result += '\n';
    }
    result += `${pitches.length}\n`;
    result += '!\n';
    for (let pitch of pitches) {
        if (getPitchCents(pitch) !== 0) {
            result += `${formatPitchScala(pitch)}\n`;
        }
    }
    result += '2/1\n';

    return result;
}

function formatPitchScala(pitch: Pitch) {
    if (isRatioPitch(pitch)) {
        return formatPitch(pitch, 'ratio').toString().replace(':', '/');
    } else {
        return getPitchCents(pitch).toFixed(3);
    }
}