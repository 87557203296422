import React, {
  useState,
  useCallback,
  useEffect,
  CSSProperties,
  FormEvent,
} from "react";
import classNames from "classnames";
import { isNumber } from "lodash";
import { Pitch } from "./core";

interface PitchInputProps {
  pitch: Pitch;
  className?: string;
  numberInputClassName?: string;
  style?: CSSProperties;
  name?: string;
  disabled?: boolean;
  showLabel?: boolean;
  actionText?: string;
  onChange: (p: Pitch) => void;
  onDelete?: () => void;
}

export const PitchInput: React.FC<PitchInputProps> = ({
  pitch,
  className,
  numberInputClassName,
  style,
  name,
  disabled,
  showLabel,
  actionText,
  onChange,
  onDelete,
}) => {
  let [internalValue, setInternalValue] = useState(
    isNumber(pitch.ratioUpper) && isNumber(pitch.ratioLower)
      ? `${pitch.ratioUpper}:${pitch.ratioLower}`
      : `${pitch.cents}`
  );
  let [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    let val =
      isNumber(pitch.ratioUpper) && isNumber(pitch.ratioLower)
        ? `${pitch.ratioUpper}:${pitch.ratioLower}`
        : `${pitch.cents}`;
    setInternalValue(val);
  }, [pitch]);

  useEffect(() => {
    setInternalValue((i) => (!hasFocus || i !== "0" ? i : ""));
  }, [hasFocus]);

  let onKeyDown = useCallback((evt: React.KeyboardEvent) => {
    if (evt.keyCode === 13) {
      // Enter; let the regular onSubmit happen
    } else if (
      (evt.keyCode >= 48 && evt.keyCode <= 57 && !evt.shiftKey) || // Digit
      evt.keyCode === 186 || // Colon
      evt.key === "/" || // Forward slash
      evt.keyCode === 8 || // Backspace
      evt.keyCode === 190 || // Period
      evt.keyCode === 37 || // Left arrow
      evt.keyCode === 39 || // Right arrow
      evt.keyCode === 9 || // Tab
      evt.metaKey ||
      evt.ctrlKey
    ) {
      // Let the regular onChange happen
    } else {
      // Something else; do nothing
      evt.preventDefault();
    }
    evt.nativeEvent.stopImmediatePropagation();
  }, []);

  let onSubmit = useCallback(
    (evt: FormEvent) => {
      evt.preventDefault();
      if (internalValue.replace("/", ":").indexOf(":") > 0) {
        let [upper, lower] = internalValue.replace("/", ":").split(":");
        let ratioUpper = +upper;
        let ratioLower = +lower;
        setInternalValue(`${ratioUpper}:${ratioLower}`);
        onChange({ ...pitch, ratioUpper, ratioLower, cents: undefined });
      } else {
        let newValue = +internalValue;
        setInternalValue(`${newValue}`);
        onChange({
          ...pitch,
          ratioUpper: undefined,
          ratioLower: undefined,
          cents: newValue,
        });
      }
    },
    [pitch, internalValue, onChange]
  );

  return (
    <form
      className={classNames("pitchInput", className)}
      style={style}
      onSubmit={onSubmit}
    >
      <input
        className={classNames("input--number", numberInputClassName)}
        name={name}
        value={internalValue}
        disabled={disabled}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        onKeyDown={onKeyDown}
        onChange={(evt) => setInternalValue(evt.currentTarget.value)}
      />
      {showLabel && <span>cents / ratio</span>}
      {actionText && (
        <button className="button" type="submit">
          {actionText} +
        </button>
      )}
      {onDelete && (
        <button className="button" onClick={onDelete}>
          Delete -
        </button>
      )}
    </form>
  );
};
