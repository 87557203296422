import React from "react";
import Linkify from "react-linkify";

export const Autolink: React.FC = ({ children }) => (
  <Linkify componentDecorator={linkDecorator}>{children}</Linkify>
);

const linkDecorator = (href: string, text: string, key: any) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);
