import { useAuth0 } from '@auth0/auth0-react';
import { isNull } from 'lodash';
import { useEffect, useState } from 'react';
import * as api from './main/api';


let cachedAdminAccess: Promise<boolean> | null = null;

export const useAdminAccess = () => {
    let { isAuthenticated, getAccessTokenSilently, isLoading: isAuthLoading } = useAuth0();
    let [isLoading, setIsLoading] = useState(true);
    let [hasAdminAccess, setHasAdminAccess] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            if (isNull(cachedAdminAccess)) {
                setIsLoading(true);
                cachedAdminAccess = getAccessTokenSilently()
                    .then(api.checkAdminAccess)
                    .then((access) => {
                        setHasAdminAccess(access);
                        setIsLoading(false);
                        return access;
                    });
            } else {
                cachedAdminAccess.then(setHasAdminAccess);
            }
        } else if (!isAuthLoading) {
            setHasAdminAccess(false);
            setIsLoading(false);
        }
    }, [isAuthLoading, isAuthenticated, getAccessTokenSilently]);

    return { hasAdminAccess, isLoading: isLoading || isAuthLoading };
}