import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Edit,
  Create,
  TabbedForm,
  DisabledInput,
  TextInput,
  LongTextInput,
  NumberInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  FormTab,
  number,
  minValue,
  maxValue,
} from "react-admin";
import { flatMap, range } from "lodash";
import { TUNING_SYSTEM_CATEGORIES } from "../constants";

const REFERENCE_MIDI_NOTES = flatMap(range(1, 10), (octave) =>
  ["B", "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#"].map(
    (note) => `${note}${octave}`
  )
);

export const TuningSystemList: React.FC = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="category" />
      <TextField source="description" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

const TuningSystemTitle = ({ record }: any) => {
  return <span>Tuning System {record ? `"${record.name}"` : ""}</span>;
};

export const TuningSystemEdit: React.FC = (props: any) => (
  <Edit title={<TuningSystemTitle />} {...props}>
    <TabbedForm>
      <FormTab label="Tuning System">
        <DisabledInput source="id" />
        <DisabledInput source="addedBy" />
        <TextInput source="name" />
        <SelectInput
          source="category"
          choices={TUNING_SYSTEM_CATEGORIES.map((c) => ({ id: c, name: c }))}
        />
        <LongTextInput source="description" />
        <LongTextInput source="source" />
        <TextInput source="creationDate" />
        <SelectInput
          source="refPitchNoteName"
          choices={REFERENCE_MIDI_NOTES.map((n) => ({ id: n, name: n }))}
        />
        <NumberInput
          source="refPitchNoteMidi"
          validate={[number(), minValue(0), maxValue(127)]}
        />
        <NumberInput source="refPitchHz" />
      </FormTab>
      <FormTab label="strings">
        <ArrayInput source="Strings">
          <SimpleFormIterator>
            <NumberInput
              label="Open string cents"
              source="cents"
              validate={[number(), minValue(0), maxValue(1200)]}
            />
            <NumberInput label="Open string ratio upper" source="ratioUpper" />
            <NumberInput label="Open string ratio lower" source="ratioLower" />
            <TextInput source="name" />
            <ArrayInput source="PitchClasses">
              <SimpleFormIterator>
                <NumberInput
                  source="cents"
                  validate={[number(), minValue(0), maxValue(1200)]}
                />
                <NumberInput source="ratioUpper" />
                <NumberInput source="ratioLower" />
                <TextInput source="name8ve1" />
                <TextInput source="name8ve2" />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const TuningSystemCreate: React.FC = (props: any) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="Tuning System">
        <DisabledInput source="addedBy" defaultValue={props.user.email} />
        <TextInput source="name" />
        <SelectInput
          source="category"
          choices={TUNING_SYSTEM_CATEGORIES.map((c) => ({ id: c, name: c }))}
        />
        <LongTextInput source="description" />
        <LongTextInput source="source" />
        <TextInput source="creationDate" />
        <SelectInput
          source="refPitchNoteName"
          choices={REFERENCE_MIDI_NOTES.map((n) => ({ id: n, name: n }))}
        />
        <NumberInput
          source="refPitchNoteMidi"
          validate={[number(), minValue(0), maxValue(127)]}
        />
        <NumberInput source="refPitchHz" />
      </FormTab>
      <FormTab label="pitchClasses">
        <ArrayInput source="PitchClasses">
          <SimpleFormIterator>
            <NumberInput
              source="cents"
              validate={[number(), minValue(0), maxValue(1200)]}
            />
            <NumberInput source="ratioUpper" />
            <NumberInput source="ratioLower" />
            <TextInput source="name8ve1" />
            <TextInput source="name8ve2" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
