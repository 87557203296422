import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
  useMemo,
} from "react";
import { RouteComponentProps, generatePath } from "react-router";
import { useParams, useHistory } from "react-router-dom";
import { isNumber, findIndex, sortBy, flatMap, every, minBy } from "lodash";
import classNames from "classnames";

import { SVGCanvas } from "./SVGCanvas";
import { TuningSystemWheel } from "./TuningSystemWheel";
import {
  parseScale,
  formatScale,
  parseSolmization,
  parseRefPitch,
  parseStrings,
  formatSolmization,
  formatStrings,
  formatRefPitch,
} from "./urlSerialization";
import { MidiMappingSelection } from "./main/MidiMappingSelection";
import {
  ScaleHeader,
  midiToFreq,
  SCALE_DEGREE_KEYBOARD_MAPPING,
  Solmization,
  Pitch,
  getPitchCents,
  Scale,
  getPitchSum,
  ScaleDegree,
  reduceIntoOctave,
  isRatioPitch,
  getRefPitchOctave,
  ScaleDegreeRole,
  getMonotonicallyIncreasingScale,
  TuningSystem,
  DEFAULT_KEYBOARD_TO_SCALE_DEGREE_MAPPING,
  TuningSnapshotContent,
} from "./main/core";
import * as api from "./main/api";
import * as audio from "./main/audio";

import "./ScaleScreen.scss";
import { ScalePicker } from "./main/ScalePicker";
import { MIDIInput } from "./main/MIDIInput";
import { MIDIOutput } from "./main/MIDIOutput";
import { MIDIContext } from "./MIDIContext";
import { QwertyInput } from "./main/QwertyInput";
import { OnScreenKeyboard } from "./OnScreenKeyboard";
import { SCALE_DEGREE_NAMES } from "./constants";
import { SolmizationDropdown } from "./SolmizationDropdown";
import { ScaleDatabaseEntryDialog } from "./ScaleDatabaseEntryDialog";
import { PitchInput } from "./main/PitchInput";
import { ScaleDegreeRoleSelection } from "./ScaleDegreeRoleSelection";
import { Breadcrumbs } from "./Breadcrumbs";
import { UserInfo } from "./UserInfo";
import { useAdminAccess } from "./useAdminAccess";
import { useAuth0 } from "@auth0/auth0-react";
import { ChooseTuningSystemDialog } from "./ChooseTuningSystemDialog";
import { Autolink } from "./Autolink";
import { LeimmaHelmet } from "./LeimmaHelmet";
import { UserGuideLink } from "./UserGuideLink";
import { ScalaExport } from "./formats/ScalaExport";
import { TuningSnapshots } from "./TuningSnapshots";

interface ScaleScreenParams {
  tuningSystemId: string;
  refPitch: string;
  strings: string;
  scaleId: string;
  solmization: string;
  scale: string;
}
export const ScaleScreen: React.FC<RouteComponentProps<ScaleScreenParams>> = ({
  match,
}) => {
  let { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  let { hasAdminAccess } = useAdminAccess();
  let {
    tuningSystemId,
    refPitch: refPitchString,
    strings: stringsString,
    scaleId,
    solmization: solmizationString,
    scale: scaleString,
  } = useParams<ScaleScreenParams>();
  let history = useHistory();
  let refPitch = parseRefPitch(refPitchString);
  let strings = parseStrings(stringsString);
  let [existingScales, setExistingScales] = useState<ScaleHeader[]>([]);
  let [
    existingTuningSystem,
    setExistingTuningSystem,
  ] = useState<TuningSystem>();

  let loadExistingRecords = useCallback(() => {
    if (tuningSystemId !== "new") {
      if (isAuthenticated) {
        getAccessTokenSilently().then((token) => {
          api.loadScales(+tuningSystemId, token).then(setExistingScales);
          api
            .loadTuningSystem(+tuningSystemId, token)
            .then(setExistingTuningSystem);
        });
      } else {
        api.loadScales(+tuningSystemId).then(setExistingScales);
        api.loadTuningSystem(+tuningSystemId).then(setExistingTuningSystem);
      }
    } else {
      setExistingScales([]);
      setExistingTuningSystem(undefined);
    }
  }, [tuningSystemId, isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (isLoading) return;
    loadExistingRecords();
  }, [isLoading, loadExistingRecords]);

  let solmization = parseSolmization(solmizationString);
  let scaleDegreeNames = solmization ? SCALE_DEGREE_NAMES[solmization] : [];
  let scale = parseScale(scaleString);
  let [selectedDivisionIndex, setSelectedDivisionIndex] = useState<{
    stringIdx: number;
    pcIdx: number;
  }>();
  let {
    outputs: { leimma: output },
  } = useContext(MIDIContext);
  let [currentlyPlaying, setCurrentlyPlaying] = useState<
    { stringIdx: number; pcIdx: number; keyCode: number }[]
  >([]);
  let cancelCurrentScalePlayer = useRef<() => void>();
  let [keyboardOctave, setKeyboardOctave] = useState(3);
  let [pitchFormat, setPitchFormat] = useState<"cents" | "ratio">(
    every(
      strings,
      (s) => isRatioPitch(s) && every(s.pitchClasses, isRatioPitch)
    )
      ? "ratio"
      : "cents"
  );
  let [onScreenKeyboardOpen, setOnScreenKeyboardOpen] = useState(false);
  let [tuningSnapshotsOpen, setTuningSnapshotsOpen] = useState(false);
  let [databaseEntryDialogOpen, setDatabaseEntryDialogOpen] = useState(false);
  let [tuningSystemDialogReloads, setTuningSystemDialogReloads] = useState(0);

  async function onPickExistingScale(id: number) {
    let existingScale = await api.loadScale(
      id,
      isAuthenticated ? await getAccessTokenSilently() : undefined
    );
    scale = existingScale.scaleDegrees.filter((d) => isNumber(d.map));
    scaleId = "" + id;
    applyScale(existingScale.solmization || solmization);
  }

  function getScaleDegree(stringIdx: number, pcIdx: number) {
    return scale.find(
      (s) => s.stringIndex === stringIdx && s.pitchClassIndex === pcIdx
    );
  }

  function getAllMidiMappings() {
    return new Set(
      flatMap(
        scale.map((d) => SCALE_DEGREE_KEYBOARD_MAPPING.get(d.map!)),
        (keybMapping) =>
          Array.from(SCALE_DEGREE_KEYBOARD_MAPPING.entries())
            .filter((e) => e[1] === keybMapping)
            .map((e) => e[0])
      )
    );
  }

  function setMidiMapping(
    stringIdx: number,
    pcIdx: number,
    mapping: number | null
  ) {
    let makeTonic = false;

    if (isNumber(mapping)) {
      // Find the (enharmonic) key equivalents for this mapping
      let mappingEntry = SCALE_DEGREE_KEYBOARD_MAPPING.get(mapping);
      let mappingEquivs = Array.from(SCALE_DEGREE_KEYBOARD_MAPPING.entries())
        .filter((e) => e[1] === mappingEntry)
        .map((e) => e[0]);

      // Check if this should be the tonic. Either it's the first degree mapped, or the same key was previously mapped to a tonic
      if (scale.length === 0) {
        makeTonic = true;
      }
      for (let sd of scale) {
        let sameMapping = mappingEquivs.indexOf(sd.map!) >= 0;
        if (sameMapping && sd.role === "tonic") {
          makeTonic = true;
        }
      }

      // Clear any previous mappings for the same key
      scale = scale.filter((s) => mappingEquivs.indexOf(s.map!) < 0);
    }

    if (makeTonic) {
      // Clear any previous tonic
      scale = scale.map((deg) => ({
        ...deg,
        role: deg.role === "tonic" ? "primary" : deg.role,
      }));
    }

    let existingDegIdx = findIndex(
      scale,
      (s) => s.stringIndex === stringIdx && s.pitchClassIndex === pcIdx
    );
    if (existingDegIdx >= 0) {
      if (isNumber(mapping)) {
        scale[existingDegIdx].map = mapping;
        if (makeTonic) scale[existingDegIdx].role = "tonic";
      } else {
        let wasTonic = scale[existingDegIdx].role === "tonic";
        scale.splice(existingDegIdx, 1);
        if (wasTonic && scale.length > 0) scale[0].role = "tonic"; // Removing tonic so assign it to another degree
      }
    } else if (isNumber(mapping)) {
      scale.push({
        stringIndex: stringIdx,
        pitchClassIndex: pcIdx,
        map: mapping,
        role: makeTonic ? "tonic" : "primary",
      });
    }
    scale = sortBy(scale, (s) =>
      getPitchCents(
        getPitchSum(
          strings[s.stringIndex],
          strings[s.stringIndex].pitchClasses[s.pitchClassIndex]
        )
      )
    );
    applyScale();
    if (isNumber(mapping)) {
      soundDivision(stringIdx, pcIdx);
    }

    setSelectedDivisionIndex(undefined);
  }

  function setScaleDegreeRole(
    stringIdx: number,
    pcIdx: number,
    role: ScaleDegreeRole
  ) {
    let degIdx = findIndex(
      scale,
      (s) => s.stringIndex === stringIdx && s.pitchClassIndex === pcIdx
    );
    if (degIdx >= 0) {
      scale[degIdx].role = role;
      if (role === "tonic") {
        for (let i = 0; i < scale.length; i++) {
          if (i !== degIdx && scale[i].role === "tonic") {
            scale[i].role = "primary";
          }
        }
      }
    }
    applyScale();
    setSelectedDivisionIndex(undefined);
  }

  function applyScale(sol = solmization) {
    let path = generatePath(match!.path, {
      tuningSystemId,
      refPitch: refPitchString,
      strings: stringsString,
      scaleId,
      solmization: formatSolmization(sol),
      scale: scale.length > 0 ? formatScale(scale) : undefined,
    });
    history.replace(path);
  }

  function onUpdateTonic(newTonicIndex: number) {
    let prevTonicIndex = findIndex(scale, (d) => d.role === "tonic");
    let roleRotation = newTonicIndex - prevTonicIndex;
    let prevRoles = scale.map((s) => s.role);
    for (let i = 0; i < scale.length; i++) {
      let fromI = i - roleRotation;
      if (fromI < 0) {
        fromI += scale.length;
      }
      scale[i].role = prevRoles[fromI % scale.length];
    }
    scale[newTonicIndex].role = "tonic"; // If not set before
    let path = generatePath(match!.path, {
      tuningSystemId,
      refPitch: refPitchString,
      strings: stringsString,
      scaleId,
      solmization: solmizationString,
      scale: scale.length > 0 ? formatScale(scale) : undefined,
    });
    history.replace(path);
  }

  function onUpdateScale(newScale: ScaleDegree[]) {
    scale = newScale;
    applyScale();
  }

  function onUpdateSelectedPitch(newPitch: Pitch) {
    let { stringIdx, pcIdx } = selectedDivisionIndex!;
    strings[stringIdx].pitchClasses[pcIdx] = {
      ...strings[stringIdx].pitchClasses[pcIdx],
      ...newPitch,
    };
    let path = generatePath(match!.path, {
      tuningSystemId,
      refPitch: refPitchString,
      strings: formatStrings(strings),
      scaleId,
      solmization: solmizationString,
      scale: scaleString,
    });
    history.replace(path);
    soundDivision(stringIdx, pcIdx);
  }

  function clearMappings() {
    let path = generatePath(match!.path, {
      tuningSystemId,
      refPitch: refPitchString,
      strings: stringsString,
      scaleId,
      solmization: solmizationString,
      tonic: 0,
    });
    history.push(path);
  }

  function onChangeSolmization(newSolmization: Solmization) {
    let path = generatePath(match!.path, {
      tuningSystemId,
      refPitch: refPitchString,
      strings: stringsString,
      scaleId,
      solmization: newSolmization,
      scale: scaleString,
    });
    history.replace(path);
  }

  function onKeyDown(
    keyCode: number,
    pitchClass: number,
    keyOctave: number,
    velocity: number
  ) {
    if (selectedDivisionIndex) {
      let mapping = DEFAULT_KEYBOARD_TO_SCALE_DEGREE_MAPPING.get(pitchClass);
      if (isNumber(mapping)) {
        setMidiMapping(
          selectedDivisionIndex.stringIdx,
          selectedDivisionIndex.pcIdx,
          mapping
        );
      }
    } else {
      let deg = scale.find(
        (s) =>
          isNumber(s.map) &&
          SCALE_DEGREE_KEYBOARD_MAPPING.get(s.map) === pitchClass
      )!;
      if (deg) {
        let refFreq = midiToFreq(+refPitch.semitones);
        let refOctave = getRefPitchOctave(refPitch.semitones, refPitch.note);
        let lowestMappedSd = minBy(scale, (sd) =>
          getPitchCents(
            reduceIntoOctave(
              getPitchSum(
                strings[sd.stringIndex],
                strings[sd.stringIndex].pitchClasses[sd.pitchClassIndex]
              )
            )
          )
        );

        let string = strings[deg.stringIndex];
        let pc = string.pitchClasses[deg.pitchClassIndex];
        let cents = getPitchCents(reduceIntoOctave(getPitchSum(string, pc)));
        let freq = refFreq * 2 ** (cents / 1200);

        let octaveShift = keyOctave - refOctave;
        if (lowestMappedSd!.map! > 0 && deg.map! >= lowestMappedSd!.map!) {
          octaveShift += 1;
        }
        freq *= 2 ** octaveShift;

        audio.noteOn(keyCode, freq, velocity, pitchClass, output);
        setCurrentlyPlaying((c) => [
          ...c,
          {
            stringIdx: deg.stringIndex,
            pcIdx: deg.pitchClassIndex,
            keyCode,
          },
        ]);
      }
    }
  }

  let onKeyUp = useCallback(
    (keyCode: number, pitchClass: number, octave: number) => {
      audio.noteOff(keyCode, output);
      setCurrentlyPlaying((c) => c.filter((p) => p.keyCode !== keyCode));
    },
    [output]
  );

  function onUpdateSelectedDivisionIndex(stringIndex: number, pcIndex: number) {
    if (
      selectedDivisionIndex?.stringIdx === stringIndex &&
      selectedDivisionIndex.pcIdx === pcIndex
    ) {
      setSelectedDivisionIndex(undefined);
    } else {
      setSelectedDivisionIndex({ stringIdx: stringIndex, pcIdx: pcIndex });
    }
  }

  function soundDivision(
    stringIndex: number,
    pcIndex: number,
    fromScale = scale,
    octaveShift = 0,
    keyCode = -2,
    startT = audio.now()
  ) {
    let soundingPitch = reduceIntoOctave(
      getPitchSum(
        strings[stringIndex],
        strings[stringIndex].pitchClasses[pcIndex]
      )
    );
    let soundingFreq =
      midiToFreq(refPitch.semitones) *
      2 ** (getPitchCents(soundingPitch) / 1200);

    if (fromScale.length > 0) {
      let tonicSd = fromScale.find((s) => s.role === "tonic")!;
      let tonicPitch = reduceIntoOctave(
        getPitchSum(
          strings[tonicSd.stringIndex],
          strings[tonicSd.stringIndex].pitchClasses[tonicSd.pitchClassIndex]
        )
      );
      let tonicFreq =
        midiToFreq(refPitch.semitones) *
        2 ** (getPitchCents(tonicPitch) / 1200);

      if (soundingFreq < tonicFreq) {
        soundingFreq *= 2;
      }

      let lowestMappedSd = minBy(scale, (sd) =>
        getPitchCents(
          reduceIntoOctave(
            getPitchSum(
              strings[sd.stringIndex],
              strings[sd.stringIndex].pitchClasses[sd.pitchClassIndex]
            )
          )
        )
      );
      if (lowestMappedSd && lowestMappedSd.map && lowestMappedSd!.map > 0) {
        soundingFreq *= 2;
      }
    }

    soundingFreq *=
      2 **
      (keyboardOctave - getRefPitchOctave(refPitch.semitones, refPitch.note));
    soundingFreq *= 2 ** octaveShift;

    let sd = fromScale.find(
      (s) => s.stringIndex === stringIndex && s.pitchClassIndex === pcIndex
    );
    let mappedNote =
      sd && isNumber(sd.map)
        ? SCALE_DEGREE_KEYBOARD_MAPPING.get(sd.map)!
        : null;

    let delay = (startT - audio.now()) * 1000;
    return setTimeout(() => {
      audio.noteOn(keyCode, soundingFreq, 1, mappedNote, output);
      let playingMarker = {
        stringIdx: stringIndex,
        pcIdx: pcIndex,
        keyCode: -2,
      };
      setCurrentlyPlaying((p) => [...p, playingMarker]);
      setTimeout(() => {
        audio.noteOff(keyCode, output);
        setCurrentlyPlaying((p) => p.filter((pl) => pl !== playingMarker));
      }, 150);
    }, delay);
  }

  function soundScale() {
    if (cancelCurrentScalePlayer.current) cancelCurrentScalePlayer.current();

    let del = audio.now(),
      keyCode = -2;
    let playScale = getMonotonicallyIncreasingScale(scale, strings).filter(
      (s) => s.role && (s.role === "tonic" || s.role === "primary")
    );
    let tonicIdx = findIndex(playScale, (s) => s.role === "tonic");
    let players: NodeJS.Timeout[] = [];
    for (let i = tonicIdx; i < tonicIdx + playScale.length; i++) {
      let wrapI = i % playScale.length;
      let prev = wrapI > 0 ? wrapI - 1 : playScale.length - 1;
      let prevOctaveShift = i > tonicIdx ? 0 : -1;
      players.push(
        soundDivision(
          playScale[wrapI].stringIndex,
          playScale[wrapI].pitchClassIndex,
          scale,
          0,
          keyCode--,
          del
        )
      );
      del += 0.15;
      players.push(
        soundDivision(
          playScale[prev].stringIndex,
          playScale[prev].pitchClassIndex,
          scale,
          prevOctaveShift,
          keyCode--,
          del
        )
      );
      del += 0.15;
      players.push(
        soundDivision(
          playScale[wrapI].stringIndex,
          playScale[wrapI].pitchClassIndex,
          scale,
          0,
          keyCode--,
          del
        )
      );
      del += 0.15;
    }
    players.push(
      soundDivision(
        playScale[tonicIdx].stringIndex,
        playScale[tonicIdx].pitchClassIndex,
        scale,
        1,
        keyCode--,
        del
      )
    );
    cancelCurrentScalePlayer.current = () => {
      players.forEach((p) => clearTimeout(p));
    };
  }

  let onSavedTuningSystem = (ts: TuningSystem) => {
    history.replace(
      `/leimma/${ts.id}/refpitch/${formatRefPitch(
        ts.refPitchNoteMidi,
        ts.refPitchNoteName
      )}/tuningsystem/${formatStrings(
        ts.strings
      )}/scale/${scaleId}/${solmizationString}${
        scaleString ? "/" + scaleString : ""
      }`
    );
    loadExistingRecords();
    setTuningSystemDialogReloads((r) => r + 1);
  };

  let onDeletedTuningSystem = () => {
    history.replace(
      `/leimma/new/refpitch/${refPitchString}/tuningsystem/${stringsString}/scale/new/${solmizationString}${
        scaleString ? "/" + scaleString : ""
      }`
    );
    setTuningSystemDialogReloads((r) => r + 1);
  };

  let onSavedScale = (s: Scale) => {
    history.replace(
      `/leimma/${tuningSystemId}/refpitch/${refPitchString}/tuningsystem/${stringsString}/scale/${
        s.id
      }/${solmizationString}${scaleString ? "/" + scaleString : ""}`
    );
    loadExistingRecords();
    setTuningSystemDialogReloads((r) => r + 1);
  };

  let onDeletedScale = () => {
    history.replace(
      `/leimma/${tuningSystemId}/refpitch/${refPitchString}/tuningsystem/${stringsString}/scale/new/${solmizationString}${
        scaleString ? "/" + scaleString : ""
      }`
    );
    loadExistingRecords();
    setTuningSystemDialogReloads((r) => r + 1);
  };

  let onPickTuningSystem = (ts: TuningSystem) => {
    history.push(
      `/leimma/${ts.id}/refpitch/${formatRefPitch(
        ts.refPitchNoteMidi,
        ts.refPitchNoteName
      )}/tuningsystem/${formatStrings(ts.strings)}/scale/new/english`
    );
  };

  let onApplyTuningSnapshot = (snapshot: TuningSnapshotContent) => {
    if (!snapshot.tuningSystem || !snapshot.scale) return;
    history.push(
      `/leimma/${snapshot.tuningSystem.id}/refpitch/${formatRefPitch(
        snapshot.tuningSystem.refPitchNoteMidi,
        snapshot.tuningSystem.refPitchNoteName
      )}/tuningsystem/${formatStrings(snapshot.tuningSystem.strings)}/scale/${
        snapshot.scale.id
      }/${formatSolmization(snapshot.scale.solmization)}/${formatScale(
        snapshot.scale?.scaleDegrees
      )}`
    );
  };

  let existingScale = useMemo(
    () =>
      scaleId && existingScales
        ? existingScales.find((s) => s.id === +scaleId)
        : undefined,
    [existingScales, scaleId]
  );

  let [tuningSystemDialogOpen, setTuningSystemDialogOpen] = useState(false);

  let [hasEntered, setHasEntered] = useState(false);
  let onEntered = useCallback(() => setHasEntered(true), []);

  return (
    <>
      <LeimmaHelmet
        tuningSystem={existingTuningSystem}
        scale={{ ...existingScale, solmization, scaleDegrees: scale } as Scale}
      />
      <div className={classNames("scaleScreen", "screen", { hasEntered })}>
        <div className="scaleScreen--htmlOverlay">
          <div className="scaleScreen--tuningSystemInfo">
            {existingTuningSystem && (
              <h1>
                <span className="fieldLabel">Tuning system:</span>{" "}
                {existingTuningSystem.name}
              </h1>
            )}
            {existingScale && (
              <h1>
                <span className="fieldLabel">Subset:</span> {existingScale.name}
              </h1>
            )}
            {!existingScale && <h1>Subset</h1>}
            {existingTuningSystem && (
              <>
                <p className="introText description">
                  <Autolink>
                    {existingScale?.description ||
                      existingTuningSystem.description}
                  </Autolink>
                </p>
                {existingTuningSystem.source && (
                  <p className="introText source">
                    Source: <Autolink>{existingTuningSystem.source}</Autolink>
                  </p>
                )}
              </>
            )}
          </div>
          <p className="introText"></p>
          <button
            className="tuningSystemDialogToggle button small"
            onClick={() => setTuningSystemDialogOpen(true)}
          >
            Switch tuning system
          </button>
          <button
            className="centsRatiosSwitch button small"
            onClick={() =>
              setPitchFormat(pitchFormat === "cents" ? "ratio" : "cents")
            }
          >
            {pitchFormat === "cents" ? (
              <>Display as ratios</>
            ) : (
              <>Display as cents</>
            )}
          </button>
          <button
            className="clearMappingsButton button small"
            onClick={clearMappings}
          >
            Clear all mappings
          </button>
          {isAuthenticated && (
            <button
              className="databaseEntryButton button small"
              onClick={() => setDatabaseEntryDialogOpen(true)}
            >
              {hasAdminAccess ? (
                <>Admin Database Entry</>
              ) : (
                <>Save to My Tunings</>
              )}
            </button>
          )}

          {selectedDivisionIndex ? (
            <>
              <div className="inputGroup">
                <label>Map to keyboard:</label>
                <MidiMappingSelection
                  currentMapping={
                    getScaleDegree(
                      selectedDivisionIndex.stringIdx,
                      selectedDivisionIndex.pcIdx
                    )?.map ?? null
                  }
                  reservedMappings={getAllMidiMappings()}
                  onChangeMapping={(mapping) =>
                    setMidiMapping(
                      selectedDivisionIndex!.stringIdx,
                      selectedDivisionIndex!.pcIdx,
                      mapping
                    )
                  }
                />
              </div>
              <div className="inputGroup secondary">
                <label>Role:</label>
                <ScaleDegreeRoleSelection
                  currentRole={
                    getScaleDegree(
                      selectedDivisionIndex.stringIdx,
                      selectedDivisionIndex.pcIdx
                    )?.role
                  }
                  isMapped={isNumber(
                    getScaleDegree(
                      selectedDivisionIndex.stringIdx,
                      selectedDivisionIndex.pcIdx
                    )?.map
                  )}
                  onChangeRole={(role) =>
                    setScaleDegreeRole(
                      selectedDivisionIndex!.stringIdx,
                      selectedDivisionIndex!.pcIdx,
                      role
                    )
                  }
                />
                <label>Cents/ratio:</label>
                <PitchInput
                  className="centsRatioInput"
                  numberInputClassName="small"
                  pitch={
                    strings[selectedDivisionIndex.stringIdx].pitchClasses[
                      selectedDivisionIndex.pcIdx
                    ]
                  }
                  onChange={onUpdateSelectedPitch}
                />
              </div>
            </>
          ) : (
            <>
              <div className="solmizationInput inputGroup">
                <label>Solmization:</label>
                <SolmizationDropdown
                  solmization={solmization}
                  onSelectSolmization={onChangeSolmization}
                />
              </div>
              {existingScales.length > 0 && (
                <div className="existingScaleInput inputGroup">
                  <label>Existing subset:</label>
                  <ScalePicker
                    scales={existingScales}
                    currentScaleId={scaleId ? +scaleId : -1}
                    onPickScale={onPickExistingScale}
                  />
                </div>
              )}
            </>
          )}
          <OnScreenKeyboard
            isOpen={onScreenKeyboardOpen}
            strings={strings}
            scale={scale}
            scaleDegreeNames={scaleDegreeNames}
            currentlyPlaying={currentlyPlaying}
            keyboardOctave={keyboardOctave}
            pitchFormat={pitchFormat}
            onToggle={() => setOnScreenKeyboardOpen((o) => !o)}
            onKeyDown={(note) => onKeyDown(-1, note, keyboardOctave, 1)}
            onKeyUp={(note) => onKeyUp(-1, note, keyboardOctave)}
            onKeyboardOctaveChange={setKeyboardOctave}
          />
          <MIDIInput
            onKeyDown={(pc, octave, velocity) =>
              onKeyDown(-(pc + octave * 12), pc, octave, velocity)
            }
            onKeyUp={(pc, octave) => onKeyUp(-(pc + octave * 12), pc, octave)}
          />
          <MIDIOutput
            className="withInput"
            productName="Leimma"
            showInternalOptions
            fixed
            withLabel
          />
          <ScalaExport
            className="withMidiInput"
            tuningSystemRefPitchSemitones={refPitch.semitones}
            tuningSystemRefPitchNote={refPitch.note}
            tuningSystemStrings={strings}
            scale={scale}
            tuningSystemMeta={existingTuningSystem ?? undefined}
            scaleMeta={existingScale ?? undefined}
          />
          <Breadcrumbs
            currentStep={3}
            currentDescription="All scales or modes are subsets of a tuning system. Most often they use between 3 and 12 notes. Click upper division to hear. Select lower division and use your QWERTY or MIDI keyboard to map, or choose an existing subset from the menu. Play."
          />
          {isAuthenticated && (
            <ScaleDatabaseEntryDialog
              isOpen={databaseEntryDialogOpen}
              tuningSystemId={tuningSystemId}
              tuningSystemRefPitch={refPitch}
              tuningSystemStrings={strings}
              id={scaleId}
              scale={scale}
              solmization={solmization}
              isAdmin={hasAdminAccess}
              onTuningSystemSaved={onSavedTuningSystem}
              onTuningSystemDeleted={onDeletedTuningSystem}
              onSaved={onSavedScale}
              onDeleted={onDeletedScale}
              onClose={() => setDatabaseEntryDialogOpen(false)}
            />
          )}
          <TuningSnapshots
            existingTuningSystem={existingTuningSystem}
            existingScale={existingScale}
            scaleDegrees={scale}
            solmization={solmization}
            isOpen={tuningSnapshotsOpen}
            onApplySnapshot={onApplyTuningSnapshot}
            onToggle={() => setTuningSnapshotsOpen((o) => !o)}
          />
        </div>
        <SVGCanvas className="scaleScreen--canvas">
          <TuningSystemWheel
            strings={strings}
            solmization={solmization}
            scale={scale}
            selectedIndex={selectedDivisionIndex}
            highlightedIndexes={currentlyPlaying}
            pitchFormat={pitchFormat}
            onSetTonic={onUpdateTonic}
            onUpdateScale={onUpdateScale}
            onSoundScale={soundScale}
            onSoundIndex={soundDivision}
            onSelectIndex={onUpdateSelectedDivisionIndex}
            onEntered={onEntered}
          />
        </SVGCanvas>
        <QwertyInput
          currentOctave={keyboardOctave}
          onKeyDown={(keyCode, pc, octave) => onKeyDown(keyCode, pc, octave, 1)}
          onKeyUp={onKeyUp}
          onOctaveChange={setKeyboardOctave}
        />
        <UserGuideLink />
        <UserInfo />
      </div>{" "}
      <ChooseTuningSystemDialog
        isOpen={tuningSystemDialogOpen}
        reloads={tuningSystemDialogReloads}
        onClose={() => setTuningSystemDialogOpen(false)}
        onTuningSystemChosen={onPickTuningSystem}
      />
    </>
  );
};
