import React from "react";

import "./NotSupported.scss";

export const NotSupported: React.FC = () => {
  return (
    <div className="notSupportedWrapper">
      <div className="notSupported">
        Sorry but Apotome doesn't work on mobile. Please use Chrome or Firefox on a desktop/laptop.
      </div>
    </div>
  );
};
