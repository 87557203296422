import ReconnectingWebSocket from 'reconnecting-websocket';
import { EventEmitter } from "events";
import { VisualizerConnectionSettings } from "./types";
import { isEqual, isNumber, uniqWith } from 'lodash';
import { Scale, TuningSystem } from '../main/core';
import { getLeimmaLink } from '../urlSerialization';

let socket: ReconnectingWebSocket | null = null;
let currentSettings: VisualizerConnectionSettings | null = null;
let trackSubscriptions = new Map<string, () => void>();
export let visualizerEvents = new EventEmitter();

export function onSettingsUpdated(newSettings: VisualizerConnectionSettings) {
    if (newSettings.on) {
        if (socket && currentSettings?.url !== newSettings.url) {
            socket.close();
            socket = null;
        }
        if (!socket) {
            console.log('Opening visualizer connection', newSettings.url)
            socket = new ReconnectingWebSocket(newSettings.url);
            socket.addEventListener('open', () => sendStateEvent(socket!));
            socket.addEventListener('close', () => sendStateEvent(socket!));
            socket.addEventListener('error', () => sendStateEvent(socket!));
        }
    } else {
        if (socket) {
            console.log('Closing visualizer connection');
            socket.close();
            socket = null;
        }
    }
    currentSettings = newSettings;
}

function sendStateEvent(socket: ReconnectingWebSocket) {
    let state = '';
    if (socket.readyState === ReconnectingWebSocket.CLOSED) state = 'closed';
    if (socket.readyState === ReconnectingWebSocket.CLOSING) state = 'closing';
    if (socket.readyState === ReconnectingWebSocket.CONNECTING) state = 'connection';
    if (socket.readyState === ReconnectingWebSocket.OPEN) state = 'open';
    visualizerEvents.emit('stateChange', state);
}


export function onTrackAdded(id: string, events: EventEmitter) {
    let onNote = ({ cents, octave, freq, duration, velocity }: { cents?: number, octave?: number, freq?: number, duration?: number, velocity?: number }) => {
        sendNoteEvent(id, cents, octave, freq, duration, velocity);
    };
    events.on('note', onNote);
    trackSubscriptions.set(id, () => events.off('note', onNote));
}

export function sendNoteEvent(trackId: string, cents: number | undefined, octave: number | undefined, freq: number | undefined, duration: number | undefined, velocity: number | undefined) {
    if (socket && isNumber(cents)) {
        let noteMessage = {
            event: 'note',
            track: +trackId,
            cents,
            octave,
            freq,
            duration,
            velocity
        };
        // console.log('Visualizer out', noteMessage);
        socket.send(JSON.stringify(noteMessage));
    }
}

export function sendCurrentTunings(tunings: { tuningSystem?: TuningSystem; scale?: Scale }[]) {
    sendTuningsData(getTuningsData(tunings));
}

export function sendTuningsData(tuningsData: { tuningSystem: string, category: string, scale: string, leimmaUrl: string }[]) {
    if (socket) {
        socket.send(JSON.stringify({ event: 'tunings', tunings: tuningsData }));
    }
}

export function getTuningsData(tunings: { tuningSystem?: TuningSystem; scale?: Scale }[]) {
    return uniqWith(
        tunings
            .filter(t => t.tuningSystem && t.scale)
            .map(t => ({
                tuningSystem: t.tuningSystem!.name,
                category: getCategoryName(t.tuningSystem!),
                scale: t.scale!.name,
                leimmaUrl: getLeimmaLink(t.tuningSystem, t.scale)
            })),
        isEqual
    );
}

function getCategoryName(tuningSystem: TuningSystem): string {
    if (tuningSystem.category.startsWith('User')) {
        return 'User tuning'
    } else {
        return tuningSystem!.category;
    }
}

export function onTrackRemoved(id: string) {
    if (trackSubscriptions.has(id)) {
        trackSubscriptions.get(id)!();
        trackSubscriptions.delete(id);
    }
}

export function onAllTracksRemoved() {
    trackSubscriptions.forEach(unSub => unSub());
    trackSubscriptions.clear();
}
