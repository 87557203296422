import React, { useEffect, useRef } from "react";
import { getDraw, getTransport } from "tone";

import "./TempoVisualizer.scss";

export const TempoVisualizer: React.FC = () => {
  let point1Ref = useRef<HTMLDivElement>(null);
  let point2Ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let i = 0;
    let sched = getTransport().scheduleRepeat((time) => {
      getDraw().schedule(() => {
        let on = i % 2 === 0 ? point1Ref.current! : point2Ref.current!;
        let off = i % 2 === 0 ? point2Ref.current! : point1Ref.current!;
        on.classList.add("on");
        off.classList.remove("on");
        i++;
      }, time);
    }, "4n");
    return () => {
      getTransport().clear(sched);
    };
  }, []);

  return (
    <div className="tempoVisualizer">
      <div className="tempoVisualizer--point" ref={point1Ref}></div>
      <div className="tempoVisualizer--point" ref={point2Ref}></div>
    </div>
  );
};
