import React from "react";
import { Helmet } from "react-helmet";
import { Scale, TuningSystem } from "./main/core";
import { getLeimmaLink } from "./urlSerialization";

interface LeimmaHelmetProps {
  tuningSystem?: TuningSystem | null;
  scale?: Scale | null;
}
export const LeimmaHelmet: React.FC<LeimmaHelmetProps> = ({
  tuningSystem,
  scale,
}) => {
  let title = "Leimma";
  if (tuningSystem) {
    title += " - " + tuningSystem.name;
  }
  if (scale?.name) {
    title += " - " + scale.name;
  }
  let url = "https://isartum.net/leimma";
  if (tuningSystem) {
    url = `https://isartum.net${getLeimmaLink(
      tuningSystem,
      scale ?? undefined
    )}`;
  }
  return (
    <Helmet>
      <title>{title}</title>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="https://isartum.net/leimma-apple-touch-icon.png?v=QEGmboy6kP"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="https://isartum.net/leimma-favicon-32x32.png?v=QEGmboy6kP"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="https://isartum.net/leimma-favicon-16x16.png?v=QEGmboy6kP"
      />
      <link 
        rel="manifest" href="https://isartum.net/leimma-site.webmanifest" 
      />
      <link
        rel="mask-icon"
        href="https://isartum.net/leimma-safari-pinned-tab.svg"
        color="#8870ff"
      />
      <meta name="msapplication-TileColor" content="#8870ff" />
      <meta name="theme-color" content="#8870ff" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@KhyamAllami" />
      <meta name="twitter:creator" content="@KhyamAllami" />
      <meta
        name="twitter:title"
        content={title}
      />
      <meta
        name="twitter:description"
        content="Explore, create, hear and play microtonal tuning systems."
      />
      <meta
        name="twitter:image"
        content="https://isartum.net/leimma-card-twitter.png"
      />
      <meta
        property="og:url"
        content={url}
      />
      <meta
        property="og:title"
        content={title}
      />
      <meta
        property="og:description"
        content="Explore, create, hear and play microtonal tuning systems."
      />
      <meta
        property="og:image"
        content="https://isartum.net/leimma-card-facebook.png"
      />
      <meta
        name="description"
        content="Explore, create, hear and play microtonal tuning systems."
      />
    </Helmet>
  );
};
