import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { UserProfile } from "./main/core";
import * as api from "./main/api";

interface UserProfileContextProps {
    profilePromise?: Promise<UserProfile | undefined>,
    updateProfilePromise: (profilePromise?: Promise<UserProfile | undefined>) => void;
}
export let UserProfileContext = createContext<UserProfileContextProps>({
    updateProfilePromise: () => { }
})

let _loading = false;

export let useUserProfile = () => {
    let { profilePromise, updateProfilePromise } = useContext(UserProfileContext);
    let { user, isLoading: isLoadingAccess, getAccessTokenSilently } = useAuth0();
    let [profile, setProfile] = useState<UserProfile>();
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        if (isLoadingAccess || !user || profilePromise) return;
        if (!_loading) {
            console.log('Refreshing user profile from API');
            _loading = true;
            setLoading(true);
            updateProfilePromise(getAccessTokenSilently().then(api.loadUserProfile).then((profile) => {
                _loading = false;
                return profile;
            }));
        }
    }, [isLoadingAccess, user, getAccessTokenSilently, profilePromise, updateProfilePromise]);


    useEffect(() => {
        profilePromise?.then(profile => {
            setProfile(profile);
            setLoading(false);
        });
    }, [profilePromise]);

    let updateProfile = useCallback(async (newProfile: UserProfile) => {
        await api.saveUserProfile(newProfile, await getAccessTokenSilently());
        updateProfilePromise(Promise.resolve(newProfile));
    }, [updateProfilePromise, getAccessTokenSilently]);

    return { profile, updateProfile, loading };
}