import React, { useState } from "react";
import classNames from "classnames";

import "./ScreenSizeWarning.scss";

export const ScreenSizeWarning: React.FC = () => {
  let [dismissed, setDismissed] = useState(false);

  return (
    <div
      className={classNames("screenSizeWarning", { dismissed })}
      onClick={() => setDismissed(true)}
    >
      Leimma currently works best on large screens. Please try on your
      laptop/desktop!
    </div>
  );
};
