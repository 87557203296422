import React, { useEffect, useState, useCallback } from "react";
import { sortBy, uniq } from "lodash";
import classNames from "classnames";

import { TuningSystemHeader, TuningSystem } from "./main/core";
import * as api from "./main/api";

import "./ChooseTuningSystemDialog.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useAdminAccess } from "./useAdminAccess";
import { Autolink } from "./Autolink";

interface ChooseTuningSystemDialogProps {
  isOpen: boolean;
  previousSelection?: TuningSystem;
  reloads?: number;
  onTuningSystemChosen: (ts: TuningSystem) => void;
  onClose: () => void;
}
export const ChooseTuningSystemDialog: React.FC<ChooseTuningSystemDialogProps> = ({
  isOpen,
  previousSelection,
  reloads,
  onTuningSystemChosen,
  onClose,
}) => {
  let { user, getAccessTokenSilently, isLoading } = useAuth0();
  let { hasAdminAccess } = useAdminAccess();
  let [tuningSystems, setTuningSystems] = useState<TuningSystemHeader[]>([]);
  let [selectedCategory, setSelectedCategory] = useState<string>();

  useEffect(() => {
    if (!previousSelection) return;
    setSelectedCategory(previousSelection.category);
  }, [previousSelection, tuningSystems]);

  useEffect(() => {
    if (isLoading) return;
    if (user) {
      getAccessTokenSilently()
        .then(api.loadTuningSystems)
        .then(setTuningSystems);
    } else {
      api.loadTuningSystems().then(setTuningSystems);
    }
  }, [isLoading, user, getAccessTokenSilently, reloads]);

  let getTuningSystemCategories = useCallback(() => {
    let cats = uniq(tuningSystems.map((t) => t.category));
    if (hasAdminAccess) {
      return sortBy(cats);
    } else {
      return [
        ...cats.filter((c) => c.startsWith("User")),
        ...sortBy(cats.filter((c) => !c.startsWith("User"))),
      ];
    }
  }, [tuningSystems, hasAdminAccess]);

  let getCategoryName = useCallback(
    (cat: string) => {
      if (!hasAdminAccess && cat === `User: ${user?.email}`) {
        return "My Tunings";
      } else {
        return cat;
      }
    },
    [user, hasAdminAccess]
  );

  let getTuningSystemsForSelectedCategory = useCallback(() => {
    return sortBy(
      tuningSystems.filter((ts) => ts.category === selectedCategory),
      (t) => t.name
    );
  }, [tuningSystems, selectedCategory]);

  let selectAndClose = useCallback(
    async (tuningSystem: TuningSystemHeader) => {
      let ts = await api.loadTuningSystem(
        tuningSystem.id!,
        user ? await getAccessTokenSilently() : undefined
      );
      onTuningSystemChosen(ts);
      onClose();
    },
    [onTuningSystemChosen, onClose, user, getAccessTokenSilently]
  );

  return (
    <div
      className={classNames("chooseTuningSystemDialogWrapper", { isOpen })}
      onClick={(evt) => evt.target === evt.currentTarget && onClose()}
    >
      <div className="chooseTuningSystemDialog">
        <h2>Select a tuning system</h2>
        <div className="chooseTuningSystemDialog--columns">
          <ul className="chooseTuningSystemDialog--column chooseTuningSystemDialog--categoryColumn">
            {getTuningSystemCategories().map((cat) => (
              <li
                key={cat}
                onClick={() => setSelectedCategory(cat)}
                className={classNames("chooseTuningSystemDialog--listItem", {
                  isSelected: cat === selectedCategory,
                })}
              >
                <div className="chooseTuningSystemDialog--categoryName">
                  {getCategoryName(cat)}
                </div>
              </li>
            ))}
          </ul>
          <ul className="chooseTuningSystemDialog--column chooseTuningSystemDialog--tuningSystemColumn">
            {getTuningSystemsForSelectedCategory().map((ts) => (
              <li
                key={ts.id}
                onClick={() => selectAndClose(ts)}
                className={classNames("chooseTuningSystemDialog--listItem")}
              >
                <div className="chooseTuningSystemDialog--tuningSystemName">
                  {ts.name}
                </div>
                <div className="chooseTuningSystemDialog--tuningSystemDescription">
                  <Autolink>{ts.description}</Autolink>
                </div>
                {ts.source && (
                  <div className="chooseTuningSystemDialog--tuningSystemSource">
                    Source: <Autolink>{ts.source}</Autolink>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="chooseTuningSystemDialog--actions">
          <button className="button isPrimary" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
