import * as api from '../main/api';
import { memoize } from "lodash";

export let loadTuningSystems = memoize(
    (accessToken?: string) => api.loadTuningSystems(accessToken)
);

export let loadTuningSystem = memoize(
    (id: number, accessToken?: string) => api.loadTuningSystem(id, accessToken),
    (...a: any[]) => a.join('-')
);

export let loadScales = memoize(
    (tuningSystemId: number, accessToken?: string) => api.loadScales(tuningSystemId, accessToken),
    (...a: any[]) => a.join('-')
)

export let loadScale = memoize(
    (id: number, accessToken?: string) => api.loadScale(id, accessToken),
    (...a: any[]) => a.join('-')
);