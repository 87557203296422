import React from "react";
import { useWindowSize } from "@react-hook/window-size";

interface SVGCanvasProps {
  className?: string;
  topAligned?: boolean;
  onPointerMove?: (evt: React.PointerEvent) => void;
}
export const SVGCanvas: React.FC<SVGCanvasProps> = ({
  className,
  topAligned,
  children,
  onPointerMove,
}) => {
  let [width, height] = useWindowSize();

  return (
    <svg
      className={className}
      viewBox={`${-width / 2} ${
        topAligned ? 0 : -height / 2
      } ${width} ${height}`}
      onPointerMove={onPointerMove}
    >
      {children}
    </svg>
  );
};
