import { Rnd } from 'react-rnd';
import { immediate } from 'tone';
import { getClosestMIDINote, getMIDIPitchBend } from '../../main/audio';
import { NumericInstrumentBank, ParamModulation } from '../types';
import { loadCommon, loadScript } from './common';

declare let WAM: any;

let loadPromise: Promise<NumericInstrumentBank[]> | null = null;
let banks: NumericInstrumentBank[] = [];
async function _load(audioCtx: AudioContext) {
    await loadCommon();
    await loadScript('/wam/dexed/dexed.js');
    if (!WAM.DEXED) return []; // No AudioWorklet
    await WAM.DEXED.importScripts(audioCtx);
    await new Promise<void>((resolve, reject) => {
        let link = document.createElement("link");
        link.rel = "import";
        link.href = "/wam/dexed/dexed.html";
        link.onload = () => {
            resolve();
        };
        link.onerror = () => {
            reject();
        };
        document.head.appendChild(link);
    });
    await Promise.all(WAM.DEXED.banklist.map(async (bankName: string) => {
        let presets = await WAM.DEXED.loadBank(bankName);
        banks.push({ name: bankName, presets });
    }))
    return banks;
}
function load(audioCtx: AudioContext) {
    if (!loadPromise) loadPromise = _load(audioCtx);
    return loadPromise;
}

export class DEXED {

    private dest: AudioNode;
    private wam?: any;
    private outputGain?: any;
    private gui?: any;
    private onParamChangeListener?: (param: number, value: number, newPackedPatch: number[]) => void;

    public currentBank: number | null = null;
    public currentPreset: number | null = null;

    static preload(audioCtx: AudioContext) {
        return load(audioCtx);
    }

    constructor(private audioCtx: AudioContext, dest: any) {
        while (dest.input) {
            dest = dest.input;
        }
        if (dest._nativeAudioNode) {
            dest = dest._nativeAudioNode;
        }
        this.dest = dest;
    }

    triggerAttackRelease(frequency: number, duration: number, time: number, velocity: number) {
        let midiNote = getClosestMIDINote(frequency, 200, null);
        let pitchBend = getMIDIPitchBend(frequency, midiNote, 200);
        var bendLevel = Math.round((pitchBend + 1) / 2 * 16383);
        var bendMsb = (bendLevel >> 7) & 0x7F;
        var bendLsb = bendLevel & 0x7F;

        this.wam?.onMidi([0x90, midiNote, velocity * 127], time);
        this.wam?.onMidi([0xE0, bendLsb, bendMsb], time);

        this.wam?.onMidi([0x80, midiNote, velocity * 127], time + duration);
    }

    sendControlChange(cc: number, value: number, time: number) {
        this.wam?.onMidi([0xB0, cc, value], time);
    }

    setParam(param: number, value: number, newPackedPatch: number[]) {
        this.wam.applyParam(param, value, newPackedPatch);
    }

    modulate(param: number, modulations: ParamModulation[]) {
        this.wam?.modulateParam(param, modulations);
    }

    resetModulation(param: number, time: number) {
        this.wam?.resetParamModulation(param, time);
    }

    onGUIParamChange(listener: (param: number, value: number, newPackedPatch: number[]) => void) {
        this.onParamChangeListener = listener;
        if (this.wam) {
            this.wam.onparamchange = listener;
        }
        return () => {
            this.onParamChangeListener = undefined;
            if (this.wam) {
                this.wam.onparamchange = undefined;
            }
        }
    }

    setPreset(bank: number, preset: number, patchState: number[]) {
        if (bank !== this.currentBank || preset !== this.currentPreset) {
            this.switchWam(patchState);
            this.currentBank = bank;
            this.currentPreset = preset;
        }
    }

    private switchWam(patchState: number[]) {
        if (this.wam) {
            let wam = this.wam;
            let oGain = this.outputGain!;
            oGain.gain.setTargetAtTime(0, immediate(), 0.33);
            wam.onparamchange = undefined;
            setTimeout(() => {
                wam.dispose();
                oGain.disconnect();
            }, 1000);
        }
        this.wam = new WAM.DEXED(this.audioCtx);
        if (this.gui) {
            this.wam._gui = this.gui;
            this.gui.plug = this.wam;
        }
        this.outputGain = this.audioCtx.createGain();
        this.wam.connect(this.outputGain);
        this.outputGain.connect(this.dest);
        let patch = this.wam?.getPatchFromPackedPatch(patchState);
        this.wam?.setPatch(patch);
        this.wam.onparamchange = this.onParamChangeListener;
    }

    async openGUI(container: Rnd) {
        if (!this.gui) {
            this.gui = this.wam.openGUI();
            let containerEl = container.getSelfElement()!.querySelector('.pluginGUIContent')!;
            containerEl.appendChild(this.gui);
            container.updateSize({ width: this.gui.width, height: this.gui.height + 15 });
            container.updatePosition({ x: window.innerWidth - this.gui.width - 20, y: 20 });
        }
    }

    closeGUI() {
        this.wam?.closeGUI();
        this.gui = undefined;
    }

    dispose(time = immediate()) {
        this.closeGUI();
        this.outputGain?.gain.setTargetAtTime(0, time, 0.33);
        setTimeout(() => {
            this.wam?.dispose();
            this.outputGain?.disconnect();
        }, 1000);
    }
}
