
export class KarplusStrongWorklet {

    static registered = false;
    static wasmBytes: ArrayBuffer;
    static async register(audioCtx: AudioContext) {
        if (!KarplusStrongWorklet.registered) {
            KarplusStrongWorklet.registered = true;
            KarplusStrongWorklet.wasmBytes = await window.fetch("/wasm-audio/wasm_audio_bg.wasm").then(res => res.arrayBuffer());
            await audioCtx.audioWorklet?.addModule(`/KarplusStrongProcessor.js`);
        }
    }

    worklet: AudioWorkletNode;
    stringIndex = 0;

    constructor(audioCtx: AudioContext) {
        this.worklet = new AudioWorkletNode!(audioCtx, 'karplus-strong-processor');
    }

    init() {
        this.worklet.port.postMessage({
            type: "send-wasm-module",
            wasmBytes: KarplusStrongWorklet.wasmBytes,
        });
        this.worklet.port.start();
        return new Promise<void>(res => this.worklet.port.addEventListener('message', (msg) => {
            if (msg.data.type === 'wasm-module-loaded') res()
        }, { once: true }));
    }

    playNext(freq: number, time: number, velocity: number, duration?: number) {
        let freqParam = this.worklet!.parameters.get(`frequency${this.stringIndex + 1}`)!;
        freqParam.value = freq;
        this.worklet!.port.postMessage({ type: 'play', string: this.stringIndex, time, velocity, duration });
        let sIdx = this.stringIndex;
        this.stringIndex++;
        if (this.stringIndex > 3) {
            this.stringIndex = 0;
        }
        return sIdx;
    }

    setFrequency(string: number, freq: number) {
        let freqParam = this.worklet!.parameters.get(`frequency${string + 1}`)!;
        freqParam.value = freq;
    }

    stopString(string: number, time: number) {
        this.worklet!.port.postMessage({ type: 'stop', string, time });
    }

    connect(dest: AudioNode) {
        this.worklet!.connect(dest);
    }

    dispose() {
        this.worklet!.disconnect();
    }
}