import React, { useRef, useEffect, useState } from "react";
import * as Nexus from "nexusui";
import classNames from "classnames";

import "./PanControl.scss";
import { round } from "lodash";

interface PanControlProps {
  value: number;
  label: string;
  disabled?: boolean;
  onUpdateValue: (newValue: number) => void;
}
export const PanControl: React.FC<PanControlProps> = ({
  value,
  label,
  disabled,
  onUpdateValue,
}) => {
  let panContainerRef = useRef<HTMLDivElement>(null);
  let [pan, setPan] = useState<any>(null);

  let changingValue = useRef(false);
  useEffect(() => {
    let pan = new Nexus.Pan(panContainerRef.current, { size: [100, 20] });
    if (!disabled) {
      pan.on("change", (val: any) => {
        if (!changingValue.current) {
          onUpdateValue(val.value);
        }
      });
    }
    setPan(pan);
    return () => {
      pan.destroy();
      setPan(null);
    };
  }, [onUpdateValue, disabled]);
  useEffect(() => {
    if (!pan) return;
    changingValue.current = true;
    pan.value = value;
    changingValue.current = false;
  }, [value, pan]);

  return (
    <div className={classNames("panControl", { isDisabled: disabled })}>
      <div className="panControl--label">{label}</div>
      <div className="panControl--pan" ref={panContainerRef}></div>
      <div className="panControl--valueLabel">{round(value, 1)}</div>
    </div>
  );
};
