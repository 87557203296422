import React, { useRef, useEffect, useState } from "react";
import * as Nexus from "nexusui";
import classNames from "classnames";

import "./KnobControl.scss";
import { round } from "lodash";

interface KnobControlProps {
  value: number;
  min: number;
  max: number;
  step: number;
  label: string;
  valueFunction?: (v: number) => string;
  valueSuffix?: string;
  disabled?: boolean;
  onUpdateValue: (newValue: number) => void;
}
export const KnobControl: React.FC<KnobControlProps> = ({
  value,
  min,
  max,
  step,
  label,
  valueFunction = (v) => round(v, 1),
  valueSuffix,
  disabled,
  onUpdateValue,
}) => {
  let knobContainerRef = useRef<HTMLDivElement>(null);
  let [knob, setKnob] = useState<any>(null);

  let latestUpdateValue = useRef<(newValue: number) => void>(onUpdateValue);
  let settingValue = useRef(false);
  useEffect(() => {
    latestUpdateValue.current = onUpdateValue;
  }, [onUpdateValue]);
  useEffect(() => {
    let knob = new Nexus.Dial(knobContainerRef.current, {
      size: [40, 40],
      min,
      max,
      step,
      interaction: "vertical",
    });
    if (!disabled) {
      knob.on("change", (val: number) => {
        if (!settingValue.current) {
          latestUpdateValue.current?.(val);
        }
      });
    }
    setKnob(knob);
    return () => {
      knob.destroy();
      setKnob(null);
    };
  }, [min, max, step, disabled]);
  useEffect(() => {
    if (!knob) return;
    settingValue.current = true;
    knob.value = value;
    knob.min = min;
    knob.max = max;
    knob.step = step;
    settingValue.current = false;
  }, [value, min, max, step, knob]);

  return (
    <div className={classNames("knobControl", { isDisabled: disabled })}>
      <div className="knobControl--label">{label}</div>
      <div className="knobControl--knob" ref={knobContainerRef}></div>
      <div className="knobControl--valueLabel">
        {valueFunction(value)}
        {valueSuffix}
      </div>
    </div>
  );
};
