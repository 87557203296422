import React from "react";
import EventListener from "react-event-listener";
import { MAX_OCTAVE, MIN_OCTAVE } from "../constants";
interface QwertyInputProps {
  currentOctave: number;
  onKeyDown: (keyCode: number, pc: number, octave: number) => void;
  onKeyUp: (keyCode: number, pc: number, octave: number) => void;
  onOctaveChange: (octave: number) => void;
}
export const QwertyInput: React.FC<QwertyInputProps> = ({
  currentOctave,
  onKeyDown,
  onKeyUp,
  onOctaveChange,
}) => {
  function keyDown(evt: KeyboardEvent) {
    if (evt.repeat) return;
    switch (evt.code) {
      case "KeyA":
        onKeyDown(evt.keyCode, 0, currentOctave);
        break;
      case "KeyW":
        onKeyDown(evt.keyCode, 1, currentOctave);
        break;
      case "KeyS":
        onKeyDown(evt.keyCode, 2, currentOctave);
        break;
      case "KeyE":
        onKeyDown(evt.keyCode, 3, currentOctave);
        break;
      case "KeyD":
        onKeyDown(evt.keyCode, 4, currentOctave);
        break;
      case "KeyF":
        onKeyDown(evt.keyCode, 5, currentOctave);
        break;
      case "KeyT":
        onKeyDown(evt.keyCode, 6, currentOctave);
        break;
      case "KeyG":
        onKeyDown(evt.keyCode, 7, currentOctave);
        break;
      case "KeyY":
        onKeyDown(evt.keyCode, 8, currentOctave);
        break;
      case "KeyH":
        onKeyDown(evt.keyCode, 9, currentOctave);
        break;
      case "KeyU":
        onKeyDown(evt.keyCode, 10, currentOctave);
        break;
      case "KeyJ":
        onKeyDown(evt.keyCode, 11, currentOctave);
        break;
      case "KeyK":
        onKeyDown(evt.keyCode, 0, currentOctave + 1);
        break;
      case "KeyO":
        onKeyDown(evt.keyCode, 1, currentOctave + 1);
        break;
      case "KeyL":
        onKeyDown(evt.keyCode, 2, currentOctave + 1);
        break;
      case "KeyP":
        onKeyDown(evt.keyCode, 3, currentOctave + 1);
        break;
      case "Semicolon":
        onKeyDown(evt.keyCode, 4, currentOctave + 1);
        break;
      case "Quote":
        onKeyDown(evt.keyCode, 5, currentOctave + 1);
        break;
      case "BracketRight":
        onKeyDown(evt.keyCode, 6, currentOctave + 1);
        break;
      case "Backslash":
        onKeyDown(evt.keyCode, 7, currentOctave + 1);
        break;
      case "KeyZ":
        onOctaveChange(Math.max(MIN_OCTAVE, currentOctave - 1));
        break;
      case "KeyX":
        onOctaveChange(Math.min(MAX_OCTAVE, currentOctave + 1));
        break;
    }
  }

  function keyUp(evt: KeyboardEvent) {
    switch (evt.code) {
      case "KeyA":
        onKeyUp(evt.keyCode, 0, currentOctave);
        break;
      case "KeyW":
        onKeyUp(evt.keyCode, 1, currentOctave);
        break;
      case "KeyS":
        onKeyUp(evt.keyCode, 2, currentOctave);
        break;
      case "KeyE":
        onKeyUp(evt.keyCode, 3, currentOctave);
        break;
      case "KeyD":
        onKeyUp(evt.keyCode, 4, currentOctave);
        break;
      case "KeyF":
        onKeyUp(evt.keyCode, 5, currentOctave);
        break;
      case "KeyT":
        onKeyUp(evt.keyCode, 6, currentOctave);
        break;
      case "KeyG":
        onKeyUp(evt.keyCode, 7, currentOctave);
        break;
      case "KeyY":
        onKeyUp(evt.keyCode, 8, currentOctave);
        break;
      case "KeyH":
        onKeyUp(evt.keyCode, 9, currentOctave);
        break;
      case "KeyU":
        onKeyUp(evt.keyCode, 10, currentOctave);
        break;
      case "KeyJ":
        onKeyUp(evt.keyCode, 11, currentOctave);
        break;
      case "KeyK":
        onKeyUp(evt.keyCode, 0, currentOctave + 1);
        break;
      case "KeyO":
        onKeyUp(evt.keyCode, 1, currentOctave + 1);
        break;
      case "KeyL":
        onKeyUp(evt.keyCode, 2, currentOctave + 1);
        break;
      case "KeyP":
        onKeyUp(evt.keyCode, 3, currentOctave + 1);
        break;
      case "Semicolon":
        onKeyUp(evt.keyCode, 4, currentOctave + 1);
        break;
      case "Quote":
        onKeyUp(evt.keyCode, 5, currentOctave + 1);
        break;
      case "BracketRight":
        onKeyUp(evt.keyCode, 6, currentOctave + 1);
        break;
      case "Backslash":
        onKeyUp(evt.keyCode, 7, currentOctave + 1);
        break;
    }
  }

  return (
    <EventListener
      target={"document"}
      onKeyDown={keyDown}
      onKeyUp={keyUp}
    ></EventListener>
  );
};
