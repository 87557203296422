import React, { useState, useEffect, useContext } from "react";
import WebMidi, { Input } from "webmidi";
import { range } from "lodash";
import { MIDIContext } from "../MIDIContext";

import "./MIDIInput.scss";

interface MIDIInputProps {
  onKeyDown: (pc: number, octave: number, velocity: number) => void;
  onKeyUp: (pc: number, octave: number) => void;
}
export const MIDIInput: React.FC<MIDIInputProps> = ({ onKeyDown, onKeyUp }) => {
  let { input, onSetInput, onCCIn, onPitchBendIn } = useContext(MIDIContext);
  let [state, setState] = useState<"init" | "error" | "active">(
    WebMidi.enabled ? "active" : "init"
  );
  let [inputs, setInputs] = useState<Input[]>([]);

  let occupiedDevices = input.input ? [input.input] : [];

  useEffect(() => {
    if (input.input) {
      input.input.addListener("noteon", input.channel, (evt) =>
        onKeyDown(evt.note.number % 12, evt.note.octave, evt.velocity)
      );
      input.input.addListener("noteoff", input.channel, (evt) =>
        onKeyUp(evt.note.number % 12, evt.note.octave)
      );
      input.input.addListener("controlchange", input.channel, (evt) =>
        onCCIn(evt.controller.number, evt.value)
      );
      input.input.addListener("pitchbend", input.channel, (evt) =>
        onPitchBendIn(evt.value)
      );
      return () => {
        input.input!.removeListener();
      };
    }
  }, [input, onKeyDown, onKeyUp, onCCIn, onPitchBendIn]);

  let onUpdateCurrentInput = (
    id: string | null,
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (id) {
      let newInput = inputs.find((i) => i.id === id)!;
      onSetInput({ input: newInput, channel: "all" });
    } else {
      onSetInput({ channel: "all" });
    }
    evt.target.blur();
  };

  let onUpdateCurrentInputChannel = (
    ch: string,
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let channel = (ch === "all" ? ch : +ch) as number | "all";
    onSetInput({
      ...input,
      channel,
    });
    evt.target.blur();
  };

  useEffect(() => {
    if (state === "init") {
      WebMidi.enable((err) => {
        setState(err ? "error" : "active");
      });
    }
    if (state === "active") {
      let updateInputs = () => {
        setInputs(Array.from(WebMidi.inputs));
      };
      updateInputs();
      WebMidi.addListener("connected", updateInputs);
      WebMidi.addListener("disconnected", updateInputs);
      return () => {
        WebMidi.removeListener("connected", updateInputs);
        WebMidi.removeListener("disconnected", updateInputs);
      };
    }
  }, [state]);

  return (
    <div className="midiInput">
      {state === "init" && <>MIDI Initializing...</>}
      {state === "error" && (
        <span className="notSupportedError">
          If you wish to enable MIDI, please use Chrome or{" "}
          <a href="https://caniuse.com/#feat=midi">
            a web browser that supports it
          </a>
          .
        </span>
      )}
      {state === "active" && (
        <div className="inputGroup">
          <label>MIDI input:</label>
          <select
            value={input.input ? input.input.id : -1}
            onChange={(evt) => onUpdateCurrentInput(evt.target.value, evt)}
          >
            <option value={-1}>QWERTY</option>
            {inputs.map((input) => (
              <option
                key={input.id}
                value={input.id}
                disabled={
                  !!occupiedDevices.find(
                    (d) =>
                      d.name === input.name &&
                      d.manufacturer === input.manufacturer
                  )
                }
              >
                {input.name}
              </option>
            ))}
          </select>
          {input.input && (
            <select
              value={input.channel}
              onChange={(evt) =>
                onUpdateCurrentInputChannel(evt.target.value, evt)
              }
            >
              <option value={"all"}>All</option>
              {range(1, 17).map((ch) => (
                <option key={ch} value={ch}>
                  {ch}
                </option>
              ))}
            </select>
          )}
        </div>
      )}
    </div>
  );
};
