import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { useWindowWidth } from "@react-hook/window-size";

import {
  formatPitch,
  getPitchCents,
  Pitch,
  reduceIntoOctave,
} from "./main/core";
import {
  getDivisionCentsFromX,
  getDivisionXFromCents,
  OCTAVE_DIVISION_BAND_DIVISION_WIDTH,
  OCTAVE_DIVISION_BAND_HEIGHT,
  OCTAVE_DIVISION_BAND_Y,
} from "./OctaveDivisionBand";
import { useDrag } from "react-use-gesture";
import { isNumber } from "lodash";

interface OctaveDivisionBandDivisionProps {
  pitch: Pitch;
  isFirst: boolean;
  isSelected: boolean;
  isPassive: boolean;
  onHover: () => void;
  onClick: () => void;
  onAdjustingCents: (newCents: number) => void;
  onAdjustedCents: (newCents: number | null) => void;
}
export const OctaveDivisionBandDivision: React.FC<OctaveDivisionBandDivisionProps> = ({
  pitch,
  isFirst,
  isSelected,
  isPassive,
  onHover,
  onClick,
  onAdjustingCents,
  onAdjustedCents,
}) => {
  let windowWidth = useWindowWidth();

  let cents = getPitchCents(pitch);
  let x = getDivisionXFromCents(cents, windowWidth);

  let label = "";
  if (isNumber(pitch.ratioUpper) && isNumber(pitch.ratioLower)) {
    let reduced = formatPitch(reduceIntoOctave(pitch), "ratio");
    let original = formatPitch(pitch, "ratio");
    if (reduced !== original) {
      label = `${original}→${reduced}`;
    } else {
      label = `${reduced}`;
    }
  } else {
    label = `${formatPitch(pitch, "cents")}`;
  }

  let rectRef = useRef<SVGRectElement>(null);
  let draggingRef = useRef(false);
  let bindDrag = useDrag(
    ({ event, xy: [x], first, last, movement: [movedX] }) => {
      event?.preventDefault();
      if (first) draggingRef.current = true;
      let newCents = getDivisionCentsFromX(x, windowWidth);
      if (last) {
        onAdjustedCents(movedX !== 0 ? newCents : null);
        requestAnimationFrame(() => (draggingRef.current = false));
      } else {
        onAdjustingCents(newCents);
      }
    },
    {
      domTarget: rectRef,
      eventOptions: { passive: false },
      axis: "x",
      threshold: 5,
      enabled: !isFirst,
    }
  );
  useEffect(bindDrag, [bindDrag]);

  return (
    <>
      <rect
        ref={rectRef}
        className={classNames("octaveDivisionBand--division", {
          isSelected,
          isPassive,
          isMoveable: !isFirst,
        })}
        x={x - OCTAVE_DIVISION_BAND_DIVISION_WIDTH / 2}
        y={OCTAVE_DIVISION_BAND_Y}
        width={OCTAVE_DIVISION_BAND_DIVISION_WIDTH}
        height={OCTAVE_DIVISION_BAND_HEIGHT}
        onPointerEnter={onHover}
        onClick={() => !draggingRef.current && onClick()}
        data-tip="Click to edit or delete this division, drag to adjust"
      />
      <text
        className="octaveDivisionBand--divisionLabel"
        x={x}
        y={OCTAVE_DIVISION_BAND_Y}
        transform={`rotate(270, ${x + (isFirst ? -5 : 0)}, ${
          OCTAVE_DIVISION_BAND_Y - 5 + (isFirst ? 5 : 0)
        })`}
      >
        {label}
      </text>
    </>
  );
};
