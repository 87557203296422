import React, { useEffect, useState } from "react";
import classNames from "classnames";
import * as api from "./main/api";

import "./TuningSnapshotsManager.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { every, sortBy } from "lodash";
import {
  ScaleDegree,
  TuningSnapshot,
  TuningSnapshotContent,
} from "./main/core";

interface TuningSnapshotsManagerProps {
  isOpen: boolean;
  currentContent?: TuningSnapshotContent;
  onApplySnapshot: (tuning: TuningSnapshotContent) => void;
  onClose?: () => void;
}
export const TuningSnapshotsManager: React.FC<TuningSnapshotsManagerProps> = React.memo(
  ({ isOpen, currentContent, onApplySnapshot, onClose }) => {
    let { isAuthenticated, getAccessTokenSilently } = useAuth0();
    let [snapshots, setSnapshots] = useState<TuningSnapshot[]>([]);
    let [newSnapshotName, setNewSnapshotName] = useState("");
    let [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
      isAuthenticated &&
        getAccessTokenSilently()
          .then(api.loadApotomeTuningSnapshots)
          .then(setSnapshots);
    }, [isAuthenticated, getAccessTokenSilently]);

    let onOpenSnapshot = (snapshot: TuningSnapshot) => {
      onApplySnapshot(snapshot.content);
    };

    let onDeleteSnapshot = async (snapshot: TuningSnapshot) => {
      if (
        window.confirm(
          `Are you sure you want to delete ${snapshot.name} from your favourite subsets?`
        )
      ) {
        let accessToken = await getAccessTokenSilently();
        await api.deleteApotomeTuningSnapshot(snapshot.id, accessToken);
        setSnapshots((s) => s.filter((s) => s.id !== snapshot.id));
      }
    };

    let isNewSnapshotValid = () => {
      return (
        isAuthenticated &&
        newSnapshotName.trim().length > 0 &&
        currentContent?.tuningSystem &&
        currentContent?.scale
      );
    };

    let onSaveNewSnapshot = async (evt: React.FormEvent) => {
      evt.preventDefault();
      setIsSaving(true);
      let accessToken = await getAccessTokenSilently();
      let newSnapshot = await api.createApotomeTuningSnapshot(
        {
          name: newSnapshotName,
          description: "",
          content: pickSnapshotContent(currentContent!),
        },
        accessToken
      );
      setIsSaving(false);
      setNewSnapshotName("");
      setSnapshots((s) => [...s, newSnapshot]);
    };

    return (
      <div
        className={classNames("tuningSnapshotsManager", {
          isOpen,
        })}
      >
        <div className="tuningSnapshotsManager--content">
          {isOpen && onClose && (
            <button className="button closeButton" onClick={() => onClose()}>
              Close
            </button>
          )}
          <h2>Favourite Subsets</h2>

          {isAuthenticated ? (
            <>
              <form
                className="tuningSnapshotsManager--newSnapshot"
                onSubmit={onSaveNewSnapshot}
              >
                <h4>Add Current Subset to Favourites</h4>
                <input
                  type="text"
                  className="input"
                  value={newSnapshotName}
                  onChange={(evt) => setNewSnapshotName(evt.target.value)}
                  onKeyPress={(evt) => evt.nativeEvent.stopImmediatePropagation()}
                  onKeyDown={(evt) => evt.nativeEvent.stopImmediatePropagation()}
                  onKeyUp={(evt) => evt.nativeEvent.stopImmediatePropagation()}
                />
                {(!currentContent?.scale || !currentContent.tuningSystem) &&
                  newSnapshotName.length > 0 && (
                    <div className="tuningSnapshotsManager--notSaved">
                      Save the subset first before adding it to favourites.
                    </div>
                  )}
                <button
                  type="submit"
                  className="button"
                  disabled={isSaving || !isNewSnapshotValid()}
                >
                  {isSaving ? <>Saving&hellip;</> : <>Save</>}
                </button>
              </form>
              <div className="tuningSnapshotsManager--snapshots">
                {sortBy(snapshots, (s) => s.name).map((snapshot) => (
                  <div
                    key={snapshot.id}
                    className={classNames("tuningSnapshotsManager--snapshot", {
                      isCurrent:
                        currentContent &&
                        isEqualContent(
                          snapshot.content,
                          pickSnapshotContent(currentContent)
                        ),
                    })}
                    onClick={() => onOpenSnapshot(snapshot)}
                  >
                    <div className="tuningSnapshotsManager--snapshotName">
                      {snapshot.name}
                    </div>
                    <div className="tuningSnapshotsManager--snapshotTuning">
                      {snapshot.content.tuningSystem?.name}{" "}
                      {snapshot.content.scale?.name}
                    </div>
                    <div className="tuningSnapshotsManager--snapshotActions">
                      <button className="button button--small tuningSnapshotsManager--openSnapshot">
                        Open
                      </button>
                      <button
                        className="button button--small tuningSnapshotsManager--deleteSnapshot"
                        onClick={(evt) => {
                          onDeleteSnapshot(snapshot);
                          evt.stopPropagation();
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="tuningSnapshotsManager--notAuthenticated">
              To manage your favourite subsets, please sign in first.
            </div>
          )}
        </div>
      </div>
    );
  }
);

function isEqualContent(a: TuningSnapshotContent, b: TuningSnapshotContent) {
  return (
    a.scale?.id === b.scale?.id &&
    a.scale?.scaleDegrees.length === b.scale?.scaleDegrees.length &&
    every(
      a.scale?.scaleDegrees,
      (sd, idx) =>
        b.scale?.scaleDegrees[idx] &&
        isEqualScaleDegree(sd, b.scale?.scaleDegrees[idx])
    )
  );
}

function isEqualScaleDegree(a: ScaleDegree, b: ScaleDegree) {
  return (
    a.map === b.map &&
    a.pitchClassIndex === b.pitchClassIndex &&
    a.stringIndex === b.stringIndex &&
    (a.role ?? "none") === (b.role ?? "none")
  );
}

function pickSnapshotContent(
  content: TuningSnapshotContent
): TuningSnapshotContent {
  return {
    tuningSystem: content!.tuningSystem,
    scale: content!.scale,
    activeScaleWeights: content!.activeScaleWeights,
    roleWeights: content!.roleWeights,
    scaleDegreeWeights: content!.scaleDegreeWeights,
  };
}
