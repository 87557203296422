import React, { useState } from "react";
import EventListener from "react-event-listener";
import ReactTooltip from "react-tooltip";

export const Tooltip: React.FC = () => {
  let [disabled, setDisabled] = useState(false);

  return (
    <>
      <ReactTooltip
        effect="float"
        delayShow={500}
        delayUpdate={0}
        delayHide={0}
        disable={disabled}
        globalEventOff={"mousedown"}
        isCapture={true}
        type={"light"}
      />
      <EventListener
        target={document}
        onMouseDown={() => setDisabled(true)}
        onMouseUp={() => setDisabled(false)}
      />
    </>
  );
};
