import React, { useState, useCallback, useEffect } from "react";

interface CompoundNumberInputProps {
  value: string;
  className?: string;
  name?: string;
  onChange: (v: string) => void;
}

export const CompoundNumberInput: React.FC<CompoundNumberInputProps> = ({
  value,
  className,
  name,
  onChange,
}) => {
  let [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  let onKeyDown = useCallback((evt: React.KeyboardEvent) => {
    if (evt.keyCode === 13) {
      // Enter; blur to trigger change upwards
      (evt.currentTarget as HTMLInputElement).blur();
      evt.preventDefault();
    } else if (
      (evt.keyCode >= 48 && evt.keyCode <= 57) ||
      evt.key === "+" ||
      evt.keyCode === 8 ||
      evt.keyCode === 190 ||
      evt.keyCode === 37 ||
      evt.keyCode === 39 ||
      evt.keyCode === 9
    ) {
      // Digit or backspace, or plus, or left/right arrow, or tab; let the regular onChange happen
    } else {
      // Something else; do nothing
      evt.preventDefault();
    }
    evt.nativeEvent.stopImmediatePropagation();
  }, []);

  let onBlur = useCallback(() => {
    let value = internalValue;
    onChange(value);
  }, [internalValue, onChange]);

  return (
    <input
      className={className}
      name={name}
      value={internalValue}
      onKeyDown={onKeyDown}
      onChange={(evt) => setInternalValue(evt.currentTarget.value)}
      onBlur={onBlur}
    />
  );
};
