import { omit, sortBy, startCase } from "lodash";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";

import * as api from "../../main/api";

import { Session, SessionHeader } from "../types";
import { useAdminAccess } from "../../useAdminAccess";
import { SubmitConfirmationDialog } from "./SubmitConfirmationDialog";

import "./SessionList.scss";

interface SessionListProps {
  startAllowed: boolean;
  onNewSession: () => void;
  onPlaySession: (session: Session) => void;
  onEditSession: (session: Session) => void;
}
export const SessionList: React.FC<SessionListProps> = ({
  startAllowed,
  onNewSession,
  onPlaySession,
  onEditSession,
}) => {
  let {
    isAuthenticated,
    isLoading: isAuthLoading,
    getAccessTokenSilently,
  } = useAuth0();
  let { hasAdminAccess } = useAdminAccess();
  let [sessions, setSessions] = useState<SessionHeader[]>([]);
  let [currentlyBrowsing, setCurrentlyBrowsing] = useState<"my" | "community">(
    "my"
  );
  let [publishingSession, setPublishingSession] = useState<SessionHeader>();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then(api.loadApotomeSessions).then(setSessions);
    } else {
      setSessions([]);
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  let getSessionList = () => {
    let sess =
      currentlyBrowsing === "my"
        ? sessions.filter((s) => s.createdBy.currentUser)
        : sessions.filter((s) =>
            hasAdminAccess
              ? s.status === "public" || s.status === "submitted"
              : s.status === "public"
          );
    return sortBy(sess, (s) => s.name);
  };

  let onUpdateSessionStatus = async (
    session: SessionHeader,
    status: "private" | "public" | "submitted"
  ) => {
    let accessToken = await getAccessTokenSilently();
    let updatedSession = { ...session, status };
    await api.updateApotomeSessionHeader({ ...session, status }, accessToken);
    setSessions((sess) =>
      sess.filter((s) => s.id !== session.id).concat([updatedSession])
    );
    setPublishingSession(undefined);
  };

  let onDeleteSession = async (session: SessionHeader) => {
    let getToken = getAccessTokenSilently();
    if (
      window.confirm(
        `Are you sure you want to permanently delete session ${session.name}?`
      )
    ) {
      let accessToken = await getToken;
      setSessions(await api.deleteApotomeSession(session.id, accessToken));
    }
  };

  let playSession = async (session: SessionHeader) => {
    let token = await getAccessTokenSilently();
    let fullSession = await api.loadApotomeSession(session.id, token);
    onPlaySession(fullSession);
  };

  let editSession = async (session: SessionHeader) => {
    let token = await getAccessTokenSilently();
    let fullSession = await api.loadApotomeSession(session.id, token);
    onEditSession(fullSession);
  };

  let duplicateSession = async (session: SessionHeader) => {
    let token = await getAccessTokenSilently();
    let fullSession = await api.loadApotomeSession(session.id, token);
    onEditSession(
      omit(fullSession, "id", "createdBy", "createdAt", "updatedAt") as Session
    );
  };

  return (
    <div className="sessionList">
      <h2>Sessions</h2>
      {!isAuthLoading && !isAuthenticated && (
        <div className="sessionList--notAuthenticated">
          To use sessions, please sign in first.
        </div>
      )}
      {isAuthenticated && (
        <>
          <div className="sessionListTabs">
            <button
              className={classNames("button button--sessionListTab", {
                isActive: currentlyBrowsing === "my",
              })}
              onClick={() => setCurrentlyBrowsing("my")}
            >
              My Sessions
            </button>
            <button
              className={classNames("button button--sessionListTab", {
                isActive: currentlyBrowsing === "community",
              })}
              onClick={() => setCurrentlyBrowsing("community")}
            >
              Community Sessions
            </button>
          </div>
          {currentlyBrowsing === "my" && (
            <button
              className="button button--primary sessionList--newSessionButton"
              onClick={onNewSession}
            >
              Create new session
            </button>
          )}
          {getSessionList().map((session, idx) => (
            <div
              key={idx}
              className={classNames("sessionList--sessionListItem")}
            >
              <h5 className="sessionList--sessionName">{session.name}</h5>
              <div className="sessionList--sessionDescription">
                {session.description}
              </div>
              {currentlyBrowsing === "community" && (
                <div className="sessionList--sessionAuthor">
                  by {session.createdBy.username}
                </div>
              )}
              <div className="sessionList--sessionActions">
                <button
                  className="button button--small"
                  onClick={() => playSession(session)}
                  disabled={!startAllowed}
                >
                  Play
                </button>
                {(session.createdBy.currentUser || hasAdminAccess) && (
                  <button
                    className="button button--small"
                    onClick={() => editSession(session)}
                  >
                    Edit
                  </button>
                )}
                {session.createdBy.currentUser && (
                  <button
                    className="button button--small"
                    onClick={() => duplicateSession(session)}
                  >
                    Duplicate
                  </button>
                )}
                {session.createdBy.currentUser && (
                  <button
                    className="button button--small"
                    onClick={(evt) => onDeleteSession(session)}
                  >
                    Delete
                  </button>
                )}
              </div>
              <div className="sessionList--sessionSharing">
                {(currentlyBrowsing === "my" || hasAdminAccess) && (
                  <div className="sessionList--sessionStatus">
                    {startCase(session.status)}
                  </div>
                )}
                {session.createdBy.currentUser && session.status === "private" && (
                  <button
                    className="button  button--primary sessionList--updateSessionStatus"
                    onClick={(evt) => setPublishingSession(session)}
                  >
                    Submit to Community
                  </button>
                )}
                {session.createdBy.currentUser &&
                  !hasAdminAccess &&
                  (session.status === "submitted" ||
                    session.status === "public") && (
                    <button
                      className="button button--primary sessionList--updateSessionStatus"
                      onClick={(evt) =>
                        onUpdateSessionStatus(session, "private")
                      }
                    >
                      Withdraw
                    </button>
                  )}
                {hasAdminAccess && session.status === "submitted" && (
                  <button
                    className="button button--primary sessionList--updateSessionStatus"
                    onClick={(evt) => onUpdateSessionStatus(session, "public")}
                  >
                    Approve
                  </button>
                )}
                {hasAdminAccess &&
                  (session.status === "submitted" ||
                    session.status === "public") && (
                    <button
                      className="button button--primary sessionList--updateSessionStatus"
                      onClick={(evt) =>
                        onUpdateSessionStatus(session, "private")
                      }
                    >
                      Reject
                    </button>
                  )}
              </div>
            </div>
          ))}
        </>
      )}
      <SubmitConfirmationDialog
        isOpenFor={publishingSession}
        onConfirm={() => onUpdateSessionStatus(publishingSession!, "submitted")}
        onCancel={() => setPublishingSession(undefined)}
      />
    </div>
  );
};
