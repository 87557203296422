import { EventEmitter } from "events";
import React, { useEffect, useRef } from "react";
import { ToggleSwitch } from "./ToggleSwitch";

import "./UseAccentToggle.scss";

interface UseAccentToggleProps {
  on: boolean;
  onToggle: (on: boolean) => void;
  events: EventEmitter;
}
export const UseAccentToggle: React.FC<UseAccentToggleProps> = React.memo(
  ({ on, onToggle, events }) => {
    let labelRef = useRef<HTMLSpanElement>(null);
    useEffect(() => {
      let onNote = (note: any) => {
        if (note.accented) {
          labelRef.current!.classList.add("isActive");
          setTimeout(() => {
            labelRef.current?.classList.remove("isActive");
          }, note.duration * 900);
        }
      };
      events.on("note", onNote);
      return () => {
        events.off("note", onNote);
      };
    }, [events]);

    return (
      <h4 className="useAccentToggle">
        <label className="toggleSwitchLabel">
          <ToggleSwitch checked={on} onToggle={onToggle} />
          <span className="useAccentToggle--label" ref={labelRef}>
            Use accents
          </span>
        </label>
      </h4>
    );
  }
);
