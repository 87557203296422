import React, { useRef, useEffect, useState } from "react";
import * as Nexus from "nexusui";
import classNames from "classnames";

import "./ToggleButton.scss";

interface ToggleButtonProps {
  checked: boolean;
  disabled?: boolean;
  onToggle: (newChecked: boolean) => void;
}
export const ToggleButton: React.FC<ToggleButtonProps> = ({
  checked,
  disabled,
  onToggle,
}) => {
  let buttonContainerRef = useRef<HTMLDivElement>(null);
  let [button, setButton] = useState<any>(null);

  let latestOnToggle = useRef(onToggle);
  let changingValue = useRef(false);
  useEffect(() => {
    latestOnToggle.current = onToggle;
  }, [onToggle]);
  useEffect(() => {
    let button = new Nexus.Button(buttonContainerRef.current, {
      size: [14, 14],
      mode: "toggle",
    });
    if (!disabled) {
      button.on("change", (val: boolean) => {
        if (!changingValue.current) {
          latestOnToggle.current?.(val);
        }
      });
    }
    setButton(button);
    return () => {
      button.destroy();
      setButton(null);
    };
  }, [disabled]);
  useEffect(() => {
    if (!button) return;
    changingValue.current = true;
    button.state = checked;
    changingValue.current = false;
  }, [checked, button]);

  return (
    <div
      className={classNames("toggleButton", { isDisabled: disabled })}
      ref={buttonContainerRef}
    ></div>
  );
};
