import React, { ChangeEvent } from "react";
import { Pitch } from "../main/core";
import * as scala from "./scala";

import "./ScalaImport.scss";

interface ScalaImportProps {
  onImport: (pitches: Pitch[]) => void;
}
export const ScalaImport: React.FC<ScalaImportProps> = ({ onImport }) => {
  let onFileChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.currentTarget.files) {
      let file = evt.currentTarget.files[0];
      let content = await (file as any).text();
      onImport(scala.parseScala(content));
    }
  };
  return (
    <div className="scalaImport">
      <div className="inputGroup">
        <input
          type="file"
          id="scalaFile"
          accept=".scl"
          onChange={onFileChange}
        />
        <button className="button">Import Scala File</button>
      </div>
    </div>
  );
};
