import React, { CSSProperties } from "react";
import { round, range } from "lodash";
import classNames from "classnames";
import { Frequency } from "tone";

import { midiToFreq, freqToMidi } from "./main/core";
import { NumberInput } from "./main/NumberInput";
import { MIN_NOTE, MAX_NOTE } from "./constants";

import "./RefPitchInput.scss";

const NOTE_OPTIONS = range(MIN_NOTE, MAX_NOTE).map((midi) =>
  Frequency(midi, "midi").toNote()
);

interface RefPitchInputProps {
  semitones: number;
  note?: string;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
  onUpdateSemitones: (newSemitones: number) => void;
  onUpdateNote: (newNote: string) => void;
}
export const RefPitchInput: React.FC<RefPitchInputProps> = ({
  semitones,
  note,
  className,
  disabled,
  style,
  onUpdateSemitones,
  onUpdateNote,
}) => {
  let effectiveRefPitchNote =
    note || Frequency(Math.floor(semitones), "midi").toNote();

  return (
    <div
      className={classNames("refPitchInput", className, {
        isDisabled: disabled,
      })}
      style={style}
    >
      <select
        className="select refPitchNote"
        value={effectiveRefPitchNote}
        onChange={(evt) => onUpdateNote(evt.target.value)}
        disabled={disabled}
      >
        {NOTE_OPTIONS.map((note) => (
          <option key={note} value={note}>
            {note}
          </option>
        ))}
      </select>
      <NumberInput
        className="freqNumberInput"
        value={round(midiToFreq(semitones), 3)}
        disabled={disabled}
        onChange={(freq) =>
          onUpdateSemitones(
            Math.max(MIN_NOTE, Math.min(MAX_NOTE, freqToMidi(freq)))
          )
        }
      />
      <div className="hzLabel">Hz</div>
    </div>
  );
};
