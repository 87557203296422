let loadPromise: Promise<void> | null = null;

async function _load() {
    await loadScript('/wam/lib/webcomponents-lite.js');
    await loadScript('/wam/lib/wam-controller.js');
}

export function loadCommon() {
    if (!loadPromise) loadPromise = _load();
    return loadPromise;
}

export function loadScript(url: string) {
    let script = document.createElement('script');
    let load = new Promise<void>(res => script.onload = () => res());
    script.src = url;
    document.head.appendChild(script);
    return load;
}