import React, { useState, useCallback, useEffect } from "react";
import { isNumber } from "lodash";

interface NumberInputProps {
  value?: number;
  min?: number;
  max?: number;
  className?: string;
  name?: string;
  disabled?: boolean;
  onChange: (v: number) => void;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  value,
  min,
  max,
  className,
  name,
  disabled,
  onChange,
}) => {
  let [internalValue, setInternalValue] = useState(
    isNumber(value) ? `${value}` : ""
  );

  useEffect(() => {
    setInternalValue(isNumber(value) ? `${value}` : "");
  }, [value]);

  let onKeyDown = useCallback((evt: React.KeyboardEvent) => {
    if (evt.keyCode === 13) {
      // Enter; blur to trigger change upwards
      (evt.currentTarget as HTMLInputElement).blur();
      evt.preventDefault();
    } else if (
      (evt.keyCode >= 48 && evt.keyCode <= 57) ||
      evt.keyCode === 8 ||
      evt.keyCode === 190 ||
      evt.keyCode === 37 ||
      evt.keyCode === 39 ||
      evt.keyCode === 9 ||
      evt.metaKey ||
      evt.ctrlKey
    ) {
      // Digit or backspace, or left/right arrow, or tab; let the regular onChange happen
    } else {
      // Something else; do nothing
      evt.preventDefault();
    }
    evt.nativeEvent.stopImmediatePropagation();
  }, []);

  let onBlur = useCallback(() => {
    let value = +internalValue;
    if (isNumber(min) && value < min) value = min;
    if (isNumber(max) && value > max) value = max;
    setInternalValue(`${value}`);
    onChange(value);
  }, [min, max, internalValue, onChange]);

  return (
    <input
      className={className}
      name={name}
      value={internalValue}
      disabled={disabled}
      onKeyDown={onKeyDown}
      onChange={(evt) => setInternalValue(evt.currentTarget.value)}
      onBlur={onBlur}
    />
  );
};
