import React from "react";
import { range, isNumber } from "lodash";
import { SCALE_DEGREE_NAMES_ENGLISH } from "../constants";

interface MidiMappingSelectionProps {
  currentMapping: number | null;
  reservedMappings: Set<number>;
  onChangeMapping: (newMapping: number | null) => void;
}
export const MidiMappingSelection: React.FC<MidiMappingSelectionProps> = ({
  currentMapping,
  reservedMappings,
  onChangeMapping,
}) => {
  return (
    <select
      value={isNumber(currentMapping) ? currentMapping : -1}
      onChange={(evt) => {
        let val = +evt.target.value;
        onChangeMapping(val === -1 ? null : val);
        evt.target.blur();
      }}
    >
      <option value="-1">None</option>
      {range(0, 17).map((degree) => (
        <option
          value={degree}
          key={degree}
          disabled={degree !== currentMapping && reservedMappings.has(degree)}
        >
          {SCALE_DEGREE_NAMES_ENGLISH[degree]}
        </option>
      ))}
    </select>
  );
};
