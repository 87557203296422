import React, { useCallback } from "react";
import { SYNCED_ECHO_OPTIONS } from "./constants";
import { KnobControl } from "./KnobControl";

import "./ReturnTrack.scss";
import { ToggleSwitch } from "./ToggleSwitch";
import { ReturnTrackSettings } from "./types";

interface ReturnTrackProps {
  settings: ReturnTrackSettings;
  onUpdateSettings: (
    updater: (newSettings: ReturnTrackSettings) => ReturnTrackSettings
  ) => void;
}
export const ReturnTrack: React.FC<ReturnTrackProps> = React.memo(
  ({ settings, onUpdateSettings }) => {
    let onToggleEchoSync = useCallback(
      (on: boolean) => {
        onUpdateSettings((settings) => ({ ...settings, echoTempoSync: on }));
      },
      [onUpdateSettings]
    );
    let onUpdateEchoDelayLeft = useCallback(
      (delay: number) => {
        onUpdateSettings((settings) =>
          settings.echoTempoSync
            ? { ...settings, echoDelayLeftSynced: delay }
            : { ...settings, echoDelayLeftFree: delay }
        );
      },
      [onUpdateSettings]
    );
    let onUpdateEchoDelayRight = useCallback(
      (delay: number) => {
        onUpdateSettings((settings) =>
          settings.echoTempoSync
            ? { ...settings, echoDelayRightSynced: delay }
            : { ...settings, echoDelayRightFree: delay }
        );
      },
      [onUpdateSettings]
    );
    let onUpdateEchoFeedback = useCallback(
      (newValue: number) => {
        onUpdateSettings((settings) => ({
          ...settings,
          echoFeedback: newValue,
        }));
      },
      [onUpdateSettings]
    );

    let onUpdateReverbDecay = useCallback(
      (newValue: number) => {
        onUpdateSettings((settings) => ({
          ...settings,
          reverbDecay: newValue,
        }));
      },
      [onUpdateSettings]
    );
    let onUpdateReverbPreDelay = useCallback(
      (newValue: number) => {
        onUpdateSettings((settings) => ({
          ...settings,
          reverbPreDelay: newValue,
        }));
      },
      [onUpdateSettings]
    );

    return (
      <div className="returnTrack">
        <div className="returnTrackGroup">
          <h4>Reverb</h4>
          <div className="returnTrack--reverbControls">
            <KnobControl
              label="Decay"
              valueSuffix="s"
              value={settings.reverbDecay}
              min={0.01}
              max={10}
              step={0.01}
              onUpdateValue={onUpdateReverbDecay}
            />
            <KnobControl
              label="Pre"
              value={settings.reverbPreDelay}
              min={0}
              max={1}
              step={0.01}
              onUpdateValue={onUpdateReverbPreDelay}
            />
          </div>
        </div>
        <div className="returnTrackGroup">
          <h4>Delay</h4>
          <div className="returnTrack--echoControls">
            <div className="returnTrack--echoControls--row">
              <label className="toggleSwitchLabel">
                <ToggleSwitch
                  checked={settings.echoTempoSync}
                  onToggle={onToggleEchoSync}
                />{" "}
                Tempo sync
              </label>
            </div>
            <div className="returnTrack--echoControls--row">
              <KnobControl
                label="L"
                value={
                  settings.echoTempoSync
                    ? settings.echoDelayLeftSynced
                    : settings.echoDelayLeftFree
                }
                min={0}
                max={
                  settings.echoTempoSync ? SYNCED_ECHO_OPTIONS.length - 1 : 2000
                }
                step={1}
                valueFunction={(v) =>
                  settings.echoTempoSync ? SYNCED_ECHO_OPTIONS[v] : `${v}`
                }
                onUpdateValue={onUpdateEchoDelayLeft}
              />
              <KnobControl
                label="R"
                value={
                  settings.echoTempoSync
                    ? settings.echoDelayRightSynced
                    : settings.echoDelayRightFree
                }
                min={0}
                max={
                  settings.echoTempoSync ? SYNCED_ECHO_OPTIONS.length - 1 : 2000
                }
                step={1}
                valueFunction={(v) =>
                  settings.echoTempoSync ? SYNCED_ECHO_OPTIONS[v] : `${v}`
                }
                onUpdateValue={onUpdateEchoDelayRight}
              />
            </div>
            <div className="returnTrack--echoControls--row">
              <KnobControl
                label="Feedback"
                value={settings.echoFeedback}
                min={0}
                max={1}
                step={0.01}
                onUpdateValue={onUpdateEchoFeedback}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
