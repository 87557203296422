import React, { useCallback, useState } from "react";
import classNames from "classnames";

import { MIDIOutput } from "../../main/core";
import {
  MIDIOutputSnapshot,
  ReturnTrackSettings,
  Session,
  SessionSection,
  TrackControls,
  TransportControls,
} from "../types";
import { SessionList } from "./SessionList";
import { SessionEditor } from "./SessionEditor";

import "./SessionManager.scss";
import { SessionPlayer } from "./SessionPlayer";

interface SessionManagerProps {
  isOpen: boolean;
  currentTracks: { id: string; controls: TrackControls }[];
  currentReturnTrackSettings: ReturnTrackSettings;
  currentTransportControls: TransportControls;
  currentMidiOutputs: {
    [trackId: string]: MIDIOutput;
  };
  startAllowed: boolean;
  onApplySnapshot: (
    tracks: { id: string; controls: TrackControls }[],
    returnTrackSettings: ReturnTrackSettings,
    transportControls: TransportControls,
    midiOutputs: MIDIOutputSnapshot[],
    effectiveAtTicks: number,
    startPlayback: boolean
  ) => void;
  onStopPlayback: (effectiveAtTicks: number) => void;
  onToggle: (open: boolean) => void;
}
export const SessionManager: React.FC<SessionManagerProps> = React.memo(
  ({
    isOpen,
    currentTracks,
    currentReturnTrackSettings,
    currentTransportControls,
    currentMidiOutputs,
    startAllowed,
    onApplySnapshot,
    onStopPlayback,
    onToggle,
  }) => {
    let [playingSession, setPlayingSession] = useState<Session>();
    let [editingSession, setEditingSession] = useState<Partial<Session>>();

    let onNewSession = useCallback(() => {
      setEditingSession({
        name: "",
        description: "",
        loopedMinDuration: 600,
        loopedMaxDuration: 600,
        loopedFadeout: false,
        sections: [],
        loop: false,
      });
    }, []);

    let onOpenSection = useCallback(
      (
        section: SessionSection,
        effectiveAtTicks: number,
        startPlayback: boolean
      ) => {
        onApplySnapshot(
          section.snapshot.tracks,
          section.snapshot.returnTrackSettings,
          section.snapshot.transportControls,
          section.snapshot.midiOutputs,
          effectiveAtTicks,
          startPlayback
        );
      },
      [onApplySnapshot]
    );

    return (
      <div
        className="sessionManager"
        onKeyPress={(evt) => evt.nativeEvent.stopImmediatePropagation()}
      >
        <div className={classNames("sessionManager--content", { isOpen })}>
          {isOpen && (
            <button
              className="button closeButton"
              onClick={() => onToggle(false)}
            >
              Close
            </button>
          )}
          {editingSession ? (
            <SessionEditor
              session={editingSession}
              currentTracks={currentTracks}
              currentTransportControls={currentTransportControls}
              currentReturnTrackSettings={currentReturnTrackSettings}
              currentMidiOutputs={currentMidiOutputs}
              onOpenSection={(section: SessionSection) =>
                onOpenSection(section, -1, false)
              }
              onClose={() => setEditingSession(undefined)}
            />
          ) : playingSession ? (
            <SessionPlayer
              session={playingSession}
              onPlaySection={onOpenSection}
              onStop={onStopPlayback}
              onClose={() => setPlayingSession(undefined)}
            />
          ) : (
            <SessionList
              startAllowed={startAllowed}
              onNewSession={onNewSession}
              onPlaySession={setPlayingSession}
              onEditSession={setEditingSession}
            />
          )}
        </div>
      </div>
    );
  }
);
