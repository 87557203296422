import { MIDIInput, MIDIOutput } from "./main/core";
import { createContext } from "react";
import { noop } from "lodash";
export interface MIDIContextProps {
    input: MIDIInput,
    outputs: { [track: string]: MIDIOutput },
    onSetInput: (newInput: MIDIInput) => void,
    onSetOutput: (track: string, newOutput: MIDIOutput) => void,
    onCCIn: (controller: number, value: number) => void;
    onPitchBendIn: (value: number) => void;
}

export const MIDIContext = createContext<MIDIContextProps>({
    input: { channel: 'all' },
    outputs: { leimma: { channel: 'all', internal: 'synth', pitchBendRangeCents: 200 } },
    onSetInput: () => { throw new Error("No setter for MIDI input provided in context") },
    onSetOutput: () => { throw new Error("No setter for MIDI output provided in context") },
    onCCIn: noop,
    onPitchBendIn: noop
});
