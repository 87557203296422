import React, { useEffect } from "react";
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
  useLocation,
} from "react-router-dom";

import { RefPitchScreen } from "./RefPitchScreen";
import { OctaveDivisionScreen } from "./OctaveDivisionScreen";
import { ScaleScreen } from "./ScaleScreen";
import * as audio from "./main/audio";
import { ScreenSizeWarning } from "./ScreenSizeWarning";

export const Screens = () => {
  let { pathname } = useLocation();
  let { path } = useRouteMatch();

  useEffect(() => {
    audio.init();
  }, []);

  return (
    <>
      <ScreenSizeWarning />
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route exact path={`${path}/:refPitch`} component={RefPitchScreen} />
        <Route
          exact
          path={`${path}/:refPitch/tuningsystem/:strings`}
          component={OctaveDivisionScreen}
        />
        <Route
          exact
          path={`${path}/:refPitch/tuningsystem/:strings/scale/:scaleId/:solmization/:scale?`}
          component={ScaleScreen}
        />
      </Switch>
    </>
  );
};
