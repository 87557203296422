import React, { useRef, useEffect } from "react";
import { useDrag } from "react-use-gesture";
import { isNumber } from "lodash";

import { Pitch, formatPitch, getPitchCents } from "./main/core";

interface OctaveDivisionStringStopProps {
  pc: Pitch;
  x: number;
  y: number;
  stringWidth: number;
  windowWidth: number;
  rulers: { pitch: Pitch; x: number }[];
  onUpdate: (newPc: Pitch) => void;
  onSound: () => void;
}
export const OctaveDivisionStringStop: React.FC<OctaveDivisionStringStopProps> = ({
  pc,
  x,
  y,
  stringWidth,
  windowWidth,
  rulers,
  onUpdate,
  onSound,
}) => {
  let handleRef = useRef<EventTarget>();
  let bindDrag = useDrag(
    ({ xy, event, last }) => {
      if (last) return;
      let minX = (windowWidth - stringWidth) / 2;
      let maxX = windowWidth - minX;
      let x = Math.max(minX, Math.min(maxX, xy[0]));
      if (rulers.length > 0) {
        let closestRuler = findClosestRuler(x - minX + 5, rulers);
        onUpdate({
          ...pc,
          cents: undefined,
          ...closestRuler.pitch,
        });
      } else {
        let relX = (x - minX) / (maxX - minX);
        let ratio = 1 / (1 - relX);
        let cents = getPitchCents({ ratioUpper: ratio, ratioLower: 1 });
        onUpdate({
          ...pc,
          cents,
          ratioUpper: undefined,
          ratioLower: undefined,
        });
      }
      event && event.preventDefault();
    },
    {
      domTarget: handleRef as any,
      axis: "x",
      threshold: 3,
      eventOptions: { passive: false },
    }
  );
  useEffect(bindDrag as any, [bindDrag]);

  return (
    <>
      <line
        x1={x}
        x2={x}
        y1={y - 8}
        y2={y + 8}
        className="octaveDivisionStringsView--stringStop"
      />
      <text
        x={x}
        y={y - 15}
        className="octaveDivisionStringsView--stringStopLabel"
        transform={`rotate(270, ${x + 5}, ${y - 15})`}
      >
        {isNumber(pc.ratioUpper) && isNumber(pc.ratioLower)
          ? formatPitch(pc, "ratio")
          : formatPitch(pc, "cents")}
      </text>
      <rect
        ref={handleRef as any}
        x={x - 20}
        y={y - 20}
        width={40}
        height={40}
        className="octaveDivisionStringsView--dragHandle"
        fillOpacity={0}
        onPointerDown={onSound}
      />
    </>
  );
};

function findClosestRuler(x: number, rulers: { pitch: Pitch; x: number }[]) {
  var l = 0,
    r = rulers.length - 1;
  while (l <= r) {
    let m = l + ((r - l) >> 1);
    let comp = rulers[m].x - x;
    if (comp < 0) {
      l = m + 1;
    } else if (comp > 0) {
      r = m - 1;
    } else {
      return rulers[m];
    }
  }
  if (l <= 0) {
    return rulers[0];
  } else if (l >= rulers.length) {
    return rulers[rulers.length - 1];
  } else {
    if (Math.abs(rulers[l - 1].x - x) > Math.abs(rulers[l].x - x)) {
      return rulers[l];
    } else {
      return rulers[l - 1];
    }
  }
}
