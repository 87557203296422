import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  Edit,
  Create,
  DisabledInput,
  TextInput,
  LongTextInput,
  NumberInput,
  SelectInput,
  ArrayInput,
  TabbedForm,
  FormTab,
  SimpleFormIterator,
  ReferenceInput,
  number,
  minValue,
  maxValue,
} from "react-admin";
import {
  SCALE_DEGREE_NAMES_ENGLISH,
  SCALE_DEGREE_NAMES,
  SCALE_DEGREE_ROLES,
} from "../constants";

export const ScaleList: React.FC = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="TuningSystemId" reference="tuning_systems">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="description" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

const ScaleTitle = ({ record }: any) => {
  return <span>Scale {record ? `"${record.name}"` : ""}</span>;
};

export const ScaleEdit: React.FC = (props: any) => (
  <Edit title={<ScaleTitle />} {...props}>
    <TabbedForm>
      <FormTab label="Scale">
        <DisabledInput source="id" />
        <DisabledInput source="TuningSystemId" />
        <DisabledInput source="addedBy" />
        <TextInput source="name" />
        <LongTextInput source="description" />
        <LongTextInput source="source" />
        <SelectInput
          source="solmization"
          choices={Object.keys(SCALE_DEGREE_NAMES).map((n) => ({
            id: n,
            name: n,
          }))}
        />
        <TextInput source="creationDate" />
      </FormTab>
      <FormTab label="Scale Degrees">
        <ArrayInput source="ScaleDegrees">
          <SimpleFormIterator>
            <NumberInput
              source="stringIndex"
              validate={[number(), minValue(0), maxValue(1200)]}
            />
            <NumberInput
              source="pitchClassIndex"
              validate={[number(), minValue(0), maxValue(1200)]}
            />
            <SelectInput
              source="map"
              choices={SCALE_DEGREE_NAMES_ENGLISH.map((n, idx) => ({
                id: idx,
                name: n,
              }))}
            />
            <SelectInput
              source="role"
              choices={SCALE_DEGREE_ROLES.map((r) => ({
                id: r,
                name: r,
              }))}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const ScaleCreate: React.FC = (props: any) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="Scale">
        <DisabledInput source="addedBy" defaultValue={props.user.email} />
        <ReferenceInput
          label="Tuning System"
          source="TuningSystemId"
          reference="tuning_systems"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" />
        <LongTextInput source="description" />
        <LongTextInput source="source" />
        <SelectInput
          source="solmization"
          choices={Object.keys(SCALE_DEGREE_NAMES).map((n) => ({
            id: n,
            name: n,
          }))}
        />
        <TextInput source="creationDate" />
      </FormTab>
      <FormTab label="Scale Degrees">
        <ArrayInput source="ScaleDegrees">
          <SimpleFormIterator>
            <NumberInput
              source="stringIndex"
              validate={[number(), minValue(0), maxValue(1200)]}
            />
            <NumberInput
              source="pitchClassIndex"
              validate={[number(), minValue(0), maxValue(1200)]}
            />
            <SelectInput
              source="map"
              choices={SCALE_DEGREE_NAMES_ENGLISH.map((n, idx) => ({
                id: idx,
                name: n,
              }))}
            />
            <SelectInput
              source="role"
              choices={SCALE_DEGREE_ROLES.map((r) => ({
                id: r,
                name: r,
              }))}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
