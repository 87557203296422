import { snakeCase, sum } from "lodash";
import React, { useState } from "react";
import dateFormat from "dateformat";
import classNames from "classnames";
import { Frequency } from "tone";

import {
  midiToFreq,
  TString,
  TuningSystemHeader,
  ScaleHeader,
  ScaleDegree,
} from "../main/core";
import { getFlatPitches } from "../OctaveDivisionBand";
import { formatScala } from "./scala";

import "./ScalaExport.scss";

interface ScalaExportProps {
  tuningSystemRefPitchSemitones: number;
  tuningSystemRefPitchNote?: string;
  tuningSystemStrings: TString[];
  scale?: ScaleDegree[];
  tuningSystemMeta?: TuningSystemHeader;
  scaleMeta?: ScaleHeader;
  className?: string;
}
export const ScalaExport: React.FC<ScalaExportProps> = ({
  tuningSystemRefPitchSemitones,
  tuningSystemRefPitchNote,
  tuningSystemStrings,
  scale,
  tuningSystemMeta,
  scaleMeta,
  className,
}) => {
  let [choiceDialogOpen, setChoiceDialogOpen] = useState(false);

  let onExportTuningSystem = () => {
    let filename = getFilename(false, tuningSystemMeta);
    let refPitchNote =
      tuningSystemRefPitchNote ??
      Frequency(Math.round(tuningSystemRefPitchSemitones), "midi").toNote();
    let scalaString = formatScala(
      getFlatPitches(tuningSystemStrings).map((s) => s.pitch),
      midiToFreq(tuningSystemRefPitchSemitones),
      refPitchNote,
      filename,
      tuningSystemMeta?.description,
      tuningSystemMeta?.source
    );
    download(scalaString, filename);
    setChoiceDialogOpen(false);
  };

  let onExportScale = () => {
    let filename = getFilename(true, tuningSystemMeta, scaleMeta);
    let refPitchNote =
      tuningSystemRefPitchNote ??
      Frequency(Math.round(tuningSystemRefPitchSemitones), "midi").toNote();
    let flatPitches = getFlatPitches(tuningSystemStrings);
    let rootSd = scale!.find((s) => s.role === "tonic");
    let rootPitch =
      rootSd &&
      flatPitches.find(
        (p) =>
          p.stringIdx === rootSd!.stringIndex &&
          p.pcIdx === rootSd!.pitchClassIndex
      );
    let scalaString = formatScala(
      flatPitches
        .filter((p) =>
          scale!.some(
            (s) =>
              s.stringIndex === p.stringIdx && s.pitchClassIndex === p.pcIdx
          )
        )
        .map((s) => s.pitch),
      midiToFreq(tuningSystemRefPitchSemitones),
      refPitchNote,
      filename,
      scaleMeta?.description ?? tuningSystemMeta?.description,
      scaleMeta?.source ?? tuningSystemMeta?.source,
      tuningSystemMeta?.name,
      rootPitch?.pitch
    );
    download(scalaString, filename);
    setChoiceDialogOpen(false);
  };

  let onExport = () => {
    if (scale && scale.length > 0) {
      setChoiceDialogOpen(true);
    } else {
      onExportTuningSystem();
    }
  };

  return (
    <>
      <button
        className={classNames("button", "scalaExport", className)}
        disabled={
          sum(tuningSystemStrings.map((s) => s.pitchClasses.length)) <= 1
        }
        onClick={onExport}
      >
        Export Scala File
      </button>
      <ScalaExportChoiceDialog
        isOpen={choiceDialogOpen}
        tuningSystemMeta={tuningSystemMeta}
        scaleMeta={scaleMeta}
        onExportTuningSystem={onExportTuningSystem}
        onExportScale={onExportScale}
        onClose={() => setChoiceDialogOpen(false)}
      />
    </>
  );
};

interface ScalaExportChoiceDialogProps {
  isOpen: boolean;
  tuningSystemMeta?: TuningSystemHeader;
  scaleMeta?: ScaleHeader;
  onExportTuningSystem: () => void;
  onExportScale: () => void;
  onClose: () => void;
}
const ScalaExportChoiceDialog: React.FC<ScalaExportChoiceDialogProps> = ({
  isOpen,
  tuningSystemMeta,
  scaleMeta,
  onExportTuningSystem,
  onExportScale,
  onClose,
}) => {
  return (
    <div
      className={classNames("scalaExportChoiceDialogWrapper", {
        isOpen,
      })}
      onClick={(evt) => evt.target === evt.currentTarget && onClose()}
    >
      {" "}
      <div className="scalaExportChoiceDialog">
        <h2>Export Scala File</h2>
        <div className="scalaExportChoiceDialog--options">
          <button className="button" onClick={onExportTuningSystem}>
            Export Tuning System {tuningSystemMeta?.name}
          </button>
          <button className="button" onClick={onExportScale}>
            Export Subset {scaleMeta?.name}
          </button>
        </div>
      </div>
    </div>
  );
};

function getFilename(
  isSubset: boolean,
  tuningSystemMeta?: TuningSystemHeader,
  scaleMeta?: ScaleHeader
) {
  let name = tuningSystemMeta
    ? snakeCase(tuningSystemMeta.name)
    : `leimma_${dateFormat(new Date(), "yyyy-mm-dd_hMM")}`;
  if (isSubset) {
    name += "_subset";
    if (scaleMeta) {
      name += "_" + snakeCase(scaleMeta.name);
    }
  }
  return name + ".scl";
}

function download(scalaString: string, filename: string) {
  let link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(scalaString)
  );
  link.setAttribute("download", filename);
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
