import React from "react";

import "./UserGuideLink.scss";

export const UserGuideLink: React.FC = () => (
  <div className="leimma--userGuideLink">
    <a
      href="https://docs.google.com/document/d/1vxLZaL8jeXQcj3m7q6qF42ZWAaG-2HlRkQZiGacRUXI/edit?usp=sharing"
      target="_blank"
      rel="noopener noreferrer"
    >
      User Guide
    </a>
    <a
      href="https://vimeo.com/503475532"
      target="_blank"
      rel="noopener noreferrer"
    >
      Tutorial
    </a>
  </div>
);
