import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";

import { isEqual, omit, isNumber } from "lodash";
import { Pitch, TuningSystem, PitchClass, TString } from "./main/core";
import { TUNING_SYSTEM_CATEGORIES } from "./constants";
import * as api from "./main/api";

import "./TuningSystemDatabaseEntryDialog.scss";
import { Frequency } from "tone";
import { useAuth0 } from "@auth0/auth0-react";

const EPSILON = 0.001;

interface TuningSystemDatabaseEntryDialogProps {
  isOpen: boolean;
  id: string;
  refPitch: { semitones: number; note?: string };
  strings: TString[];
  isAdmin: boolean;
  onSaved: (ts: TuningSystem) => void;
  onDeleted: () => void;
  onClose: () => void;
}
export const TuningSystemDatabaseEntryDialog: React.FC<TuningSystemDatabaseEntryDialogProps> = ({
  isOpen,
  id,
  strings,
  refPitch,
  isAdmin,
  onSaved,
  onDeleted,
  onClose,
}) => {
  return (
    <div
      className={classNames("tuningSystemDatabaseEntryWrapper", { isOpen })}
      onClick={(evt) => evt.target === evt.currentTarget && onClose()}
    >
      <TuningSystemDatabaseEntry
        id={id}
        refPitch={refPitch}
        strings={strings}
        isAdmin={isAdmin}
        onSaved={onSaved}
        onDeleted={onDeleted}
        onClose={onClose}
      />
    </div>
  );
};

interface TuningSystemDatabaseEntryProps {
  id: string;
  refPitch: { semitones: number; note?: string };
  strings: TString[];
  isAdmin: boolean;
  preventCopy?: boolean;
  onSaved: (ts: TuningSystem) => void;
  onDeleted: () => void;
  onClose?: () => void;
}
export const TuningSystemDatabaseEntry: React.FC<TuningSystemDatabaseEntryProps> = ({
  id,
  strings,
  refPitch,
  isAdmin,
  preventCopy,
  onSaved,
  onDeleted,
  onClose,
}) => {
  let { getAccessTokenSilently, user } = useAuth0();

  let [currentTuningSystem, setCurrentTuningSystem] = useState<
    Partial<TuningSystem>
  >(
    applyToTuningSystem(
      {
        name: "",
        description: "",
        source: "",
        category: undefined,
      },
      strings,
      refPitch.semitones,
      refPitch.note
    )
  );

  let [
    persistentTuningSystem,
    setPersistentTuningSystem,
  ] = useState<TuningSystem>();

  let isDirty = hasDifferences(currentTuningSystem, persistentTuningSystem);

  useEffect(() => {
    if (id !== "new") {
      api.loadTuningSystem(+id).then(setPersistentTuningSystem);
    } else {
      setPersistentTuningSystem(undefined);
    }
  }, [id]);

  useEffect(() => {
    if (persistentTuningSystem) {
      setCurrentTuningSystem(
        applyToTuningSystem(
          persistentTuningSystem,
          strings,
          refPitch.semitones,
          refPitch.note
        )
      );
    } else {
      setCurrentTuningSystem((cur) =>
        applyToTuningSystem(
          {
            name: cur.name,
            description: cur.description,
            source: cur.source,
            category: cur.category,
          },
          strings,
          refPitch.semitones,
          refPitch.note
        )
      );
    }
  }, [persistentTuningSystem, refPitch, strings]);

  let onSaveAsNew = useCallback(async () => {
    let accessToken = await getAccessTokenSilently();
    api
      .saveTuningSystem(
        {
          ...(persistentTuningSystem || {}),
          ...currentTuningSystem,
          strings: currentTuningSystem.strings!.map((s) => ({
            ...omit(s, "id"),
            pitchClasses: s.pitchClasses.map((pc) => omit(pc, "id")),
          })),
          id: undefined,
        } as TuningSystem,
        accessToken
      )
      .then((savedTs) => {
        setPersistentTuningSystem(savedTs);
        onSaved(savedTs);
        onClose?.();
      });
  }, [
    persistentTuningSystem,
    currentTuningSystem,
    onSaved,
    onClose,
    getAccessTokenSilently,
  ]);

  let onUpdateExisting = useCallback(async () => {
    let accessToken = await getAccessTokenSilently();
    api
      .saveTuningSystem(
        {
          ...(persistentTuningSystem || {}),
          ...currentTuningSystem,
        } as TuningSystem,
        accessToken
      )
      .then((savedTs) => {
        setPersistentTuningSystem(savedTs);
        onSaved(savedTs);
        onClose?.();
      });
  }, [
    persistentTuningSystem,
    currentTuningSystem,
    onSaved,
    onClose,
    getAccessTokenSilently,
  ]);

  let onDelete = useCallback(async () => {
    if (
      window.confirm(
        `Are you sure you want to delete tuning system "${
          persistentTuningSystem!.name
        }" and all of its subsets?`
      )
    ) {
      let accessToken = await getAccessTokenSilently();
      await api.deleteTuningSystem(persistentTuningSystem!.id!, accessToken);
      onDeleted();
      onClose?.();
    }
  }, [persistentTuningSystem, onDeleted, onClose, getAccessTokenSilently]);

  let isUserTuningSystem = useCallback(() => {
    return persistentTuningSystem?.category === `User: ${user?.email}`;
  }, [persistentTuningSystem, user]);

  return isAdmin || isUserTuningSystem() || id === "new" || !preventCopy ? (
    <div
      className={classNames("tuningSystemDatabaseEntry", "databaseEntry", {
        isAdmin,
      })}
    >
      <h3>
        {" "}
        {isAdmin ? (
          <>Tuning System Database (Admin)</>
        ) : (
          <>Save Tuning System to My Tunings</>
        )}
      </h3>
      {isAdmin && (
        <div className="tuningSystemDatabaseEntry--field">
          <label htmlFor="tuningSystemDatabaseEntry--category">Category</label>
          <select
            name="tuningSystemDatabaseEntry--category"
            value={currentTuningSystem.category || ""}
            onChange={(evt) =>
              setCurrentTuningSystem({
                ...currentTuningSystem,
                category: evt.currentTarget.value,
              })
            }
          >
            <option value=""></option>
            {TUNING_SYSTEM_CATEGORIES.map((cat) => (
              <option value={cat} key={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="tuningSystemDatabaseEntry--field">
        <label htmlFor="tuningSystemDatabaseEntry--name">Name</label>
        <input
          name="tuningSystemDatabaseEntry--name"
          value={currentTuningSystem.name}
          onChange={(evt) =>
            setCurrentTuningSystem({
              ...currentTuningSystem,
              name: evt.currentTarget.value,
            })
          }
        />
      </div>
      <div className="tuningSystemDatabaseEntry--field">
        <label htmlFor="tuningSystemDatabaseEntry--description">
          Description
        </label>
        <textarea
          name="tuningSystemDatabaseEntry--name"
          value={currentTuningSystem.description}
          onChange={(evt) =>
            setCurrentTuningSystem({
              ...currentTuningSystem,
              description: evt.currentTarget.value,
            })
          }
        />
      </div>
      <div className="tuningSystemDatabaseEntry--field">
        <label htmlFor="tuningSystemDatabaseEntry--source">Source</label>
        <textarea
          name="tuningSystemDatabaseEntry--source"
          value={currentTuningSystem.source}
          onChange={(evt) =>
            setCurrentTuningSystem({
              ...currentTuningSystem,
              source: evt.currentTarget.value,
            })
          }
        />
      </div>
      <div className="tuningSystemDatabaseEntry--actions">
        {(isAdmin || isUserTuningSystem()) && (
          <button
            disabled={!persistentTuningSystem}
            onClick={onDelete}
            className="button"
          >
            Delete
          </button>
        )}
        {(isAdmin || isUserTuningSystem()) && (
          <button
            disabled={!persistentTuningSystem || !isDirty}
            onClick={onUpdateExisting}
            className="button"
          >
            Update existing
          </button>
        )}
        {(id === "new" || !preventCopy) && (
          <button disabled={!isDirty} onClick={onSaveAsNew} className="button">
            <>Save as new</>
          </button>
        )}
      </div>
      <div className="tuningSystemDatabaseEntry--actions">
        {onClose && (
          <button onClick={onClose} className="button">
            Close
          </button>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

function applyToTuningSystem(
  ts: Partial<TuningSystem>,
  strings: TString[],
  refPitchSemitones: number,
  refPitchNote?: string
): Partial<TuningSystem> {
  let freq = Frequency(refPitchSemitones, "midi");
  return {
    ...ts,
    refPitchHz: freq.toFrequency(),
    refPitchNoteMidi: refPitchSemitones,
    refPitchNoteName: refPitchNote,
    strings: mergeStrings(strings, ts.strings || []),
  };
}

function mergeStrings(
  strings: TString[],
  existingStrings: TString[]
): TString[] {
  return strings.map((string, idx) => {
    let existing = existingStrings[idx] || { pitchClasses: [] };
    return {
      ...existing,
      ...string,
      pitchClasses: mergePitches(string.pitchClasses, existing.pitchClasses),
    };
  });
}

function mergePitches(
  pitches: Pitch[],
  existingPitchClasses: PitchClass[]
): PitchClass[] {
  return pitches.map((pitch) => {
    let existing = existingPitchClasses.find((pc) => isSamePitch(pitch, pc));
    return existing || { ...pitch, name8ve1: "", name8ve2: "" };
  });
}

function hasDifferences(
  current: Partial<TuningSystem>,
  persistent?: TuningSystem
) {
  if (!persistent) return true;

  return (
    current.name !== persistent.name ||
    current.category !== persistent.category ||
    current.description !== persistent.description ||
    Math.abs(current.refPitchHz! - persistent.refPitchHz) > EPSILON ||
    current.refPitchNoteMidi !== persistent.refPitchNoteMidi ||
    (current.refPitchNoteName
      ? current.refPitchNoteName === persistent.refPitchNoteName
      : persistent.refPitchNoteName) ||
    !isEqual(current.strings, persistent.strings)
  );
}

function isSamePitch(p1: Pitch, p2: Pitch) {
  return isNumber(p1.ratioLower)
    ? p1.ratioLower === p2.ratioLower && p1.ratioUpper === p2.ratioUpper
    : p1.cents === p2.cents;
}
