import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { Session, SessionSection } from "../types";
import { PlaybackState, SessionPlayer as PlayerModel } from "./player";
import { SectionTimer, formatSeconds } from "./SectionTimer";

import "./SessionPlayer.scss";

interface SessionPlayerProps {
  session: Session;
  onPlaySection: (
    section: SessionSection,
    effectiveAtTicks: number,
    startPlayback: boolean
  ) => void;
  onStop: (effectiveAtTicks: number) => void;
  onClose: () => void;
}
export const SessionPlayer: React.FC<SessionPlayerProps> = ({
  session,
  onPlaySection,
  onStop,
  onClose,
}) => {
  let [player, setPlayer] = useState<PlayerModel>();
  let [playbackState, setPlaybackState] = useState<PlaybackState>();

  useEffect(() => {
    let player = new PlayerModel(session);
    player.on("updatePlaybackState", (state: PlaybackState) => {
      if (state.type === "section") {
        onPlaySection(state.section, state.atTicks, true);
      } else {
        onStop(state.atTicks);
      }
      setPlaybackState(state);
    });
    player.start();
    setPlayer(player);
    return () => {
      player.stop();
    };
  }, [session, onPlaySection, onStop]);

  let isCurrentSection = (section: SessionSection) => {
    return (
      playbackState?.type === "section" && playbackState?.section === section
    );
  };

  return (
    <div className="sessionPlayer">
      <h2>
        <span className="backToMain" onClick={onClose}>
          &larr;
        </span>
        {session.name}
      </h2>

      <div className="sessionPlayer--author">
        by {session.createdBy.username}
      </div>
      <div className="sessionPlayer--description">{session.description}</div>

      <div className="sessionPlayer--playbackActions">
        {playbackState?.type !== "stopped" && (
          <button className="button" onClick={() => player?.stop()}>
            Stop
          </button>
        )}
        {(!playbackState || playbackState.type === "stopped") && (
          <button className="button" onClick={() => player?.start()}>
            Start
          </button>
        )}
        {playbackState?.type === "section" && (
          <div className="sessionPlayer--sessionTiming">
            <SectionTimer startedAt={playbackState.firstAtSeconds} />
            {playbackState.totalPlannedDuration > 0 && (
              <> / {formatSeconds(playbackState.totalPlannedDuration)}</>
            )}
          </div>
        )}
      </div>

      {session.sections.map((section, idx) => (
        <div
          className={classNames("sessionPlayer--section", {
            isPlaying: isCurrentSection(section),
          })}
          key={section.id}
          onClick={() => player?.jumpToSection(idx)}
        >
          <h4>Snapshot {idx + 1}</h4>
          <div className="sessionPlayer--sectionName">{section.name}</div>
          {isCurrentSection(section) && (
            <div className="sessionPlayer--sectionTimer">
              <SectionTimer startedAt={playbackState!.atSeconds} />
              {playbackState?.type === "section" &&
                playbackState.plannedDuration && (
                  <> / {formatSeconds(playbackState.plannedDuration)}</>
                )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
