import { omit, cloneDeep } from 'lodash';
import { Session, SessionHeader, StageBooking } from '../apotome/types';

import { TuningSystem, TuningSystemHeader, ScaleHeader, Scale, UserProfile, TuningSnapshot } from "./core";

export function checkAdminAccess(accessToken: string): Promise<boolean> {
    return fetch('/.netlify/functions/api/admin_access', {
        headers: { "Authorization": `Bearer ${accessToken}` }
    }).then(res => res.status === 200);
}

export function loadTuningSystems(accessToken?: string): Promise<TuningSystemHeader[]> {
    return fetch(
        '/.netlify/functions/api/tuning_systems?range=[0,1000]',
        { headers: accessToken ? { "Authorization": `Bearer ${accessToken}` } : {} }
    ).then(res => res.json());
}

export async function loadTuningSystem(id: number, accessToken?: string): Promise<TuningSystem> {
    return fetch(
        `/.netlify/functions/api/tuning_systems/${id}`,
        { headers: accessToken ? { "Authorization": `Bearer ${accessToken}` } : {} }
    )
        .then(res => res.json())
        .then(res => ({
            ...omit(res, 'Strings'),
            strings: res.Strings.map((string: any) => ({
                ...omit(string, 'PitchClasses'),
                pitchClasses: string.PitchClasses
            })),
        }) as TuningSystem)
}

export async function saveTuningSystem(tuningSystem: TuningSystem, accessToken: string): Promise<TuningSystem> {
    let toSave = cloneDeep(tuningSystem) as any;
    toSave.Strings = toSave.strings.map((string: any) => {
        string.PitchClasses = string.pitchClasses;
        delete string.pitchClasses;
        return string;
    });
    delete toSave.strings;
    console.log('sav', toSave)
    let request = toSave.id ? fetch(`/.netlify/functions/api/tuning_systems/${toSave.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${accessToken}` },
        body: JSON.stringify(toSave)
    }) : fetch(`/.netlify/functions/api/tuning_systems`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${accessToken}` },
        body: JSON.stringify(toSave)
    });
    return request
        .then(res => res.json())
        .then(res => ({
            ...omit(res, 'Strings'),
            strings: res.Strings.map((string: any) => ({
                ...omit(string, 'PitchClasses'),
                pitchClasses: string.PitchClasses
            })),
        }) as TuningSystem)
}

export async function deleteTuningSystem(tuningSystemId: number, accessToken: string): Promise<void> {
    return fetch(`/.netlify/functions/api/tuning_systems/${tuningSystemId}`, {
        method: 'DELETE',
        headers: { "Authorization": `Bearer ${accessToken}` }
    }).then(res => {
        if (res.status !== 200) {
            throw new Error('Server error when deleting tuning system');
        }
    });
}


export function loadScales(tuningSystemId: number, accessToken?: string): Promise<ScaleHeader[]> {
    return fetch(
        `/.netlify/functions/api/scales?tuning_system_id=${tuningSystemId}&range=[0,1000]`,
        { headers: accessToken ? { "Authorization": `Bearer ${accessToken}` } : {} }
    ).then(res => res.json());
}

export async function loadScale(id: number, accessToken?: string): Promise<Scale> {
    return fetch(
        `/.netlify/functions/api/scales/${id}`,
        { headers: accessToken ? { "Authorization": `Bearer ${accessToken}` } : {} }
    )
        .then(res => res.json())
        .then(res => ({
            ...omit(res, 'ScaleDegrees'),
            scaleDegrees: res.ScaleDegrees,
        }) as Scale)
}

export async function saveScale(scale: Scale, forTuningSystemId: number, accessToken: string): Promise<Scale> {
    let toSave = cloneDeep(scale) as any;
    toSave.ScaleDegrees = toSave.scaleDegrees;
    delete toSave.scaleDegrees;
    toSave.TuningSystemId = forTuningSystemId;
    let request = toSave.id ? fetch(`/.netlify/functions/api/scales/${toSave.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${accessToken}` },
        body: JSON.stringify(toSave)
    }) : fetch(`/.netlify/functions/api/scales`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${accessToken}` },
        body: JSON.stringify(toSave)
    })
    return request
        .then(res => res.json())
        .then(res => ({
            ...omit(res, 'ScaleDegrees'),
            scaleDegrees: res.ScaleDegrees,
        }) as Scale)
}

export async function deleteScale(scaleId: number, accessToken: string): Promise<void> {
    return fetch(`/.netlify/functions/api/scales/${scaleId}`, {
        method: 'DELETE',
        headers: { "Authorization": `Bearer ${accessToken}` }
    }).then(res => {
        if (res.status !== 200) {
            throw new Error('Server error when deleting scale');
        }
    });
}

export function loadApotomeSessions(accessToken: string): Promise<SessionHeader[]> {
    return fetch('/.netlify/functions/api/apotomeSessions', {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${accessToken}` }
    }).then(res => res.json());
}

export function loadApotomeSession(id: string, accessToken?: string): Promise<Session> {
    return fetch(`/.netlify/functions/api/apotomeSessions/${id}`, {
        method: 'GET',
        headers: accessToken ? { 'Authorization': `Bearer ${accessToken}` } : {}
    }).then(res => res.json());
}

export function createApotomeSession(snapshot: Partial<Session>, accessToken: string): Promise<Session> {
    return fetch('/.netlify/functions/api/apotomeSessions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` },
        body: JSON.stringify(snapshot)
    }).then(res => res.json());
}

export function updateApotomeSessionHeader(session: SessionHeader, accessToken: string): Promise<SessionHeader> {
    return fetch(`/.netlify/functions/api/apotomeSessions/${session.id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` },
        body: JSON.stringify(session)
    }).then(res => res.json());
}

export function updateApotomeSession(session: Session, accessToken: string): Promise<Session> {
    return fetch(`/.netlify/functions/api/apotomeSessions/${session.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` },
        body: JSON.stringify(session)
    }).then(res => res.json());
}

export function deleteApotomeSession(id: string, accessToken: string): Promise<SessionHeader[]> {
    return fetch(`/.netlify/functions/api/apotomeSessions/${id}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${accessToken}` }
    }).then(res => res.json());
}

export function loadApotomeTuningSnapshots(accessToken: string): Promise<TuningSnapshot[]> {
    return fetch('/.netlify/functions/api/apotomeTuningSnapshots', {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${accessToken}` }
    }).then(res => res.json());
}

export function createApotomeTuningSnapshot(snapshot: Partial<TuningSnapshot>, accessToken: string): Promise<TuningSnapshot> {
    return fetch('/.netlify/functions/api/apotomeTuningSnapshots', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` },
        body: JSON.stringify(snapshot)
    }).then(res => res.json());
}

export function deleteApotomeTuningSnapshot(id: string, accessToken: string): Promise<TuningSnapshot[]> {
    return fetch(`/.netlify/functions/api/apotomeTuningSnapshots/${id}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${accessToken}` }
    }).then(res => res.json());
}

export function loadApotomeBooking(id: string): Promise<StageBooking | undefined> {
    return fetch(`/.netlify/functions/api/apotomeBookings/${id}`, {
        method: 'GET'
    }).then(res => res.status === 200 ? res.json() : undefined);
}


export function loadUserProfile(accessToken: string): Promise<UserProfile | undefined> {
    return fetch(`/.netlify/functions/api/userProfile`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${accessToken}` }
    }).then(res => res.status === 200 ? res.json() : undefined);
}

export function saveUserProfile(profile: UserProfile, accessToken: string): Promise<void> {
    return fetch(`/.netlify/functions/api/userProfile`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` },
        body: JSON.stringify(profile)
    }).then(() => { });
}