import React, { useMemo, useState } from "react";
import { isNumber, flatMap, findIndex, sortBy, uniqBy, min } from "lodash";
import classNames from "classnames";

import "./OctaveDivisionBand.scss";
import {
  Pitch,
  getPitchCents,
  TString,
  getPitchSum,
  reduceIntoOctave,
} from "./main/core";
import { useWindowWidth } from "@react-hook/window-size";
import { OctaveDivisionBandDivision } from "./OctaveDivisionBandDivision";

export const OCTAVE_DIVISION_BAND_MARGIN = 40;
export const OCTAVE_DIVISION_BAND_HEIGHT = 80;
export const OCTAVE_DIVISION_GAP_WIDTH = 2;
export const OCTAVE_DIVISION_BAND_Y = 10;

export const OCTAVE_DIVISION_BAND_DIVISION_WIDTH = 8;

interface OctaveDivisionBandProps {
  strings: TString[];
  selectedDivisionIndex: { stringIdx: number; pcIdx: number };
  onSoundDivision: (string: TString, pc: Pitch, retrigger?: boolean) => void;
  onOctaveHover: () => void;
  onDivisionClick: (stringIndex: number, divisionIndex: number) => void;
  onDivisionUpdate: (
    stringIndex: number,
    divisionIndex: number,
    newCents: number
  ) => void;
  onIntervalEnter: (evt: React.PointerEvent) => void;
  onIntervalLeave: (evt: React.PointerEvent) => void;
  onIntervalMove: (evt: React.PointerEvent) => void;
  onIntervalClick: (evt: React.MouseEvent) => void;
}
export const OctaveDivisionBand: React.FC<OctaveDivisionBandProps> = ({
  strings,
  selectedDivisionIndex,
  onSoundDivision,
  onOctaveHover,
  onDivisionClick,
  onDivisionUpdate,
  onIntervalEnter,
  onIntervalLeave,
  onIntervalMove,
  onIntervalClick,
}) => {
  let windowWidth = useWindowWidth();
  let [centsAdjustment, setCentsAdjustment] = useState<{
    stringIdx: number;
    pcIdx: number;
    cents: number;
  }>({ stringIdx: -1, pcIdx: -1, cents: 0 });
  let flatPitches = useMemo(() => getFlatPitches(strings, centsAdjustment), [
    strings,
    centsAdjustment,
  ]);
  let isAdjustingCents = centsAdjustment.stringIdx >= 0;

  let onAdjustingCents = (stringIdx: number, pcIdx: number, cents: number) => {
    setCentsAdjustment({ stringIdx, pcIdx, cents });
    onSoundDivision(strings[stringIdx], { cents }, false);
  };
  let onAdjustedCents = (
    stringIdx: number,
    pcIdx: number,
    cents: number | null
  ) => {
    setCentsAdjustment({ stringIdx: -1, pcIdx: -1, cents: 0 });
    isNumber(cents) && onDivisionUpdate(stringIdx, pcIdx, cents);
  };

  let anyRatios = flatPitches.find(
    (p) => isNumber(p.pitch.ratioUpper) && isNumber(p.pitch.ratioLower)
  );

  return (
    <g className="octaveDivisionBand">
      {flatPitches.map(({ stringIdx, pcIdx }) => (
        <rect
          key={`${stringIdx}-${pcIdx}`}
          className={classNames("octaveDivisionBand--interval", {
            isPassive: isAdjustingCents,
          })}
          x={
            getDivisionX(stringIdx, pcIdx, flatPitches, windowWidth) +
            OCTAVE_DIVISION_GAP_WIDTH
          }
          y={OCTAVE_DIVISION_BAND_Y}
          width={
            getDivisionWidth(stringIdx, pcIdx, flatPitches, windowWidth) -
            OCTAVE_DIVISION_GAP_WIDTH
          }
          height={OCTAVE_DIVISION_BAND_HEIGHT}
          onPointerEnter={onIntervalEnter}
          onPointerLeave={onIntervalLeave}
          onPointerMove={onIntervalMove}
          onClick={onIntervalClick}
          data-tip="Click to add a division here"
        />
      ))}
      {flatPitches.map(({ stringIdx, pcIdx, pitch }) => (
        <OctaveDivisionBandDivision
          key={`${stringIdx}-${pcIdx}`}
          pitch={pitch}
          isFirst={stringIdx === 0 && pcIdx === 0}
          isSelected={
            selectedDivisionIndex.stringIdx === stringIdx &&
            selectedDivisionIndex.pcIdx === pcIdx
          }
          isPassive={
            isAdjustingCents &&
            (centsAdjustment.stringIdx !== stringIdx ||
              centsAdjustment.pcIdx !== pcIdx)
          }
          onClick={() => onDivisionClick(stringIdx, pcIdx)}
          onHover={() =>
            !isAdjustingCents &&
            onSoundDivision(
              strings[stringIdx],
              strings[stringIdx].pitchClasses[pcIdx],
              true
            )
          }
          onAdjustingCents={(newCents) =>
            onAdjustingCents(stringIdx, pcIdx, newCents)
          }
          onAdjustedCents={(newCents) =>
            onAdjustedCents(stringIdx, pcIdx, newCents)
          }
        />
      ))}
      <rect
        className={classNames("octaveDivisionBand--division", {
          isPassive: isAdjustingCents,
        })}
        x={
          getOctaveDivisionBandWidth(windowWidth) / 2 -
          OCTAVE_DIVISION_BAND_DIVISION_WIDTH / 2
        }
        y={OCTAVE_DIVISION_BAND_Y}
        width={OCTAVE_DIVISION_BAND_DIVISION_WIDTH}
        height={OCTAVE_DIVISION_BAND_HEIGHT}
        onPointerEnter={onOctaveHover}
      />
      <text
        className={classNames("octaveDivisionBand--divisionLabel", {
          isPassive: isAdjustingCents,
        })}
        x={getOctaveDivisionBandWidth(windowWidth) / 2}
        y={OCTAVE_DIVISION_BAND_Y}
        transform={`rotate(270, ${
          getOctaveDivisionBandWidth(windowWidth) / 2
        }, ${OCTAVE_DIVISION_BAND_Y - 5})`}
      >
        {anyRatios ? "2:1" : "1200"}
      </text>
    </g>
  );
};

export let getFlatPitches = (
  strings: TString[],
  overrideCentsFor?: { stringIdx: number; pcIdx: number; cents: number }
) => {
  let flatPitches = sortBy(
    flatMap(strings, (string, stringIdx) =>
      string.pitchClasses.map((pc, pcIdx) => ({
        stringIdx,
        pcIdx,
        pitch:
          overrideCentsFor?.stringIdx === stringIdx &&
          overrideCentsFor?.pcIdx === pcIdx
            ? { cents: overrideCentsFor.cents }
            : reduceIntoOctave(getPitchSum(string, pc)),
      }))
    ),
    (p) => getPitchCents(p.pitch)
  );
  if (
    !overrideCentsFor ||
    overrideCentsFor.stringIdx < 0 ||
    overrideCentsFor.pcIdx < 0
  ) {
    flatPitches = uniqBy(flatPitches, (p) => getPitchCents(p.pitch));
  }
  return flatPitches;
};

export let getDivisionWidth = (
  stringIdx: number,
  pcIdx: number,
  flatPitches: { stringIdx: number; pcIdx: number; pitch: Pitch }[],
  windowWidth: number
) => {
  let totalDivsWidth = getOctaveDivisionBandWidth(windowWidth);
  let flatIdx = findIndex(
    flatPitches,
    (p) => p.stringIdx === stringIdx && p.pcIdx === pcIdx
  );
  let startCents = getPitchCents(flatPitches[flatIdx].pitch);
  let endCents =
    min(
      flatPitches
        .map((p) => getPitchCents(p.pitch))
        .filter((c) => c > startCents)
    ) ?? 1200;
  let cents = endCents - startCents;
  return totalDivsWidth * (cents / 1200);
};

export let getDivisionX = (
  stringIdx: number,
  pcIdx: number,
  flatPitches: { stringIdx: number; pcIdx: number; pitch: Pitch }[],
  windowWidth: number
) => {
  let flatIdx = findIndex(
    flatPitches,
    (p) => p.stringIdx === stringIdx && p.pcIdx === pcIdx
  );
  let startCents = getPitchCents(flatPitches[flatIdx].pitch);
  return getDivisionXFromCents(startCents, windowWidth);
};

export let getDivisionXFromCents = (cents: number, windowWidth: number) => {
  let totalDivsWidth = getOctaveDivisionBandWidth(windowWidth);
  let widthToTheLeft = (totalDivsWidth * cents) / 1200;
  return -getOctaveDivisionBandWidth(windowWidth) / 2 + widthToTheLeft;
};

export let getDivisionCentsFromX = (x: number, windowWidth: number) => {
  let xRel =
    (x - OCTAVE_DIVISION_BAND_MARGIN) / getOctaveDivisionBandWidth(windowWidth);
  let xRelClamp = Math.max(0, Math.min(1, xRel));
  return Math.round(xRelClamp * 1200);
};

export let getOctaveDivisionBandWidth = (windowWidth: number) => {
  return windowWidth - 2 * OCTAVE_DIVISION_BAND_MARGIN;
};
