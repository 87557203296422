import { EventEmitter } from "events";
import { isNumber } from "lodash";
import React, { useEffect, useRef } from "react";

import "./MelodicIntervalToggle.scss";
import { ToggleButton } from "./ToggleButton";

interface MelodicIntervalToggleProps {
  interval: number | "8ve";
  on: boolean;
  disabled?: boolean;
  onToggle: (on: boolean) => void;
  events: EventEmitter;
}
export const MelodicIntervalToggle: React.FC<MelodicIntervalToggleProps> = React.memo(
  ({ interval, on, disabled, onToggle, events }) => {
    let labelRef = useRef<HTMLSpanElement>(null);
    useEffect(() => {
      let onNote = (note: any) => {
        let labelEl = labelRef.current!;
        if (
          note.interval === interval &&
          !labelEl.classList.contains("isPlaying")
        ) {
          labelEl.classList.add("isPlaying");
          setTimeout(() => {
            labelEl.classList.remove("isPlaying");
          }, note.duration * 900);
        } else if (
          note.interval !== interval &&
          labelEl.classList.contains("isPlaying")
        ) {
          labelEl.classList.remove("isPlaying");
        }
      };
      events.on("note", onNote);
      return () => {
        events.off("note", onNote);
      };
    }, [events, interval]);
    return (
      <label className="melodicIntervalToggle">
        <ToggleButton checked={on} disabled={disabled} onToggle={onToggle} />
        <span className="melodicIntervalToggle--label" ref={labelRef}>
          {isNumber(interval) ? interval : interval}
        </span>
      </label>
    );
  }
);
