import pako from 'pako';
import { immediate } from 'tone';
import ZipLoader from 'zip-loader';

import { getClosestMIDINote, getMIDIPitchBend } from '../../main/audio';
import { StringInstrumentBank } from '../types';
import { loadCommon, loadScript } from './common';

declare let WAM: any;

let loadPromise: Promise<StringInstrumentBank[]> | null = null;
let banks: StringInstrumentBank[] | null = null;
async function _load(audioCtx: AudioContext) {
    await loadCommon();
    await loadScript('/wam/yoshimi/yoshimi.js');
    if (!WAM.YOSHIMI) return []; // No AudioWorklet
    await WAM.YOSHIMI.importScripts(audioCtx);
    let bankMeta = await fetch('/wam/yoshimi/banks/root.json').then(res => res.json());
    banks = bankMeta.map((bank: any) => ({
        name: bank.name,
        presets: []
    }));
    let loader = new ZipLoader('/wam/yoshimi/banks/allbanks.zip');
    await loader.load();
    for (let i = 0; i < bankMeta.length; i++) {
        let bank = bankMeta[i];
        for (let j = 0; j < bank.instruments.length; j++) {
            let instr = bank.instruments[j];
            let utf8 = pako.inflate(loader.files[`${bank.name}/${instr}`].buffer);
            let xml = new TextDecoder('utf-8').decode(utf8);
            banks![i].presets[j] = { name: instr, patch: xml };
        }
    }
    return banks!;
}

function load(audioCtx: AudioContext) {
    if (!loadPromise) loadPromise = _load(audioCtx);
    return loadPromise;
}

export class Yoshimi {

    private patchSet = false;
    private wam?: any;
    private outputGain?: GainNode;
    public currentBank: number | null = null;
    public currentPreset: number | null = null;

    static preload(audioCtx: AudioContext) {
        return load(audioCtx);
    }

    constructor(audioCtx: AudioContext, dest: any) {
        this.wam = new WAM.YOSHIMI(audioCtx);
        this.outputGain = audioCtx.createGain();
        while (dest.input) {
            dest = dest.input;
        }
        if (dest._nativeAudioNode) {
            dest = dest._nativeAudioNode;
        }
        this.wam.connect(this.outputGain);
        this.outputGain.connect(dest);
    }

    async setPreset(bank: number, preset: number) {
        if (bank !== this.currentBank || preset !== this.currentPreset) {
            this.currentBank = bank;
            this.currentPreset = preset;
            this.wam?.sendMessage("set", "patch", banks![this.currentBank].presets[this.currentPreset].patch);
            this.patchSet = true;
        }
    }


    triggerAttackRelease(frequency: number, duration: number, time: number, velocity: number) {
        if (!this.patchSet) return;
        let midiNote = getClosestMIDINote(frequency, 200, null);
        let pitchBend = getMIDIPitchBend(frequency, midiNote, 200);
        var bendLevel = Math.round((pitchBend + 1) / 2 * 16383);
        var bendMsb = (bendLevel >> 7) & 0x7F;
        var bendLsb = bendLevel & 0x7F;
        this.wam?.onMidi([0x90, midiNote, velocity * 127], time);
        this.wam?.onMidi([0xE0, bendLsb, bendMsb], time);
        this.wam?.onMidi([0x80, midiNote, velocity * 127], time + duration);
    }

    sendControlChange(cc: number, value: number, time: number) {
        this.wam?.onMidi([0xB0, cc, value], time);
    }

    dispose(time = immediate()) {
        this.outputGain?.gain.setTargetAtTime(0, time, 0.33);
        setTimeout(() => {
            this.wam?.dispose();
            this.outputGain?.disconnect();
        }, 1000);
    }
}
