import React, { ChangeEvent } from "react";
import classNames from "classnames";

import { ScaleHeader } from "./core";

import "./ScalePicker.scss";
import { useAdminAccess } from "../useAdminAccess";

interface ScalePickerProps {
  scales: ScaleHeader[];
  currentScaleId?: number;
  label?: string;
  className?: string;
  disabled?: boolean;
  onPickScale: (id: number) => void;
}
export const ScalePicker: React.FC<ScalePickerProps> = ({
  scales,
  currentScaleId,
  label = "Choose",
  className,
  disabled,
  onPickScale,
}) => {
  let { hasAdminAccess } = useAdminAccess();
  let onPick = (evt: ChangeEvent<HTMLSelectElement>) => {
    let id = +evt.target.value;
    if (id >= 0) {
      onPickScale(id);
    }
    evt.target.blur();
  };
  let getScaleName = (scale: ScaleHeader) => {
    if (hasAdminAccess && scale.category?.startsWith("User")) {
      return `(${scale.category}) ${scale.name}`;
    } else {
      return scale.name;
    }
  };
  return (
    <select
      className={classNames("scalePicker", className)}
      onChange={onPick}
      value={currentScaleId || -1}
      disabled={disabled || scales.length === 0}
    >
      <option value={-1}>{label}</option>
      {scales.map((t) => (
        <option key={t.id} value={t.id}>
          {getScaleName(t)}
        </option>
      ))}
    </select>
  );
};
