import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { isEqual, omit, sortBy } from "lodash";
import { ReactSortable } from "react-sortablejs";
import { nanoid } from "nanoid";

import { DurationInput } from "../../main/DurationInput";
import { NumberInput } from "../../main/NumberInput";
import { ToggleSwitch } from "../ToggleSwitch";
import {
  MIDIOutputSnapshot,
  ReturnTrackSettings,
  Session,
  SessionSection,
  TrackControls,
  TransportControls,
} from "../types";
import * as api from "../../main/api";
import { MIDIOutput } from "../../main/core";

import "./SessionEditor.scss";

interface SessionEditorProps {
  session: Partial<Session>;
  currentTracks: { id: string; controls: TrackControls }[];
  currentReturnTrackSettings: ReturnTrackSettings;
  currentTransportControls: TransportControls;
  currentMidiOutputs: {
    [trackId: string]: MIDIOutput;
  };
  onOpenSection: (section: SessionSection) => void;
  onClose: () => void;
}
export const SessionEditor: React.FC<SessionEditorProps> = ({
  session: inputSession,
  currentTracks,
  currentReturnTrackSettings,
  currentTransportControls,
  currentMidiOutputs,
  onOpenSection,
  onClose,
}) => {
  let { getAccessTokenSilently } = useAuth0();
  let [session, setSession] = useState(inputSession);
  let [lastSavedSession, setLastSavedSession] = useState<Session>();
  let [saving, setSaving] = useState(false);
  let isDirty = !isEqual(session, lastSavedSession);

  let isValid = (session: Partial<Session>) => {
    return (
      (session.name?.length ?? 0) > 0 && (session.sections?.length ?? 0) > 0
    );
  };

  let onSave = async (sessionToSave: Partial<Session>) => {
    setSaving(true);
    let accessToken = await getAccessTokenSilently();
    let saved = sessionToSave.id
      ? await api.updateApotomeSession(sessionToSave as Session, accessToken)
      : await api.createApotomeSession(sessionToSave, accessToken);
    setSession(saved);
    setLastSavedSession(saved);
    setSaving(false);
  };

  let onCaptureAsNewSection = () => {
    let updatedSession = {
      ...session,
      sections: [
        ...(session.sections ?? []),
        {
          id: nanoid(),
          name: "",
          timing: "manual",
          minDuration: 20,
          maxDuration: 40,
          snapshot: {
            tracks: getTracksSnapshot(currentTracks),
            returnTrackSettings: currentReturnTrackSettings,
            transportControls: currentTransportControls,
            midiOutputs: serializeMidiOutputs(currentMidiOutputs),
          },
        } as SessionSection,
      ],
    };
    setSession(updatedSession);
    isValid(updatedSession) && onSave(updatedSession);
  };

  let updateSection = (
    idx: number,
    updater: (section: SessionSection) => SessionSection
  ) => {
    let updatedSession = {
      ...session,
      sections: [
        ...session.sections!.slice(0, idx),
        updater(session.sections![idx]),
        ...session.sections!.slice(idx + 1),
      ],
    };
    setSession(updatedSession);
    return updatedSession;
  };

  let onCaptureAndOverwriteSection = (idx: number) => {
    let updatedSession = updateSection(idx, (section) => ({
      ...section,
      snapshot: {
        tracks: getTracksSnapshot(currentTracks),
        returnTrackSettings: currentReturnTrackSettings,
        transportControls: currentTransportControls,
        midiOutputs: serializeMidiOutputs(currentMidiOutputs),
      },
    }));
    isValid(updatedSession) && onSave(updatedSession);
  };

  let onDeleteSection = (idx: number) => {
    setSession({
      ...session,
      sections: [
        ...session.sections!.slice(0, idx),
        ...session.sections!.slice(idx + 1),
      ],
    });
  };

  return (
    <div className="sessionEditor">
      <h2>
        <span className="backToMain" onClick={onClose}>
          &larr;
        </span>
        {session.id ? <>Edit Session</> : <>New Session</>}
      </h2>
      <div className="sessionEditor--actions">
        <button
          className="button button--primary"
          onClick={() => onSave(session)}
          disabled={saving || !isValid(session) || !isDirty}
        >
          {saving ? (
            <>Saving session&hellip;</>
          ) : !isDirty ? (
            <>All changes saved</>
          ) : (
            <>Save session</>
          )}
        </button>
        <button className="button" onClick={onClose}>
          Close
        </button>
      </div>

      <div className="sessionEditor--attributes">
        <label className="sessionEditor--attribute">
          Name
          <input
            type="text"
            className="input"
            value={session.name}
            onChange={(e) => setSession({ ...session, name: e.target.value })}
          />
        </label>
        <label className="sessionEditor--attribute">
          Description
          <input
            type="text"
            className="input"
            value={session.description}
            onChange={(e) =>
              setSession({ ...session, description: e.target.value })
            }
          />
        </label>
        <label className="sessionEditor--attribute">
          <ToggleSwitch
            checked={!!session.loop}
            onToggle={(loop) =>
              setSession({
                ...session,
                loop,
              })
            }
          />{" "}
          Looped
        </label>
        {session.loop && (
          <>
            <label className="sessionEditor--attribute">
              Total Min Duration <span className="formatLabel">(mm:ss)</span>
              <DurationInput
                className="input"
                value={session.loopedMinDuration}
                onChange={(loopedMinDuration) =>
                  setSession({ ...session, loopedMinDuration })
                }
              />
            </label>
            <label className="sessionEditor--attribute">
              Total Max Duration <span className="formatLabel">(mm:ss)</span>
              <DurationInput
                className="input"
                value={session.loopedMaxDuration}
                onChange={(loopedMaxDuration) =>
                  setSession({ ...session, loopedMaxDuration })
                }
              />
            </label>
            <label className="sessionEditor--attribute">
              <ToggleSwitch
                checked={!!session.loopedFadeout}
                onToggle={(loopedFadeout) =>
                  setSession({
                    ...session,
                    loopedFadeout,
                  })
                }
              />{" "}
              End with fade-out
            </label>
          </>
        )}
      </div>

      <h3>Snapshots</h3>
      <ReactSortable
        list={session.sections!}
        setList={(sections) => setSession({ ...session, sections })}
        direction="vertical"
      >
        {session.sections?.map((section, idx) => (
          <div className="sessionEditor--section" key={section.id}>
            <h4>Snapshot {idx + 1}</h4>
            <label className="sessionEditor--sectionName">
              Name
              <input
                type="text"
                className="input"
                value={section.name}
                onChange={(evt) =>
                  updateSection(idx, (section) => ({
                    ...section,
                    name: evt.target.value,
                  }))
                }
              />
            </label>
            <div className="sessionEditor--sectionTiming">
              Duration
              <select
                className="select"
                value={section.timing}
                onChange={(evt) =>
                  updateSection(idx, (s) => ({
                    ...s,
                    timing: evt.target.value as "manual" | "seconds" | "bars",
                  }))
                }
              >
                <option value="manual">Manual</option>
                <option value="seconds">Seconds</option>
                <option value="bars">Bars</option>
              </select>
              {section.timing === "seconds" && (
                <>
                  <label className="sessionEditor--timingRange">
                    <div>
                      Min <span className="formatLabel">(mm:ss)</span>
                    </div>
                    <DurationInput
                      className="input"
                      value={section.minDuration}
                      onChange={(minDuration) =>
                        updateSection(idx, (s) => ({ ...s, minDuration }))
                      }
                    />
                  </label>
                  <label className="sessionEditor--timingRange">
                    <div>
                      Max <span className="formatLabel">(mm:ss)</span>
                    </div>
                    <DurationInput
                      className="input"
                      value={section.maxDuration}
                      onChange={(maxDuration) =>
                        updateSection(idx, (s) => ({ ...s, maxDuration }))
                      }
                    />
                  </label>
                </>
              )}
              {section.timing === "bars" && (
                <>
                  <label className="sessionEditor--timingRange">
                    Min
                    <NumberInput
                      className="input"
                      value={section.minDuration}
                      onChange={(minDuration) =>
                        updateSection(idx, (s) => ({ ...s, minDuration }))
                      }
                    />
                  </label>
                  <label className="sessionEditor--timingRange">
                    Max
                    <NumberInput
                      className="input"
                      value={section.maxDuration}
                      onChange={(maxDuration) =>
                        updateSection(idx, (s) => ({ ...s, maxDuration }))
                      }
                    />
                  </label>
                </>
              )}
            </div>
            <div className="sessionEditor--sectionActions">
              <button className="button" onClick={() => onOpenSection(section)}>
                Load
              </button>
              <button
                className="button"
                onClick={() => onCaptureAndOverwriteSection(idx)}
              >
                Capture current and overwrite
              </button>
              <button className="button" onClick={() => onDeleteSection(idx)}>
                Delete
              </button>
            </div>
          </div>
        ))}
      </ReactSortable>
      <button
        className="button sessionEditor--newSection"
        onClick={onCaptureAsNewSection}
      >
        Capture current as new snapshot
      </button>
    </div>
  );
};

function getTracksSnapshot(tracks: { id: string; controls: TrackControls }[]) {
  return tracks.map(({ id, controls }) => ({
    id,
    controls: omit(controls, "looper"),
  }));
}

let serializeMidiOutputs = (outputs: {
  [trackId: string]: MIDIOutput;
}): MIDIOutputSnapshot[] => {
  return sortBy(
    Object.keys(outputs).map((trackId) => ({
      trackId,
      outputId: outputs[trackId].output?.id,
      channel: outputs[trackId].channel,
      pitchBendRangeCents: outputs[trackId].pitchBendRangeCents,
    })),
    (o) => o.trackId
  );
};
