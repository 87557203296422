import React from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
import { range } from "lodash";

import "./Breadcrumbs.scss";

interface BreadcrumbsProps {
  currentStep: 1 | 2 | 3;
  currentDescription: string;
  onNextAction?: () => Promise<void>;
  nextActionUrl?: string;
  nextActionEnabled?: boolean;
}
export const Breadcrumbs: React.FC<BreadcrumbsProps> = React.memo(
  ({
    currentStep,
    currentDescription,
    nextActionUrl,
    nextActionEnabled,
    onNextAction,
  }) => {
    let history = useHistory();
    let matchRefPitch = useRouteMatch(
      "/leimma/:tuningSystemId/refpitch/:refPitch"
    );
    let matchDivisions = useRouteMatch(
      "/leimma/:tuningSystemId/refpitch/:refPitch/tuningsystem/:divisions"
    );

    let getBackLinkHref = (num: 0 | 1 | 2) => {
      if (num === 0) {
        return "/leimma";
      }
      if (num === 1) {
        return matchRefPitch!.url;
      } else {
        return matchDivisions!.url;
      }
    };

    let onNextActionClick = async () => {
      if (onNextAction) {
        await onNextAction();
      }
      history.push(nextActionUrl!);
    };

    return (
      <div className="breadcrumbs">
        {range(0, currentStep).map((num) => (
          <Link
            key={num}
            className="breadcrumbs--number previousStep"
            to={getBackLinkHref(num as 0 | 1 | 2)}
          >
            {num}
          </Link>
        ))}
        <div className="breadcrumbs--number">{currentStep}</div>
        <div className="breadcrumbs--description">{currentDescription}</div>
        {nextActionUrl && (
          <button
            className={classNames("breadcrumbs--nextAction", "button", {
              isDisabled: !nextActionEnabled,
            })}
            onClick={onNextActionClick}
          >
            Next
          </button>
        )}
      </div>
    );
  }
);
