import { Frequency, Synth, now as toneNow, start as toneStart, getContext } from "tone";
import { isNumber, minBy, range } from "lodash";
import { MIDIOutput } from "./core";
import { KarplusStrongWorklet } from "../karplusstrong/KarplusStrongWorklet";
import { MIDI_CHANNEL_MPE_ROUND_ROBIN_MAX, MIDI_CHANNEL_MPE_ROUND_ROBIN_MIN, MIDI_CHANNEL_ROUND_ROBIN_MAX, MIDI_CHANNEL_ROUND_ROBIN_MIN } from "../constants";

let inactiveSynths = range(12).map(() =>
  new Synth({
    oscillator: {
      type: "triangle"
    }
  }).toDestination()
);
let activeSynths = new Map<number, Synth>();
let karplus: KarplusStrongWorklet | null = null;

let activeStrings = new Map<number, { stringNumber: number, frequency: number }>();


let activeMidiNotes = new Map<number, { note: number, pitchBend: number, velocity: number, channel: number }>();
let midiChannelRoundRobin = MIDI_CHANNEL_ROUND_ROBIN_MIN;

export function getClosestMIDINote(freq: number, pitchBendRangeCents: number, mappedNote: number | null) {
  if (isNumber(mappedNote)) {
    let mappedNoteFreqs = range(8).map(o => Frequency(mappedNote + o * 12, 'midi').toFrequency());
    let closestMappedNoteFreq = minBy(mappedNoteFreqs, f => Math.abs(f - freq))!;
    let closestMappedNotePitchBendCents = 1200 * Math.log2(freq / closestMappedNoteFreq);
    if (Math.abs(closestMappedNotePitchBendCents) < pitchBendRangeCents) {
      let closestMappedNoteOctave = mappedNoteFreqs.indexOf(closestMappedNoteFreq);
      let closestMappedNoteMidi = mappedNote + closestMappedNoteOctave * 12;
      return closestMappedNoteMidi;
    }
  }
  return Math.round(Math.log(freq / 440) / Math.log(2) * 12 + 69);
}

export function getMIDIPitchBend(freq: number, baseMidiNote: number, pitchBendRangeCents: number) {
  if (pitchBendRangeCents === 0) return 0;
  let midiNoteFreq = Frequency(baseMidiNote, 'midi').toFrequency();
  let cents = Math.round(Math.log(freq / midiNoteFreq) / Math.log(2) * 1200);
  return Math.max(-1, Math.min(1, cents / pitchBendRangeCents));
}

export function init() {
  KarplusStrongWorklet.register(getContext().rawContext as any);
}

export function now() {
  return toneNow();
}

export function noteOn(keyCode: number, freq: number, velocity: number, mappedNote: number | null, midiOutput: MIDIOutput, retrigger = true) {
  if (midiOutput.output) {
    let midiNote = getClosestMIDINote(freq, midiOutput.pitchBendRangeCents, mappedNote);
    let pitchBend = getMIDIPitchBend(freq, midiNote, midiOutput.pitchBendRangeCents);
    let midiChannel = getNextMIDIChannel(midiOutput);
    if (activeMidiNotes.has(keyCode)) {
      if (retrigger) {
        noteOff(keyCode, midiOutput);
      } else {
        return;
      }
    }
    midiOutput.output.playNote(midiNote, midiChannel, { velocity });
    midiOutput.output.sendPitchBend(pitchBend, midiChannel);
    activeMidiNotes.set(keyCode, { note: midiNote, pitchBend, channel: midiChannel, velocity });
  } else if (midiOutput.internal === 'synth') {
    toneStart();
    if (activeSynths.has(keyCode)) {
      if (retrigger) {
        noteOff(keyCode, midiOutput);
      } else {
        return;
      }
    }
    let synth = inactiveSynths.pop();
    if (synth) {
      synth.triggerAttack(freq, now(), velocity);
      activeSynths.set(keyCode, synth);
    }
  } else if (midiOutput.internal === 'string') {
    toneStart();
    if (!karplus) {
      let ctx = (getContext().rawContext as any)._nativeAudioContext;
      karplus = new KarplusStrongWorklet(ctx);
      karplus.connect(ctx.destination);
      karplus.init().then(() => {
        if (activeStrings.has(keyCode)) {
          if (!retrigger) {
            return;
          }
        }
        let stringNumber = karplus!.playNext(freq, now(), velocity);
        activeStrings.set(keyCode, { stringNumber, frequency: freq });
      })
    } else {
      if (activeStrings.has(keyCode)) {
        if (!retrigger) {
          return;
        }
      }
      let stringNumber = karplus.playNext(freq, now(), 1);
      activeStrings.set(keyCode, { stringNumber, frequency: freq });
    }

  }
}

function getNextMIDIChannel(midiOutput: MIDIOutput) {
  if (midiOutput.channel === 'all' || midiOutput.channel === 'mpe') {
    let min = midiOutput.channel === 'all' ? MIDI_CHANNEL_ROUND_ROBIN_MIN : MIDI_CHANNEL_MPE_ROUND_ROBIN_MIN;
    let max = midiOutput.channel === 'all' ? MIDI_CHANNEL_ROUND_ROBIN_MAX : MIDI_CHANNEL_MPE_ROUND_ROBIN_MAX;
    if (midiChannelRoundRobin < min) {
      midiChannelRoundRobin = min;
    }
    let rr = midiChannelRoundRobin;
    midiChannelRoundRobin++;
    if (midiChannelRoundRobin > max) {
      midiChannelRoundRobin = min;
    }
    return rr;
  } else {
    return midiOutput.channel;
  }
}

export function setFrequency(keyCode: number, freq: number, mappedNote: number | null, midiOutput: MIDIOutput) {
  if (midiOutput.output) {
    let midiNote = getClosestMIDINote(freq, midiOutput.pitchBendRangeCents, mappedNote);
    let pitchBend = getMIDIPitchBend(freq, midiNote, midiOutput.pitchBendRangeCents);
    if (activeMidiNotes.has(keyCode)) {
      let active = activeMidiNotes.get(keyCode)!;
      if (active.note !== midiNote) {
        noteOff(keyCode, midiOutput);
        noteOn(keyCode, freq, active.velocity, mappedNote, midiOutput);
      } else {
        active.pitchBend = pitchBend;
        midiOutput.output.sendPitchBend(pitchBend, active.channel);
      }
    }
  } else if (midiOutput.internal === 'synth') {
    let synth = activeSynths.get(keyCode);
    if (synth) {
      synth.setNote(freq);
    }
  } else if (midiOutput.internal === 'string') {
    let string = activeStrings.get(keyCode);
    if (string) {
      karplus?.setFrequency(string.stringNumber, freq);
    }
  }
}

export function noteOff(keyCode: number, midiOutput: MIDIOutput) {
  if (midiOutput.output) {
    let activeNote = activeMidiNotes.get(keyCode);
    if (activeNote) {
      midiOutput.output.stopNote(activeNote.note, activeNote.channel);
      activeMidiNotes.delete(keyCode);
    }
  } else if (midiOutput.internal === 'synth') {
    let synth = activeSynths.get(keyCode);
    if (synth) {
      synth.triggerRelease();
      inactiveSynths.push(synth);
      activeSynths.delete(keyCode);
    }
  } else if (midiOutput.internal === 'string') {
    let string = activeStrings.get(keyCode);
    if (string) {
      karplus?.stopString(string.stringNumber, now());
      activeStrings.delete(keyCode);
    }
  }
}

export function forwardMIDICC(controller: number, value: number, midiOutput: MIDIOutput) {
  if (midiOutput.output) {
    midiOutput.output.sendControlChange(controller, value, midiOutput.channel === 'mpe' ? 'all' : midiOutput.channel);
  }
}

export function forwardMIDIPitchBend(bend: number, midiOutput: MIDIOutput) {
  if (midiOutput.output) {
    let scaledBend = midiOutput.pitchBendRangeCents > 200 ? bend * 200 / midiOutput.pitchBendRangeCents : bend;
    activeMidiNotes.forEach(active => {
      let baseBend = active.pitchBend;
      let maxBendRange = 1 - Math.abs(baseBend);
      let clampedBend = scaledBend < 0 ? Math.max(scaledBend, -maxBendRange) : Math.min(scaledBend, maxBendRange);
      let newTotalBend = active.pitchBend + clampedBend;
      midiOutput.output!.sendPitchBend(newTotalBend, active.channel);
    });
  } else if (midiOutput.internal === 'synth') {
    let bendCents = bend * 200;
    activeSynths.forEach(synth => {
      synth.detune.setTargetAtTime(bendCents, now(), 0.01);
    });
  } else if (midiOutput.internal === 'string') {
    activeStrings.forEach(string => {
      let baseFreq = string.frequency;
      let bentFreq = baseFreq * (2 ** ((2 * bend) / 12));
      karplus?.setFrequency(string.stringNumber, bentFreq);
    });
  }
}


export function allOff(midiOutput: MIDIOutput) {
  if (midiOutput.output) {
    activeMidiNotes.forEach(({ note, channel }) => {
      midiOutput.output!.stopNote(note, channel);
    });
    activeMidiNotes.clear();
  } else {
    activeSynths.forEach((_, keyCode) => noteOff(keyCode, midiOutput));
  }
}
