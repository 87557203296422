import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { euclideanPattern } from "./apotomeController";

import "./EuclideanVisualizer.scss";
import { EventEmitter } from "ws";

interface EuclideanVisualizerProps {
  k: number;
  n: number;
  events?: EventEmitter;
}
export const EuclideanVisualizer: React.FC<EuclideanVisualizerProps> = React.memo(
  ({ k, n, events }) => {
    let pattern = euclideanPattern(k, n);
    let circlesRef = useRef<(SVGCircleElement | null)[]>([]);

    let getX = (idx: number) => {
      let rad = (idx / pattern.length) * Math.PI * 2;
      return Math.cos(rad) * 40;
    };
    let getY = (idx: number) => {
      let rad = (idx / pattern.length) * Math.PI * 2;
      return Math.sin(rad) * 40;
    };

    useEffect(() => {
      let onStep = (step: number) => {
        for (let i = 0; i < circlesRef.current.length; i++) {
          circlesRef.current[i]?.classList.toggle("current", i === step);
        }
      };
      events?.on("euclideanStep", onStep);
      return () => {
        events?.off("euclideanStep", onStep);
      };
    }, [events]);

    return (
      <svg className="euclideanVisualizer" viewBox="-50 -50 100 100">
        {pattern.map((on, idx) => (
          <circle
            key={idx}
            ref={(e) => (circlesRef.current[idx] = e)}
            cx={getX(idx)}
            cy={getY(idx)}
            r={5}
            className={classNames("beat", { on: !!on })}
          />
        ))}
      </svg>
    );
  }
);
