import React, { useCallback, useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { findIndex, noop, pick } from "lodash";
import * as Nexus from "nexusui";
import classNames from "classnames";
import WebMidi from "webmidi";
import { isMobile } from "mobile-device-detect";

import "./Apotome.scss";

import { GlobalControls } from "./GlobalControls";
import {
  makeDefaultControls,
  syncScaleDegreeWeightsAndAllowedIntervalsWithScale,
  Track,
} from "./Track";
import * as controller from "./apotomeController";
import * as visCon from "./apotomeVisualiserConnection";
import { Rnd } from "react-rnd";
import { ReturnTrack } from "./ReturnTrack";
import { MIDIContext } from "../MIDIContext";
import { MIDIOutput, TuningSystem } from "../main/core";
import { Helmet } from "react-helmet";
import { SessionManager } from "./sessions/SessionManager";
import { ChooseTuningSystemDialog } from "../ChooseTuningSystemDialog";
import {
  InstrumentBanks,
  ReturnTrackSettings,
  TrackControls,
  TransportControls,
} from "./types";
import { LoadingSplash } from "./LoadingSplash";
import { TuningSnapshotsManager } from "../TuningSnapshotsManager";
import {
  ALLOWED_INTERVALS_LEADER_FOLLOWER_CONTROLS,
  BEAT_DIVISION_LEADER_FOLLOWER_CONTROLS,
  DEFAULT_RETURN_TRACK_SETTINGS,
  MAX_POLYPHONY,
  SCALE_WEIGHT_LEADER_FOLLOWING_CONTROLS,
  TUNING_LEADER_FOLLOWING_CONTROLS,
} from "./constants";
import { OBXD } from "./wam/obxd";
import { NotSupported } from "./NotSupported";

Nexus.colors.fill = "#333";
Nexus.colors.accent = "#BDE1F4";

interface ApotomeProps {
  currentSpace?: "Studio" | "Stage";
  startAllowed: boolean;
}
export const Apotome: React.FC<ApotomeProps> = ({
  currentSpace = "Studio",
  startAllowed = true,
}) => {
  let [loading, setLoading] = useState(true);
  let [audioSupported, setAudioSupported] = useState(true);
  let [instrumentBanks, setInstrumentBanks] = useState<InstrumentBanks>();
  let [tracks, setTracks] = useState<{ id: string; controls: TrackControls }[]>(
    []
  );
  let [trackMidiOutputs, setTrackMidiOutputs] = useState<{
    [trackId: string]: MIDIOutput;
  }>({});
  let [transportControls, setTransportControls] = useState(
    controller.getTransportControls()
  );
  let [
    tuningSystemDialogOpenForTrack,
    setTuningSystemDialogOpenForTrack,
  ] = useState<string | null>(null);
  let [tuningSnapshotsOpenForTrack, setTuningSnapshotsOpenForTrack] = useState<
    string | null
  >(null);
  let [sessionManagerOpen, setSessionManagerOpen] = useState(false);

  let [returnTrackSettings, setReturnTrackSettings] = useState(
    DEFAULT_RETURN_TRACK_SETTINGS
  );

  let pluginGUIRndRef = useCallback((rnd: Rnd | null) => {
    rnd && controller.setPluginGUIContainer(rnd);
  }, []);

  let subscribeTrackControlsChange = useCallback((trackId: string) => {
    controller
      .getTrackEvents(trackId)!
      .on("controlsChange", (controls: TrackControls) => {
        setTracks((tracks) => {
          let idx = findIndex(tracks, (t) => t.id === trackId);
          if (idx >= 0) {
            return [
              ...tracks.slice(0, idx),
              { ...tracks[idx], controls },
              ...tracks.slice(idx + 1),
            ];
          } else {
            return tracks;
          }
        });
      });
  }, []);

  let setTrackControls = useCallback(
    (trackId: string, updater: (controls: TrackControls) => TrackControls) => {
      setTracks((tracks) => {
        let previousControls = tracks.find((t) => t.id === trackId)!.controls;
        let updatedControls = updater(previousControls);
        let isLeader = trackId === "1";

        let tuningFollowerPatch: Partial<TrackControls> | null = isLeader
          ? pick(updatedControls, TUNING_LEADER_FOLLOWING_CONTROLS)
          : null;
        let scaleWeightFollowerPatch: Partial<TrackControls> | null = isLeader
          ? pick(updatedControls, SCALE_WEIGHT_LEADER_FOLLOWING_CONTROLS)
          : null;
        let allowedIntervalFollowerPatch: Partial<TrackControls> | null = isLeader
          ? pick(updatedControls, ALLOWED_INTERVALS_LEADER_FOLLOWER_CONTROLS)
          : null;
        let beatDivisionFollowerPatch: Partial<TrackControls> | null = isLeader
          ? pick(updatedControls, BEAT_DIVISION_LEADER_FOLLOWER_CONTROLS)
          : null;

        let isNewTuningFollower =
          !isLeader &&
          updatedControls.followTuningFromLeadTrack &&
          !previousControls.followTuningFromLeadTrack;
        let isNewScaleWeightFollower =
          !isLeader &&
          updatedControls.followScaleWeightsFromLeadTrack &&
          !previousControls.followScaleWeightsFromLeadTrack;
        let isNewAllowedIntervalFollower =
          !isLeader &&
          updatedControls.followAllowedIntervalsFromLeadTrack &&
          !previousControls.followAllowedIntervalsFromLeadTrack;
        let isNewBeatDivisionFollower =
          !isLeader &&
          updatedControls.followBeatDivisionWeightsFromLeadTrack &&
          !previousControls.followBeatDivisionWeightsFromLeadTrack;

        let selfTuningPatch: Partial<TrackControls> | null = isNewTuningFollower
          ? pick(
              tracks.find((t) => t.id === "1")!.controls,
              TUNING_LEADER_FOLLOWING_CONTROLS
            )
          : {};
        let selfScaleWeightPatch: Partial<TrackControls> | null = isNewScaleWeightFollower
          ? pick(
              tracks.find((t) => t.id === "1")!.controls,
              SCALE_WEIGHT_LEADER_FOLLOWING_CONTROLS
            )
          : {};
        let selfAllowedIntervalPatch: Partial<TrackControls> | null = isNewAllowedIntervalFollower
          ? pick(
              tracks.find((t) => t.id === "1")!.controls,
              ALLOWED_INTERVALS_LEADER_FOLLOWER_CONTROLS
            )
          : {};
        let selfBeatDivisionPatch: Partial<TrackControls> | null = isNewBeatDivisionFollower
          ? pick(
              tracks.find((t) => t.id === "1")!.controls,
              BEAT_DIVISION_LEADER_FOLLOWER_CONTROLS
            )
          : {};

        return tracks.map((t) => {
          if (t.id === trackId) {
            updatedControls = syncScaleDegreeWeightsAndAllowedIntervalsWithScale(
              {
                ...updatedControls,
                ...selfTuningPatch,
                ...selfScaleWeightPatch,
                ...selfAllowedIntervalPatch,
                ...selfBeatDivisionPatch,
              }
            );
            controller.setTrack(trackId, updatedControls);
            return { ...t, controls: updatedControls };
          } else {
            let newT = t;
            if (
              (tuningFollowerPatch && t.controls.followTuningFromLeadTrack) ||
              (scaleWeightFollowerPatch &&
                t.controls.followScaleWeightsFromLeadTrack) ||
              (allowedIntervalFollowerPatch &&
                t.controls.followAllowedIntervalsFromLeadTrack) ||
              (beatDivisionFollowerPatch &&
                t.controls.followBeatDivisionWeightsFromLeadTrack)
            ) {
              newT = {
                ...newT,
                controls: syncScaleDegreeWeightsAndAllowedIntervalsWithScale({
                  ...newT.controls,
                  ...(newT.controls.followTuningFromLeadTrack
                    ? tuningFollowerPatch
                    : {}),
                  ...(newT.controls.followScaleWeightsFromLeadTrack
                    ? scaleWeightFollowerPatch
                    : {}),
                  ...(newT.controls.followAllowedIntervalsFromLeadTrack
                    ? allowedIntervalFollowerPatch
                    : {}),
                  ...(newT.controls.followBeatDivisionWeightsFromLeadTrack
                    ? beatDivisionFollowerPatch
                    : {}),
                }),
              };
            }
            if (updatedControls.pluginGUIOpen && newT.controls.pluginGUIOpen) {
              newT = {
                ...newT,
                controls: { ...newT.controls, pluginGUIOpen: false },
              };
            }
            if (newT !== t) {
              controller.setTrack(newT.id, newT.controls);
            }
            return newT;
          }
        });
      });
    },
    []
  );

  let addTrack = useCallback(
    (first: boolean, instrBanks: InstrumentBanks) => {
      let id = controller.nextTrackId();
      let controls = {
        ...makeDefaultControls(instrBanks),
        started: first,
      };
      setTrackMidiOutputs((outputs) => {
        let newOutputs = {
          ...outputs,
          [id]: { channel: 1, pitchBendRangeCents: 200 },
        };
        controller.setMidiOutputs(newOutputs);
        return newOutputs;
      });
      controller.setTrack(id, controls);
      setTracks((tracks) => [...tracks, { id, controls }]);
      if (!first) {
        setTrackControls(id, (c) => ({
          ...c,
          followTuningFromLeadTrack: true,
          followAllowedIntervalsFromLeadTrack: true,
          followScaleWeightsFromLeadTrack: true,
          followBeatDivisionWeightsFromLeadTrack: true,
        }));
      }
      subscribeTrackControlsChange(id);
      visCon.onTrackAdded(id, controller.getTrackEvents(id)!);
    },
    [setTrackControls, subscribeTrackControlsChange]
  );

  useEffect(() => {
    controller.init().then(({ banks, isSupported }) => {
      setInstrumentBanks(banks);
      setAudioSupported(isSupported);
      isSupported && addTrack(true, banks);
      setLoading(false);
    });
  }, [addTrack]);

  let duplicateTrack = useCallback(
    (trackId: string) => {
      let newId = controller.nextTrackId();
      setTrackMidiOutputs((outputs) => {
        let newOutputs = {
          ...outputs,
          [newId]: outputs[trackId],
        };
        controller.setMidiOutputs(newOutputs);
        return newOutputs;
      });
      setTracks((tracks) => {
        let duplicateIndex = findIndex(tracks, (t) => t.id === trackId);
        let newTrack = {
          id: newId,
          controls: {
            ...tracks[duplicateIndex].controls,
            started: false,
            followTuningFromLeadTrack:
              trackId === "1" ||
              tracks[duplicateIndex].controls.followTuningFromLeadTrack,
            followScaleWeightsFromLeadTrack:
              trackId === "1" ||
              tracks[duplicateIndex].controls.followScaleWeightsFromLeadTrack,
            followAllowedIntervalsFromLeadTrack:
              trackId === "1" ||
              tracks[duplicateIndex].controls
                .followAllowedIntervalsFromLeadTrack,
            followBeatDivisionWeightsFromLeadTrack:
              trackId === "1" ||
              tracks[duplicateIndex].controls
                .followBeatDivisionWeightsFromLeadTrack,
            pluginGUIOpen: false,
            looper: undefined,
            obState: {
              ...tracks[duplicateIndex].controls.obState,
              patchState: tracks[
                duplicateIndex
              ].controls.obState.patchState?.slice(),
            },
            dxState: {
              ...tracks[duplicateIndex].controls.dxState,
              patchState: tracks[
                duplicateIndex
              ].controls.dxState.patchState?.slice(),
            },
          } as TrackControls,
        };
        controller.setTrack(newTrack.id, newTrack.controls);
        subscribeTrackControlsChange(newTrack.id);
        visCon.onTrackAdded(
          newTrack.id,
          controller.getTrackEvents(newTrack.id)!
        );
        return [
          ...tracks.slice(0, duplicateIndex + 1),
          newTrack,
          ...tracks.slice(duplicateIndex + 1),
        ];
      });
    },
    [subscribeTrackControlsChange]
  );

  let removeTrack = useCallback((trackId: string) => {
    controller.removeTrack(trackId);
    visCon.onTrackRemoved(trackId);
    setTracks((tracks) => tracks.filter((track) => track.id !== trackId));
  }, []);

  let setTrackSolo = useCallback((trackId: string, soloOn: boolean) => {
    setTracks((tracks) =>
      tracks.map((track) => {
        let soloStatus = soloOn
          ? trackId === track.id
            ? "this"
            : "other"
          : ("none" as "this" | "other" | "none");
        let newControls = { ...track.controls, soloStatus };
        controller.setTrack(track.id, newControls);
        return { ...track, controls: newControls };
      })
    );
  }, []);

  let onUpdateMidiOutput = useCallback(
    (trackId: string, output: MIDIOutput) => {
      setTrackMidiOutputs((out) => {
        let newOutputs = { ...out, [trackId]: output };
        controller.setMidiOutputs(newOutputs);
        return newOutputs;
      });
    },
    []
  );

  let onUpdateTransportControls = useCallback(
    (
      updater: (newTransportControls: TransportControls) => TransportControls
    ) => {
      setTransportControls((controls) => {
        let newTransportControls = updater(controls);
        controller.setTransportControls(newTransportControls);
        return newTransportControls;
      });
    },
    []
  );

  let onUpdateReturnTrackSettings = useCallback(
    (updater: (newSettings: ReturnTrackSettings) => ReturnTrackSettings) => {
      setReturnTrackSettings((settings) => {
        let newSettings = updater(settings);
        controller.updateReturnTrackSettings(newSettings);
        return newSettings;
      });
    },
    []
  );

  let onClosePluginGUIs = useCallback(() => {
    setTracks((tracks) =>
      tracks.map((track) => {
        if (track.controls.pluginGUIOpen) {
          let closed = { ...track.controls, pluginGUIOpen: false };
          controller.setTrack(track.id, closed);
          return { ...track, controls: closed };
        } else {
          return track;
        }
      })
    );
  }, []);

  let pickTuningSystem = (tuningSystem: TuningSystem) => {
    if (!tuningSystemDialogOpenForTrack) return;
    setTrackControls(tuningSystemDialogOpenForTrack, (c) => ({
      ...c,
      tuningSystem,
      scale: undefined,
    }));
  };

  let onApplySnapshot = useCallback(
    async (
      newTracks: { id: string; controls: TrackControls }[],
      newReturnTrackSettings: ReturnTrackSettings,
      newTransportControls: TransportControls,
      newMidiOutputs: {
        trackId: string;
        outputId?: string;
        channel: number | "all" | "mpe";
        pitchBendRangeCents: number;
      }[],
      effectiveAtTicks: number,
      startPlayback: boolean
    ) => {
      let midiOutputs: { [trackId: string]: MIDIOutput } = {};
      for (let out of newMidiOutputs) {
        let output = WebMidi.outputs.find((o) => o.id === out.outputId);
        if (output) {
          midiOutputs[out.trackId] = {
            output,
            channel: out.channel,
            pitchBendRangeCents: out.pitchBendRangeCents,
          };
        } else {
          midiOutputs[out.trackId] = {
            channel: out.channel,
            pitchBendRangeCents: out.pitchBendRangeCents,
          };
        }
      }

      visCon.onAllTracksRemoved();

      await controller.scheduleBatchUpdate(
        newTracks,
        midiOutputs,
        newReturnTrackSettings,
        newTransportControls,
        effectiveAtTicks,
        startPlayback
      );
      unstable_batchedUpdates(() => {
        setTracks(newTracks);
        setTrackMidiOutputs(midiOutputs);
        setReturnTrackSettings(newReturnTrackSettings);
        setTransportControls(newTransportControls);
      });
      for (let newTrack of newTracks) {
        subscribeTrackControlsChange(newTrack.id);
        visCon.onTrackAdded(
          newTrack.id,
          controller.getTrackEvents(newTrack.id)!
        );
      }
    },
    [subscribeTrackControlsChange]
  );

  let onStopPlayback = useCallback((effectiveAtTicks: number) => {
    controller.stop(effectiveAtTicks);
  }, []);

  let onToggleTuningSnapshots = useCallback((trackId: string) => {
    setTuningSnapshotsOpenForTrack((t) => (t === trackId ? null : trackId));
  }, []);

  let reachedMaxPolyphony = getPolyphony(tracks) >= MAX_POLYPHONY;

  return (
    <div
      className={classNames("apotome", {
        sessionManagerOpen,
        tuningSnapshotsManagerOpen: !!tuningSnapshotsOpenForTrack,
      })}
    >
      <MIDIContext.Provider
        value={{
          input: { channel: -1 },
          outputs: trackMidiOutputs,
          onSetInput: () => {},
          onSetOutput: onUpdateMidiOutput,
          onCCIn: noop,
          onPitchBendIn: noop,
        }}
      >
        <Helmet>
          <title>Apotome</title>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="https://isartum.net/apotome-apple-touch-icon.png?v=QEGmboy6kP"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="https://isartum.net/apotome-favicon-32x32.png?v=QEGmboy6kP"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="https://isartum.net/apotome-favicon-16x16.png?v=QEGmboy6kP"
          />
          <link
            rel="manifest"
            href="https://isartum.net/apotome-site.webmanifest"
          />
          <link
            rel="mask-icon"
            href="https://isartum.net/apotome-safari-pinned-tab.svg"
            color="#8870ff"
          />
          <meta name="msapplication-TileColor" content="#8870ff" />
          <meta name="theme-color" content="#8870ff" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@KhyamAllami" />
          <meta name="twitter:creator" content="@KhyamAllami" />
          <meta name="twitter:title" content="Apotome" />
          <meta
            name="twitter:description"
            content="A browser-based generative music environment based on octave-repeating microtonal tuning systems and their subsets (scales/modes)."
          />
          <meta
            name="twitter:image"
            content="https://isartum.net/apotome-card-twitter.png"
          />

          <meta property="og:url" content="https://isartum.net/apotome" />
          <meta property="og:title" content="Apotome" />
          <meta
            property="og:description"
            content="A browser-based generative music environment based on octave-repeating microtonal tuning systems and their subsets (scales/modes)."
          />
          <meta
            property="og:image"
            content="https://isartum.net/apotome-card-facebook.png"
          />
          <meta
            name="description"
            content="A browser-based generative music environment based on octave-repeating microtonal tuning systems and their subsets (scales/modes)."
          />
        </Helmet>
        <header className="header">
          <GlobalControls
            currentSpace={currentSpace}
            transportControls={transportControls}
            startAllowed={startAllowed}
            onUpdateTransportControls={onUpdateTransportControls}
          />
        </header>
        <main className="main">
          <div className="tracks">
            {instrumentBanks &&
              tracks.map((track) => (
                <Track
                  key={track.id}
                  id={track.id}
                  controls={track.controls}
                  instrumentBanks={instrumentBanks!}
                  events={controller.getTrackEvents(track.id)!}
                  onSetControls={setTrackControls}
                  onRemove={removeTrack}
                  onDuplicate={reachedMaxPolyphony ? undefined : duplicateTrack}
                  onSolo={setTrackSolo}
                  onPickTuningSystem={setTuningSystemDialogOpenForTrack}
                  onToggleTuningSnapshots={onToggleTuningSnapshots}
                />
              ))}
            <div className="apotome--addTrack">
              <button
                className="button button--addTrack"
                onClick={() => addTrack(false, instrumentBanks!)}
                disabled={reachedMaxPolyphony}
                title={
                  reachedMaxPolyphony
                    ? "Maximum amount of polyphony reached. Before adding tracks, remove tracks or reduce the number of voices in instruments to make room"
                    : "Add Track"
                }
              >
                <svg
                  className="icon iconAdd"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M27,38V27h11v-6H27V10h-6v11H10v6h11v11H27z" />
                  <path fill="none" d="M0,0h48v48H0V0z" />
                </svg>
              </button>
            </div>
          </div>
        </main>
        <aside className="aside">
          <ReturnTrack
            settings={returnTrackSettings}
            onUpdateSettings={onUpdateReturnTrackSettings}
          />
          <SessionManager
            isOpen={sessionManagerOpen}
            currentTracks={tracks}
            currentReturnTrackSettings={returnTrackSettings}
            currentTransportControls={transportControls}
            currentMidiOutputs={trackMidiOutputs}
            startAllowed={startAllowed}
            onApplySnapshot={onApplySnapshot}
            onStopPlayback={onStopPlayback}
            onToggle={setSessionManagerOpen}
          />
          <TuningSnapshotsManager
            isOpen={!!tuningSnapshotsOpenForTrack}
            currentContent={
              tracks.find((t) => t.id === tuningSnapshotsOpenForTrack)?.controls
            }
            onApplySnapshot={(snapshot) =>
              tuningSnapshotsOpenForTrack &&
              setTrackControls(tuningSnapshotsOpenForTrack, (c) => ({
                ...c,
                ...snapshot,
              }))
            }
            onClose={() => setTuningSnapshotsOpenForTrack(null)}
          />
          <div
            className={classNames("sidebarCallouts", {
              isVisible: !sessionManagerOpen || !tuningSnapshotsOpenForTrack,
            })}
          >
            <div className="sidebarCallouts--buttons">
              {!sessionManagerOpen && (
                <button
                  className="button primary"
                  onClick={() => setSessionManagerOpen(true)}
                >
                  Sessions
                </button>
              )}
              {!tuningSnapshotsOpenForTrack && (
                <button
                  className="button primary"
                  onClick={() => setTuningSnapshotsOpenForTrack(tracks[0].id)}
                >
                  Favourite Subsets
                </button>
              )}
            </div>
          </div>
        </aside>
      </MIDIContext.Provider>
      <Rnd ref={pluginGUIRndRef} dragHandleClassName="pluginGUIBar">
        <div className="pluginGUIBar">
          <button
            className="button closePluginGUIBar"
            onClick={onClosePluginGUIs}
          >
            x
          </button>
        </div>
        <div className="pluginGUIContent"></div>
      </Rnd>
      <ChooseTuningSystemDialog
        isOpen={!!tuningSystemDialogOpenForTrack}
        previousSelection={
          (tuningSystemDialogOpenForTrack &&
            tracks.find((track) => track.id === tuningSystemDialogOpenForTrack)
              ?.controls.tuningSystem) ||
          undefined
        }
        onClose={() => setTuningSystemDialogOpenForTrack(null)}
        onTuningSystemChosen={pickTuningSystem}
      />
      <LoadingSplash isOpen={loading} />
      {(!audioSupported || isMobile) && <NotSupported />}
    </div>
  );
};

function getPolyphony(tracks: { id: string; controls: TrackControls }[]) {
  let polyphony = 0;
  for (let track of tracks) {
    if (track.controls.instrument === "obxd") {
      polyphony += Math.max(
        1,
        OBXD.getNumberOfVoices(track.controls.obState.patchState) / 1.5
      );
    } else if (track.controls.instrument === "string") {
      polyphony += 1.5;
    } else {
      polyphony += 1;
    }
  }
  return polyphony;
}
