import { EventEmitter } from "events";
import { isNull, isNumber } from "lodash";
import { Ticks, getTransport } from "tone";

import { getTimeSignatureTicks, setMasterVolume } from '../apotomeController';
import { MIN_VOLUME_DB } from "../constants";
import { Session, SessionSection } from "../types";


type StoppedPlaybackState = {
    type: 'stopped';
    atTicks: number;
    atSeconds: number;
}
type SectionPlaybackState = {
    type: 'section';
    section: SessionSection;
    firstAtSeconds: number;
    atTicks: number;
    atSeconds: number;
    plannedDuration?: number;
    totalPlannedDuration: number;
}
export type PlaybackState = StoppedPlaybackState | SectionPlaybackState;

const FADE_OUT_RAMP_TIME = 10;

export class SessionPlayer extends EventEmitter {

    private playbackState: PlaybackState = { type: 'stopped', atTicks: 0, atSeconds: 0 };
    private nextTransitionTimeout: NodeJS.Timeout | null = null;
    private nextTransitionTransportEvent: number | null = null;
    private fadeOutTimeout: NodeJS.Timeout | null = null;

    constructor(private session: Session, private givenTotalDuration?: number, private givenSectionDurations?: number[]) {
        super();
    }

    start() {
        if (this.session.sections.length === 0) return;
        this.cancelFadeOut();
        this.launchSection(0);
        setMasterVolume(1, 0.03);
    }

    stop(atTicks = this.nextBeatTicks()) {
        this.cancelScheduledTransitions();
        this.playbackState = { type: 'stopped', atTicks, atSeconds: Ticks(atTicks).toSeconds() };
        this.emit('updatePlaybackState', this.playbackState);
        this.cancelFadeOut();
    }

    jumpToSection(index: number) {
        this.launchSection(index);
    }

    private launchSection(index: number, atTicks = this.nextBeatTicks()) {
        this.cancelScheduledTransitions();
        let section = this.session.sections[index];
        if (section.timing === 'seconds') {
            let durationSeconds = this.givenSectionDurations?.[index];
            if (!isNumber(durationSeconds)) {
                let min = section.minDuration ?? section.maxDuration ?? 60;
                let max = section.maxDuration ?? section.minDuration ?? 120;
                durationSeconds = min + Math.random() * (max - min);
            }
            this.playbackState = {
                type: 'section',
                section,
                atTicks,
                atSeconds: Ticks(atTicks).toSeconds(),
                firstAtSeconds: this.playbackState?.type === 'section' ? this.playbackState.firstAtSeconds : Ticks(atTicks).toSeconds(),
                plannedDuration: durationSeconds,
                totalPlannedDuration: this.playbackState?.type === 'section' ? this.playbackState.totalPlannedDuration : this.chooseTotalPlannedDuration()
            };
            let durationUntilStop = this.getDurationUntilStop();
            if (durationUntilStop < durationSeconds) {
                this.nextTransitionTimeout = setTimeout(() => {
                    this.stop();
                }, durationUntilStop * 1000);
            } else {
                this.nextTransitionTimeout = setTimeout(() => {
                    this.proceedSections(index);
                }, durationSeconds * 1000);
            }
            this.scheduleFadeOut(durationUntilStop);
        } else if (section.timing === 'bars') {
            let durationSeconds: number, nextTransitionTicks: number;
            if (this.givenSectionDurations) {
                durationSeconds = this.givenSectionDurations[index];
                let durationTicks = durationSeconds * (section.snapshot.transportControls.tempo / 60) * getTransport().PPQ;
                nextTransitionTicks = atTicks + durationTicks;
            } else {
                let min = section.minDuration ?? section.maxDuration ?? 4;
                let max = section.maxDuration ?? section.minDuration ?? 8;
                let durationBars = Math.round(min + Math.random() * (max - min));
                let { timeSignatureNumerator, timeSignatureDenominator } = section.snapshot.transportControls;
                let { totalTicks: timeSignatureTicks } = getTimeSignatureTicks(timeSignatureNumerator, timeSignatureDenominator, getTransport().PPQ);
                let durationTicks = timeSignatureTicks * durationBars;
                durationSeconds = ticksToSeconds(durationTicks, section);
                nextTransitionTicks = atTicks + durationTicks;
            }
            this.playbackState = {
                type: 'section',
                section, atTicks,
                atSeconds: Ticks(atTicks).toSeconds(),
                firstAtSeconds: this.playbackState?.type === 'section' ? this.playbackState.firstAtSeconds : Ticks(atTicks).toSeconds(),
                plannedDuration: durationSeconds,
                totalPlannedDuration: this.playbackState?.type === 'section' ? this.playbackState.totalPlannedDuration : this.chooseTotalPlannedDuration()
            };
            let durationUntilStop = this.getDurationUntilStop();
            let durationUntilTransition = durationSeconds;
            if (durationUntilStop < durationUntilTransition) {
                this.nextTransitionTimeout = setTimeout(() => {
                    this.stop();
                }, durationUntilStop * 1000);
            } else {
                this.nextTransitionTransportEvent = getTransport().scheduleOnce(() => {
                    this.proceedSections(index, nextTransitionTicks);
                }, `${nextTransitionTicks - getTransport().toTicks('16n')}i`);
            }
            this.scheduleFadeOut(durationUntilStop);
        } else {
            this.playbackState = {
                type: 'section',
                section,
                atTicks,
                atSeconds: Ticks(atTicks).toSeconds(),
                firstAtSeconds: this.playbackState?.type === 'section' ? this.playbackState.firstAtSeconds : Ticks(atTicks).toSeconds(),
                totalPlannedDuration: this.playbackState?.type === 'section' ? this.playbackState.totalPlannedDuration : this.chooseTotalPlannedDuration()
            };
        }
        console.log(
            "Session playbackState",
            this.playbackState.atSeconds,
            "dur",
            this.playbackState.plannedDuration,
            "total",
            this.playbackState.totalPlannedDuration
        );
        this.emit('updatePlaybackState', this.playbackState);
    }

    private proceedSections(fromIndex: number, atTicks = this.nextBeatTicks()) {
        if (fromIndex < this.session.sections.length - 1) {
            this.launchSection(fromIndex + 1, atTicks);
        } else {
            if (this.session.loop) {
                this.launchSection(0, atTicks);
            } else {
                this.stop(atTicks);
            }
        }
    }

    private nextBeatTicks() {
        let time = getTransport().nextSubdivision(`4n`);
        return getTransport().getTicksAtTime(time);
    }

    private chooseTotalPlannedDuration() {
        if (isNumber(this.givenTotalDuration)) {
            return this.givenTotalDuration;
        }
        if (!this.session.loop) {
            return -1;
        }
        let min = this.session.loopedMinDuration ?? this.session.loopedMaxDuration ?? 5 * 60;
        let max = this.session.loopedMaxDuration ?? this.session.loopedMinDuration ?? 10 * 60;
        return min + Math.random() * (max - min);
    }

    private getDurationUntilStop() {
        if (this.playbackState.type === 'stopped' || this.playbackState.totalPlannedDuration < 0) return Number.MAX_VALUE;
        let elapseduration = this.playbackState.atSeconds - this.playbackState.firstAtSeconds;
        return this.playbackState.totalPlannedDuration - elapseduration;
    }

    private scheduleFadeOut(durationUntilStop: number) {
        if (isNull(this.fadeOutTimeout) && this.session.loopedFadeout) {
            let del = (durationUntilStop - FADE_OUT_RAMP_TIME) * 1000;
            this.fadeOutTimeout = setTimeout(() => {
                setMasterVolume(MIN_VOLUME_DB, FADE_OUT_RAMP_TIME);
            }, del);
        }
    }

    private cancelFadeOut() {
        if (!isNull(this.fadeOutTimeout)) {
            clearTimeout(this.fadeOutTimeout);
            this.fadeOutTimeout = null;
        }
    }
    private cancelScheduledTransitions() {
        !isNull(this.nextTransitionTimeout) && clearTimeout(this.nextTransitionTimeout)
        !isNull(this.nextTransitionTransportEvent) && getTransport().clear(this.nextTransitionTransportEvent);
        this.nextTransitionTimeout = null;
        this.nextTransitionTransportEvent = null;
    }

}

function ticksToSeconds(ticks: number, section: SessionSection) {
    let tempo = section.snapshot.transportControls.tempo;
    return ticks / getTransport().PPQ * (60 / tempo);
}