import React from "react";
import classNames from "classnames";

import { Looper as LooperType } from "./types";

import "./Looper.scss";

interface LooperProps {
  loop?: LooperType;
  onSetLoop: (loop?: LooperType) => void;
}
export const Looper: React.FC<LooperProps> = React.memo(
  ({ loop, onSetLoop }) => {
    return (
      <div className="looper">
        <div className="tabs">
          {[4, 3, 2, 1].map((length) => (
            <button
              key={length}
              className={classNames("button button--tab", {
                isActive: loop === length,
              })}
              onClick={() =>
                loop === length
                  ? onSetLoop()
                  : onSetLoop(length as 1 | 2 | 3 | 4)
              }
            >
              <span>{length} bars</span>
            </button>
          ))}
        </div>
      </div>
    );
  }
);
