import React, { useCallback, useContext, useState, useEffect } from "react";
import { RouteComponentProps, generatePath } from "react-router";
import { useParams, useHistory } from "react-router-dom";
import classNames from "classnames";
import { Frequency } from "tone";

import { debounce } from "lodash";

import {
  Pitch,
  getPitchCents,
  TuningSystem,
  midiToFreq,
  TString,
  reduceIntoOctave,
  getPitchSum,
} from "./main/core";
import {
  parseRefPitch,
  parseStrings,
  formatStrings,
  formatRefPitch,
} from "./urlSerialization";

import { MIDIOutput } from "./main/MIDIOutput";
import { TuningSystemDatabaseEntryDialog } from "./TuningSystemDatabaseEntryDialog";
import { ScalaImport } from "./formats/ScalaImport";
import * as audio from "./main/audio";
import { MIDIContext } from "./MIDIContext";
import { OctaveDivisionOctaveView } from "./OctaveDivisionOctaveView";
import { OctaveDivisionStringsView } from "./OctaveDivisionStringsView";
import { ChooseTuningSystemDialog } from "./ChooseTuningSystemDialog";
import * as api from "./main/api";

import "./OctaveDivisionScreen.scss";
import { MIN_NOTE, MAX_NOTE } from "./constants";
import { useWindowHeight } from "@react-hook/window-size";
import { SVGCanvas } from "./SVGCanvas";
import { FrequencyBand, FREQUENCY_BAND_HEIGHT } from "./FrequencyBand";
import { RefPitchInput } from "./RefPitchInput";
import { Breadcrumbs } from "./Breadcrumbs";
import { UserInfo } from "./UserInfo";
import { useAdminAccess } from "./useAdminAccess";
import { useAuth0 } from "@auth0/auth0-react";
import { Autolink } from "./Autolink";
import { LeimmaHelmet } from "./LeimmaHelmet";
import { UserGuideLink } from "./UserGuideLink";
import { ScalaExport } from "./formats/ScalaExport";

interface OctaveDivisionScreenParams {
  tuningSystemId: string;
  refPitch: string;
  strings?: string;
}
export const OctaveDivisionScreen: React.FC<
  RouteComponentProps<OctaveDivisionScreenParams>
> = ({ match, location }) => {
  let { isAuthenticated } = useAuth0();
  let { hasAdminAccess } = useAdminAccess();
  let {
    tuningSystemId,
    refPitch: refPitchString,
    strings: stringString,
  } = useParams<OctaveDivisionScreenParams>();
  let history = useHistory();
  let refPitch = parseRefPitch(refPitchString);
  let strings = parseStrings(stringString);
  let {
    outputs: { leimma: output },
  } = useContext(MIDIContext);
  let [soundingPitch, setSoundingPitch] = useState<{
    openString: Pitch;
    div: Pitch;
  }>();
  let [tuningSystemDialogOpen, setTuningSystemDialogOpen] = useState(false);
  let [databaseEntryDialogOpen, setDatabaseEntryDialogOpen] = useState(false);
  let [tuningSystem, setTuningSystem] = useState<TuningSystem | null>();
  let [view] = useState<"octave" | "strings">("octave");
  let windowHeight = useWindowHeight();
  let [hasEntered, setHasEntered] = useState(false);
  let [isExiting, setIsExiting] = useState(false);
  let [tuningSystemDialogReloads, setTuningSystemDialogReloads] = useState(0);

  useEffect(() => {
    if (location.state?.openTuningSystemDialog) {
      let to = setTimeout(() => setTuningSystemDialogOpen(true), 200);
      return () => clearTimeout(to);
    }
  }, [location.state]);

  useEffect(() => {
    if (tuningSystemId !== "new") {
      api.loadTuningSystem(+tuningSystemId).then(setTuningSystem);
    } else {
      setTuningSystem(null);
    }
  }, [tuningSystemId]);

  let setRefPitch = (semitones: number, note?: string) => {
    let path = generatePath(match!.path, {
      tuningSystemId,
      refPitch: formatRefPitch(semitones, note),
      strings: stringString,
    });
    if (soundingPitch) {
      let baseFreq = midiToFreq(refPitch.semitones);
      let sPitch = reduceIntoOctave(
        getPitchSum(soundingPitch.openString, soundingPitch.div)
      );
      let newFreq = baseFreq * 2 ** (getPitchCents(sPitch) / 1200);
      audio.setFrequency(-1, newFreq, null, output);
    }
    history.replace(path);
  };

  let setStrings = (strings: TString[]) => {
    let path = generatePath(match!.path, {
      tuningSystemId,
      refPitch: refPitchString,
      strings: formatStrings(strings),
    });
    history.replace(path);
  };

  let setStringsFromPitches = (pitches: Pitch[]) => {
    setStrings(
      pitches.map((p) => ({
        ratioUpper: 1,
        ratioLower: 1,
        pitchClasses: [{ ...p, name8ve1: "", name8ve2: "" }],
      }))
    );
  };

  let stopSound = useCallback(
    debounce(() => {
      audio.noteOff(-1, output);
      setSoundingPitch(undefined);
    }, 800),
    [output]
  );

  let soundDivision = useCallback(
    (
      openString: Pitch,
      div: Pitch,
      doRetrigger = false,
      doReduceIntoOctave = true
    ) => {
      let baseFreq = midiToFreq(refPitch.semitones);
      let soundingPitch = getPitchSum(openString, div);
      if (doReduceIntoOctave && getPitchCents(soundingPitch) > 1200) {
        soundingPitch = reduceIntoOctave(soundingPitch);
      }
      let freq = baseFreq * 2 ** (getPitchCents(soundingPitch) / 1200);
      audio.noteOn(-1, freq, 1, null, output, doRetrigger);
      audio.setFrequency(-1, freq, 1, output);
      setSoundingPitch({ openString, div });
      stopSound();
    },
    [refPitch, stopSound, output]
  );

  let onPickTuningSystem = async (ts: TuningSystem) => {
    history.push(
      `/leimma/${ts.id}/refpitch/${formatRefPitch(
        ts.refPitchNoteMidi,
        ts.refPitchNoteName
      )}/tuningsystem/${formatStrings(ts.strings)}`
    );
  };

  let onSavedTuningSystem = (ts: TuningSystem) => {
    history.replace(
      `/leimma/${ts.id}/refpitch/${refPitchString}/tuningsystem/${stringString}`
    );
    setTuningSystemDialogReloads((r) => r + 1);
  };

  let onDeletedTuningSystem = () => {
    history.replace(
      `/leimma/new/refpitch/${refPitchString}/tuningsystem/${stringString}`
    );
    setTuningSystemDialogReloads((r) => r + 1);
  };

  let onNextAction = useCallback(async () => {
    setIsExiting(true);
    return new Promise<void>((res) => setTimeout(res, 500));
  }, []);

  return (
    <>
      <LeimmaHelmet tuningSystem={tuningSystem} />
      <div
        className={classNames("octaveDivisionScreen", "screen", {
          hasEntered,
          isExiting,
        })}
      >
        <div className="octaveDivisionScreen--htmlOverlay">
          <div className="octaveDivisionScreen--tuningSystemInfo">
            {tuningSystem ? (
              <>
                <h1>
                  <span className="fieldLabel">Tuning system:</span>{" "}
                  {tuningSystem.name}
                </h1>
                <p className="introText description">
                  <Autolink>{tuningSystem.description}</Autolink>
                </p>
                {tuningSystem.source && (
                  <p className="introText source">
                    Source: <Autolink>{tuningSystem.source}</Autolink>
                  </p>
                )}
              </>
            ) : (
              <h1>Tuning system</h1>
            )}
          </div>
          <p className="tuningSystemDialogToggle">
            <button
              className="button small"
              onClick={() => setTuningSystemDialogOpen(true)}
            >
              Switch tuning system
            </button>
          </p>
          {isAuthenticated && (
            <p className="databaseEntryDialogToggle">
              <button
                className="button small"
                onClick={() => setDatabaseEntryDialogOpen(true)}
              >
                {hasAdminAccess ? (
                  <>Admin Database Entry</>
                ) : (
                  <>Save to My Tunings</>
                )}
              </button>
            </p>
          )}
          {/*<p className="viewSelector">
            {view === "octave" ? (
              <button
                className="button small"
                onClick={() => setView("strings")}
              >
                Strings view
              </button>
            ) : (
              <button
                className="button small"
                onClick={() => setView("octave")}
              >
                Octave view
              </button>
            )}
            </p>*/}
          <RefPitchInput
            className="small"
            style={{
              position: "fixed",
              top: windowHeight / 2 - FREQUENCY_BAND_HEIGHT - 25,
              left: 10,
            }}
            semitones={refPitch.semitones}
            note={refPitch.note}
            onUpdateSemitones={(st) => setRefPitch(st, refPitch.note)}
            onUpdateNote={(note) => setRefPitch(Frequency(note).toMidi(), note)}
          />
          <MIDIOutput
            showInternalOptions={true}
            productName="Leimma"
            fixed
            withLabel
          />
          <ScalaImport onImport={setStringsFromPitches} />
          <ScalaExport
            tuningSystemRefPitchSemitones={refPitch.semitones}
            tuningSystemRefPitchNote={refPitch.note}
            tuningSystemStrings={strings}
            tuningSystemMeta={tuningSystem ?? undefined}
          />
          {isAuthenticated && (
            <TuningSystemDatabaseEntryDialog
              id={tuningSystemId}
              strings={strings}
              refPitch={refPitch}
              onSaved={onSavedTuningSystem}
              onDeleted={onDeletedTuningSystem}
              isAdmin={hasAdminAccess}
              isOpen={databaseEntryDialogOpen}
              onClose={() => setDatabaseEntryDialogOpen(false)}
            />
          )}
          <Breadcrumbs
            currentStep={2}
            currentDescription={`Octave-repeating tuning systems are based on the division of the
            octave into different pitches. Click the bar or type cents/ratio
            value to divide. Select a division to edit/delete. Hover to hear.`}
            nextActionUrl={`/leimma/${tuningSystemId}/refpitch/${refPitchString}/tuningsystem/${stringString}/scale/new/english`}
            nextActionEnabled={
              strings.reduce((su, s) => su + s.pitchClasses.length, 0) >= 2
            }
            onNextAction={onNextAction}
          />
        </div>
        <SVGCanvas className="svgCanvas octaveDivisionScreen--canvas">
          <FrequencyBand
            value={refPitch.semitones}
            note={refPitch.note}
            min={MIN_NOTE}
            max={MAX_NOTE}
            entry="octaveDivision"
            onChange={setRefPitch}
            onStartInteraction={() => {}}
            onEndInteraction={() => {}}
            onEntered={() => setHasEntered(true)}
          />
        </SVGCanvas>
        {view === "octave" ? (
          <OctaveDivisionOctaveView
            strings={strings}
            onUpdateStrings={setStrings}
            onSoundDivision={soundDivision}
          />
        ) : (
          <OctaveDivisionStringsView
            strings={strings}
            onUpdateStrings={setStrings}
            refPitchSemitones={refPitch.semitones}
          />
        )}
        <UserGuideLink />
        <UserInfo />
      </div>
      <ChooseTuningSystemDialog
        isOpen={tuningSystemDialogOpen}
        reloads={tuningSystemDialogReloads}
        onClose={() => setTuningSystemDialogOpen(false)}
        onTuningSystemChosen={onPickTuningSystem}
      />
    </>
  );
};
