import React, { useCallback } from "react";
import { useWindowWidth } from "@react-hook/window-size";

import { Pitch, TString, getExactOrEstimatedPitchRatio } from "./main/core";
import { OctaveDivisionStringStop } from "./OctaveDivisionStringStop";

interface OctaveDivisionStringProps {
  string: TString;
  index: number;
  rulers: { pitch: Pitch; x: number }[];
  onSoundDivision: (pc: Pitch) => void;
  onUpdateDivision: (newDiv: Pitch, atIdx: number) => void;
}
export const OctaveDivisionString: React.FC<OctaveDivisionStringProps> = ({
  string,
  index,
  rulers,
  onSoundDivision,
  onUpdateDivision,
}) => {
  let windowWidth = useWindowWidth();

  let getStringStopX = useCallback(
    (pitch: Pitch) => {
      let { numerator, denominator } = getExactOrEstimatedPitchRatio(pitch);
      let stringWidth = getStringRightX(windowWidth) - getStringLeftX();
      let x = stringWidth - (stringWidth * denominator) / numerator;
      return getStringLeftX() + x;
    },
    [windowWidth]
  );

  return (
    <>
      <line
        x1={getStringLeftX()}
        x2={getStringRightX(windowWidth)}
        y1={getStringY(index)}
        y2={getStringY(index)}
        className="octaveDivisionStringsView--string"
      />
      <circle
        cx={getStringLeftX()}
        cy={getStringY(index)}
        r={5}
        className="octaveDivisionStringsView--stringEnd"
      />
      <circle
        cx={getStringRightX(windowWidth)}
        cy={getStringY(index)}
        r={5}
        className="octaveDivisionStringsView--stringEnd"
      />
      {string.pitchClasses.map((pc, idx) => (
        <OctaveDivisionStringStop
          key={idx}
          pc={pc}
          x={getStringStopX(pc)}
          y={getStringY(index)}
          stringWidth={windowWidth - 310}
          windowWidth={windowWidth}
          rulers={rulers}
          onUpdate={(newPc) => onUpdateDivision(newPc, idx)}
          onSound={() => onSoundDivision(pc)}
        />
      ))}
    </>
  );
};

export let getStringY = (index: number) => {
  return 50 + index * 55;
};

export let getStringPitchX = (pitch: Pitch, windowWidth: number) => {
  let { numerator, denominator } = getExactOrEstimatedPitchRatio(pitch);
  let stringWidth = getStringRightX(windowWidth) - getStringLeftX();
  let x = stringWidth - (stringWidth * denominator) / numerator;
  return getStringLeftX() + x;
};

let getStringLeftX = () => {
  return 5;
};

let getStringRightX = (windowWidth: number) => {
  return windowWidth - 305;
};
