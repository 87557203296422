import React from "react";
import { range, isNumber } from "lodash";
import classNames from "classnames";
import {
  SCALE_DEGREE_KEYBOARD_MAPPING,
  isAccidentalMidi,
  formatPitch,
  TString,
  ScaleDegree,
  getPitchSum,
  reduceIntoOctave,
  Pitch,
} from "./main/core";

import "./OnScreenKeyboard.scss";
import { MAX_OCTAVE, MIN_OCTAVE } from "./constants";

const QWERTY_MAPPING = [
  "a",
  "w",
  "s",
  "e",
  "d",
  "f",
  "t",
  "g",
  "y",
  "h",
  "u",
  "j",
];
interface OnScreenKeyboardProps {
  isOpen: boolean;
  strings: TString[];
  scale: ScaleDegree[];
  scaleDegreeNames: string[];
  currentlyPlaying: { stringIdx: number; pcIdx: number; keyCode: number }[];
  keyboardOctave: number;
  pitchFormat: "cents" | "ratio";
  onToggle: () => void;
  onKeyDown: (note: number) => void;
  onKeyUp: (note: number) => void;
  onKeyboardOctaveChange: (octave: number) => void;
}
export const OnScreenKeyboard: React.FC<OnScreenKeyboardProps> = ({
  isOpen,
  strings,
  scale,
  scaleDegreeNames,
  currentlyPlaying,
  keyboardOctave,
  pitchFormat,
  onToggle,
  onKeyDown,
  onKeyUp,
  onKeyboardOctaveChange,
}) => {
  return (
    <div className={classNames("onScreenKeyboardContainer", { isOpen })}>
      <button className="onScreenKeyboardCallout" onClick={onToggle}>
        {isOpen ? <>&darr;</> : <>&uarr;</>} Keyboard
      </button>
      <div className="onScreenKeyboard">
        {range(0, 12).map((note) => {
          let deg = scale.find(
            (sd) => SCALE_DEGREE_KEYBOARD_MAPPING.get(sd.map!) === note
          );
          let division =
            deg && strings[deg.stringIndex]?.pitchClasses[deg.pitchClassIndex];
          let isPlaying =
            deg &&
            currentlyPlaying.some(
              (p) =>
                p.stringIdx === deg!.stringIndex &&
                p.pcIdx === deg!.pitchClassIndex
            );
          return (
            <React.Fragment key={note}>
              <button
                className={`onScreenKeyboard--step ${
                  isPlaying ? "isPlaying" : ""
                } ${isAccidentalMidi(note) ? "isAccidental" : ""}`}
                onPointerDown={() => onKeyDown(note)}
                onPointerUp={() => onKeyUp(note)}
                disabled={!division}
              >
                <span className="onScreenKeyboard--step--qwertyMapping">
                  {QWERTY_MAPPING[note]}
                </span>

                <span className="onScreenKeyboard--step--cents">
                  {division
                    ? getLabel(strings[deg!.stringIndex], division, pitchFormat)
                    : ""}
                </span>
                <span className="onScreenKeyboard--step--solmization">
                  {deg && scaleDegreeNames[deg.map!]}
                </span>
              </button>
            </React.Fragment>
          );
        })}
      </div>
      <div className="onScreenKeyboardOctave">
        <button
          className={classNames("onScreenKeyboardOctave--change", {
            isDisabled: keyboardOctave <= MIN_OCTAVE,
          })}
          onClick={() => onKeyboardOctaveChange(keyboardOctave - 1)}
        >
          <span className="onScreenKeyboardOctave--qwertyMapping">z</span>
        </button>
        Octave: {keyboardOctave}
        <button
          className={classNames("onScreenKeyboardOctave--change", {
            isDisabled: keyboardOctave >= MAX_OCTAVE,
          })}
          onClick={() => onKeyboardOctaveChange(keyboardOctave + 1)}
        >
          <span className="onScreenKeyboardOctave--qwertyMapping">x</span>
        </button>
      </div>
    </div>
  );
};

function getLabel(string: Pitch, pitch: Pitch, format: "cents" | "ratio") {
  if (format === "cents") {
    return formatPitch(getPitchSum(string, pitch), "cents");
  } else if (
    isNumber(pitch.ratioUpper) &&
    isNumber(pitch.ratioLower) &&
    isNumber(string.ratioUpper) &&
    isNumber(string.ratioLower)
  ) {
    return formatPitch(reduceIntoOctave(getPitchSum(string, pitch)), "ratio");
  }
}
