import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useAdminAccess } from "./useAdminAccess";

import "./UserInfo.scss";
import { useUserProfile } from "./useUserProfile";

export const UserInfo: React.FC = () => {
  let {
    isLoading,
    isAuthenticated,
    user,
    logout,
    loginWithPopup,
  } = useAuth0();
  let { profile } = useUserProfile();
  let { hasAdminAccess } = useAdminAccess();

  return isLoading ? (
    <></>
  ) : (
    <div className="userInfo">
      <div className="userName">
        {isAuthenticated ? profile?.username ?? user.email : "Anonymous user"}
        {hasAdminAccess && <> (Administrator)</>}
      </div>
      {isAuthenticated && (
        <button
          className="button"
          onClick={() =>
            logout({ returnTo: `${window.location.origin}/leimma` })
          }
        >
          Sign out
        </button>
      )}
      {!isAuthenticated && (
        <button
          className="button"
          onClick={() =>
            loginWithPopup()
          }
        >
          Sign in
        </button>
      )}
    </div>
  );
};
