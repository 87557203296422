import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";

import { isEqual, omit } from "lodash";
import {
  Scale,
  Solmization,
  ScaleDegree,
  TString,
  TuningSystem,
} from "./main/core";
import * as api from "./main/api";

import "./ScaleDatabaseEntryDialog.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { TuningSystemDatabaseEntry } from "./TuningSystemDatabaseEntryDialog";

interface ScaleDatabaseEntryDialogProps {
  isOpen: boolean;
  tuningSystemId: string;
  tuningSystemRefPitch: { semitones: number; note?: string };
  tuningSystemStrings: TString[];
  id: string;
  scale: ScaleDegree[];
  solmization: Solmization;
  isAdmin: boolean;
  onTuningSystemSaved: (ts: TuningSystem) => void;
  onTuningSystemDeleted: () => void;
  onSaved: (s: Scale) => void;
  onDeleted: () => void;
  onClose: () => void;
}
export const ScaleDatabaseEntryDialog: React.FC<ScaleDatabaseEntryDialogProps> = ({
  isOpen,
  tuningSystemId,
  tuningSystemRefPitch,
  tuningSystemStrings,
  id,
  scale,
  solmization,
  isAdmin,
  onTuningSystemSaved,
  onTuningSystemDeleted,
  onSaved,
  onDeleted,
  onClose,
}) => {
  let { user, getAccessTokenSilently } = useAuth0();
  let [currentScale, setCurrentScale] = useState<Partial<Scale>>(
    applyToScale(
      {
        name: "",
        description: "",
        source: "",
      },
      scale,
      solmization
    )
  );

  let [persistentScale, setPersistentScale] = useState<Scale>();

  let isDirty = hasDifferences(currentScale, persistentScale);

  useEffect(() => {
    if (id !== "new") {
      api.loadScale(+id).then(setPersistentScale);
    } else {
      setPersistentScale(undefined);
    }
  }, [id]);

  useEffect(() => {
    if (persistentScale) {
      setCurrentScale(applyToScale(persistentScale, scale, solmization));
    } else {
      setCurrentScale((cur) =>
        applyToScale(
          {
            name: cur.name,
            description: cur.description,
            source: cur.source,
          },
          scale,
          solmization
        )
      );
    }
  }, [persistentScale, scale, solmization]);

  let onSaveAsNew = useCallback(async () => {
    let accessToken = await getAccessTokenSilently();
    api
      .saveScale(
        {
          ...(persistentScale || {}),
          ...currentScale,
          scaleDegrees: currentScale.scaleDegrees!.map((d) => omit(d, "id")),
          id: undefined,
        } as Scale,
        +tuningSystemId,
        accessToken
      )
      .then((savedS) => {
        setPersistentScale(savedS);
        onSaved(savedS);
        onClose();
      });
  }, [
    persistentScale,
    currentScale,
    tuningSystemId,
    onSaved,
    onClose,
    getAccessTokenSilently,
  ]);

  let onUpdateExisting = useCallback(async () => {
    let accessToken = await getAccessTokenSilently();
    api
      .saveScale(
        {
          ...(persistentScale || {}),
          ...currentScale,
        } as Scale,
        +tuningSystemId,
        accessToken
      )
      .then((savedS) => {
        setPersistentScale(savedS);
        onSaved(savedS);
        onClose();
      });
  }, [
    persistentScale,
    currentScale,
    tuningSystemId,
    onSaved,
    onClose,
    getAccessTokenSilently,
  ]);

  let onDelete = useCallback(async () => {
    if (
      window.confirm(
        `Are you sure you want to delete subset "${persistentScale!.name}"?`
      )
    ) {
      let accessToken = await getAccessTokenSilently();
      await api.deleteScale(persistentScale!.id!, accessToken);
      setPersistentScale(undefined);
      onDeleted();
      onClose();
    }
  }, [persistentScale, onDeleted, onClose, getAccessTokenSilently]);

  let isUserScale = useCallback(() => {
    return persistentScale?.category === `User: ${user?.email}`;
  }, [persistentScale, user]);

  return (
    <div
      className={classNames("scaleDatabaseEntryWrapper", { isOpen })}
      onKeyDown={(evt) => evt.nativeEvent.stopImmediatePropagation()}
      onKeyUp={(evt) => evt.nativeEvent.stopImmediatePropagation()}
      onClick={(evt) => evt.target === evt.currentTarget && onClose()}
    >
      <TuningSystemDatabaseEntry
        id={tuningSystemId}
        refPitch={tuningSystemRefPitch}
        strings={tuningSystemStrings}
        isAdmin={isAdmin}
        onSaved={onTuningSystemSaved}
        onDeleted={() => {
          onTuningSystemDeleted();
          onClose();
        }}
        preventCopy
      />
      <div
        className="scaleDatabaseEntry databaseEntry"
        onKeyDown={(evt) => {
          evt.nativeEvent.stopImmediatePropagation();
        }}
        onKeyUp={(evt) => {
          evt.nativeEvent.stopImmediatePropagation();
        }}
      >
        <h3>
          {" "}
          {isAdmin ? (
            <>Scale/Mode Database (Admin)</>
          ) : (
            <>Save Scale/Mode to My Tunings</>
          )}
        </h3>
        <div className="scaleDatabaseEntry--field">
          <label htmlFor="scaleDatabaseEntry--name">Name</label>
          <input
            name="scaleDatabaseEntry--name"
            value={currentScale.name}
            onChange={(evt) =>
              setCurrentScale({
                ...currentScale,
                name: evt.currentTarget.value,
              })
            }
          />
        </div>
        <div className="scaleDatabaseEntry--field">
          <label htmlFor="scaleDatabaseEntry--description">Description</label>
          <textarea
            name="scaleDatabaseEntry--name"
            value={currentScale.description}
            onChange={(evt) =>
              setCurrentScale({
                ...currentScale,
                description: evt.currentTarget.value,
              })
            }
          />
        </div>
        <div className="scaleDatabaseEntry--field">
          <label htmlFor="scaleDatabaseEntry--source">Source</label>
          <input
            name="scaleDatabaseEntry--soure"
            value={currentScale.source}
            onChange={(evt) =>
              setCurrentScale({
                ...currentScale,
                source: evt.currentTarget.value,
              })
            }
          />
        </div>
        <div className="scaleDatabaseEntry--actions">
          {(isAdmin || isUserScale()) && (
            <button
              disabled={!persistentScale || tuningSystemId === "new"}
              onClick={onDelete}
              className="button"
            >
              Delete
            </button>
          )}
          {(isAdmin || isUserScale()) && (
            <button
              disabled={
                !persistentScale || tuningSystemId === "new" || !isDirty
              }
              onClick={onUpdateExisting}
              className="button"
            >
              Update existing
            </button>
          )}
          <button
            disabled={!isDirty || tuningSystemId === "new"}
            onClick={onSaveAsNew}
            className="button"
          >
            Save as new
          </button>
        </div>
        <div className="scaleDatabaseEntry--actions">
          <button onClick={onClose} className="button">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

function applyToScale(
  s: Partial<Scale>,
  scale: ScaleDegree[],
  solmization: Solmization
): Partial<Scale> {
  return {
    ...s,
    solmization,
    scaleDegrees: mergeScaleDegrees(scale, s.scaleDegrees || []),
  };
}

function mergeScaleDegrees(
  scale: ScaleDegree[],
  existingScaleDegrees: ScaleDegree[]
): ScaleDegree[] {
  return scale.map((s) => {
    let existing = existingScaleDegrees.find(
      (sd) =>
        sd.stringIndex === s.stringIndex &&
        sd.pitchClassIndex === s.pitchClassIndex &&
        sd.map === s.map &&
        sd.role === s.role
    );
    return (
      existing || {
        stringIndex: s.stringIndex,
        pitchClassIndex: s.pitchClassIndex,
        map: s.map,
        role: s.role,
      }
    );
  });
}

function hasDifferences(current: Partial<Scale>, persistent?: Scale) {
  if (!persistent) return true;

  return (
    current.name !== persistent.name ||
    current.description !== persistent.description ||
    current.source !== persistent.source ||
    current.solmization !== persistent.solmization ||
    !isEqual(current.scaleDegrees, persistent.scaleDegrees)
  );
}
