import React, { useState, useEffect, useContext, useCallback } from "react";
import classNames from "classnames";
import WebMidi, { Output } from "webmidi";
import { range } from "lodash";

import { MIDIContext } from "../MIDIContext";

import "./MIDIOutput.scss";
import { CalibrateMIDIOutputDialog } from "../CalibrateMIDIOutputDialog";

interface MIDIOutputProps {
  showInternalOptions: boolean;
  productName: string;
  track?: string;
  className?: string;
  fixed?: boolean;
  withLabel?: boolean;
}
export const MIDIOutput: React.FC<MIDIOutputProps> = ({
  showInternalOptions,
  productName,
  track = "leimma",
  className,
  fixed,
  withLabel,
}) => {
  let { input, outputs, onSetOutput } = useContext(MIDIContext);
  let [state, setState] = useState<"init" | "error" | "active">(
    WebMidi.enabled ? "active" : "init"
  );
  let [outputOptions, setOutputOptions] = useState<Output[]>();
  let [calibrating, setCalibrating] = useState(false);

  let occupiedDevices = input.input ? [input.input] : [];

  let onUpdateCurrentOutput = useCallback(
    (id?: string, evt?: React.ChangeEvent<HTMLSelectElement>) => {
      if (id !== "synth" && id !== "string") {
        let newOutput = outputOptions!.find((o) => o.id === id)!;
        onSetOutput(track, {
          ...outputs[track],
          output: newOutput,
        });
      } else if (id) {
        onSetOutput(track, {
          ...outputs[track],
          output: undefined,
          internal: id,
        });
      }
      evt?.target.blur();
    },
    [outputOptions, onSetOutput, track, outputs]
  );

  let onUpdateCurrentOutputChannel = useCallback(
    (ch: string, evt?: React.ChangeEvent<HTMLSelectElement>) => {
      let channel = (ch === "all" || ch === "mpe" ? ch : +ch) as
        | number
        | "all"
        | "mpe";
      onSetOutput(track, {
        ...outputs[track],
        channel,
      });
      if (channel === "mpe") {
        let output = outputs[track].output!;
        output.send(0xb0, [0x7e, 0x0f]); // mono mode on
        // Enable lower MPE zone with 15 channels (2-16)
        output.send(0xb0, [0x79, 0x00]); // c0 reset all controllers
        output.send(0xb0, [0x64, 0x06]); // c0 registered parameter number lsb
        output.send(0xb0, [0x65, 0x00]); // c0 registered parameter number hsb
        output.send(0xb0, [0x06, 0x0f]); // c0 data entry = 15
        // output.send(0xb0, [0x64, 0x00]); // c0 registered parameter number lsb
        // output.send(0xb0, [0x65, 0x00]); // c0 registered parameter number hsb
        // output.send(0xb0, [0x02, 0x00]); // c0 data entry = 2 semitones
        // Turn off upper MPE zone
        output.send(0xbf, [0x79, 0x00]); // c15 reset all controllers
        output.send(0xbf, [0x64, 0x06]); // c15 registered parameter number lsb
        output.send(0xbf, [0x65, 0x00]); // c15 registered parameter number hsb
        output.send(0xbf, [0x06, 0x00]); // c15 data entry = 0
      }
      evt?.target.blur();
    },
    [onSetOutput, track, outputs]
  );

  useEffect(() => {
    if (
      outputOptions &&
      outputs[track].output &&
      outputOptions.indexOf(outputs[track].output!) < 0
    ) {
      onUpdateCurrentOutput("synth");
    }
  }, [outputOptions, outputs, track, onUpdateCurrentOutput]);

  useEffect(() => {
    if (state === "init") {
      WebMidi.enable((err) => {
        setState(err ? "error" : "active");
      });
    } else if (state === "active") {
      let updateOutputs = () => {
        setOutputOptions(Array.from(WebMidi.outputs));
      };
      updateOutputs();
      WebMidi.addListener("connected", updateOutputs);
      WebMidi.addListener("disconnected", updateOutputs);
      return () => {
        WebMidi.removeListener("connected", updateOutputs);
        WebMidi.removeListener("disconnected", updateOutputs);
      };
    }
  }, [state]);

  return (
    <div className={classNames("midiOutput", className, { fixed })}>
      {state === "init" && <>MIDI Initializing...</>}
      {state === "error" && (
        <span className="notSupportedError">
          If you wish to enable MIDI, please use Chrome or{" "}
          <a href="https://caniuse.com/#feat=midi">
            a web browser that supports it
          </a>
          .
        </span>
      )}
      {state === "active" && (
        <div className="inputGroup">
          {withLabel && <label>MIDI output:</label>}
          <select
            className="select midiOutput--device"
            value={
              outputs[track].output
                ? outputs[track].output!.id
                : outputs[track].internal
            }
            onChange={(evt) => onUpdateCurrentOutput(evt.target.value, evt)}
          >
            <option></option>
            {showInternalOptions && (
              <option value={"synth"}>Internal synth (poly)</option>
            )}
            {showInternalOptions && (
              <option value={"string"}>Internal strings (poly)</option>
            )}
            {(outputOptions || []).map((output) => (
              <option
                key={output.id}
                value={output.id}
                disabled={
                  !!occupiedDevices.find(
                    (p) =>
                      p.name === output.name &&
                      p.manufacturer === output.manufacturer
                  )
                }
              >
                {output.name}
              </option>
            ))}
          </select>
          {outputs[track].output && (
            <>
              <select
                className="select midiOutput--channel"
                value={outputs[track].channel}
                onChange={(evt) =>
                  onUpdateCurrentOutputChannel(evt.target.value, evt)
                }
              >
                {range(1, 17).map((ch) => (
                  <option key={ch} value={ch}>
                    {ch} (mono)
                  </option>
                ))}
                <option value={"mpe"}>MPE (poly)</option>
                <option value={"all"}>All round-robin (poly)</option>
              </select>
              <button className="button" onClick={() => setCalibrating(true)}>
                Calibrate
              </button>
            </>
          )}
        </div>
      )}
      {outputs[track].output && (
        <CalibrateMIDIOutputDialog
          isOpen={calibrating}
          output={outputs[track]}
          productName={productName}
          onUpdatePitchBendRange={(newPBr) =>
            onSetOutput(track, {
              ...outputs[track],
              pitchBendRangeCents: newPBr,
            })
          }
          onClose={() => setCalibrating(false)}
        />
      )}
    </div>
  );
};
