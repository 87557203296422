import { padStart } from "lodash";
import React, { useEffect, useState } from "react";
import { getTransport } from "tone";

interface SectionTimerProps {
  startedAt: number;
}
export const SectionTimer: React.FC<SectionTimerProps> = ({ startedAt }) => {
  let [timeElapsed, setTimeElapsed] = useState(0);

  useEffect(() => {
    let stillOn = true;
    let update = () => {
      if (!stillOn) return;
      let secondsRaw = getTransport().seconds - startedAt;
      let secondsRound = Math.max(0, Math.floor(secondsRaw));
      setTimeElapsed(secondsRound);
      let delToNext = Math.floor(secondsRaw + 1) - secondsRaw;
      setTimeout(update, delToNext * 1000);
    };
    update();
    return () => {
      stillOn = false;
    };
  }, [startedAt]);

  return <>{formatSeconds(timeElapsed)}</>;
};

export let formatSeconds = (totalSeconds: number) => {
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = Math.floor(totalSeconds - minutes * 60);
  return `${padStart("" + minutes, 2, "0")}:${padStart("" + seconds, 2, "0")}`;
};
