import React from "react";
import { startCase } from "lodash";

import { Solmization } from "./main/core";
import { SCALE_DEGREE_NAMES } from "./constants";

interface SolmizationDropdownProps {
  solmization?: Solmization;
  showExamples?: boolean;
  includeBlank?: boolean;
  onSelectSolmization: (solmization: Solmization) => void;
}
export const SolmizationDropdown: React.FC<SolmizationDropdownProps> = ({
  solmization,
  showExamples,
  includeBlank,
  onSelectSolmization,
}) => {
  return (
    <select
      name="solmizationSelection"
      className="solmizationSelection"
      value={solmization}
      onChange={(evt) => {
        onSelectSolmization(evt.currentTarget.value as Solmization)
        evt.target.blur();
      }}
    >
      {includeBlank && <option>Select a solmization system</option>}
      {Object.entries(SCALE_DEGREE_NAMES).map(([sol, degrees]) => (
        <option key={sol} value={sol}>
          {startCase(sol)} {showExamples && getDegreeExamples(degrees)}
        </option>
      ))}
    </select>
  );
};

function getDegreeExamples(degrees: string[]) {
  return `${degrees[0]}, ${degrees[1]}, ${degrees[2]}, ${degrees[3]}...`;
}
