import { EventEmitter } from "events";
import { isNumber } from "lodash";
import React, { useEffect, useRef } from "react";
import { Handles, Rail, Slider } from "react-compound-slider";
import classNames from "classnames";

import "./RangeSlider.scss";

interface RangeSliderProps {
  minValue: number;
  maxValue: number;
  minMinValue?: number;
  maxMaxValue?: number;
  step?: number;
  immediate?: boolean;
  disabled?: boolean;
  onUpdate: (minValue: number, maxValue: number) => void;
  events?: EventEmitter;
  noteEventValueKey?: string;
}
export const RangeSlider: React.FC<RangeSliderProps> = React.memo(
  ({
    minValue,
    maxValue,
    minMinValue = 0,
    maxMaxValue = 1,
    step = 0.01,
    immediate,
    disabled,
    onUpdate,
    events,
    noteEventValueKey,
  }) => {
    let valueMarkerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!events || !noteEventValueKey) return;
      let onNote = (note: any) => {
        if (isNumber(note[noteEventValueKey])) {
          let val = note[noteEventValueKey];
          let rel = (val - minMinValue) / (maxMaxValue - minMinValue);
          let marker = valueMarkerRef.current!;
          marker.style.left = `${rel * 100}%`;
          marker.classList.add("isVisible");
          setTimeout(() => {
            marker.classList.remove("isVisible");
          }, note.duration * 900);
        }
      };
      events.on("note", onNote);
      return () => {
        events.off("note", onNote);
      };
    }, [events, noteEventValueKey, minMinValue, maxMaxValue]);
    return (
      <Slider
        domain={[minMinValue, maxMaxValue]}
        step={step}
        values={[minValue, maxValue]}
        mode={2}
        disabled={disabled}
        onUpdate={(vals) => immediate && onUpdate(vals[0], vals[1])}
        onChange={(vals) => !immediate && onUpdate(vals[0], vals[1])}
        className={classNames("rangeSlider", { isDisabled: disabled })}
      >
        <Rail>
          {({ getRailProps }) => (
            <div className="rangeSlider--rail" {...getRailProps()} />
          )}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <>
              <div
                className="rangeSlider--activeRange"
                style={{
                  left: `${handles[0].percent}%`,
                  width: `${handles[1].percent - handles[0].percent + 2}%`,
                }}
              />
              {handles.map((handle) => (
                <div
                  key={handle.id}
                  className="rangeSlider--handle"
                  style={{ left: `${handle.percent}%` }}
                  {...getHandleProps(handle.id)}
                />
              ))}
              <div
                className="rangeSlider--currentValueMarker"
                ref={valueMarkerRef}
              />
            </>
          )}
        </Handles>
      </Slider>
    );
  }
);
