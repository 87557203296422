import { Rnd } from "react-rnd";
import { Scale, TuningSystem } from "../main/core";
import { ApotomePlayer } from "./ApotomePlayer";
import { BasicTone, Envelope, ParamModulation } from "./types";

export class CompositeApotomePlayer implements ApotomePlayer {

    private players: ApotomePlayer[] = [];

    addPlayer(player: ApotomePlayer) {
        this.players.push(player);
    }

    removePlayer(player: ApotomePlayer) {
        let idx = this.players.indexOf(player);
        if (idx >= 0) {
            this.players.slice(idx, 1);
        }
    }

    async start() {
        await Promise.all(this.players.map(p => p.start()));
    }


    setMasterVolume(newMasterVolume: number, rampTime: number, atTime: number) {
        for (let player of this.players) {
            player.setMasterVolume(newMasterVolume, rampTime, atTime);
        }
    }

    setReverbSettings(decay: number, preDelay: number) {
        for (let player of this.players) {
            player.setReverbSettings(decay, preDelay);
        }
    }

    setEchoFeedback(echoFeedback: number, atTime: number) {
        for (let player of this.players) {
            player.setEchoFeedback(echoFeedback, atTime);
        }
    }

    setEchoDelayTimes(left: number, right: number, atTime: number) {
        for (let player of this.players) {
            player.setEchoDelayTimes(left, right, atTime);
        }
    }


    addTrack(id: string) {
        for (let player of this.players) {
            player.addTrack(id);
        }
    }

    removeTrack(id: string) {
        for (let player of this.players) {
            player.removeTrack(id);
        }
    }

    setTrackPan(id: string, pan: number, atTime: number) {
        for (let player of this.players) {
            player.setTrackPan(id, pan, atTime);
        }
    }

    modulateTrackPan(id: string, pans: ParamModulation[]) {
        for (let player of this.players) {
            player.modulateTrackPan(id, pans);
        }
    }

    setTrackGain(id: string, gain: number, atTime: number) {
        for (let player of this.players) {
            player.setTrackGain(id, gain, atTime);
        }
    }

    modulateTrackGain(id: string, gains: ParamModulation[]) {
        for (let player of this.players) {
            player.modulateTrackGain(id, gains);
        }
    }

    setTrackSend1Gain(id: string, gain: number, atTime: number) {
        for (let player of this.players) {
            player.setTrackSend1Gain(id, gain, atTime);
        }
    }

    modulateTrackSend1Gain(id: string, gains: ParamModulation[]) {
        for (let player of this.players) {
            player.modulateTrackSend1Gain(id, gains);
        }
    }

    setTrackSend2Gain(id: string, gain: number, atTime: number) {
        for (let player of this.players) {
            player.setTrackSend2Gain(id, gain, atTime);
        }
    }

    modulateTrackSend2Gain(id: string, gains: ParamModulation[]) {
        for (let player of this.players) {
            player.modulateTrackSend2Gain(id, gains);
        }
    }

    initTrackBasicSynth(id: string, tone: BasicTone, ampEnvelope: Envelope, filterFreq: number, filterQ: number) {
        for (let player of this.players) {
            player.initTrackBasicSynth(id, tone, ampEnvelope, filterFreq, filterQ);
        }
    }

    disposeTrackBasicSynth(id: string, atTime: number) {
        for (let player of this.players) {
            player.disposeTrackBasicSynth(id, atTime);
        }
    }

    setTrackBasicSynthToneControls(id: string, tone: BasicTone, ampEnvelope: Envelope) {
        for (let player of this.players) {
            player.setTrackBasicSynthToneControls(id, tone, ampEnvelope);
        }
    }

    setTrackBasicSynthFilterFrequency(id: string, filterFreq: number, atTime: number) {
        for (let player of this.players) {
            player.setTrackBasicSynthFilterFrequency(id, filterFreq, atTime);
        }
    }

    modulateTrackBasicSynthFilterFrequency(id: string, filterFreqs: ParamModulation[]) {
        for (let player of this.players) {
            player.modulateTrackBasicSynthFilterFrequency(id, filterFreqs);
        }
    }

    setTrackBasicSynthFilterQ(id: string, filterQ: number, atTime: number) {
        for (let player of this.players) {
            player.setTrackBasicSynthFilterQ(id, filterQ, atTime);
        }
    }

    modulateTrackBasicSynthFilterQ(id: string, filterQs: ParamModulation[]) {
        for (let player of this.players) {
            player.modulateTrackBasicSynthFilterQ(id, filterQs);
        }
    }

    playTrackNoteOnBasicSynth(id: string, cents: number, octave: number, freq: number, duration: number, delay: number, velocity: number, atTime: number) {
        for (let player of this.players) {
            player.playTrackNoteOnBasicSynth(id, cents, octave, freq, duration, delay, velocity, atTime);
        }
    }

    initTrackString(id: string) {
        for (let player of this.players) {
            player.initTrackString(id);
        }
    }

    disposeTrackString(id: string, atTime: number) {
        for (let player of this.players) {
            player.disposeTrackString(id, atTime);
        }
    }


    playTrackNoteOnString(id: string, cents: number, octave: number, freq: number, duration: number, delay: number, velocity: number, atTime: number) {
        for (let player of this.players) {
            player.playTrackNoteOnString(id, cents, octave, freq, duration, delay, velocity, atTime);
        }
    }

    initTrackOBXD(id: string, onGUIParamChange?: (param: number, value: number, emitEvent: boolean) => void) {
        for (let player of this.players) {
            player.initTrackOBXD(id, onGUIParamChange);
        }
    }

    setOBXDState(id: string, bank: number, preset: number, patchState: number[]) {
        for (let player of this.players) {
            player.setOBXDState(id, bank, preset, patchState);
        }
    }

    setOBXDParam(id: string, param: number, value: number) {
        for (let player of this.players) {
            player.setOBXDParam(id, param, value);
        }
    }

    modulateOBXDParam(id: string, param: number, modulations: ParamModulation[]) {
        for (let player of this.players) {
            player.modulateOBXDParam(id, param, modulations);
        }
    }

    resetOBXDParamModulation(id: string, param: number, atTime: number) {
        for (let player of this.players) {
            player.resetOBXDParamModulation(id, param, atTime);
        }
    }

    openOBXDGUI(id: string, container: Rnd) {
        for (let player of this.players) {
            player.openOBXDGUI(id, container);
        }
    }

    closeOBXDGUI(id: string) {
        for (let player of this.players) {
            player.closeOBXDGUI(id);
        }
    }

    disposeTrackOBXD(id: string, atTime: number) {
        for (let player of this.players) {
            player.disposeTrackOBXD(id, atTime);
        }
    }

    playTrackNoteOnOBXD(id: string, cents: number, octave: number, freq: number, duration: number, delay: number, velocity: number, atTime: number) {
        for (let player of this.players) {
            player.playTrackNoteOnOBXD(id, cents, octave, freq, duration, delay, velocity, atTime);
        }
    }

    initTrackDEXED(id: string, onGUIParamChange?: (param: number, value: number, newPackedPatch: number[]) => void) {
        for (let player of this.players) {
            player.initTrackDEXED(id, onGUIParamChange);
        }
    }

    setDEXEDState(id: string, bank: number, preset: number, patchState: number[]) {
        for (let player of this.players) {
            player.setDEXEDState(id, bank, preset, patchState);
        }
    }

    setDEXEDParam(id: string, param: number, value: number, newPackedPatch: number[]) {
        for (let player of this.players) {
            player.setDEXEDParam(id, param, value, newPackedPatch);
        }
    }


    modulateDEXEDParam(id: string, param: number, modulations: ParamModulation[]) {
        for (let player of this.players) {
            player.modulateDEXEDParam(id, param, modulations);
        }
    }

    resetDEXEDParamModulation(id: string, param: number, atTime: number) {
        for (let player of this.players) {
            player.resetDEXEDParamModulation(id, param, atTime);
        }
    }

    openDEXEDGUI(id: string, container: Rnd) {
        for (let player of this.players) {
            player.openDEXEDGUI(id, container);
        }
    }

    closeDEXEDGUI(id: string) {
        for (let player of this.players) {
            player.closeDEXEDGUI(id);
        }
    }

    disposeTrackDEXED(id: string, atTime: number) {
        for (let player of this.players) {
            player.disposeTrackDEXED(id, atTime);
        }
    }

    playTrackNoteOnDEXED(id: string, cents: number, octave: number, freq: number, duration: number, delay: number, velocity: number, atTime: number) {
        for (let player of this.players) {
            player.playTrackNoteOnDEXED(id, cents, octave, freq, duration, delay, velocity, atTime);
        }
    }

    initTrackYoshimi(id: string) {
        for (let player of this.players) {
            player.initTrackYoshimi(id);
        }
    }

    setYoshimiState(id: string, bank: number, preset: number) {
        for (let player of this.players) {
            player.setYoshimiState(id, bank, preset);
        }
    }

    disposeTrackYoshimi(id: string, atTime: number) {
        for (let player of this.players) {
            player.disposeTrackYoshimi(id, atTime);
        }
    }

    playTrackNoteOnYoshimi(id: string, cents: number, octave: number, freq: number, duration: number, delay: number, velocity: number, atTime: number) {
        for (let player of this.players) {
            player.playTrackNoteOnYoshimi(id, cents, octave, freq, duration, delay, velocity, atTime);
        }
    }

    playTrackNoteOnMIDI(id: string, cents: number, octave: number, freq: number, duration: number, delay: number, velocity: number, atTime: number) {
        for (let player of this.players) {
            player.playTrackNoteOnMIDI(id, cents, octave, freq, duration, delay, velocity, atTime);
        }
    }

    panic(atTime: number) {
        for (let player of this.players) {
            player.panic(atTime);
        }
    }

    setCurrentTunings(tunings: { tuningSystem?: TuningSystem, scale?: Scale }[]) {
        for (let player of this.players) {
            player.setCurrentTunings(tunings);
        }
    }

}