import React, { useState, useCallback, useMemo, useEffect } from "react";
import useWindowSize from "@react-hook/window-size";
import { isEqual, isNumber, sortBy } from "lodash";

import { PitchInput } from "./main/PitchInput";
import {
  getOctaveDivisionBandWidth,
  getDivisionX,
  getFlatPitches,
  OCTAVE_DIVISION_BAND_HEIGHT,
  OctaveDivisionBand,
  OCTAVE_DIVISION_BAND_Y,
  getDivisionCentsFromX,
} from "./OctaveDivisionBand";
import {
  Pitch,
  getPitchCents,
  TString,
  getPitchSum,
  getPitchDelta,
} from "./main/core";
import { SVGCanvas } from "./SVGCanvas";

import "./OctaveDivisionOctaveView.scss";

interface OctaveDivisionOctaveViewProps {
  strings: TString[];
  onUpdateStrings: (strings: TString[]) => void;
  onSoundDivision: (
    string: TString,
    division: Pitch,
    retrigger?: boolean,
    reduceIntoOctave?: boolean
  ) => void;
}
export const OctaveDivisionOctaveView: React.FC<OctaveDivisionOctaveViewProps> = ({
  strings,
  onUpdateStrings,
  onSoundDivision,
}) => {
  let [windowWidth, windowHeight] = useWindowSize();
  let [selectedDivisionIndex, setSelectedDivisionIndex] = useState({
    stringIdx: -1,
    pcIdx: -1,
  });
  let [mouseMarkerLineVisible, setMouseMarkerLineVisible] = useState(false);
  let [mouseMarkerLineX, setMouseMarkerLineX] = useState(-windowWidth);
  let flatPitches = useMemo(() => getFlatPitches(strings), [strings]);

  useEffect(() => {
    // If tuning system changes so that the selected index is no longer present, clear it.
    if (
      selectedDivisionIndex.stringIdx >= 0 &&
      selectedDivisionIndex.pcIdx >= 0 &&
      !strings[selectedDivisionIndex.stringIdx]?.pitchClasses[
        selectedDivisionIndex.pcIdx
      ]
    ) {
      setSelectedDivisionIndex({ stringIdx: -1, pcIdx: -1 });
    }
  }, [strings, selectedDivisionIndex]);

  let addDivisionValue = (value: Pitch) => {
    if (!flatPitches.find((p) => isEqual(p, value)) && isValidPitch(value)) {
      let string = strings.find(
        (s) => s.ratioUpper === 1 && s.ratioLower === 1
      );
      if (!string) {
        string = { ratioUpper: 1, ratioLower: 1, pitchClasses: [] };
        strings.push(string);
      }
      string.pitchClasses.push({ ...value, name8ve1: "", name8ve2: "" });
      onUpdateStrings(sortBy(strings, getPitchCents));
      onSoundDivision(string, value, true, true);
    }
  };

  let addDivisionAtPointer = (evt: React.MouseEvent) => {
    let mouseX = evt.clientX;
    addDivisionValue({ cents: getDivisionCentsFromX(mouseX, windowWidth) });
  };

  let onOctaveHover = useCallback(() => {
    let pc = { ratioUpper: 2, ratioLower: 1, name8ve1: "", name8ve2: "" };
    onSoundDivision(
      { ratioUpper: 1, ratioLower: 1, pitchClasses: [pc] },
      pc,
      false
    );
  }, [onSoundDivision]);

  let onDivisionClick = useCallback(
    (stringIndex: number, pcIndex: number) => {
      if (
        ((stringIndex > 0 && pcIndex >= 0) ||
          (stringIndex === 0 && pcIndex > 0)) &&
        (stringIndex !== selectedDivisionIndex.stringIdx ||
          pcIndex !== selectedDivisionIndex.pcIdx)
      ) {
        setSelectedDivisionIndex({ stringIdx: stringIndex, pcIdx: pcIndex });
      } else {
        setSelectedDivisionIndex({ stringIdx: -1, pcIdx: -1 });
      }
    },
    [selectedDivisionIndex]
  );

  let updateSelectedDivisionValue = (flatPitch: Pitch) => {
    let string = strings[selectedDivisionIndex.stringIdx];
    let pitch = getPitchDelta(flatPitch, string);
    strings[selectedDivisionIndex.stringIdx].pitchClasses[
      selectedDivisionIndex.pcIdx
    ] = { ...pitch, name8ve1: "", name8ve2: "" };
    strings[selectedDivisionIndex.stringIdx].pitchClasses = sortBy(
      strings[selectedDivisionIndex.stringIdx].pitchClasses,
      getPitchCents
    );
    onUpdateStrings(strings);
    setSelectedDivisionIndex({ stringIdx: -1, pcIdx: -1 });
    onSoundDivision(string, pitch, true, true);
  };

  let deleteSelectedDivisionValue = () => {
    strings[selectedDivisionIndex.stringIdx].pitchClasses.splice(
      selectedDivisionIndex.pcIdx,
      1
    );
    onUpdateStrings(strings);
    setSelectedDivisionIndex({ stringIdx: -1, pcIdx: -1 });
  };

  let onUpdateDivisionCents = (
    stringIdx: number,
    pcIdx: number,
    newCents: number
  ) => {
    let stringDeltaCents = getPitchCents(
      getPitchDelta({ cents: newCents }, strings[stringIdx])
    );
    strings[stringIdx].pitchClasses[pcIdx] = {
      ...strings[stringIdx].pitchClasses[pcIdx],
      name8ve1: "",
      name8ve2: "",
      cents: stringDeltaCents,
      ratioLower: undefined,
      ratioUpper: undefined,
    };
    strings[stringIdx].pitchClasses = sortBy(
      strings[stringIdx].pitchClasses,
      getPitchCents
    );
    onUpdateStrings(strings);
  };

  let getSelectedDivisionX = useCallback(() => {
    return getDivisionX(
      selectedDivisionIndex.stringIdx,
      selectedDivisionIndex.pcIdx,
      flatPitches,
      windowWidth
    );
  }, [selectedDivisionIndex, flatPitches, windowWidth]);

  let getDivisionUpdaterTop = useCallback(() => {
    return (
      windowHeight / 2 +
      OCTAVE_DIVISION_BAND_Y +
      OCTAVE_DIVISION_BAND_HEIGHT +
      20
    );
  }, [windowHeight]);

  let getDivisionUpdaterPosition = useCallback(() => {
    let cents = getPitchCents(
      getPitchSum(
        strings[selectedDivisionIndex.stringIdx],
        strings[selectedDivisionIndex.stringIdx].pitchClasses[
          selectedDivisionIndex.pcIdx
        ]
      )
    );
    let top = getDivisionUpdaterTop();
    let markerX = getSelectedDivisionX() + windowWidth / 2;
    if (cents < 900) {
      return {
        left: markerX,
        top,
      };
    } else {
      return {
        left: markerX - 340,
        top,
      };
    }
  }, [
    windowWidth,
    strings,
    selectedDivisionIndex,
    getSelectedDivisionX,
    getDivisionUpdaterTop,
  ]);

  let updateMouseMarkerLine = useCallback((evt: React.PointerEvent) => {
    setMouseMarkerLineX(evt.clientX);
  }, []);

  return (
    <div className="octaveDivisionOctaveView">
      <div className="octaveDivisionScreen--htmlOverlay">
        {(selectedDivisionIndex.stringIdx < 0 ||
          selectedDivisionIndex.pcIdx < 0) && (
          <PitchInput
            className="divisionValueInput addDivision"
            style={{
              left: (windowWidth - getOctaveDivisionBandWidth(windowWidth)) / 2,
              top: getDivisionUpdaterTop(),
            }}
            pitch={{ cents: 0 }}
            actionText="Add"
            showLabel
            onChange={(newDiv) => addDivisionValue(newDiv)}
          />
        )}
        {selectedDivisionIndex.stringIdx >= 0 &&
          selectedDivisionIndex.pcIdx >= 0 && (
            <PitchInput
              className="divisionValueInput updateDivision withDelete"
              style={getDivisionUpdaterPosition()}
              pitch={getPitchSum(
                strings[selectedDivisionIndex.stringIdx],
                strings[selectedDivisionIndex.stringIdx].pitchClasses[
                  selectedDivisionIndex.pcIdx
                ]
              )}
              actionText="Update"
              showLabel
              onChange={(newDiv) => updateSelectedDivisionValue(newDiv)}
              onDelete={deleteSelectedDivisionValue}
            />
          )}
      </div>
      <SVGCanvas className="svgCanvas">
        <OctaveDivisionBand
          strings={strings}
          selectedDivisionIndex={selectedDivisionIndex}
          onSoundDivision={onSoundDivision}
          onOctaveHover={onOctaveHover}
          onDivisionClick={onDivisionClick}
          onDivisionUpdate={onUpdateDivisionCents}
          onIntervalEnter={() => setMouseMarkerLineVisible(true)}
          onIntervalLeave={() => setMouseMarkerLineVisible(false)}
          onIntervalMove={updateMouseMarkerLine}
          onIntervalClick={(evt) => addDivisionAtPointer(evt)}
        />
        {(selectedDivisionIndex.stringIdx > 0 ||
          selectedDivisionIndex.pcIdx > 0) && (
          <line
            className="selectedDivisionMarker"
            x1={getSelectedDivisionX()}
            y1={-OCTAVE_DIVISION_BAND_HEIGHT / 2}
            x2={getSelectedDivisionX()}
            y2={(OCTAVE_DIVISION_BAND_HEIGHT * 3) / 2}
          />
        )}
        {mouseMarkerLineVisible && (
          <>
            <line
              className="mouseMarkerLine"
              x1={mouseMarkerLineX - windowWidth / 2}
              x2={mouseMarkerLineX - windowWidth / 2}
              y1={-windowHeight}
              y2={windowHeight}
            />
            <text
              className="mouseMarkerLineLabel"
              x={mouseMarkerLineX - windowWidth / 2 + 5}
              y={-OCTAVE_DIVISION_BAND_HEIGHT / 2}
            >
              {getDivisionCentsFromX(mouseMarkerLineX, windowWidth)} cents
            </text>
          </>
        )}
      </SVGCanvas>
    </div>
  );
};

function isValidPitch(p: Pitch) {
  if (isNumber(p.ratioLower) && isNumber(p.ratioUpper)) {
    return (
      isFinite(p.ratioLower) &&
      !isNaN(p.ratioLower) &&
      p.ratioLower > 0 &&
      isFinite(p.ratioUpper) &&
      !isNaN(p.ratioUpper) &&
      p.ratioUpper > 0
    );
  } else if (isNumber(p.cents)) {
    return (
      isFinite(p.cents) && !isNaN(p.cents) && p.cents > 0 && p.cents < 1200
    );
  } else {
    return true;
  }
}
