import React, { useRef, useEffect, useState } from "react";
import * as Nexus from "nexusui";
import classNames from "classnames";

import "./ToggleSwitch.scss";

interface ToggleSwitchProps {
  checked: boolean;
  disabled?: boolean;
  onToggle: (newChecked: boolean) => void;
}
export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked,
  disabled,
  onToggle,
}) => {
  let buttonContainerRef = useRef<HTMLDivElement>(null);
  let [toggle, setToggle] = useState<any>(null);

  let latestOnToggle = useRef(onToggle);
  let changingValue = useRef(false);
  useEffect(() => {
    latestOnToggle.current = onToggle;
  }, [onToggle]);
  useEffect(() => {
    let button = new Nexus.Toggle(buttonContainerRef.current, {
      size: [20, 14],
    });

    button.colors.fill = "black";
    //button.colors.accent = "royalblue"

    if (!disabled) {
      button.on("change", (val: boolean) => {
        if (!changingValue.current) {
          latestOnToggle.current?.(val);
        }
      });
    }
    setToggle(button);
    return () => {
      button.destroy();
      setToggle(null);
    };
  }, [disabled]);
  useEffect(() => {
    if (!toggle) return;
    changingValue.current = true;
    toggle.state = checked;
    changingValue.current = false;
  }, [checked, toggle]);

  return (
    <div
      className={classNames("toggleSwitch", { isDisabled: disabled })}
      ref={buttonContainerRef}
    ></div>
  );
};
