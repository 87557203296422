import React from "react";
import classNames from "classnames";

import "./TuningSnapshots.scss";
import { TuningSnapshotsManager } from "./TuningSnapshotsManager";
import {
  ScaleDegree,
  ScaleHeader,
  Solmization,
  TuningSnapshotContent,
  TuningSystem,
} from "./main/core";

interface TuningSnapshotsProps {
  isOpen: boolean;
  existingTuningSystem?: TuningSystem;
  existingScale?: ScaleHeader;
  scaleDegrees: ScaleDegree[];
  solmization: Solmization;
  onApplySnapshot: (snapshot: TuningSnapshotContent) => void;
  onToggle: () => void;
}
export const TuningSnapshots: React.FC<TuningSnapshotsProps> = ({
  isOpen,
  existingTuningSystem,
  existingScale,
  scaleDegrees,
  solmization,
  onApplySnapshot,
  onToggle,
}) => {
  let currentContent: TuningSnapshotContent = {
    tuningSystem: existingTuningSystem,
    scale: existingScale
      ? { ...existingScale, scaleDegrees, solmization }
      : undefined,
  };

  return (
    <div className={classNames("tuningSnapshotsContainer", { isOpen })}>
      <button className="tuningSnapshotsCallout" onClick={onToggle}>
        {isOpen ? <>&darr;</> : <>&uarr;</>} Favourites
      </button>
      <TuningSnapshotsManager
        isOpen={isOpen}
        currentContent={currentContent}
        onApplySnapshot={onApplySnapshot}
      />
    </div>
  );
};
