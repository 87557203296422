import { isNumber } from "lodash";
import { Rnd } from "react-rnd";
import {
  connect,
  getContext,
  dbToGain,
  Filter,
  Reverb,
  start as toneStart,
  Synth,
  setContext,
  Context,
} from "tone";
import { KarplusStrongWorklet } from "../karplusstrong/KarplusStrongWorklet";
import { ApotomePlayer } from "./ApotomePlayer";
import { DEFAULT_RETURN_TRACK_SETTINGS, MIN_VOLUME_DB } from "./constants";
import { BasicTone, Envelope, InstrumentBanks, ParamModulation } from "./types";
import { DEXED } from "./wam/dexed";
import { OBXD } from "./wam/obxd";
import { Yoshimi } from "./wam/yoshimi";

type Track = {
  gain: GainNode;
  pan: StereoPannerNode;
  send1Gain: GainNode;
  send2Gain: GainNode;
  directGain: GainNode;
  basicSynth?: { synth: Synth; filter: Filter; outputGain: GainNode };
  karplus?: KarplusStrongWorklet;
  obxd?: OBXD;
  dexed?: DEXED;
  yoshimi?: Yoshimi;
};

export class LocalApotomePlayer implements ApotomePlayer {

  static rawContext: AudioContext;

  static async init(latencyHint?: number, lookAhead?: number): Promise<InstrumentBanks> {
    if (isNumber(latencyHint) || isNumber(lookAhead)) {
      setContext(new Context({ latencyHint: latencyHint as any, lookAhead }));
    }
    LocalApotomePlayer.rawContext = (getContext().rawContext as any)
      ._nativeAudioContext as AudioContext;
    console.log('AudioContext sample rate', LocalApotomePlayer.rawContext.sampleRate, 'latency', LocalApotomePlayer.rawContext.baseLatency, 'lookahead', getContext().lookAhead);
    await KarplusStrongWorklet.register(LocalApotomePlayer.rawContext);
    return {
      OBXD: await OBXD.preload(LocalApotomePlayer.rawContext),
      DEXED: await DEXED.preload(LocalApotomePlayer.rawContext),
      Yoshimi: await Yoshimi.preload(LocalApotomePlayer.rawContext),
    };
  }

  private masterGain: GainNode;

  private reverb: ConvolverNode;
  private reverbGenerator: Reverb;

  private echoLeft: DelayNode;
  private echoFeedbackLeft: GainNode;
  private echoLeftOut: StereoPannerNode;
  private echoRight: DelayNode;
  private echoFeedbackRight: GainNode;
  private echoRightOut: StereoPannerNode;
  private echoSplit: ChannelSplitterNode;

  private tracks = new Map<string, Track>();

  constructor() {
    this.masterGain = LocalApotomePlayer.rawContext.createGain();
    this.masterGain.gain.value = 1.0;
    this.masterGain.connect(LocalApotomePlayer.rawContext.destination);

    this.reverb = LocalApotomePlayer.rawContext.createConvolver();
    this.reverb.connect(this.masterGain);
    this.reverbGenerator = new Reverb({
      decay: DEFAULT_RETURN_TRACK_SETTINGS.reverbDecay,
      preDelay: DEFAULT_RETURN_TRACK_SETTINGS.reverbPreDelay,
    });
    this.regenerateReverbImpulse();

    this.echoLeft = LocalApotomePlayer.rawContext.createDelay(20);
    this.echoLeft.delayTime.value = 0.1;
    this.echoFeedbackLeft = LocalApotomePlayer.rawContext.createGain();
    this.echoFeedbackLeft.gain.value = 0.3;
    this.echoLeft.connect(this.echoFeedbackLeft);
    this.echoFeedbackLeft.connect(this.echoLeft);
    this.echoLeftOut = LocalApotomePlayer.rawContext.createStereoPanner?.();
    if (this.echoLeftOut) {
      this.echoLeftOut.pan.value = -1;
      this.echoLeft.connect(this.echoLeftOut);
      this.echoLeftOut.connect(this.masterGain);
    }

    this.echoRight = LocalApotomePlayer.rawContext.createDelay(20);
    this.echoRight.delayTime.value = 0.1;
    this.echoFeedbackRight = LocalApotomePlayer.rawContext.createGain();
    this.echoFeedbackRight.gain.value = 0.3;
    this.echoRight.connect(this.echoFeedbackRight);
    this.echoFeedbackRight.connect(this.echoRight);
    this.echoRightOut = LocalApotomePlayer.rawContext?.createStereoPanner?.();
    if (this.echoRightOut) {
      this.echoRightOut.pan.value = 1;
      this.echoRight.connect(this.echoRightOut);
      this.echoRightOut.connect(this.masterGain);
    }
    this.echoSplit = LocalApotomePlayer.rawContext.createChannelSplitter(2);
    this.echoSplit.connect(this.echoLeft, 0);
    this.echoSplit.connect(this.echoRight, 1);
  }

  isSupported() {
    return !!(getContext().rawContext as any)._nativeAudioContext.audioWorklet;
  }

  async start() {
    await toneStart();
  }

  setMasterVolume(newMasterVolume: number, rampTime: number, atTime: number) {
    this.masterGain.gain.setValueAtTime(this.masterGain.gain.value, atTime);
    this.masterGain.gain.linearRampToValueAtTime(
      newMasterVolume === MIN_VOLUME_DB ? 0 : dbToGain(newMasterVolume),
      atTime + rampTime
    );
  }

  setReverbSettings(decay: number, preDelay: number) {
    if (
      this.reverbGenerator.decay !== decay ||
      this.reverbGenerator.preDelay !== preDelay
    ) {
      this.reverbGenerator.decay = decay;
      this.reverbGenerator.preDelay = preDelay;
      this.regenerateReverbImpulse();
    }
  }

  setEchoFeedback(echoFeedback: number, atTime: number) {
    this.echoFeedbackLeft.gain.setTargetAtTime(echoFeedback, atTime, 0.01);
    this.echoFeedbackRight.gain.setTargetAtTime(echoFeedback, atTime, 0.01);
  }

  setEchoDelayTimes(left: number, right: number, atTime: number) {
    this.echoLeft.delayTime.setTargetAtTime(left, atTime, 0.01);
    this.echoRight.delayTime.setTargetAtTime(right, atTime, 0.01);
  }

  addTrack(id: string) {
    let track: Track = {
      gain: LocalApotomePlayer.rawContext.createGain(),
      pan: LocalApotomePlayer.rawContext.createStereoPanner?.(),
      send1Gain: LocalApotomePlayer.rawContext.createGain(),
      send2Gain: LocalApotomePlayer.rawContext.createGain(),
      directGain: LocalApotomePlayer.rawContext.createGain(),
    };
    if (track.pan) {
      track.gain.connect(track.pan);
      track.pan.connect(track.send1Gain);
      track.pan.connect(track.send2Gain);
      track.pan.connect(track.directGain);
    }

    track.send1Gain.connect(this.reverb);
    track.send2Gain.connect(this.echoSplit);
    track.directGain.connect(this.masterGain);
    this.tracks.set(id, track);
  }

  removeTrack(id: string) {
    let track = this.tracks.get(id);
    if (track) {
      track.gain.disconnect();
      track.pan.disconnect();
      track.send1Gain.disconnect();
      track.send2Gain.disconnect();
      track.directGain.disconnect();
      this.tracks.delete(id);
    }
  }

  setTrackPan(id: string, pan: number, atTime: number) {
    this.modulateTrackPan(id, [{ value: pan, atTime }]);
  }

  modulateTrackPan(id: string, pans: ParamModulation[]) {
    let track = this.tracks.get(id);
    for (let pan of pans) {
      track?.pan.pan.setTargetAtTime(pan.value, pan.atTime, 0.01);
    }
  }

  setTrackGain(id: string, gain: number, atTime: number) {
    this.modulateTrackGain(id, [{ value: gain, atTime }]);
  }

  modulateTrackGain(id: string, gains: ParamModulation[]) {
    let track = this.tracks.get(id);
    for (let gain of gains) {
      track?.gain.gain.setTargetAtTime(gain.value, gain.atTime, 0.01);
    }
  }

  setTrackSend1Gain(id: string, gain: number, atTime: number) {
    this.modulateTrackSend1Gain(id, [{ value: gain, atTime }]);
  }

  modulateTrackSend1Gain(id: string, gains: ParamModulation[]) {
    let track = this.tracks.get(id);
    for (let gain of gains) {
      track?.send1Gain.gain.setTargetAtTime(gain.value, gain.atTime, 0.01);
    }
  }

  setTrackSend2Gain(id: string, gain: number, atTime: number) {
    this.modulateTrackSend2Gain(id, [{ value: gain, atTime }]);
  }

  modulateTrackSend2Gain(id: string, gains: ParamModulation[]) {
    let track = this.tracks.get(id);
    for (let gain of gains) {
      track?.send2Gain.gain.setTargetAtTime(gain.value, gain.atTime, 0.01);
    }
  }

  initTrackBasicSynth(id: string, tone: BasicTone, ampEnvelope: Envelope, filterFreq: number, filterQ: number) {
    let track = this.tracks.get(id);
    track!.basicSynth = makeBasicSynth(tone, ampEnvelope, filterFreq, filterQ, track!.gain);
  }

  disposeTrackBasicSynth(id: string, atTime: number) {
    let track = this.tracks.get(id);
    if (track?.basicSynth) {
      disposeBasicSynth(track.basicSynth, atTime);
      track.basicSynth = undefined;
    }
  }

  setTrackBasicSynthToneControls(id: string, tone: BasicTone, ampEnvelope: Envelope) {
    let track = this.tracks.get(id);
    if (track?.basicSynth) {
      track.basicSynth.synth.oscillator.type = tone;
      track.basicSynth.synth.envelope.attack = ampEnvelope.attack;
      track.basicSynth.synth.envelope.decay = ampEnvelope.decay;
      track.basicSynth.synth.envelope.sustain = ampEnvelope.sustain;
      track.basicSynth.synth.envelope.release = ampEnvelope.release;
    }
  }

  setTrackBasicSynthFilterFrequency(id: string, filterFreq: number, atTime: number) {
    this.modulateTrackBasicSynthFilterFrequency(id, [{ value: filterFreq, atTime }]);
  }

  modulateTrackBasicSynthFilterFrequency(id: string, filterFreqs: ParamModulation[]) {
    let track = this.tracks.get(id);
    if (track?.basicSynth) {
      for (let freq of filterFreqs) {
        track.basicSynth.filter.frequency.setTargetAtTime(freq.value, freq.atTime, 0.01);
      }
    }
  }

  setTrackBasicSynthFilterQ(id: string, filterQ: number, atTime: number) {
    this.modulateTrackBasicSynthFilterQ(id, [{ value: filterQ, atTime }]);
  }

  modulateTrackBasicSynthFilterQ(id: string, filterQs: ParamModulation[]) {
    let track = this.tracks.get(id);
    if (track?.basicSynth) {
      for (let q of filterQs) {
        track.basicSynth.filter.Q.setTargetAtTime(q.value, q.atTime, 0.01);
      }
    }
  }

  playTrackNoteOnBasicSynth(id: string, cents: number, octave: number, freq: number, duration: number, delay: number, velocity: number, atTime: number) {
    let track = this.tracks.get(id);
    track?.basicSynth?.synth.triggerAttackRelease(freq, duration, atTime + delay, velocity);
  }

  initTrackString(id: string) {
    let track = this.tracks.get(id);
    if (track) {
      track.karplus = new KarplusStrongWorklet(LocalApotomePlayer.rawContext);
      track.karplus.init();
      connect(track.karplus.worklet as any, track.gain)
    }
  }

  disposeTrackString(id: string, atTime: number) {
    let track = this.tracks.get(id);
    if (track && track.karplus) {
      track.karplus.dispose();
      track.karplus = undefined;
    }
  }

  playTrackNoteOnString(id: string, cents: number, octave: number, freq: number, duration: number, delay: number, velocity: number, atTime: number) {
    let track = this.tracks.get(id);
    if (track && track.karplus) {
      track.karplus.playNext(freq, atTime + delay, velocity, duration);
    }
  }

  initTrackOBXD(id: string, onGUIParamChange?: (param: number, value: number, emitEvent: boolean) => void) {
    let track = this.tracks.get(id);
    if (track) {
      track.obxd = new OBXD(LocalApotomePlayer.rawContext, track.gain);
      if (onGUIParamChange) {
        track.obxd.onGUIParamChange(onGUIParamChange);
      }
    }
  }

  setOBXDState(id: string, bank: number, preset: number, patchState: number[]) {
    let track = this.tracks.get(id);
    if (track && track.obxd) {
      track.obxd.setPreset(bank, preset, patchState);
    }
  }

  setOBXDParam(id: string, param: number, value: number) {
    let track = this.tracks.get(id);
    track?.obxd?.setParam(param, value);
  }

  modulateOBXDParam(id: string, param: number, modulations: ParamModulation[]) {
    let track = this.tracks.get(id);
    if (track && track.obxd) {
      track.obxd.modulate(param, modulations);
    }
  }

  resetOBXDParamModulation(id: string, param: number, atTime: number) {
    let track = this.tracks.get(id);
    if (track && track.obxd) {
      track.obxd.resetModulation(param, atTime);
    }
  }

  openOBXDGUI(id: string, container: Rnd) {
    let track = this.tracks.get(id);
    if (track && track.obxd) {
      track.obxd.openGUI(container);
    }
  }

  closeOBXDGUI(id: string) {
    let track = this.tracks.get(id);
    if (track && track.obxd) {
      track.obxd.closeGUI();
    }
  }

  disposeTrackOBXD(id: string, atTime: number) {
    let track = this.tracks.get(id);
    if (track?.obxd) {
      track.obxd.dispose(atTime);
      track.obxd = undefined;
    }
  }

  playTrackNoteOnOBXD(id: string, cents: number, octave: number, freq: number, duration: number, delay: number, velocity: number, atTime: number) {
    let track = this.tracks.get(id);
    track?.obxd?.triggerAttackRelease(freq, duration, atTime + delay, velocity);
  }

  initTrackDEXED(id: string, onGUIParamChange?: (param: number, value: number, newPackedPatch: number[]) => void) {
    let track = this.tracks.get(id);
    if (track) {
      track.dexed = new DEXED(LocalApotomePlayer.rawContext, track.gain);
      if (onGUIParamChange) {
        track.dexed.onGUIParamChange(onGUIParamChange);
      }
    }
  }

  setDEXEDState(id: string, bank: number, preset: number, patchState: number[]) {
    let track = this.tracks.get(id);
    if (track && track.dexed) {
      track.dexed.setPreset(bank, preset, patchState);
    }
  }

  setDEXEDParam(id: string, param: number, value: number, newPackedPatch: number[]) {
    let track = this.tracks.get(id);
    track?.dexed?.setParam(param, value, newPackedPatch);
  }

  modulateDEXEDParam(id: string, param: number, modulations: ParamModulation[]) {
    let track = this.tracks.get(id);
    if (track && track.dexed) {
      track.dexed.modulate(param, modulations);
    }
  }

  resetDEXEDParamModulation(id: string, param: number, atTime: number) {
    let track = this.tracks.get(id);
    if (track && track.dexed) {
      track.dexed.resetModulation(param, atTime);
    }
  }

  openDEXEDGUI(id: string, container: Rnd) {
    let track = this.tracks.get(id);
    if (track && track.dexed) {
      track.dexed.openGUI(container);
    }
  }

  closeDEXEDGUI(id: string) {
    let track = this.tracks.get(id);
    if (track && track.dexed) {
      track.dexed.closeGUI();
    }
  }

  disposeTrackDEXED(id: string, atTime: number) {
    let track = this.tracks.get(id);
    if (track?.dexed) {
      track.dexed.dispose(atTime);
      track.dexed = undefined;
    }
  }

  playTrackNoteOnDEXED(id: string, cents: number, octave: number, freq: number, duration: number, delay: number, velocity: number, atTime: number) {
    let track = this.tracks.get(id);
    track?.dexed?.triggerAttackRelease(freq, duration, atTime + delay, velocity);
  }

  initTrackYoshimi(id: string) {
    let track = this.tracks.get(id);
    if (track) {
      track.yoshimi = new Yoshimi(LocalApotomePlayer.rawContext, track.gain);
    }
  }

  setYoshimiState(id: string, bank: number, preset: number) {
    let track = this.tracks.get(id);
    if (track && track.yoshimi) {
      track.yoshimi.setPreset(bank, preset);
    }
  }

  disposeTrackYoshimi(id: string, atTime: number) {
    let track = this.tracks.get(id);
    if (track?.yoshimi) {
      track.yoshimi.dispose(atTime);
      track.yoshimi = undefined;
    }
  }

  playTrackNoteOnYoshimi(id: string, cents: number, octave: number, freq: number, duration: number, delay: number, velocity: number, atTime: number) {
    let track = this.tracks.get(id);
    track?.yoshimi?.triggerAttackRelease(freq, duration, atTime + delay, velocity);
  }

  playTrackNoteOnMIDI() {
  }


  panic(atTime: number) {
    for (let track of Array.from(this.tracks.values())) {
      if (track.obxd) {
        track.obxd.sendControlChange(123, 0, atTime);
        track.obxd.sendControlChange(121, 0, atTime);
      }
      if (track.dexed) {
        track.dexed.sendControlChange(120, 0, atTime);
        track.dexed.sendControlChange(123, 0, atTime);
        track.dexed.sendControlChange(121, 0, atTime);
      }
      if (track.yoshimi) {
        track.yoshimi.sendControlChange(123, 0, atTime);
        track.yoshimi.sendControlChange(121, 0, atTime);
      }
    }
  }

  setCurrentTunings() {
  }

  private async regenerateReverbImpulse() {
    await this.reverbGenerator.generate();
    this.reverb.buffer = (this.reverbGenerator as any)._convolver.buffer;
  }

}

function makeBasicSynth(type: BasicTone, amplitudeEnvelope: Envelope, filterFrequency: number, filterResonance: number, dest: AudioNode) {
  let synth = new Synth({
    oscillator: {
      type
    },
    envelope: amplitudeEnvelope,
    volume: -10
  });
  let filter = new Filter({ type: 'lowpass', rolloff: -24, frequency: filterFrequency, Q: filterResonance });
  synth.connect(filter);
  let filterOut: any = filter;
  while (filterOut.output) {
    filterOut = filterOut.output;
  }
  if (filterOut._nativeAudioNode) {
    filterOut = filterOut._nativeAudioNode;
  }
  let outputGain = LocalApotomePlayer.rawContext.createGain();

  filterOut.connect(outputGain);
  outputGain.connect(dest);

  return { synth, filter, outputGain };
}

function disposeBasicSynth({ synth, filter, outputGain }: { synth: Synth, filter: Filter, outputGain: GainNode }, atTime: number) {
  outputGain.gain.setTargetAtTime(0, atTime, 0.33);
  setTimeout(() => {
    synth.dispose();
    filter.dispose();
    outputGain.disconnect();
  }, 1000);
}
