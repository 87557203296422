import React, { useRef, useEffect, useState } from "react";
import * as Nexus from "nexusui";

import "./EnvelopeControl.scss";
import { isEqual } from "lodash";
import { Envelope } from "./types";

const MAX_ATTACK = 1;
const MAX_DECAY = 1;
const MAX_RELEASE = 2;

interface EnvelopeControlProps {
  label: string;
  envelope: Envelope;
  onUpdateEnvelope: (env: Envelope) => void;
}
export const EnvelopeControl: React.FC<EnvelopeControlProps> = ({
  label,
  envelope,
  onUpdateEnvelope,
}) => {
  let envelopeContainerRef = useRef<HTMLDivElement>(null);
  let [envelopeInstance, setEnvelopeInstance] = useState<any>(null);

  let settingPoints = useRef<boolean>(false);
  useEffect(() => {
    let env = new Nexus.Envelope(envelopeContainerRef.current, {
      size: [envelopeContainerRef.current!.offsetWidth, 80],
      noNewPoints: true,
    });
    env.on("change", (evt: { x: number; y: number }[]) => {
      if (settingPoints.current || evt.length < 4) return;
      let newEnvelope = {
        attack: Math.max(
          0,
          Math.min(MAX_ATTACK, (evt[1].x / 0.3) * MAX_ATTACK)
        ),
        decay: Math.max(
          0.001,
          Math.min(MAX_DECAY, ((evt[2].x - 0.3) / 0.3) * MAX_DECAY)
        ),
        release:
          MAX_RELEASE -
          Math.max(
            0,
            Math.min(MAX_RELEASE, ((evt[3].x - 0.6) / 0.4) * MAX_RELEASE)
          ),
        sustain: evt[2].y,
      };
      onUpdateEnvelope(newEnvelope);
      let newPoints = getEnvelopePoints(newEnvelope);
      if (!isEqual(newPoints, env.points)) {
        settingPoints.current = true;
        env.setPoints(newPoints);
        settingPoints.current = false;
      }
    });
    setEnvelopeInstance(env);
    return () => {
      env.destroy();
      setEnvelopeInstance(null);
    };
  }, [onUpdateEnvelope]);
  useEffect(() => {
    if (!envelopeInstance) return;
    let newPoints = getEnvelopePoints(envelope);
    if (!isEqual(newPoints, envelopeInstance.points)) {
      envelopeInstance.setPoints(newPoints);
    }
  }, [envelopeInstance, envelope]);

  return (
    <div className="envelopeControl">
      <div className="envelopeControl--label">{label}</div>
      <div
        className="envelopeControl--envelope"
        ref={envelopeContainerRef}
      ></div>
    </div>
  );
};

function getEnvelopePoints(envelope: Envelope): any {
  return [
    {
      x: 0,
      y: 0,
    },
    {
      x: (envelope.attack / MAX_ATTACK) * 0.3,
      y: 1,
    },
    {
      x: 0.3 + (envelope.decay / MAX_DECAY) * 0.3,
      y: envelope.sustain,
    },
    {
      x: 1.0 - (envelope.release / MAX_RELEASE) * 0.4,
      y: envelope.sustain,
    },
    {
      x: 1,
      y: 0,
    },
  ];
}
