import React, { useCallback, useContext, useMemo, useState } from "react";
import { debounce, sortBy } from "lodash";
import { useWindowWidth } from "@react-hook/window-size";

import {
  Pitch,
  getPitchCents,
  midiToFreq,
  TString,
  getPrimeLimitRatios,
} from "./main/core";
import * as audio from "./main/audio";

import { MIDIContext } from "./MIDIContext";
import {
  OctaveDivisionString,
  getStringY,
  getStringPitchX,
} from "./OctaveDivisionString";

import { PitchInput } from "./main/PitchInput";

import "./OctaveDivisionStringsView.scss";

const RATIO_LIMITS = [3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41];

interface OctaveDivisionStringsViewProps {
  strings: TString[];
  refPitchSemitones: number;
  onUpdateStrings: (newStrings: TString[]) => void;
}
export const OctaveDivisionStringsView: React.FC<OctaveDivisionStringsViewProps> = ({
  strings,
  refPitchSemitones,
  onUpdateStrings,
}) => {
  let {
    outputs: { leimma: output },
  } = useContext(MIDIContext);
  let windowWidth = useWindowWidth();
  let [ratioLimit, setRatioLimit] = useState(0);
  let rulers = useMemo(
    () =>
      sortBy(
        (ratioLimit > 0 ? getPrimeLimitRatios(ratioLimit) : []).map(
          (pitch) => ({
            pitch,
            x: getStringPitchX(pitch, windowWidth),
          })
        ),
        (r) => r.x
      ),
    [ratioLimit, windowWidth]
  );

  let stopSound = useCallback(
    debounce((keyCode: number) => {
      audio.noteOff(keyCode, output);
    }, 500),
    [output]
  );

  let soundDivision = useCallback(
    (string: TString, div: Pitch, idx: number) => {
      let baseFreq = midiToFreq(refPitchSemitones);
      let openStringFreq = baseFreq * 2 ** (getPitchCents(string) / 1200);
      let freq = openStringFreq * 2 ** (getPitchCents(div) / 1200);
      audio.noteOn(-idx, freq, 1, null, output, false);
      audio.setFrequency(-idx, freq, null, output);
      stopSound(-idx);
    },
    [refPitchSemitones, output, stopSound]
  );

  let addString = useCallback(() => {
    strings.push({ ratioUpper: 1, ratioLower: 1, pitchClasses: [] });
    onUpdateStrings(strings);
  }, [strings, onUpdateStrings]);

  let updateString = useCallback(
    (newPitch: Pitch, stringIdx: number) => {
      strings[stringIdx] = { ...strings[stringIdx], ...newPitch };
      onUpdateStrings(strings);
    },
    [strings, onUpdateStrings]
  );

  let deleteString = useCallback(
    (stringIdx: number) => {
      strings.splice(stringIdx, 1);
      onUpdateStrings(strings);
    },
    [strings, onUpdateStrings]
  );

  let updateDivision = useCallback(
    (newPc: Pitch, pcIdx: number, stringIdx: number) => {
      strings[stringIdx].pitchClasses[pcIdx] = {
        ...newPc,
        name8ve1: "",
        name8ve2: "",
      };
      onUpdateStrings(strings);
      soundDivision(strings[stringIdx], newPc, stringIdx);
    },
    [strings, soundDivision, onUpdateStrings]
  );

  return (
    <div className="octaveDivisionStringsView">
      <div className="octaveDivisionStringsView--openStringTunings">
        {strings.map((string, index) => (
          <PitchInput
            key={index}
            pitch={string}
            onChange={(newPitch) => updateString(newPitch, index)}
            style={{ top: getStringY(index) - 30 }}
          />
        ))}
        <button
          className="octaveDivisionStringsView--newString"
          style={{ top: getStringY(strings.length) }}
          onClick={addString}
        >
          Add string
        </button>
      </div>
      <div
        className="octaveDivisionStringsView--rulerSwitcher"
        style={{ top: getStringY(strings.length + 1) }}
      >
        <label htmlFor="rulerOptions">Ratio limit ruler:</label>
        <select
          name="rulerOptions"
          className="octaveDivisionStringsView--rulerOptions"
          value={ratioLimit}
          onChange={(e) => setRatioLimit(+e.target.value)}
        >
          <option value={0}>None</option>
          {RATIO_LIMITS.map((l) => (
            <option key={l} value={l}>
              {l}-limit
            </option>
          ))}
        </select>
      </div>
      <svg
        className="octaveDivisionStringsView--canvas"
        style={{ height: getStringY(strings.length) }}
      >
        {rulers.map((guidePitch, idx) => (
          <line
            key={idx}
            className="octaveDivisionStringsView--ruler"
            x1={guidePitch.x}
            x2={guidePitch.x}
            y1={0}
            y2={getStringY(strings.length)}
          />
        ))}
        {strings.map((string, index) => (
          <OctaveDivisionString
            key={index}
            string={string}
            index={index}
            rulers={rulers}
            onUpdateDivision={(newPc, pcIdx) =>
              updateDivision(newPc, pcIdx, index)
            }
            onSoundDivision={(pc) => soundDivision(string, pc, index)}
          />
        ))}
      </svg>
      <div className="octaveDivisionStringsView--stringActions">
        {strings.map((string, index) => (
          <button
            key={index}
            className="octaveDivisionStringsView--deleteString"
            style={{ top: getStringY(index) - 20 }}
            onClick={() => deleteString(index)}
          >
            Delete
          </button>
        ))}
      </div>
    </div>
  );
};
