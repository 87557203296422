import React, { useEffect, useMemo, useRef } from "react";
import classNames from "classnames";
import { isNumber, findIndex, isEqual, startCase, round } from "lodash";
import useWindowSize from "@react-hook/window-size";
import { useDrag } from "react-use-gesture";
import { ReactEventHandlers } from "react-use-gesture/dist/types";
import { useSpring, a, SpringValue, to } from "@react-spring/web";

import {
  isAccidental,
  Solmization,
  Pitch,
  formatPitch,
  getPitchCents,
  TString,
  ScaleDegree,
  reduceRatio,
  SCALE_DEGREE_KEYBOARD_MAPPING,
} from "./main/core";
import { SCALE_DEGREE_NAMES, SCALE_DEGREE_NAMES_ENGLISH } from "./constants";
import {
  getFlatPitches,
  getOctaveDivisionBandWidth,
  OCTAVE_DIVISION_BAND_HEIGHT,
  OCTAVE_DIVISION_BAND_Y,
} from "./OctaveDivisionBand";

import "./TuningSystemWheel.scss";

interface TuningSystemWheelProps {
  strings: TString[];
  solmization?: Solmization;
  scale?: ScaleDegree[];
  selectedIndex?: { stringIdx: number; pcIdx: number };
  highlightedIndexes: { stringIdx: number; pcIdx: number }[];
  pitchFormat: "cents" | "ratio";
  onSetTonic?: (idx: number) => void;
  onUpdateScale?: (newScale: ScaleDegree[]) => void;
  onSoundScale?: () => void;
  onSoundIndex?: (stringIndex: number, pcIndex: number) => void;
  onSelectIndex?: (stringIndex: number, pcIndex: number) => void;
  onEntered?: () => void;
}
export const TuningSystemWheel: React.FC<TuningSystemWheelProps> = ({
  strings,
  solmization,
  scale = [],
  selectedIndex,
  highlightedIndexes,
  pitchFormat,
  onSetTonic,
  onUpdateScale,
  onSoundScale,
  onSoundIndex,
  onSelectIndex,
  onEntered,
}) => {
  let tonicMarkerDragGuideLineRef = useRef<SVGLineElement>(null);
  let scaleBandGuideRef = useRef<SVGGElement>(null);
  let scaleLabelGuideRef = useRef<SVGGElement>(null);
  let [windowWidth, windowHeight] = useWindowSize();

  let circleOuterRadius = windowHeight / 2.6;
  let bandWidth = getOctaveDivisionBandWidth(windowWidth);

  let [spring, set] = useSpring(() => ({
    totalRadians: 1 / 1000,
    totalWidth: bandWidth,
    ringWidth: OCTAVE_DIVISION_BAND_HEIGHT,
    translateY: circleOuterRadius + OCTAVE_DIVISION_BAND_Y,
    wheelColor: "#333",
    config: { friction: 25, clamp: true },
  }));
  useEffect(() => {
    set({
      totalRadians: Math.PI * 2,
      totalWidth: circleOuterRadius * Math.PI * 2,
      ringWidth: 160,
      translateY: 0,
      wheelColor: "#333",
      onRest: onEntered,
    });
  }, [circleOuterRadius, onEntered, set]);

  let totalRadians = spring.totalRadians as SpringValue<number>;
  let totalWidth = spring.totalWidth as SpringValue<number>;
  let ringWidth = spring.ringWidth as SpringValue<number>;
  let wheelColor = spring.wheelColor as SpringValue<string>;

  let radiusScale = spring.totalRadians.to((rad) => (Math.PI * 2) / rad);
  let outerRadius = to(
    [totalWidth, radiusScale],
    (width, scale) => (width * scale) / (2 * Math.PI)
  );
  let innerRadius = to([outerRadius, ringWidth], (outer, rw) => outer - rw);
  let midiMappingBandInnerRadius = to(
    [outerRadius, ringWidth],
    (outer, rw) => outer - Math.min(100, rw)
  );
  let midiMappingBandOuterRadius = to(
    [outerRadius, ringWidth],
    (outer, rw) => outer - Math.min(75, rw)
  );
  let scaleBandInnerRadius = midiMappingBandInnerRadius.to(
    (inner) => inner + 1
  );
  let tonicMarkerHandleRadius = 10;

  let flatPitches = useMemo(() => getFlatPitches(strings), [strings]);
  let selectedFlatIndex =
    isNumber(selectedIndex?.pcIdx) && isNumber(selectedIndex?.stringIdx)
      ? findIndex(
          flatPitches,
          (f) =>
            f.stringIdx === selectedIndex?.stringIdx &&
            f.pcIdx === selectedIndex.pcIdx
        )
      : -1;

  function getDivisionSizeCents(flatIndex: number) {
    let fromCents = getPitchCents(flatPitches[flatIndex].pitch);
    let toCents =
      flatIndex < flatPitches.length - 1
        ? getPitchCents(flatPitches[flatIndex + 1].pitch)
        : 1200;
    return toCents - fromCents;
  }

  function getScaleDegree(flatIndex: number) {
    let pitch = flatPitches[flatIndex];
    return scale.find(
      (s) =>
        s.stringIndex === pitch.stringIdx && s.pitchClassIndex === pitch.pcIdx
    );
  }

  function getInitialRadians(totalRadians: number) {
    let rel = totalRadians / (Math.PI * 2);
    let initialShift = -Math.PI / 2 - totalRadians / 4;
    let finalShift = -Math.PI;
    let shift = (1 - rel) * initialShift + rel * finalShift;
    return shift - (getDivisionWidthRadians(0, totalRadians) / 2) * rel;
  }

  function getDivisionStartRadians(flatIndex: number, totalRadians: number) {
    let radians = getInitialRadians(totalRadians);
    for (let i = 0; i < flatIndex; i++) {
      if (i === flatIndex) {
        return radians;
      }
      radians += getDivisionWidthRadians(i, totalRadians);
    }
    return wrapAngle(radians % (Math.PI * 2));
  }

  function getDivisionWidthRadians(flatIndex: number, totalRadians: number) {
    let sizeCents = getDivisionSizeCents(flatIndex);
    let relSize = sizeCents / 1200;
    return relSize * totalRadians;
  }

  function getTonicRadians(totalRadians: number) {
    if (scale.length === 0) return 0;
    let tonicSd = scale.find((s) => s.role === "tonic")!;
    let tonicFlatIndex = findIndex(
      flatPitches,
      (fp) =>
        fp.stringIdx === tonicSd.stringIndex &&
        fp.pcIdx === tonicSd.pitchClassIndex
    );
    return getDivisionStartRadians(tonicFlatIndex, totalRadians);
  }

  function getFlatPitchIndexAtRadians(radians: number, totalRadians: number) {
    for (let i = 0; i < flatPitches.length; i++) {
      let start = wrapAngle(getDivisionStartRadians(i, totalRadians));
      let end = wrapAngle(start + getDivisionWidthRadians(i, totalRadians));
      if (start < end && radians >= start && radians <= end) {
        return i;
      } else if (
        start > end &&
        ((radians >= 0 && radians <= end) || radians >= start)
      ) {
        return i;
      }
    }
    throw new Error(
      "No flat pitch found at radians " + radians + ". This should not happen."
    );
  }

  function getScaleDegreeSolmization(
    flatIdx: number,
    scaleDegreeNames = SCALE_DEGREE_NAMES[solmization!]
  ): string | undefined {
    let mapping = getScaleDegree(flatIdx)?.map;
    if (isNumber(mapping)) {
      return scaleDegreeNames[mapping];
    }
  }

  let bindTonicDragRotate = useDrag(
    ({ event, first, last, xy: [x, y], movement: [movedX, movedY], memo }) => {
      if (first) {
        event!.preventDefault();
        let element = event!.currentTarget as SVGElement;
        let svg = element.ownerSVGElement!;
        let pt = svg.createSVGPoint();
        pt.x = x;
        pt.y = y;
        let pointOnCanvas = pt.matrixTransform(svg.getScreenCTM()!.inverse());
        return {
          initSVGx: pointOnCanvas.x,
          initSVGy: pointOnCanvas.y,
        };
      } else {
        let { initSVGx, initSVGy } = memo as {
          initSVGx: number;
          initSVGy: number;
        };
        let svgX = initSVGx + movedX;
        let svgY = initSVGy + movedY;
        let angle = circAngle(svgX, svgY);

        let closestScaleDegreeIdx = 0,
          closestDistance = Number.MAX_VALUE;
        for (let i = 0; i < scale.length; i++) {
          let sd = scale[i];
          let flatIndex = flatPitches.findIndex(
            (fp) =>
              fp.stringIdx === sd.stringIndex && fp.pcIdx === sd.pitchClassIndex
          );
          let sdAngle = getDivisionStartRadians(
            flatIndex,
            spring.totalRadians.getValue()
          );
          if (Math.abs(sdAngle - angle) < closestDistance) {
            closestScaleDegreeIdx = i;
            closestDistance = Math.abs(sdAngle - angle);
          }
        }

        let currentTonicIdx = findIndex(scale, (d) => d.role === "tonic");

        if (closestScaleDegreeIdx !== currentTonicIdx && onSetTonic) {
          onSetTonic(closestScaleDegreeIdx);
        }

        let lineGuide = tonicMarkerDragGuideLineRef.current;
        let guideAngle = last
          ? getTonicRadians(spring.totalRadians.getValue())
          : angle;
        if (lineGuide) {
          lineGuide.setAttribute(
            "x1",
            "" + getTonicMarkerInnerX(guideAngle, innerRadius.getValue())
          );
          lineGuide.setAttribute(
            "y1",
            "" + getTonicMarkerInnerY(guideAngle, innerRadius.getValue())
          );
          lineGuide.setAttribute(
            "x2",
            "" + getTonicMarkerOuterX(guideAngle, outerRadius.getValue())
          );
          lineGuide.setAttribute(
            "y2",
            "" + getTonicMarkerOuterY(guideAngle, outerRadius.getValue())
          );
        }

        return memo;
      }
    }
  );

  let bindScaleDragRotate = useDrag(
    ({
      event,
      first,
      last,
      xy: [x, y],
      movement: [movedX, movedY],
      args: [initialDivFlatIdx],
      memo,
    }) => {
      if (first) {
        event!.preventDefault();

        // In first handler memo the starting point relative to SVG viewbox
        let element = event!.currentTarget as SVGElement;
        let svg = element.ownerSVGElement!;
        let pt = svg.createSVGPoint();
        pt.x = x;
        pt.y = y;
        let pointOnCanvas = pt.matrixTransform(svg.getScreenCTM()!.inverse());
        return {
          initSVGx: pointOnCanvas.x,
          initSVGy: pointOnCanvas.y,
          initAngle: circAngle(pointOnCanvas.x, pointOnCanvas.y),
          initPitchAngle: getDivisionStartRadians(
            getFlatPitchIndexAtRadians(
              circAngle(pointOnCanvas.x, pointOnCanvas.y),
              spring.totalRadians.getValue()
            ),
            spring.totalRadians.getValue()
          ),
          initScale: scale,
          initTonicAngle: getTonicRadians(spring.totalRadians.getValue()),
          initDivFlatIdx: initialDivFlatIdx,
        };
      } else {
        // In subsequent handlers, get point relative to SVG viewbox by offsetting the original...
        let {
          initSVGx,
          initSVGy,
          initAngle,
          initPitchAngle,
          initScale,
          initTonicAngle,
          initDivFlatIdx,
        } = memo as {
          initSVGx: number;
          initSVGy: number;
          initAngle: number;
          initPitchAngle: number;
          initScale: ScaleDegree[];
          initTonicAngle: number;
          initDivFlatIdx: number;
        };
        let svgX = initSVGx + movedX;
        let svgY = initSVGy + movedY;
        /// ... then find the angle we've rotated
        let angle = circAngle(svgX, svgY);
        let currentDivFlatIdx = getFlatPitchIndexAtRadians(
          angle,
          spring.totalRadians.getValue()
        );
        let divShift = currentDivFlatIdx - initDivFlatIdx;
        if (divShift < 0) {
          divShift += flatPitches.length;
        }
        if (divShift >= flatPitches.length) {
          divShift -= flatPitches.length;
        }
        let shiftedScale = initScale.map((s) => {
          let currentFlatIndex = findIndex(
            flatPitches,
            (f) =>
              f.stringIdx === s.stringIndex && f.pcIdx === s.pitchClassIndex
          );
          let shiftedFlatIdx =
            (currentFlatIndex + divShift) % flatPitches.length;
          let shiftedPc = flatPitches[shiftedFlatIdx];
          return {
            ...s,
            stringIndex: shiftedPc.stringIdx,
            pitchClassIndex: shiftedPc.pcIdx,
          };
        });

        onUpdateScale &&
          !isEqual(shiftedScale, scale) &&
          onUpdateScale(shiftedScale);

        let lineGuide = tonicMarkerDragGuideLineRef.current;
        let guideAngle = last
          ? getTonicRadians(spring.totalRadians.getValue())
          : initTonicAngle + angle - initAngle;
        if (lineGuide) {
          lineGuide.setAttribute(
            "x1",
            "" + getTonicMarkerInnerX(guideAngle, innerRadius.getValue())
          );
          lineGuide.setAttribute(
            "y1",
            "" + getTonicMarkerInnerY(guideAngle, innerRadius.getValue())
          );
          lineGuide.setAttribute(
            "x2",
            "" + getTonicMarkerOuterX(guideAngle, outerRadius.getValue())
          );
          lineGuide.setAttribute(
            "y2",
            "" + getTonicMarkerOuterY(guideAngle, outerRadius.getValue())
          );
        }
        let scaleGuideAngle = last
          ? 0
          : angle -
            getDivisionStartRadians(
              currentDivFlatIdx,
              spring.totalRadians.getValue()
            ) -
            (initAngle - initPitchAngle);
        scaleBandGuideRef.current?.setAttribute(
          "transform",
          `rotate(${(scaleGuideAngle / Math.PI) * 180})`
        );
        scaleLabelGuideRef.current?.setAttribute(
          "transform",
          `rotate(${(scaleGuideAngle / Math.PI) * 180})`
        );

        return memo;
      }
    }
  );

  let tonicRadians = totalRadians.to((total) => getTonicRadians(total));
  let startRadians = flatPitches.map((_, index) =>
    totalRadians.to((total) => getDivisionStartRadians(index, total))
  );
  let widthRadians = flatPitches.map((_, index) =>
    totalRadians.to((total) => getDivisionWidthRadians(index, total))
  );

  return (
    <a.g
      className={classNames("tuningSystemWheel", { isSpinnable: !!onSetTonic })}
      transform={to(
        [outerRadius, spring.translateY],
        (outer, translateY) =>
          `translate(0, ${outer - circleOuterRadius + translateY})`
      )}
    >
      {flatPitches.map(
        (p, flatIndex) =>
          (p.stringIdx !== selectedIndex?.stringIdx ||
            p.pcIdx !== selectedIndex?.pcIdx) && (
            <TuningSystemWheelDiv
              key={flatIndex}
              outerRadius={outerRadius}
              innerRadius={innerRadius}
              startRadians={startRadians[flatIndex]}
              widthRadians={widthRadians[flatIndex]}
              wheelColor={wheelColor}
              isRefPitchRoot={flatIndex === 0}
              isSelected={false}
              onClick={
                onSelectIndex && (() => onSelectIndex(p.stringIdx, p.pcIdx))
              }
            />
          )
      )}
      {selectedFlatIndex >= 0 && (
        /* Render selected spoke separately so its borders are on top of other ones and it can be highlighted */
        <TuningSystemWheelDiv
          outerRadius={outerRadius}
          innerRadius={innerRadius}
          startRadians={startRadians[selectedFlatIndex]}
          widthRadians={widthRadians[selectedFlatIndex]}
          wheelColor={wheelColor}
          isRefPitchRoot={selectedFlatIndex === 0}
          isSelected={true}
          onClick={
            onSelectIndex &&
            (() =>
              onSelectIndex(selectedIndex!.stringIdx, selectedIndex!.pcIdx))
          }
        />
      )}
      {flatPitches.map(
        (p, flatIndex) =>
          (p.stringIdx !== selectedIndex?.stringIdx ||
            p.pcIdx !== selectedIndex?.pcIdx) && (
            <TuningSystemWheelScaleBand
              key={flatIndex}
              outerRadius={outerRadius}
              scaleBandInnerRadius={scaleBandInnerRadius}
              midiMappingBandInnerRadius={midiMappingBandInnerRadius}
              midiMappingBandOuterRadius={midiMappingBandOuterRadius}
              mapping={getScaleDegree(flatIndex)?.map}
              role={getScaleDegree(flatIndex)?.role}
              startRadians={startRadians[flatIndex]}
              widthRadians={widthRadians[flatIndex]}
              wheelColor={wheelColor}
              isSelected={false}
              isHighlighted={
                !!highlightedIndexes.find(
                  (i) => i.pcIdx === p.pcIdx && i.stringIdx === p.stringIdx
                )
              }
              isAnythingMapped={scale.length > 0}
              eventHandlers={bindScaleDragRotate(flatIndex)}
              onClick={
                onSoundIndex && (() => onSoundIndex(p.stringIdx, p.pcIdx))
              }
            />
          )
      )}
      {selectedFlatIndex >= 0 && (
        /* Render selected spoke separately so its borders are on top of other ones and it can be highlighted */
        <TuningSystemWheelScaleBand
          outerRadius={outerRadius}
          scaleBandInnerRadius={scaleBandInnerRadius}
          midiMappingBandInnerRadius={midiMappingBandInnerRadius}
          midiMappingBandOuterRadius={midiMappingBandOuterRadius}
          startRadians={startRadians[selectedFlatIndex]}
          widthRadians={widthRadians[selectedFlatIndex]}
          wheelColor={wheelColor}
          mapping={getScaleDegree(selectedFlatIndex)?.map}
          role={getScaleDegree(selectedFlatIndex)?.role}
          isSelected={true}
          isHighlighted={
            !!highlightedIndexes.find(
              (i) =>
                i.pcIdx === selectedIndex!.pcIdx &&
                i.stringIdx === selectedIndex!.stringIdx
            )
          }
          isAnythingMapped={scale.length > 0}
          eventHandlers={bindScaleDragRotate(selectedFlatIndex)}
          onClick={
            onSoundIndex &&
            (() => onSoundIndex(selectedIndex!.stringIdx, selectedIndex!.pcIdx))
          }
        />
      )}
      <g ref={scaleBandGuideRef} className="tuningSystemWheel--scaleBandGuide">
        {flatPitches.map(
          (p, flatIndex) =>
            (p.stringIdx !== selectedIndex?.stringIdx ||
              p.pcIdx !== selectedIndex?.pcIdx) && (
              <TuningSystemWheelScaleBand
                key={flatIndex}
                outerRadius={outerRadius}
                scaleBandInnerRadius={scaleBandInnerRadius}
                midiMappingBandInnerRadius={midiMappingBandInnerRadius}
                midiMappingBandOuterRadius={midiMappingBandOuterRadius}
                mapping={getScaleDegree(flatIndex)?.map}
                role={getScaleDegree(flatIndex)?.role}
                startRadians={startRadians[flatIndex]}
                widthRadians={widthRadians[flatIndex]}
                wheelColor={wheelColor}
                isSelected={false}
                isHighlighted={false}
                isAnythingMapped={scale.length > 0}
                eventHandlers={bindScaleDragRotate(flatIndex)}
                onClick={
                  onSoundIndex && (() => onSoundIndex(p.stringIdx, p.pcIdx))
                }
              />
            )
        )}
        {selectedFlatIndex >= 0 && (
          /* Render selected spoke separately so its borders are on top of other ones and it can be highlighted */
          <TuningSystemWheelScaleBand
            outerRadius={outerRadius}
            scaleBandInnerRadius={scaleBandInnerRadius}
            midiMappingBandInnerRadius={midiMappingBandInnerRadius}
            midiMappingBandOuterRadius={midiMappingBandOuterRadius}
            startRadians={startRadians[selectedFlatIndex]}
            widthRadians={widthRadians[selectedFlatIndex]}
            wheelColor={wheelColor}
            mapping={getScaleDegree(selectedFlatIndex)?.map}
            role={getScaleDegree(selectedFlatIndex)?.role}
            isSelected={true}
            isHighlighted={false}
            isAnythingMapped={scale.length > 0}
            eventHandlers={bindScaleDragRotate(selectedFlatIndex)}
            onClick={
              onSoundIndex &&
              (() =>
                onSoundIndex(selectedIndex!.stringIdx, selectedIndex!.pcIdx))
            }
          />
        )}
      </g>
      {flatPitches.map((div, flatIdx) => (
        <TuningSystemWheelDivLabels
          key={flatIdx}
          innerRadius={innerRadius}
          division={div.pitch}
          startRadians={startRadians[flatIdx]}
          widthRadians={widthRadians[flatIdx]}
          pitchFormat={pitchFormat}
        />
      ))}
      <g
        ref={scaleLabelGuideRef}
        className="tuningSystemWheel--scaleLabelGuide"
      >
        {flatPitches.map((div, flatIdx) => {
          let sd = getScaleDegree(flatIdx);
          return (
            <TuningSystemWheelScaleLabels
              key={flatIdx}
              outerRadius={outerRadius}
              mapping={sd?.map}
              role={sd?.role}
              centsDeviation={
                sd ? getCentsDeviation(sd, scale, flatPitches) : undefined
              }
              label={
                solmization === "english"
                  ? ""
                  : getScaleDegreeSolmization(
                      flatIdx,
                      SCALE_DEGREE_NAMES_ENGLISH
                    )
              }
              solmizationLabel={getScaleDegreeSolmization(flatIdx)}
              startRadians={startRadians[flatIdx]}
              widthRadians={widthRadians[flatIdx]}
              isHighlighted={false}
            />
          );
        })}
      </g>
      {flatPitches.map((div, flatIdx) => {
        let sd = getScaleDegree(flatIdx);
        return (
          <TuningSystemWheelScaleLabels
            key={flatIdx}
            outerRadius={outerRadius}
            mapping={sd?.map}
            role={sd?.role}
            centsDeviation={
              sd ? getCentsDeviation(sd, scale, flatPitches) : undefined
            }
            label={
              solmization === "english"
                ? ""
                : getScaleDegreeSolmization(flatIdx, SCALE_DEGREE_NAMES_ENGLISH)
            }
            solmizationLabel={getScaleDegreeSolmization(flatIdx)}
            startRadians={startRadians[flatIdx]}
            widthRadians={widthRadians[flatIdx]}
            isHighlighted={
              !!highlightedIndexes.find(
                (i) => i.pcIdx === div.pcIdx && i.stringIdx === div.stringIdx
              )
            }
          />
        );
      })}
      {scale.length > 0 && (
        <>
          <a.line
            ref={tonicMarkerDragGuideLineRef}
            x1={to([tonicRadians, innerRadius], getTonicMarkerInnerX)}
            y1={to([tonicRadians, innerRadius], getTonicMarkerInnerY)}
            x2={to([tonicRadians, outerRadius], getTonicMarkerOuterX)}
            y2={to([tonicRadians, outerRadius], getTonicMarkerOuterY)}
            className="tuningSystemWheel--tonicMarkerDragGuideLine"
          />
          <a.line
            x1={to([tonicRadians, innerRadius], getTonicMarkerInnerX)}
            y1={to([tonicRadians, innerRadius], getTonicMarkerInnerY)}
            x2={to([tonicRadians, outerRadius], getTonicMarkerOuterX)}
            y2={to([tonicRadians, outerRadius], getTonicMarkerOuterY)}
            className="tuningSystemWheel--tonicMarkerLine"
          />
          <a.circle
            cx={to([tonicRadians, innerRadius], getTonicMarkerInnerX)}
            cy={to([tonicRadians, innerRadius], getTonicMarkerInnerY)}
            r={tonicMarkerHandleRadius}
            className="tuningSystemWheel--tonicMarkerHandle"
            {...bindTonicDragRotate()}
            data-tip="Drag to move scale root"
          />
          <a.path
            d={to([tonicRadians, innerRadius], getTonicPlayIconPath)}
            className="tuningSystemWheel--tonicMarkerPlayIcon"
            onClick={onSoundScale}
            data-tip="Play primary intervals of scale"
          />
        </>
      )}
    </a.g>
  );
};

function getTonicMarkerInnerX(tonicRadians: number, innerRadius: number) {
  return Math.cos(tonicRadians) * (innerRadius - 30);
}

function getTonicMarkerInnerY(tonicRadians: number, innerRadius: number) {
  return Math.sin(tonicRadians) * (innerRadius - 30);
}

function getTonicMarkerOuterX(tonicRadians: number, outerRadius: number) {
  return Math.cos(tonicRadians) * (outerRadius + 30);
}

function getTonicMarkerOuterY(tonicRadians: number, outerRadius: number) {
  return Math.sin(tonicRadians) * (outerRadius + 30);
}

function getTonicPlayIconX(tonicRadians: number, innerRadius: number) {
  return Math.cos(tonicRadians) * (innerRadius - 60);
}

function getTonicPlayIconY(tonicRadians: number, innerRadius: number) {
  return Math.sin(tonicRadians) * (innerRadius - 60);
}

function getTonicPlayIconPath(tonicRadians: number, innerRadius: number) {
  let centerX = getTonicPlayIconX(tonicRadians, innerRadius);
  let centerY = getTonicPlayIconY(tonicRadians, innerRadius);
  let iconWidth = 15;
  let iconHeight = 18;
  return `
    M${centerX - iconWidth / 2} ${centerY - iconHeight / 2}
    L${centerX - iconWidth / 2} ${centerY + iconHeight / 2}
    L${centerX + iconWidth / 2} ${centerY}
    Z
  `;
}

function circAngle(x: number, y: number) {
  return wrapAngle(Math.atan2(y, x));
}

function wrapAngle(angle: number) {
  if (angle < 0) {
    angle += Math.PI * 2;
  }
  if (angle > Math.PI * 2) {
    angle -= Math.PI * 2;
  }
  return angle;
}

interface TuningSystemWheelDivProps {
  outerRadius: SpringValue<number>;
  innerRadius: SpringValue<number>;
  startRadians: SpringValue<number>;
  widthRadians: SpringValue<number>;
  wheelColor: SpringValue<string>;
  isRefPitchRoot: boolean;
  isSelected: boolean;
  onClick?: () => void;
}
const TuningSystemWheelDiv: React.FC<TuningSystemWheelDivProps> = ({
  outerRadius,
  innerRadius,
  startRadians,
  widthRadians,
  wheelColor,
  isRefPitchRoot,
  isSelected,
  onClick,
}) => {
  return (
    <g
      onClick={onClick}
      data-tip="Click to define a keyboard mapping for this division"
    >
      <a.path
        className={classNames("tuningSystemWheel--div", {
          isRefPitchRoot,
          isSelected,
        })}
        style={{ fill: wheelColor }}
        d={to(
          [startRadians, widthRadians, outerRadius, innerRadius],
          (start, width, outer, inner) =>
            getSpokePath(start, width, inner, outer)
        )}
      />
    </g>
  );
};

interface TuningSystemWheelScaleBandProps {
  outerRadius: SpringValue<number>;
  scaleBandInnerRadius: SpringValue<number>;
  midiMappingBandOuterRadius: SpringValue<number>;
  midiMappingBandInnerRadius: SpringValue<number>;
  wheelColor: SpringValue<string>;
  mapping?: number | null;
  role?: string;
  startRadians: SpringValue<number>;
  widthRadians: SpringValue<number>;
  isSelected: boolean;
  isHighlighted: boolean;
  isAnythingMapped: boolean;
  onClick?: () => void;
  eventHandlers: ReactEventHandlers;
}
const TuningSystemWheelScaleBand: React.FC<TuningSystemWheelScaleBandProps> = ({
  outerRadius,
  scaleBandInnerRadius,
  midiMappingBandOuterRadius,
  midiMappingBandInnerRadius,
  mapping,
  role,
  startRadians,
  widthRadians,
  wheelColor,
  isSelected,
  isHighlighted,
  isAnythingMapped,
  onClick,
  eventHandlers,
}) => {
  return (
    <g
      {...eventHandlers}
      onClick={onClick}
      data-tip={`Click to play this division${
        isAnythingMapped ? ". Drag to rotate scale." : ""
      }`}
    >
      <a.path
        className={classNames(
          "tuningSystemWheel--scaleBand",
          role && `mapping${startCase(role)}`,
          {
            isSelected,
            isHighlighted,
          }
        )}
        style={{ fill: !isSelected && !isHighlighted ? wheelColor : undefined }}
        d={to(
          [startRadians, widthRadians, outerRadius, scaleBandInnerRadius],
          (start, width, outer, bandInner) =>
            getSpokePath(start, width, bandInner, outer)
        )}
      />
      {isNumber(mapping) && (
        <a.path
          className={classNames("tuningSystemWheel--midiMappingBand", {
            isAccidental: isAccidental(mapping),
          })}
          d={to(
            [
              startRadians,
              widthRadians,
              midiMappingBandInnerRadius,
              midiMappingBandOuterRadius,
            ],
            (start, width, midiInner, midiOuter) =>
              getSpokePath(start, width, midiInner, midiOuter)
          )}
        />
      )}
    </g>
  );
};

function getSpokePath(
  startAngle: number,
  angleWidth: number,
  innerRadius: number,
  outerRadius: number
) {
  let largeArcFlag = angleWidth > Math.PI ? 1 : 0;

  let cosStart = Math.cos(startAngle);
  let sinStart = Math.sin(startAngle);
  let cosEnd = Math.cos(startAngle + angleWidth);
  let sinEnd = Math.sin(startAngle + angleWidth);
  return `
    M${cosStart * innerRadius} ${sinStart * innerRadius}
    L${cosStart * outerRadius} ${sinStart * outerRadius}
    A${outerRadius} ${outerRadius} 0 ${largeArcFlag} 1 ${
    cosEnd * outerRadius
  } ${sinEnd * outerRadius}
    L${cosEnd * innerRadius} ${sinEnd * innerRadius}
    A${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${
    cosStart * innerRadius
  } ${sinStart * innerRadius}
  `;
}

interface TuningSystemWheelDivLabelsProps {
  innerRadius: SpringValue<number>;
  division: Pitch;
  startRadians: SpringValue<number>;
  widthRadians: SpringValue<number>;
  pitchFormat: "cents" | "ratio";
}
const TuningSystemWheelDivLabels: React.FC<TuningSystemWheelDivLabelsProps> = ({
  innerRadius,
  division,
  startRadians,
  widthRadians,
  pitchFormat,
}) => {
  let middleRadians = to(
    [startRadians, widthRadians],
    (start, width) => start + width / 2
  );
  let sinMiddle = middleRadians.to((middle) => Math.sin(middle));
  let cosMiddle = middleRadians.to((middle) => Math.cos(middle));
  let labelAngle = middleRadians.to(
    (middle) => (middle / (2 * Math.PI)) * 360 + 90
  );
  let divisionLabelRadiusOffset = 45;

  let [numerator, denominator] =
    isNumber(division.ratioUpper) && isNumber(division.ratioLower)
      ? reduceRatio(division.ratioUpper, division.ratioLower)
      : [];

  return (
    <g>
      {pitchFormat === "cents" ? (
        <a.text
          className="tuningSystemWheel--divisionLabel"
          x={to(
            [cosMiddle, innerRadius],
            (cos, inner) => cos * (inner + divisionLabelRadiusOffset)
          )}
          y={to(
            [sinMiddle, innerRadius],
            (sin, inner) => sin * (inner + divisionLabelRadiusOffset) + 25
          )}
          textAnchor="middle"
          transform={to(
            [labelAngle, cosMiddle, sinMiddle, innerRadius],
            (angle, cos, sin, inner) =>
              `rotate(${angle}, ${cos * (inner + divisionLabelRadiusOffset)}, ${
                sin * (inner + divisionLabelRadiusOffset)
              })`
          )}
        >
          {formatPitch(division, pitchFormat)}
        </a.text>
      ) : (
        <>
          <a.text
            className="tuningSystemWheel--divisionLabel"
            x={to(
              [cosMiddle, innerRadius],
              (cos, inner) => cos * (inner + divisionLabelRadiusOffset)
            )}
            y={to(
              [sinMiddle, innerRadius],
              (sin, inner) => sin * (inner + divisionLabelRadiusOffset) + 25 - 6
            )}
            textAnchor="middle"
            transform={to(
              [labelAngle, cosMiddle, sinMiddle, innerRadius],
              (angle, cos, sin, inner) =>
                `rotate(${angle}, ${
                  cos * (inner + divisionLabelRadiusOffset)
                }, ${sin * (inner + divisionLabelRadiusOffset)})`
            )}
          >
            {isNumber(numerator) ? numerator : ""}
          </a.text>
          <a.text
            className="tuningSystemWheel--divisionLabel"
            x={to(
              [cosMiddle, innerRadius],
              (cos, inner) => cos * (inner + divisionLabelRadiusOffset)
            )}
            y={to(
              [sinMiddle, innerRadius],
              (sin, inner) => sin * (inner + divisionLabelRadiusOffset) + 25 + 6
            )}
            textAnchor="middle"
            transform={to(
              [labelAngle, cosMiddle, sinMiddle, innerRadius],
              (angle, cos, sin, inner) =>
                `rotate(${angle}, ${
                  cos * (inner + divisionLabelRadiusOffset)
                }, ${sin * (inner + divisionLabelRadiusOffset)})`
            )}
          >
            {isNumber(denominator) ? denominator : ""}
          </a.text>
        </>
      )}
    </g>
  );
};

interface TuningSystemWheelScaleLabelsProps {
  outerRadius: SpringValue<number>;
  mapping?: number | null;
  centsDeviation?: string;
  label?: string;
  solmizationLabel?: string;
  role?: string;
  startRadians: SpringValue<number>;
  widthRadians: SpringValue<number>;
  isHighlighted: boolean;
}
const TuningSystemWheelScaleLabels: React.FC<TuningSystemWheelScaleLabelsProps> = ({
  outerRadius,
  mapping,
  centsDeviation,
  label,
  solmizationLabel,
  role,
  startRadians,
  widthRadians,
  isHighlighted,
}) => {
  let middleRadians = to(
    [startRadians, widthRadians],
    (start, width) => start + width / 2
  );
  let sinMiddle = middleRadians.to((middle) => Math.sin(middle));
  let cosMiddle = middleRadians.to((middle) => Math.cos(middle));
  let labelAngle = middleRadians.to(
    (middle) => (middle / (2 * Math.PI)) * 360 + 90
  );
  let solmizationLabelRadius = outerRadius.to((outer) => outer - 65);
  let midiMappingLabelRadius = outerRadius.to((outer) => outer);

  return (
    <g>
      {isNumber(mapping) && (
        <>
          <a.text
            className={classNames(
              "tuningSystemWheel--solmizationLabel",
              role && `solmizationLabel${startCase(role)}`,
              {
                isHighlighted,
              }
            )}
            x={to(
              [cosMiddle, solmizationLabelRadius],
              (cos, radius) => cos * radius
            )}
            y={to(
              [sinMiddle, solmizationLabelRadius],
              (sin, radius) => sin * radius - 15
            )}
            textAnchor="middle"
            transform={to(
              [labelAngle, cosMiddle, sinMiddle, solmizationLabelRadius],
              (angle, cos, sin, radius) =>
                `rotate(${angle}, ${cos * radius}, ${sin * radius})`
            )}
          >
            {solmizationLabel}
          </a.text>
          <a.text
            className={classNames("tuningSystemWheel--midiMappingLabel", {
              isHighlighted,
            })}
            x={to(
              [cosMiddle, midiMappingLabelRadius],
              (cos, radius) => cos * radius + 15
            )}
            y={to(
              [sinMiddle, midiMappingLabelRadius],
              (sin, radius) => sin * radius
            )}
            alignmentBaseline="middle"
            transform={to(
              [labelAngle, cosMiddle, sinMiddle, midiMappingLabelRadius],
              (angle, cos, sin, radius) =>
                `rotate(${angle - 90}, ${cos * radius}, ${sin * radius})`
            )}
          >
            {label} {centsDeviation && <>{centsDeviation}¢</>}
          </a.text>
        </>
      )}
    </g>
  );
};

function getCentsDeviation(
  scaleDegree: ScaleDegree,
  scale: ScaleDegree[],
  flatPitches: { stringIdx: number; pcIdx: number; pitch: Pitch }[]
) {
  let rootPitch = flatPitches.find((p) => getPitchCents(p.pitch) === 0);
  let rootSd = scale.find(
    (s) =>
      rootPitch?.stringIdx === s.stringIndex &&
      rootPitch.pcIdx === s.pitchClassIndex
  );
  let pitch = flatPitches.find(
    (p) =>
      p.stringIdx === scaleDegree.stringIndex &&
      p.pcIdx === scaleDegree.pitchClassIndex
  )!.pitch;
  if (isNumber(scaleDegree.map) && rootSd && isNumber(rootSd.map)) {
    let pc = SCALE_DEGREE_KEYBOARD_MAPPING.get(scaleDegree.map)!;
    let rootPc = SCALE_DEGREE_KEYBOARD_MAPPING.get(rootSd.map)!;
    if (pc < rootPc) {
      pc += 12;
    }
    let semitoneDifference = pc - rootPc;
    let tet12CentsUp = semitoneDifference * 100;
    let actualCents = getPitchCents(pitch);
    let diff = actualCents - tet12CentsUp;
    if (diff === 0) {
      return "±0";
    } else if (diff > 0) {
      return `+${round(diff)}`;
    } else {
      return `${round(diff)}`;
    }
  } else {
    return "";
  }
}
