import React, { useEffect, useState } from "react";
import classNames from "classnames";
import WebMidi, { Output } from "webmidi";
import { includes, isEqual } from "lodash";

import { ToggleSwitch } from "./ToggleSwitch";

import "./SettingsDialog.scss";
interface SettingsDialogProps {
  isOpen: boolean;
  midiClockOutputs: Output[];
  onSetMidiClockOutputs: (newoutputs: Output[]) => void;
  onClose: () => void;
}
export const SettingsDialog: React.FC<SettingsDialogProps> = ({
  isOpen,
  midiClockOutputs,
  onSetMidiClockOutputs,
  onClose,
}) => {
  let [midiState, setMidiState] = useState<"init" | "error" | "active">(
    WebMidi.enabled ? "active" : "init"
  );
  let [clockOutputOptions, setClockOutputOptions] = useState<Output[]>();

  useEffect(() => {
    if (midiState === "init") {
      WebMidi.enable((err) => {
        setMidiState(err ? "error" : "active");
      });
    } else if (midiState === "active") {
      let updateOutputs = () => {
        setClockOutputOptions(Array.from(WebMidi.outputs));
      };
      updateOutputs();
      WebMidi.addListener("connected", updateOutputs);
      WebMidi.addListener("disconnected", updateOutputs);
      return () => {
        WebMidi.removeListener("connected", updateOutputs);
        WebMidi.removeListener("disconnected", updateOutputs);
      };
    }
  }, [midiState]);

  useEffect(() => {
    if (!clockOutputOptions) return;
    let availableOutputs = midiClockOutputs.filter((o) =>
      includes(clockOutputOptions, o)
    );
    if (!isEqual(availableOutputs, midiClockOutputs)) {
      onSetMidiClockOutputs(availableOutputs);
    }
  }, [clockOutputOptions, midiClockOutputs, onSetMidiClockOutputs]);

  return (
    <div
      className={classNames("settingsDialogWrapper", { isOpen })}
      onClick={(evt) => evt.target === evt.currentTarget && onClose()}
    >
      <div className="settingsDialog">
        <h2>Settings</h2>
        <h3>MIDI Clock Out</h3>
        <p>Send MIDI clock to</p>
        {clockOutputOptions?.map((output) => (
          <div key={output.id}>
            <label>
              <ToggleSwitch
                checked={includes(midiClockOutputs, output)}
                onToggle={(on) =>
                  onSetMidiClockOutputs(
                    on
                      ? [...midiClockOutputs, output]
                      : midiClockOutputs.filter((o) => o !== output)
                  )
                }
              />
              {output.name}
            </label>
          </div>
        ))}
        <div className="settingsDialog--actions">
          <button className="button isPrimary" onClick={onClose}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
};
