import { range, first, last } from 'lodash';
import { Frequency } from "tone";
import { Solmization, ScaleDegreeRole } from "./main/core";


export const MIN_REF_PITCH_MIDI = 21;
export const MAX_REF_PITCH_MIDI = 108;
export const REF_PITCHES: [string, number][] = range(MIN_REF_PITCH_MIDI, MAX_REF_PITCH_MIDI + 1).map(midi => {
    let freq = Frequency(midi, 'midi');
    return [freq.toNote(), freq.toFrequency()];
});

export const MIN_NOTE = Frequency(first(REF_PITCHES)![1]).toMidi();
export const MAX_NOTE = Frequency(last(REF_PITCHES)![1]).toMidi();


export const TUNING_SYSTEM_CATEGORIES = [
    "African",
    "Arabic",
    "Chinese",
    "Columbian",
    "Greek",
    "Indian",
    "Indonesian",
    "Japanese",
    "Mexican",
    "Persian",
    "Peruvian",
    "Singaporean",
    "Spanish",
    "Thai",
    "Turkish",
    "User",
    "Western Experimental",
    "Western Historical"
];

export const SCALE_DEGREE_ROLES: ScaleDegreeRole[] = ["tonic", "primary", "secondary"];

export const SCALE_DEGREE_NAMES_ENGLISH = [
    "C",
    "C#",
    "Db",
    "D",
    "D#",
    "Eb",
    "E",
    "F",
    "F#",
    "Gb",
    "G",
    "G#",
    "Ab",
    "A",
    "A#",
    "Bb",
    "B"
];

export const SCALE_DEGREE_NAMES_SOLFEGE = [
    "Do",
    "Do #",
    "Re b",
    "Re",
    "Re #",
    "Mi b",
    "Mi",
    "Fa",
    "Fa #",
    "Sol b",
    "Sol",
    "Sol #",
    "La b",
    "La",
    "La #",
    "Si b",
    "Si"
];
export const SCALE_DEGREE_NAMES_NORTH_INDIAN = [
    "Sa",
    "Re -",
    "Re -",
    "Re",
    "Gā -",
    "Gā -",
    "Gā",
    "Mā",
    "Mā +",
    "Mā +",
    "Pā",
    "Dhā -",
    "Dhā -",
    "Dhā",
    "Nī -",
    "Nī -",
    "Nī"
];
export const SCALE_DEGREE_NAMES_SOUTH_INDIAN = [
    "Sa",
    "Ri -",
    "Ri -",
    "Ri",
    "Ri +",
    "Gā -",
    "Gā",
    "Mā",
    "Mā +",
    "Mā +",
    "Pa",
    "Dhā -",
    "Dhā -",
    "Dha",
    "Dha +",
    "Ni -",
    "Ni"
];
export const SCALE_DEGREE_NAMES_GERMAN = [
    "C",
    "Cis",
    "Des",
    "D",
    "Dis",
    "Es",
    "E",
    "F",
    "Fis",
    "Ges",
    "G",
    "Gis",
    "As",
    "A",
    "Ais",
    "B",
    "H"
];
export const SCALE_DEGREE_NAMES_DUTCH = [
    "C",
    "Cis",
    "Des",
    "D",
    "Dis",
    "Es",
    "E",
    "F",
    "Fis",
    "Ges",
    "G",
    "Gis",
    "As",
    "A",
    "Ais",
    "Bes",
    "B"
];
export const SCALE_DEGREE_NAMES_JAPANESE = [
    "Ha",
    "Ei-ha",
    "Hen-ni",
    "Ni",
    "Ei-ni",
    "Hen-ho",
    "Ho",
    "He",
    "Ei-he",
    "Hen-to",
    "To",
    "Ei-to",
    "Hen-i",
    "I",
    "Ei-i",
    "Hen-ro",
    "Ro"
];

export const SCALE_DEGREE_NAMES_JAVANESE = [
    "Ji",
    "Ji +",
    "Ji + ",
    "Ro",
    "Ro +",
    "Ro +",
    "Lu",
    "Pat",
    "Pat +",
    "Pat +",
    "Ma",
    "Ma +",
    "Ma +",
    "Nem",
    "Nem +",
    "Nem +",
    "Pi"
];

export const SCALE_DEGREE_NAMES_BYZANTINE = [
    "Ni",
    "Ni #",
    "Pa b",
    "Pa",
    "Pa #",
    "Vu b",
    "Vu",
    "Ga",
    "Ga #",
    "Di b",
    "Di",
    "Di #",
    "Ke b",
    "Ke",
    "Ke #",
    "Zo b",
    "Zo"
];

export const SCALE_DEGREE_NAMES = {
    english: SCALE_DEGREE_NAMES_ENGLISH,
    solfege: SCALE_DEGREE_NAMES_SOLFEGE,
    northIndian: SCALE_DEGREE_NAMES_NORTH_INDIAN,
    southIndian: SCALE_DEGREE_NAMES_SOUTH_INDIAN,
    german: SCALE_DEGREE_NAMES_GERMAN,
    dutch: SCALE_DEGREE_NAMES_DUTCH,
    japanese: SCALE_DEGREE_NAMES_JAPANESE,
    javanese: SCALE_DEGREE_NAMES_JAVANESE,
    byzantine: SCALE_DEGREE_NAMES_BYZANTINE
} as { [k in Solmization]: string[] };

export const MIN_OCTAVE = 0;
export const MAX_OCTAVE = 8;

export const MIDI_CHANNEL_ROUND_ROBIN_MIN = 1;
export const MIDI_CHANNEL_ROUND_ROBIN_MAX = 16;

export const MIDI_CHANNEL_MPE_CONTROL = 'all';
export const MIDI_CHANNEL_MPE_ROUND_ROBIN_MIN = 2;
export const MIDI_CHANNEL_MPE_ROUND_ROBIN_MAX = 16;
