import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { CountryDropdown } from "react-country-region-selector";

import "./UserProfileDialog.scss";
import { useUserProfile } from "../useUserProfile";
import { UserProfile } from "../main/core";

interface UserProfileDialogProps {
  isOpen: boolean;
  onClose: () => void;
}
export const UserProfileDialog: React.FC<UserProfileDialogProps> = ({
  isOpen,
  onClose,
}) => {
  let { user } = useAuth0();
  let [isForced, setIsForced] = useState(false);
  let { profile, updateProfile, loading } = useUserProfile();
  let [localProfile, setLocalProfile] = useState<UserProfile>();

  useEffect(() => {
    if (loading) return;
    if (profile) {
      setLocalProfile(profile);
      setIsForced(false);
    } else if (user) {
      setLocalProfile({ username: "" });
      setIsForced(true);
    }
  }, [user, loading, profile]);

  let onSave = async () => {
    await updateProfile(localProfile!);
    setIsForced(false);
    onClose();
  };

  return (
    <div
      className={classNames("userProfileDialogWrapper", {
        isOpen: isOpen || isForced,
      })}
      onClick={(evt) =>
        !isForced && evt.target === evt.currentTarget && onClose()
      }
      onKeyPress={(evt) => evt.nativeEvent.stopImmediatePropagation()}
    >
      {localProfile && (
        <div className="userProfileDialog">
          <h2>{isForced ? <>Finish signup</> : <>My Profile</>}</h2>
          <div className="userProfileDialog--field">
            <label>Email address</label>
            <div className="readOnlyInput">{user?.email}</div>
          </div>
          <div className="userProfileDialog--field">
            <label htmlFor="profileUsername">
              Username
              <span className="userProfileDialog--requiredMarker">*</span>
            </label>
            <input
              id="profileUsername"
              className="input"
              value={localProfile.username}
              onChange={(evt) =>
                setLocalProfile({ ...localProfile, username: evt.target.value })
              }
            />
          </div>
          <div className="userProfileDialog--field">
            <label htmlFor="profileTwitter">Twitter handle</label>
            <input
              id="profileTwitter"
              className="input"
              value={localProfile.twitterHandle || ""}
              onChange={(evt) =>
                setLocalProfile({
                  ...localProfile!,
                  twitterHandle: evt.target.value,
                })
              }
            />
          </div>
          <div className="userProfileDialog--inputConsentPrompt">
            By entering your Twitter handle you're giving us permission to
            display it publicly.
          </div>
          <div className="userProfileDialog--field">
            <label htmlFor="profileCountry">Country</label>
            <CountryDropdown
              id="profileCountry"
              classes="select"
              value={localProfile.country || ""}
              onChange={(country) =>
                setLocalProfile({ ...localProfile!, country })
              }
            />
          </div>
          <div className="userProfileDialog--inputConsentPrompt">
            By entering a country you're giving us permission to display it
            publicly.
          </div>
          {isForced && (
            <div className="userProfileDialog--consentPrompt">
              By signing up you consent to receiving updates and information
              about Apotome and Leimma by email.
            </div>
          )}
          <div className="userProfileDialog--actions">
            {!isForced && (
              <button className="button" onClick={onClose}>
                Cancel
              </button>
            )}
            <button
              className="button"
              onClick={onSave}
              disabled={localProfile.username.trim().length === 0}
            >
              {isForced ? <>Finish signup</> : <>Save and close</>}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
