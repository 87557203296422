import React from "react";

import { ScaleDegreeRole } from "./main/core";
import { SCALE_DEGREE_ROLES } from "./constants";

import "./ScaleDegreeRoleSelection.scss";
import { startCase } from "lodash";

interface ScaleDegreeRoleSelectionProps {
  currentRole?: ScaleDegreeRole;
  isMapped: boolean;
  onChangeRole: (role: ScaleDegreeRole) => void;
}
export const ScaleDegreeRoleSelection: React.FC<ScaleDegreeRoleSelectionProps> = ({
  currentRole,
  isMapped,
  onChangeRole,
}) => {
  return (
    <select
      className="scaleDegreeRoleSelection small"
      value={currentRole}
      onChange={(evt) => onChangeRole(evt.target.value as ScaleDegreeRole)}
    >
      {SCALE_DEGREE_ROLES.map((role) => (
        <option
          key={role}
          value={role}
          disabled={!isMapped || currentRole === "tonic"}
        >
          {getRoleLabel(role)}
        </option>
      ))}
    </select>
  );
};

function getRoleLabel(role: string) {
  if (role === 'tonic') {
    return 'Root';
  } else {
    return startCase(role);
  }
}