import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import "./CalibrateMIDIOutputDialog.scss";
import { MIDIOutput } from "./main/core";
import { context, Frequency } from "tone";
import { getMIDIPitchBend } from "./main/audio";
import { NumberInput } from "./main/NumberInput";
import {
  MIDI_CHANNEL_MPE_ROUND_ROBIN_MAX,
  MIDI_CHANNEL_MPE_ROUND_ROBIN_MIN,
  MIDI_CHANNEL_ROUND_ROBIN_MAX,
  MIDI_CHANNEL_ROUND_ROBIN_MIN,
} from "./constants";

interface CalibrateMIDIOutputDialogProps {
  isOpen: boolean;
  productName: string;
  output: MIDIOutput;
  onUpdatePitchBendRange: (range: number) => void;
  onClose: () => void;
}
export const CalibrateMIDIOutputDialog: React.FC<CalibrateMIDIOutputDialogProps> = ({
  isOpen,
  productName,
  output,
  onUpdatePitchBendRange,
  onClose,
}) => {
  let [looping, setLooping] = useState(false);
  let point1Ref = useRef<HTMLDivElement>(null);
  let point2Ref = useRef<HTMLDivElement>(null);

  let doClose = useCallback(() => {
    setLooping(false);
    onClose();
  }, [onClose]);

  let latestOutput = useRef<MIDIOutput>(output);
  useEffect(() => {
    latestOutput.current = output;
  }, [output]);
  useEffect(() => {
    if (!looping) return;

    let i = 0,
      noteDur = 0.5,
      point1El = point1Ref.current!,
      point2El = point2Ref.current!;

    let nextRr = MIDI_CHANNEL_ROUND_ROBIN_MIN;
    let getNextChannel = (output: MIDIOutput) => {
      if (output.channel === "all" || output.channel === "mpe") {
        let min =
          output.channel === "all"
            ? MIDI_CHANNEL_ROUND_ROBIN_MIN
            : MIDI_CHANNEL_MPE_ROUND_ROBIN_MIN;
        let max =
          output.channel === "all"
            ? MIDI_CHANNEL_ROUND_ROBIN_MAX
            : MIDI_CHANNEL_MPE_ROUND_ROBIN_MAX;
        if (nextRr < min) {
          nextRr = min;
        }
        let rr = nextRr;
        nextRr++;
        if (nextRr > max) {
          nextRr = min;
        }
        return rr;
      } else {
        return output.channel;
      }
    };

    let intr = setInterval(() => {
      let o = latestOutput.current!;
      let time = context.now();
      let channel = getNextChannel(o);
      let midiNoteTime = `+${(time - context.immediate()) * 1000}`;
      if (i++ % 2 === 0) {
        let midiNote = Frequency("C4").toMidi();
        o.output!.sendPitchBend(0, channel, {
          time: midiNoteTime,
        });
        o.output!.playNote(midiNote, channel, {
          duration: noteDur * 1000 - 10,
          velocity: 0.5,
          time: midiNoteTime,
        });
        point1El.classList.add("on");
        point2El.classList.remove("on");
      } else {
        let midiNote = Frequency("C#4").toMidi();
        let targetFreq = Frequency("C4").toFrequency();
        let pitchBend = getMIDIPitchBend(
          targetFreq,
          midiNote,
          o.pitchBendRangeCents
        );
        o.output!.playNote(midiNote, channel, {
          duration: noteDur * 1000 - 10,
          velocity: 0.5,
          time: midiNoteTime,
        });
        o.output!.sendPitchBend(pitchBend, channel, {
          time: midiNoteTime,
        });
        point1El.classList.remove("on");
        point2El.classList.add("on");
      }
    }, noteDur * 1000);

    return () => {
      clearInterval(intr);
      point1El.classList.remove("on");
      point2El.classList.remove("on");
    };
  }, [looping]);

  return (
    <div
      className={classNames("calibrateMIDIOutputDialogWrapper", { isOpen })}
      onClick={(evt) => evt.target === evt.currentTarget && doClose()}
    >
      <div className="calibrateMIDIOutputDialog">
        <h2>Calibrate MIDI Output</h2>
        <p>
          To achieve microtonal tunings over MIDI, {productName} sends out a
          Pitch Bend message on every note. It is important that {productName}{" "}
          and your MIDI instrument agree on the range of Pitch Bend messages.
        </p>
        <p>
          Use the following loop to adjust {productName}'s pitch bend range, so
          that the two notes you hear are in exact unison.
        </p>
        <div className="testLooper">
          {!looping && (
            <button
              className="button isPrimary"
              onClick={() => setLooping(true)}
            >
              Start Loop
            </button>
          )}
          {looping && (
            <button
              className="button isPrimary"
              onClick={() => setLooping(false)}
            >
              Stop Loop
            </button>
          )}
          <div
            className={classNames("testLooper--indicators", {
              isVisible: looping,
            })}
          >
            <div className="testLooper--point" ref={point1Ref}></div>
            <div className="testLooper--point" ref={point2Ref}></div>
          </div>
        </div>
        <div className="pitchInput">
          <label>
            Pitch bend range
            <input
              type="range"
              value={output.pitchBendRangeCents}
              min={0}
              max={4800}
              step={100}
              onChange={(evt) => onUpdatePitchBendRange(+evt.target.value)}
            />
          </label>
          <NumberInput
            className="input numberInput"
            value={Math.floor(output.pitchBendRangeCents / 100)}
            min={0}
            max={48}
            onChange={(newST) =>
              onUpdatePitchBendRange(
                newST * 100 + (output.pitchBendRangeCents % 100)
              )
            }
          />{" "}
          semitones
        </div>
        <div className="calibrateMIDIOutputDialog--actions">
          <button className="button isPrimary" onClick={doClose}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
};
