import React, { useMemo } from "react";
import { Admin, Resource, fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import {
  TuningSystemList,
  TuningSystemEdit,
  TuningSystemCreate,
} from "./TuningSystems";
import { ScaleList, ScaleEdit, ScaleCreate } from "./Scales";
import { useAuth0 } from "@auth0/auth0-react";

let makeHttpClient = (
  getAccessTokenSilently: (options?: any) => Promise<string>
) => {
  return async (url: string, options: any = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: "application/json" });
    }
    let accessToken = await getAccessTokenSilently();
    options.headers.set("Authorization", `Bearer ${accessToken}`);
    return fetchUtils.fetchJson(url, options);
  };
};

export const AdminApp: React.FC = () => {
  let { getAccessTokenSilently, user } = useAuth0();
  let httpClient = useMemo(() => makeHttpClient(getAccessTokenSilently), [
    getAccessTokenSilently,
  ]);

  return (
    <Admin
      dataProvider={simpleRestProvider("/.netlify/functions/api", httpClient)}
    >
      <Resource
        name="tuning_systems"
        list={TuningSystemList}
        edit={withUser(TuningSystemEdit, user)}
        create={withUser(TuningSystemCreate, user)}
      />
      <Resource
        name="scales"
        list={ScaleList}
        edit={withUser(ScaleEdit, user)}
        create={withUser(ScaleCreate, user)}
      />
    </Admin>
  );
};

const withUser = (BaseComponent: any, user: any) => (props: any) => (
  <BaseComponent {...props} user={user} />
);
