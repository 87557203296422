import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import Logo from "./images/logoLeimma.png";

import { formatRefPitch, formatStrings } from "./urlSerialization";

import "./NewOrExistingChoiceScreen.scss";
import { ChooseTuningSystemDialog } from "./ChooseTuningSystemDialog";
import { TuningSystem } from "./main/core";
import { UserInfo } from "./UserInfo";
import { LeimmaHelmet } from "./LeimmaHelmet";
import { ScreenSizeWarning } from "./ScreenSizeWarning";
import { UserGuideLink } from "./UserGuideLink";

export const NewOrExistingChoiceScreen: React.FC<RouteComponentProps> = () => {
  let history = useHistory();
  let [isExiting, setIsExiting] = useState(false);
  let [tuningSystemDialogOpen, setTuningSystemDialogOpen] = useState(false);

  let pickTuningSystem = async (ts: TuningSystem) => {
    setIsExiting(true);
    setTuningSystemDialogOpen(false);
    setTimeout(() => {
      history.push(
        `/leimma/${ts.id}/refpitch/${formatRefPitch(
          ts.refPitchNoteMidi,
          ts.refPitchNoteName
        )}/tuningsystem/${formatStrings(ts.strings)}`
      );
    }, 500);
  };

  let createNewTuningSystem = () => {
    setIsExiting(true);
    setTimeout(() => {
      history.push(`/leimma/new/refpitch/${formatRefPitch(60)}`);
    }, 500);
  };

  return (
    <>
      <LeimmaHelmet />
      <ScreenSizeWarning />
      <div
        className={classNames("newOrExistingChoiceScreen", "screen", {
          isExiting,
        })}
      >
        <div className="newOrExistingChoiceScreen--htmlOverlay">
          <img className="leimmaLogo" src={Logo} alt="Leimma" />
          <div className="newOrExistingChoiceScreen--credits">
            by{" "}
            <a
              href="https://khyamallami.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Khyam Allami
            </a>{" "}
            &{" "}
            <a href="https://ctpt.co" target="_blank" rel="noopener noreferrer">
              Counterpoint
            </a>
          </div>
          <div className="newOrExistingChoiceScreen--intro">
            Explore, create, hear and play microtonal tuning systems.
          </div>
          <div className="divisionSelector">
            <button
              className="button chooseExistingButton"
              onClick={() => setTuningSystemDialogOpen(true)}
            >
              Select a tuning system
            </button>{" "}
            or{" "}
            <button
              className="button createNewButton"
              onClick={createNewTuningSystem}
            >
              Create a new tuning system
            </button>
          </div>
        </div>
      </div>{" "}
      <ChooseTuningSystemDialog
        isOpen={tuningSystemDialogOpen}
        onClose={() => setTuningSystemDialogOpen(false)}
        onTuningSystemChosen={pickTuningSystem}
      />
      <UserGuideLink />
      <UserInfo />
    </>
  );
};
