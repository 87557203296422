import React, { useRef, useEffect, useState } from "react";
import * as Nexus from "nexusui";

import "./Slider.scss";

interface SliderProps {
  value: number;
  min: number;
  max: number;
  step: number;
  width?: number;
  onUpdateValue: (newValue: number) => void;
}
export const Slider: React.FC<SliderProps> = ({
  value,
  min,
  max,
  step,
  width = 120,
  onUpdateValue,
}) => {
  let sliderContainerRef = useRef<HTMLDivElement>(null);
  let [slider, setSlider] = useState<any>(null);

  let changingValue = useRef(false);
  useEffect(() => {
    let slider = new Nexus.Slider(sliderContainerRef.current, {
      size: [width, 20],
      min,
      max,
      step,
    });
    slider.on("change", (val: number) => {
      if (!changingValue.current) {
        onUpdateValue(val);
      }
    });
    setSlider(slider);
    return () => {
      slider.destroy();
      setSlider(null);
    };
  }, [width, min, max, step, onUpdateValue]);
  useEffect(() => {
    if (!slider) return;
    changingValue.current = true;
    slider.value = value;
    slider.min = min;
    slider.max = max;
    slider.step = step;
    changingValue.current = false;
  }, [value, min, max, step, slider]);

  return <div className="slider" ref={sliderContainerRef}></div>;
};
