import React from "react";
import { Helmet } from "react-helmet";

import "./NewHome.scss";

import ApotomeLogo from "./images/logoApotome.png";
import LeimmaLogo from "./images/logoLeimma.png";
import { Link } from "react-router-dom";

export const NewHome: React.FC = () => {
  return (
    <div className="newHome">
      <Helmet>
        <title>Leimma & Apotome</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://isartum.net/isartum-apple-touch-icon.png?v=QEGmboy6kP"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://isartum.net/isartum-favicon-32x32.png?v=QEGmboy6kP"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://isartum.net/isartum-favicon-16x16.png?v=QEGmboy6kP"
        />
        <link
          rel="manifest"
          href="https://isartum.net/isartum-site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="https://isartum.net/isartum-safari-pinned-tab.svg"
          color="#8870ff"
        />
        <meta name="msapplication-TileColor" content="#8870ff" />
        <meta name="theme-color" content="#8870ff" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@KhyamAllami" />
        <meta name="twitter:creator" content="@KhyamAllami" />
        <meta name="twitter:title" content="Leimma & Apotome" />
        <meta
          name="twitter:description"
          content="Explore, create, hear and play microtonal tuning systems."
        />
        <meta
          name="twitter:image"
          content="https://isartum.net/isartum-card-twitter.png"
        />

        <meta property="og:url" content="https://isartum.net" />
        <meta property="og:title" content="Leimma & Apotome" />
        <meta
          property="og:description"
          content="Explore, create, hear and play microtonal tuning systems."
        />
        <meta
          property="og:image"
          content="https://isartum.net/isartum-card-facebook.png"
        />
        <meta
          name="description"
          content="Explore, create, hear and play microtonal tuning systems."
        />
      </Helmet>
      <main className="main">
        <div className="leimma">
          <div className="logoWrapper">
            <img className="logo logoLeimma" src={LeimmaLogo} alt="Leimma" />
          </div>
          <p>
            A browser-based tool for exploring, creating, hearing, and playing
            microtonal tuning systems.
          </p>
          <div className="fauxButtons">
            <Link className="fauxButton" to="/leimma">
              Play
            </Link>
            <a className="fauxButton" href="https://vimeo.com/503475532">
              Watch tutorial
            </a>
          </div>
        </div>

        <div className="apotome">
          <div className="logoWrapper">
            <img className="logo logoApotome" src={ApotomeLogo} alt="Apotome" />
          </div>
          <p>
            A browser-based generative music environment based on
            octave-repeating microtonal tuning systems and their subsets
            (scales/modes).
          </p>
          <div className="fauxButtons">
            <Link className="fauxButton" to="/apotome">
              Play
            </Link>
            <a className="fauxButton" href="https://vimeo.com/503451447">
              Watch tutorial
            </a>
          </div>
        </div>

        <div className="ctm">
          <div className="logoWrapper">
            <h1>CTM 2021: Transformation</h1>
          </div>
          <p>
            Apotome featured in CTM Festival 2021, Jan 19 - March 14 with
            generative compositions, artist takeovers, a presentation, and a
            panel discussion.
          </p>
          <div className="fauxButtons">
            <a className="fauxButton" href="https://ctm.isartum.net">
              Watch the recordings
            </a>
          </div>
        </div>

        <div className="credits">
          <p>
            Created by <a href="https://khyamallami.com/">Khyam Allami</a> and{" "}
            <a href="https://ctpt.co/">Counterpoint</a>
          </p>
          <p>
            The development of Leimma and Apotome was funded via the{" "}
            <a href="https://www.midlands4cities.ac.uk/">
              AHRC Midlands4Cities Doctoral Training Partnership
            </a>{" "}
            with additional support from{" "}
            <a href="https://www.ctm-festival.de/">CTM Festival</a>.
          </p>
          <p>
            Contact: <a href="mailto:ka@khyamallami.com">ka@khyamallami.com</a>
          </p>
        </div>
      </main>
    </div>
  );
};
